import styles from "../../layout/assets/css/core.min.module.css";

import { Box, Button, TextField, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const SectionAntecedentesPerinatales = ({ formik, status }) => {
  return (
    <div className={`${styles.row} ${styles.colSmm12}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          Antecedentes perinatales{" "}
        </div>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            <div className={`${styles["my-2"]} ${styles["col-sm-12"]}`}>
              <Typography>Semanas o meses que duró el embarazo: </Typography>
              <TextField
                variant="standard"
                placeholder="Semanas, Meses"
                fullWidth
                id="duracionEmbarazo"
                name="duracionEmbarazo"
                value={formik.values.antecedentesPerinatales.duracionEmbarazo}
                onChange={(event) => {
                  formik.setFieldValue(
                    "antecedentesPerinatales.duracionEmbarazo",
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.duracionEmbarazo &&
                    Boolean(
                      formik.errors.antecedentesPerinatales?.duracionEmbarazo
                    )) ||
                  (status === "edit" &&
                    Boolean(
                      formik.errors.antecedentesPerinatales?.duracionEmbarazo
                    ))
                }
                helperText={
                  (formik.touched.duracionEmbarazo &&
                    formik.errors.antecedentesPerinatales?.duracionEmbarazo) ||
                  (status === "edit" &&
                    formik.errors.antecedentesPerinatales?.duracionEmbarazo)
                }
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Parto</Typography>
              <ToggleButtonGroup
                value={
                  formik.values.antecedentesPerinatales.parto
                    ? formik.values.antecedentesPerinatales.parto
                    : ""
                }
                exclusive
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "antecedentesPerinatales.parto",
                    value ? value : ""
                  );
                }}
                aria-label="Platform"
              >
                <ToggleButton value="Normal">Normal</ToggleButton>
                <ToggleButton value="Cesarea">Cesárea</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Lloró al nacer</Typography>
              <ToggleButtonGroup
                value={
                  formik.values.antecedentesPerinatales.lloroNacer
                    ? formik.values.antecedentesPerinatales.lloroNacer
                    : ""
                }
                exclusive
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "antecedentesPerinatales.lloroNacer",
                    value ? value : ""
                  );
                }}
                aria-label="Platform"
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Peso al nacer:</Typography>
              <TextField
                variant="standard"
                placeholder="Kg"
                fullWidth
                id="pesoNacimiento"
                name="pesoNacimiento"
                value={formik.values.antecedentesPerinatales.pesoNacimiento}
                onChange={(event) => {
                  formik.setFieldValue(
                    "antecedentesPerinatales.pesoNacimiento",
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.pesoNacimiento &&
                    Boolean(
                      formik.errors.antecedentesPerinatales?.pesoNacimiento
                    )) ||
                  (status === "edit" &&
                    Boolean(
                      formik.errors.antecedentesPerinatales?.pesoNacimiento
                    ))
                }
                helperText={
                  (formik.touched.pesoNacimiento &&
                    formik.errors.antecedentesPerinatales?.pesoNacimiento) ||
                  (status === "edit" &&
                    formik.errors.antecedentesPerinatales?.pesoNacimiento)
                }
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Midió: </Typography>
              <TextField
                variant="standard"
                placeholder="cm"
                fullWidth
                id="tamano"
                name="tamano"
                value={formik.values.antecedentesPerinatales.tamano}
                onChange={(event) => {
                  formik.setFieldValue(
                    "antecedentesPerinatales.tamano",
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.tamano &&
                    Boolean(formik.errors.antecedentesPerinatales?.tamano)) ||
                  (status === "edit" &&
                    Boolean(formik.errors.antecedentesPerinatales?.tamano))
                }
                helperText={
                  (formik.touched.tamano &&
                    formik.errors.antecedentesPerinatales?.tamano) ||
                  (status === "edit" &&
                    formik.errors.antecedentesPerinatales?.tamano)
                }
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Calificación APGAR: </Typography>
              <TextField
                variant="standard"
                placeholder="..."
                fullWidth
                id="apgar"
                name="apgar"
                value={formik.values.antecedentesPerinatales.apgar}
                onChange={(event) => {
                  formik.setFieldValue(
                    "antecedentesPerinatales.apgar",
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.apgar &&
                    Boolean(formik.errors.antecedentesPerinatales?.apgar)) ||
                  (status === "edit" &&
                    Boolean(formik.errors.antecedentesPerinatales?.apgar))
                }
                helperText={
                  (formik.touched.apgar &&
                    formik.errors.antecedentesPerinatales?.apgar) ||
                  (status === "edit" &&
                    formik.errors.antecedentesPerinatales?.apgar)
                }
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Embarazo Número: </Typography>
              <TextField
                variant="standard"
                placeholder="..."
                fullWidth
                id="embarazoNumero"
                name="embarazoNumero"
                value={formik.values.antecedentesPerinatales.embarazoNumero}
                onChange={(event) => {
                  formik.setFieldValue(
                    "antecedentesPerinatales.embarazoNumero",
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.embarazoNumero &&
                    Boolean(
                      formik.errors.antecedentesPerinatales?.embarazoNumero
                    )) ||
                  (status === "edit" &&
                    Boolean(
                      formik.errors.antecedentesPerinatales?.embarazoNumero
                    ))
                }
                helperText={
                  (formik.touched.embarazoNumero &&
                    formik.errors.antecedentesPerinatales?.embarazoNumero) ||
                  (status === "edit" &&
                    formik.errors.antecedentesPerinatales?.embarazoNumero)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionAntecedentesPerinatales;
