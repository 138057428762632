import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Typed from "react-typed";

//servicios
import ServicioService from "../../../services/Servicio.service";

import { AuthContext } from "../../../context/AuthContext";

//componentes
import Servicio from "./Servicio";
import Modal from "@mui/material/Modal";

const Servicios = ({ subtitle }) => {
  const [paquetes, setPaquetes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(12);
  const [tipo, setTipo] = useState(0);
  const { currentUser } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    getPaquetes();
    window.scrollTo(0, 0);
  }, []);

  const getPaquetes = async () => {
    setLoading(true);
    try {
      const data = await ServicioService.mostrarInicio(4);
      setPaquetes(data.data);
      if (data.data.total < 6) {
        let limite = 6 - data.total;
        console.log("----" + limite);
        const dataDESC = await ServicioService.list(limite, 0);
        setPaquetes({ ...paquetes, dataDESC });
      }
      if (currentUser != undefined && currentUser.tipo == "Empresa") {
        setTipo("Empresa");
      } else {
        setTipo("Usuario");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="pt-9 pb-6 py-md-7" id="servicios">
        <div className="container-xl">
          <div className="section-title justify-content-center mb-4 wow fadeInUp">
            <span className="shape shape-left bg-info" />
            <h2 className="text-danger">Nuestros servicios</h2>
            <span className="shape shape-right bg-info" />
          </div>
          <div
            className="font-size-18 mb-md-8 text-center"
            dangerouslySetInnerHTML={{
              __html: String(subtitle ? subtitle : ""),
            }}
          ></div>
          <div className="row wow fadeInUp">
            {paquetes.map((record, index) => {
              return (
                <Servicio
                  key={index}
                  servicio={record}
                  descuento={record.descuento}
                />
              );
            })}
            <div style={{ textAlign: "center" }}>
              <button
                type="button"
                className="btn btn-danger btn-lg mb-2"
                style={{ width: "50%" }}
                onClick={() => navigate(`/servicios`)}
              >
                Ver todo
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Servicios;
