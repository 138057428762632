import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterService from "../../../services/Footer.service";

const Sidebar = () => {
  const navigate = useNavigate();
  const [footer, setFooter] = useState({ undefined });

  useEffect(() => {
    getFooter();
  }, []);

  const getFooter = async () => {
    try {
      const foot = await FooterService.getAll();
      setFooter(foot[0]);
      console.log(foot[0]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="col-12 col-lg-4 order-2 order-lg-1">
      <div className="sticky-kit z-index-1">
        <div className="card mb-4">
          <div className="card-body p-md-4">
            <div className="border-bottom border-light mb-3 pb-2 text-gray-700">
              <h2 className="h5">Tiene preguntas?</h2>
              <p className="small">
                Si tiene preguntas relacionadas co nuestros servicios, por favor
                no dude en
                <button
                  className="link-normal text-decoration-none text-primary"
                  style={{ border: "none", backgroundColor: "unset" }}
                  onClick={() => navigate(`/contactanos`)}
                >
                  contactarnos.
                </button>
              </p>
            </div>
            <ul className="nav flex-column m-0 p-0">
              <li className="nav-item">
                <button
                  className="nav-link px-0 link-normal text-primary"
                  style={{ border: "none", backgroundColor: "unset" }}
                  onClick={() => navigate(`/privacy`)}
                >
                  <svg
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                  Aviso de privacidad
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link px-0 link-normal text-primary"
                  style={{ border: "none", backgroundColor: "unset" }}
                  onClick={() => navigate(`/termsofservice`)}
                >
                  <svg
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                  Términos y condiciones
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link px-0 link-normal text-primary"
                  style={{ border: "none", backgroundColor: "unset" }}
                  onClick={() => navigate(`/contactanos`)}
                >
                  <svg
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                  Contáctanos
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div className="card-body p-lg-4">
            <h3 className="h6 fw-bold">Fundación corazón down</h3>
            <ul className="list-unstyled mb-0 small">
              <li>
                <b>Teléfono: </b>
                <a className="link-normal" href="tel:18005652390">
                  {footer?.Telefono}
                </a>
              </li>
              <li>
                <b>Correo: </b>
                <a className="link-normal" href="tel:18005652390">
                  {footer?.Correo}
                </a>
              </li>
              <li>
                <b>Dirección: </b>
                <address className="mb-0 d-inline">
                  {footer?.Direccion?.Direccion}
                </address>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
