import { useState } from "react";
import styles from "../layout/assets/css/core.min.module.css";
import { toast } from "react-toastify";

export const FormCounter = ({
  createCounter,
  showFormCounter,
  counter,
  updateCounter,
}) => {
  const [newNameCount, setNewNameCount] = useState(counter.name);
  const [newValueCount, setNewValueCount] = useState(counter.value);

  const handleNewNameCount = (event) => {
    setNewNameCount(event.target.value);
  };
  const handleNewValueCount = (event) => {
    setNewValueCount(event.target.value);
  };

  const createCount = (event) => {
    event.preventDefault();
    const newCounter = {
      name: newNameCount,
      value: newValueCount,
    };
    if (!newCounter.name) {
      toast.info("Error, tienes que colocar nombre un al contador.");
    }

    if (!counter.id) {
      createCounter(newCounter);
    } else {
      updateCounter({ ...newCounter, id: counter.id });
    }

    setNewNameCount("");
    setNewValueCount(0);
  };

  return (
    <div
      className={styles.formControl + " " + styles["animate-slidein"]}
      style={{ position: "absolute", zIndex: "1", maxWidth: "35rem" }}
    >
      <form onSubmit={createCount}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <label
            className="core_min_linkNormal__Ni-I+ core_min_fwMedium__0c1EJ"
            style={{ padding: "10px" }}
          >
            Nombre:
          </label>
          <input
            id="inputNameCount"
            className={`${styles.formControl} ${styles.shadowNone} ${styles.formControlSm} ${styles.border0} ${styles.ps5} ${styles.bgGray100}`}
            style={{ width: "70%" }}
            value={newNameCount}
            onChange={handleNewNameCount}
            placeholder="Nombre"
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <label
            className="core_min_linkNormal__Ni-I+ core_min_fwMedium__0c1EJ"
            style={{ padding: "10px" }}
          >
            Valor:
          </label>
          <input
            id="inputValueCount"
            className={`${styles.formControl} ${styles.shadowNone} ${styles.formControlSm} ${styles.border0} ${styles.ps5} ${styles.bgGray100}`}
            style={{ width: "70%" }}
            value={newValueCount}
            onChange={handleNewValueCount}
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <button
            id="submitNewCountButton"
            className="core_min_btn__VqM-c core_min_btnSm__O4qK7 core_min_btnPrimary__rFFHk"
            type="submit"
          >
            <svg
              width="18px"
              height="18px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            <span class="core_min_small__a7O1U">Guardar</span>
          </button>
          <button
            id="calcelNewCountButton"
            className="core_min_btn__VqM-c core_min_btnSm__O4qK7 core_min_btn-secondary__rFFHk btn-secondary"
            onClick={showFormCounter}
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
};
