import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useState } from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Chip,
  Collapse,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { toast } from "react-toastify";
import CustomStatus from "./CustomStatus";

const CustomList = ({ values, handleValues }) => {
  const [openAddElement, setOpenAddElement] = useState(false);
  const [inputElementValue, setInputElementValue] = useState("");
  const [estado, setEstado] = useState("Inicial");

  const handleOpenAddElement = () => {
    setOpenAddElement((old) => !old);
  };

  const addElement = () => {
    if (!inputElementValue) {
      toast.info("Debes nombrar el objetivo");
      return;
    }
    handleOpenAddElement();
    handleValues([
      ...values,
      { label: inputElementValue, value: 1, estado: estado },
    ]);
    setInputElementValue("");
    setEstado("Inicial");
  };

  const removeElement = (element) => {
    let valuesFilter = values.filter(
      (elementValue) => elementValue.label !== element.label
    );

    handleValues(valuesFilter);
  };

  const handleEstado = (event) => {
    console.info("You clicked the Chip.");
    console.log(event.target.textContent);
    setEstado(event.target.textContent);
  };

  return (
    <List
      dense
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
    >
      <ListItem
        key="list-item-button"
        secondaryAction={
          <ListItemButton onClick={() => handleOpenAddElement()}>
            {openAddElement ? <RemoveCircleIcon /> : <AddCircleIcon />}
          </ListItemButton>
        }
        disablePadding
        style={{
          marginBlock: "0.5rem",
        }}
      >
        <ListItemText>Agregar objetivo</ListItemText>
      </ListItem>
      <Collapse in={openAddElement}>
        <>
          <ListItem
            key="list-item-input"
            disablePadding
            style={{
              marginBlockStart: "1rem",
              border: "1px solid light-dark(#767676, #858585)",
            }}
          >
            <TextField
              variant="filled"
              fullWidth
              placeholder="Escribe aquí"
              value={inputElementValue}
              onChange={(event) => setInputElementValue(event.target.value)}
              sx={{ backgroundColor: "#ececec" }}
            />
            <IconButton onClick={() => addElement()}>
              <DoneIcon />
            </IconButton>
          </ListItem>
          <ListItem
            style={{
              marginBlock: "1rem",
            }}
            disablePadding
          >
            <ListItemText primary="Estado:" />
            <Stack direction="row" spacing={1}>
              <Chip
                label="Inicial"
                color="primary"
                variant={estado === "Inicial" ? "filled" : "outlined"}
                onClick={handleEstado}
              />
              <Chip
                label="Intermedio"
                color="warning"
                variant={estado === "Intermedio" ? "filled" : "outlined"}
                onClick={handleEstado}
              />
              <Chip
                label="Final"
                color="success"
                variant={estado === "Final" ? "filled" : "outlined"}
                onClick={handleEstado}
              />
            </Stack>
          </ListItem>
        </>
      </Collapse>
      {values?.map((value, index) => {
        return (
          <>
            <Divider key={`${index}-divider`} component="li" />
            <ListItem key={index} disablePadding>
              <IconButton onClick={() => removeElement(value)}>
                <DeleteIcon sx={{ fontSize: "15px", color: "#9b242c" }} />
              </IconButton>
              <CustomStatus status={value.estado} />
              <Typography
                sx={{
                  display: "inline",
                  cursor: "pointer",
                  marginInline: "0.5rem",
                  color: "#000",
                }}
                component="p"
                variant="body2"
              >
                {`${value.label}`}
              </Typography>
            </ListItem>
          </>
        );
      })}
    </List>
  );
};

export default CustomList;
