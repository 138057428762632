import { useContext, useEffect, useState } from "react";
import styles from "../layout/assets/css/core.min.module.css";

import DetallesAlumno from "./DetallesAlumno";
import DetallesTabs from "./DetallesTabs";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AlumnoService from "../../../services/Alumno.service";
import Preloader from "../../utils/Preloader";
import NotaProvider from "../../../context/NotaContext";
import { AlumnoContext } from "../../../context/AlumnoContext";

const DetallesNotaSeccion = () => {
  let today = new Date();
  const navigate = useNavigate();

  const { id } = useParams();
  const { currentUser } = useContext(AuthContext);
  const { currentAlumno, handleCurrentAlumno } = useContext(AlumnoContext);

  const [fechaCita, setFechaCita] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(today)
  );

  const [status, setStatus] = useState("Pendiente");
  const [detallesAdicionales, setDetallesAdicionales] = useState({});

  const [personas, setPersonas] = useState([]);
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState("N/A");

  const [historialClinico, setHistorialClinico] = useState({});

  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const handleFechaCita = (newFecha) => {
    let nuevaFechaCita = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(newFecha.toDate());
    setFechaCita(nuevaFechaCita);
  };

  useEffect(() => {
    if (id) {
      getAlumno();
    }
  }, [id]);

  const getAlumno = async () => {
    setLoading(true);
    try {
      const data = await AlumnoService.getById(id);

      console.log(data);
      if (data && data[0]) {
        handleCurrentAlumno(data[0]);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Preloader />
  ) : (
    <>
      <DetallesAlumno
        alumno={currentAlumno}
        fechaCita={fechaCita}
        handleFechacita={handleFechaCita}
      />

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div className={`${styles.mb4}`}>
            <div className={`${styles.row} ${styles["m-2"]}`}>
              <div className={`${styles["my-2"]} ${styles["col-sm-12"]}`}>
                <div
                  className={`${styles.borderLight}`}
                  style={{ textAlign: "right" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.row} ${styles.g4}`}>
        <NotaProvider>
          <DetallesTabs />
        </NotaProvider>
      </div>
    </>
  );
};

export default DetallesNotaSeccion;
