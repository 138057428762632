/* React */
import React, { useState, useEffect, useContext } from "react";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";

/* Servicios */
import HistorialService from "../../../services/Historial.service";
import styles from "../layout/assets/css/core.min.module.css";
import { CitaContext } from "../../../context/CitaContext";

const TabHistorialClinico = () => {
  const { currentCita } = useContext(CitaContext);

  const [historial, setHistorial] = useState({});
  const [fechaNacimiento, setFechaNacimiento] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getHistorial();
  }, []);

  const getHistorial = async () => {
    setLoading(true);
    try {
      const getData = await HistorialService.getByAlumno(
        currentCita?.alumno?.id
      );
      if (getData[0]) {
        let data = getData[0];

        setHistorial(data);
        setFechaNacimiento(
          new Intl.DateTimeFormat("az", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }).format(new Date(data.alumno.fechaNacimiento))
        );
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className={`${styles.row} ${styles.tableResponsiveMd}`}>
          <div className={`${styles.row} ${styles.colSm6}`}>
            <div className={`${styles.col}`}>
              <div
                className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
              >
                Antecedentes perinatales{" "}
              </div>
              <div className={`${styles.section} ${styles.mb4}`}>
                <div className={`${styles.cardBody}`}>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Semanas o meses que duró el embarazo:{" "}
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesPerinatales?.duracionEmbarazo ||
                          "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Parto:{" "}
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesPerinatales?.parto || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Lloró al nacer:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesPerinatales?.lloroNacer || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Peso al nacer:{" "}
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesPerinatales?.pesoNacimiento ||
                          "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Midió:{" "}
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesPerinatales?.tamano || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Calificación APGAR:{" "}
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesPerinatales?.apgar || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Embarazo Número:{" "}
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesPerinatales?.embarazoNumero ||
                          "-"}
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.row} ${styles.colSm6}`}>
            <div className={`${styles.col}`}>
              <div
                className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
              >
                Factores del desarrollo
              </div>
              <div className={`${styles.section} ${styles.mb4}`}>
                <div className={`${styles.cardBody}`}>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Tomó leche materna:{" "}
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.factoresDesarrollo?.tomoLecheMaterna || "-"}
                      </span>
                    </Typography>

                    {historial?.factoresDesarrollo?.tomoLecheMaterna ===
                      "Si" && (
                      <Typography variant="overline">
                        Especifique hasta que edad:{" "}
                        {historial?.factoresDesarrollo
                          ?.especificaiontomoLechaMaterna || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Tomó formula:{" "}
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.factoresDesarrollo?.tomoFormula || "-"}
                      </span>
                    </Typography>

                    {historial?.factoresDesarrollo?.tomoFormula === "Si" && (
                      <Typography variant="overline">
                        Especifique hasta que edad:{" "}
                        {historial?.factoresDesarrollo
                          ?.especificacintomoFormula || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Tomo biberón:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.factoresDesarrollo?.tomoBiberon || "-"}
                      </span>
                    </Typography>

                    {historial?.factoresDesarrollo?.tomoBiberon === "Si" && (
                      <Typography variant="overline">
                        Especifique hasta que edad:{" "}
                        {historial?.factoresDesarrollo
                          ?.especificacionTomoBiberon || "-"}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.row} ${styles.colSm6}`}>
            <div className={`${styles.col}`}>
              <div
                className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
              >
                Antecedentes médicos
              </div>
              <div className={`${styles.section} ${styles.mb4}`}>
                <div className={`${styles.cardBody}`}>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Otras patologías que tenga su hijo (a):
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesMedicos?.otrosPatologias || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Ha sido hospitalizado:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesMedicos?.hospitalizado || "-"}
                      </span>
                    </Typography>

                    {historial?.antecedentesMedicos?.hospitalizado === "Si" && (
                      <Typography variant="overline">
                        Cuánto tiempo y porque:
                        {historial?.antecedentesMedicos
                          ?.especificacionHospitalizado || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Tiene todas sus vacunas:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesMedicos?.vacunas || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Padece alergias:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesMedicos?.alergias || "-"}
                      </span>
                    </Typography>

                    {historial?.antecedentesMedicos?.alergias === "Si" && (
                      <Typography variant="overline">
                        ¿Cuáles?:
                        {historial?.antecedentesMedicos
                          ?.especificaionAlergias || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Le han hecho radiografías:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesMedicos?.radiografias || "-"}
                      </span>
                    </Typography>

                    {historial?.antecedentesMedicos?.radiografias === "Si" && (
                      <Typography variant="overline">
                        Ultima fecha:
                        {historial?.antecedentesMedicos
                          ?.especificacionRadiografias || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Le han hecho ecocardiogramas:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesMedicos?.ecocardiogramas || "-"}
                      </span>
                    </Typography>

                    {historial?.antecedentesMedicos?.ecocardiogramas ===
                      "Si" && (
                      <Typography variant="overline">
                        Ultima fecha:
                        {historial?.antecedentesMedicos
                          ?.especificacionEcocardiogramas || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Le han hecho audiometrías:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesMedicos?.audiometrias || "-"}
                      </span>
                    </Typography>

                    {historial?.antecedentesMedicos?.audiometrias === "Si" && (
                      <Typography variant="overline">
                        Ultima fecha:
                        {historial?.antecedentesMedicos
                          ?.especificacionAudiometrias || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Le han hecho potenciales:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesMedicos?.potenciales || "-"}
                      </span>
                    </Typography>

                    {historial?.antecedentesMedicos?.potenciales === "Si" && (
                      <Typography variant="overline">
                        Ultima fecha:
                        {historial?.antecedentesMedicos
                          ?.especificacionPotenciales || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Otros:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesMedicos?.otros || "-"}
                      </span>
                    </Typography>
                    {historial?.antecedentesMedicos?.otrosFecha && (
                      <Typography variant="overline">
                        Fecha:
                        {historial?.antecedentesMedicos?.otrosFecha || "-"}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.row} ${styles.colSm6}`}>
            <div className={`${styles.col}`}>
              <div
                className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
              >
                Desarrollo psicomotor
              </div>
              <div className={`${styles.section} ${styles.mb4}`}>
                <div className={`${styles.cardBody}`}>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en que sostuvo la cabeza:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloSicomotor?.edadSostenerCabeza ||
                          "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en la que sonrió:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloSicomotor?.edadSonreir || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en la que gateo:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloSicomotor?.edadGatear || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en la que se sentó:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloSicomotor?.edadSentar || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en la que caminó con ayuda:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloSicomotor?.edadCaminarAyuda ||
                          "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en la que caminó solo:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloSicomotor?.edadCaminarSolo || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en la que controló esfínteres en el día:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloSicomotor
                          ?.edadControlEsfinteresDay || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en la que controló esfínteres en la noche:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloSicomotor
                          ?.edadControlEsfinteresNight || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en la que comió solo:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloSicomotor?.edadComerSolo || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en la que se bañó solo:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloSicomotor?.edadBanoSolo || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en la que se vistió solo:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloSicomotor?.edadVestirSolo || "-"}
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.row} ${styles.colSm6}`}>
            <div className={`${styles.col}`}>
              <div
                className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
              >
                Desarrollo del lenguaje
              </div>
              <div className={`${styles.section} ${styles.mb4}`}>
                <div className={`${styles.cardBody}`}>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en la que balbuceó:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloLenguaje?.edadBalbuceo || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en la que dijo la primera palabra:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloLenguaje?.edadPrimerPalabra ||
                          "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Edad en la que dijo su primera oración:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloLenguaje?.edadPrimerOracion ||
                          "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Mastica:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloLenguaje?.mastica || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Actualmente como es su alimentación:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloLenguaje?.alimentacion || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Cuantas veces come al día:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloLenguaje?.comidasDia || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Su respiración es por:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloLenguaje?.respiracion || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Ronca:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloLenguaje?.ronca || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Problemas para dormir:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.desarrolloLenguaje?.problemasDormir || "-"}
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.row} ${styles.colSm6}`}>
            <div className={`${styles.col}`}>
              <div
                className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
              >
                Socio – afectivo
              </div>
              <div className={`${styles.section} ${styles.mb4}`}>
                <div className={`${styles.cardBody}`}>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Número de personas que integran la familia:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioAfectivo?.integrantesFamilia || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Como se lleva con su mamá:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioAfectivo?.relacionMadre || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Como se lleva con su papá:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioAfectivo?.relacionPadre || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Con quién pasa más tiempo su hijo (a):
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioAfectivo?.mayorConvivencia || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Con quien juega el niño en sus ratos libres:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioAfectivo?.companeroJuego || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Quien ejerce la autoridad en casa:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioAfectivo?.autoridad || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Con quien duerme el niño:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioAfectivo?.companeroSiesta || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Cuantas veces se baña al día:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioAfectivo?.banoDia || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Cuantas veces se cambia de muda de ropa:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioAfectivo?.cambiosDia || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Como apoya a su hijo (a) en casa:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioAfectivo?.apoyoOfrecido || "-"}
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.row} ${styles.colSm6}`}>
            <div className={`${styles.col}`}>
              <div
                className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
              >
                Conductual
              </div>
              <div className={`${styles.section} ${styles.mb4}`}>
                <div className={`${styles.cardBody}`}>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Que le gusta hacer a su hijo (a):
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.conductual?.actividadesPositivas || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Que no le gusta hacer a su hijo (a):
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.conductual?.actividadesNegadas || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Se enoja con facilidad:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.conductual?.enojo || "-"}
                      </span>
                    </Typography>
                    {historial?.conductual?.enojo === "Si" && (
                      <Typography variant="overline">
                        Porque?:
                        {historial?.conductual?.pasatiempo || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Su hijo (a) miente:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.conductual?.miente || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Su hijo (a) tiene educación sexual:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.conductual?.educacionSexual || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Como describe a su hijo:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.conductual?.descripcion || "-"}
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.row} ${styles.colSm6}`}>
            <div className={`${styles.col}`}>
              <div
                className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
              >
                Antecedentes heredofamiliares
              </div>
              <div className={`${styles.section} ${styles.mb4}`}>
                <div className={`${styles.cardBody}`}>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>Padecen de enfermedades crónicas</Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Sus abuelos maternos:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesHeredofamiliares?.maternos ||
                          "-"}
                      </span>
                    </Typography>

                    {historial?.antecedentesHeredofamiliares?.maternos ===
                      "Si" && (
                      <Typography variant="overline">
                        Cual?:
                        {historial?.antecedentesHeredofamiliares
                          ?.especificacionMaternos || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Sus abuelos paternos:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesHeredofamiliares?.paternos ||
                          "-"}
                      </span>
                    </Typography>

                    {historial?.antecedentesHeredofamiliares?.paternos ===
                      "Si" && (
                      <Typography variant="overline">
                        Cual?:
                        {historial?.antecedentesHeredofamiliares
                          ?.especificacionPaternos || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Mamá:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesHeredofamiliares?.madre || "-"}
                      </span>
                    </Typography>
                    {historial?.antecedentesHeredofamiliares?.madre ===
                      "Si" && (
                      <Typography variant="overline">
                        Cual?:
                        {historial?.antecedentesHeredofamiliares
                          ?.especificacionMadre || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Papá:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesHeredofamiliares?.padre || "-"}
                      </span>
                    </Typography>
                    {historial?.antecedentesHeredofamiliares?.padre ===
                      "Si" && (
                      <Typography variant="overline">
                        Cual?:
                        {historial?.antecedentesHeredofamiliares
                          ?.especificacionPadre || "-"}
                      </Typography>
                    )}
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Hermanos:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.antecedentesHeredofamiliares?.hermanos ||
                          "-"}
                      </span>
                    </Typography>
                    {historial?.antecedentesHeredofamiliares?.hermanos ===
                      "Si" && (
                      <Typography variant="overline">
                        Cual?:
                        {historial?.antecedentesHeredofamiliares
                          ?.especificacionHermanos || "-"}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.row} ${styles.colSm6}`}>
            <div className={`${styles.col}`}>
              <div
                className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
              >
                Socioeconómico
              </div>
              <div className={`${styles.section} ${styles.mb4}`}>
                <div className={`${styles.cardBody}`}>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Tipo de vivienda:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioEconomico?.vivienda || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Cuenta con todos los servicios:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioEconomico?.servicios || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      Cuantas personas viven:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioEconomico?.personas || "-"}
                      </span>
                    </Typography>
                  </div>
                  <div className={`${styles["m-2"]}`}>
                    <Typography>
                      De que material es la casa:
                      <span
                        style={{
                          color: "#9b242c",
                          fontWeight: "bold",
                        }}
                      >
                        {historial?.socioEconomico?.materialCasa || "-"}
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TabHistorialClinico;
