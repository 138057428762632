import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({});

const AntecedentesMedicos = ({ data }) => {
  return (
    <View style={styles.container} wrap={true}>
      <View style={styles.row} wrap={false}>
        <Text
          style={[styles.text]}
        >{`Otras patologías que tenga su hijo (a): ${
          data?.otrosPatologias ? data?.otrosPatologias : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Ha sido hospitalizado: ${
          data?.hospitalizado ? data?.hospitalizado : "-"
        }`}</Text>
      </View>
      {data?.hospitalizado === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Cuánto tiempo y porque: ${
            data?.especificacionHospitalizado
              ? data?.especificacionHospitalizado
              : "-"
          }`}</Text>
        </View>
      )}

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Tiene todas sus vacunas: ${
          data?.vacunas ? data?.vacunas : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Padece alergias: ${
          data?.alergias ? data?.alergias : "-"
        }`}</Text>
      </View>
      {data?.alergias === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`¿Cuáles?: ${
            data?.especificaionAlergias ? data?.especificaionAlergias : "-"
          }`}</Text>
        </View>
      )}

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Le han hecho radiografías: ${
          data?.radiografias ? data?.radiografias : "-"
        }`}</Text>
      </View>
      {data?.radiografias === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Ultima fecha: ${
            data?.especificacionRadiografias
              ? data?.especificacionRadiografias
              : "-"
          }`}</Text>
        </View>
      )}

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Le han hecho ecocardiogramas: ${
          data?.ecocardiogramas ? data?.ecocardiogramas : "-"
        }`}</Text>
      </View>
      {data?.ecocardiogramas === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Ultima fecha: ${
            data?.especificacionEcocardiogramas
              ? data?.especificacionEcocardiogramas
              : "-"
          }`}</Text>
        </View>
      )}

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Le han hecho audiometrías: ${
          data?.audiometrias ? data?.audiometrias : "-"
        }`}</Text>
      </View>
      {data?.audiometrias === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Ultima fecha: ${
            data?.especificacionAudiometrias
              ? data?.especificacionAudiometrias
              : "-"
          }`}</Text>
        </View>
      )}

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Le han hecho potenciales: ${
          data?.potenciales ? data?.potenciales : "-"
        }`}</Text>
      </View>
      {data?.potenciales === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Ultima fecha: ${
            data?.especificacionPotenciales
              ? data?.especificacionPotenciales
              : "-"
          }`}</Text>
        </View>
      )}

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Otros: ${
          data?.otros ? data?.otros : "-"
        }`}</Text>
      </View>
      {data?.otros && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Fecha: ${
            data?.otrosFecha ? data?.otrosFecha : "-"
          }`}</Text>
        </View>
      )}
    </View>
  );
};

export default AntecedentesMedicos;
