import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({});

const SocioAfectivo = ({ data }) => {
  return (
    <View style={styles.container} wrap={true}>
      <View style={styles.row} wrap={false}>
        <Text
          style={[styles.text]}
        >{`Número de personas que integran la familia: ${
          data?.integrantesFamilia ? data?.integrantesFamilia : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Como se lleva con su mamá: ${
          data?.relacionMadre ? data?.relacionMadre : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Como se lleva con su papá: ${
          data?.relacionPadre ? data?.relacionPadre : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Con quién pasa más tiempo su hijo (a): ${
          data?.mayorConvivencia ? data?.mayorConvivencia : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text
          style={[styles.text]}
        >{`Con quien juega el niño en sus ratos libres: ${
          data?.companeroJuego ? data?.companeroJuego : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Quien ejerce la autoridad en casa: ${
          data?.autoridad ? data?.autoridad : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Con quien duerme el niño: ${
          data?.companeroSiesta ? data?.companeroSiesta : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Cuantas veces se baña al día: ${
          data?.banoDia ? data?.banoDia : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text
          style={[styles.text]}
        >{`Cuantas veces se cambia de muda de ropa: ${
          data?.cambiosDia ? data?.cambiosDia : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Como apoya a su hijo (a) en casa: ${
          data?.apoyoOfrecido ? data?.apoyoOfrecido : "-"
        }`}</Text>
      </View>
    </View>
  );
};

export default SocioAfectivo;
