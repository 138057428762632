import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (embedId) {
      setLoading(false);
    }
  }, [embedId]);
  return loading ? null : (
    <div
      className="video-responsive"
      style={{
        overflow: "hidden",
        paddingBottom: " 56.25%",
        position: "relative",
        height: "0",
      }}
    >
      {" "}
      {embedId ? (
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          style={{
            left: "0",
            top: "0",
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
        />
      ) : (
        <div>Enlace no valido</div>
      )}
    </div>
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
