import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({});

const DesarrolloSicomotor = ({ data }) => {
  return (
    <View style={styles.container} wrap={true}>
      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Edad en que sostuvo la cabeza: ${
          data?.edadSostenerCabeza ? data?.edadSostenerCabeza : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Edad en la que sonrió: ${
          data?.edadSonreir ? data?.edadSonreir : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Edad en la que gateo: ${
          data?.edadGatear ? data?.edadGatear : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Edad en la que se sentó: ${
          data?.edadSentar ? data?.edadSentar : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Edad en la que caminó con ayuda: ${
          data?.edadCaminarAyuda ? data?.edadCaminarAyuda : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Edad en la que caminó solo: ${
          data?.edadCaminarSolo ? data?.edadCaminarSolo : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text
          style={[styles.text]}
        >{`Edad en la que controló esfínteres en el día: ${
          data?.edadControlEsfinteresDay ? data?.edadControlEsfinteresDay : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text
          style={[styles.text]}
        >{`Edad en la que controló esfínteres en la noche: ${
          data?.edadControlEsfinteresNight
            ? data?.edadControlEsfinteresNight
            : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Edad en la que comió solo: ${
          data?.edadComerSolo ? data?.edadComerSolo : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Edad en la que se bañó solo: ${
          data?.edadBanoSolo ? data?.edadBanoSolo : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Edad en la que se vistió solo: ${
          data?.edadVestirSolo ? data?.edadVestirSolo : "-"
        }`}</Text>
      </View>
    </View>
  );
};

export default DesarrolloSicomotor;
