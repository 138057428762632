import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { AuthContext } from "../../../context/AuthContext";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { FormatDate } from "../Format";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationActionsTableReportes({
  reportes,
  page,
  total,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const navigate = useNavigate();
  const { currentUser, checkRole } = React.useContext(AuthContext);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - reportes?.length) : 0;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableBody>
          {reportes?.map((row) => (
            <TableRow key={row.titulo}>
              <TableCell>
                <ListItemText
                  primary={row.titulo}
                  secondary={
                    <React.Fragment>
                      <ListItemText
                        primary={
                          checkRole(["Administrador"]) && (
                            <ListItemText
                              secondary={
                                <Typography
                                  sx={{ display: "inline", cursor: "pointer" }}
                                  component="span"
                                  variant="caption"
                                  color="text.primary"
                                >
                                  Elaboró: {row.maestro?.nombre}
                                </Typography>
                              }
                            />
                          )
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline", cursor: "pointer" }}
                              component="span"
                              variant="caption"
                              color="text.primary"
                            >
                              Fecha creación:
                            </Typography>
                            <Typography
                              sx={{
                                display: "inline",
                                cursor: "pointer",
                                color: "green",
                                marginInline: "0.5rem",
                              }}
                              component="a"
                              variant="body2"
                            >
                              {FormatDate(row?.fechaCreacion)}
                            </Typography>
                            <Typography
                              sx={{ display: "inline", cursor: "pointer" }}
                              component="span"
                              variant="caption"
                              color="text.primary"
                            >
                              Ultima edición:
                            </Typography>
                            <Typography
                              sx={{
                                display: "inline",
                                cursor: "pointer",
                                color: "green",
                                marginInline: "0.5rem",
                              }}
                              component="a"
                              variant="body2"
                            >
                              {FormatDate(row?.fechaEdicion)}
                            </Typography>
                            {row.alumno ? (
                              <Typography
                                sx={{ color: "#9b242c" }}
                                component="span"
                                typography="subtitle2"
                                display="block"
                              >
                                {"Alumno: " + row.alumno?.nombre}
                              </Typography>
                            ) : (
                              <Typography
                                sx={{ color: "#9b242c" }}
                                component="span"
                                typography="subtitle2"
                                display="block"
                              >
                                {"Grupo: " + row.grupo?.nombre}
                              </Typography>
                            )}
                          </React.Fragment>
                        }
                      />
                    </React.Fragment>
                  }
                />
              </TableCell>
              {checkRole(["Administrador", "Maestro"]) && (
                <TableCell style={{ width: 160 }} align="right">
                  <div>
                    <IconButton
                      edge="end"
                      aria-label="details"
                      onClick={() =>
                        navigate("/planeaciones/detalles/" + row.id)
                      }
                    >
                      <FindInPageIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => navigate("/planeaciones/editar/" + row.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 88.52 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              labelRowsPerPage="Items por pagina"
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={3}
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
