import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Typography, useTheme } from "@mui/material";

import icon from "./cloud-computing.png";

export default function DropZoneEdit({ saveFile }) {
  const onDrop = useCallback((acceptedFiles) => {
    saveFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: [".jpg", ".png", ".jpeg", ".JPG", ".PNG", ".JPEG"],
    maxSize: 5242880,
  });

  return (
    <>
      <div
        className="text-bg-primary "
        {...getRootProps()}
        style={{
          height: "100px",
          borderRadius: 10,
          borderColor: "whith",
          justifyContent: "center",
          alignItems: "center",
          width: "-webkit-fill-available",
          border: "dashed",
          backgroundImage: `url(${icon})`,
          padding: "20px",
          margin: "30px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "rgba(155,36,44,255)",
          borderRadius: "10px",
        }}
      >
        <input {...getInputProps()} />
        <Typography
          variant="subtitle2"
          textAlign="center"
          style={{ padding: 20 }}
        >
          {/* {isDragActive
          ? "Arroja aqui tus archivos"
          : "Arrastra aqui o click para agregar una foto"} */}
        </Typography>
      </div>
      <Typography
        variant="subtitle2"
        textAlign="center"
        style={{ padding: 20 }}
      >
        {" "}
        *Tipos de imágenes aceptadas: ".jpg", ".png", ".jpeg"
        {/* {isDragActive
      ? "Arroja aqui tus archivos"
      : "Arrastra aqui o click para agregar una foto"} */}
      </Typography>
    </>
  );
}
