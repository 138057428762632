import { useEffect, useState } from "react";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";

import { toast } from "react-toastify";
import styles from "../layout/assets/css/core.min.module.css";
import AreaService from "../../../services/Area.service";
import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";

const FormCategoria = ({
  createCategoria,
  showFormCategoria,
  updateCategoria,
  categoria,
}) => {
  const [loading, setLoading] = useState(false);

  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState("Área terapéutica");

  const [newCategoria, setNewCategoria] = useState(
    categoria?.nombre ? categoria.nombre : ""
  );

  const handleNewCategoria = (event) => {
    setNewCategoria(event.target.value);
  };

  const saveCategoria = () => {
    const newTipo = {
      nombre: newCategoria,
    };

    if (!newTipo.nombre) {
      toast.info("Error, tienes que colocar nombre.");
      return;
    } else if (area === "" || area === "N/A") {
      toast.info("Error, tienes que seleccionar el area.");
      return;
    }

    if (!categoria.id) {
      newTipo.area = area;

      createCategoria(newTipo);
    } else {
      if (area && area !== categoria?.area?.tipo) {
        newTipo.area = area;
      }
      updateCategoria({ ...newTipo, id: categoria.id });
    }

    setNewCategoria("");
  };

  const handleArea = (event, newArea) => {
    setArea(newArea);
  };

  useEffect(() => {
    getAreas();
  }, []);

  const getAreas = async () => {
    try {
      const getData = await AreaService.getAll();
      if (getData) {
        setAreas(getData);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div
      className={styles.formControl + " " + styles["animate-slidein"]}
      style={{ position: "absolute", zIndex: "1", maxWidth: "21rem" }}
    >
      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div
            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
          >
            Nueva sub-área
          </div>
          <div className={`${styles.section} ${styles.mb4}`}>
            <TextField
              fullWidth
              value={newCategoria}
              onChange={handleNewCategoria}
              placeholder="Nombre de sub-área"
              label="Nombre de sub-área"
            />
          </div>
        </div>
      </div>

      {/* options */}
      <div
        className={`${styles.order1}`}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <button
          onClick={() => saveCategoria()}
          className={`${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
          style={{ width: "45%" }}
        >
          <svg
            width="18px"
            height="18px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1={12} y1={5} x2={12} y2={19} />
            <line x1={5} y1={12} x2={19} y2={12} />
          </svg>
          Guardar
        </button>
        <button
          id="calcelNewCountButton"
          className={`${styles.btn} ${styles.btnSm} ${styles["btn-secondary"]}`}
          onClick={showFormCategoria}
          style={{ width: "45%" }}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default FormCategoria;
