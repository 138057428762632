import { useNavigate } from "react-router-dom";

const CopyRight = ({ footer }) => {
  const navigate = useNavigate();

  return (
    <div className="copyright">
      <div className="container">
        <div className="row py-4 align-items-center">
          <div className="col-sm-7 col-xs-12 order-1 order-md-0">
            <p className="copyright-text">
              © <span id="copy-year" /> Copyright 2023{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.karimnot.com/"
              >
                KARIMNOT INC
              </a>
            </p>
            <p className="small mb-0">
              Todos los derechos reservados <br />
              <button
                onClick={() => navigate("/privacy")}
                style={{
                  color: "#f0c24b",
                  border: "none",
                  backgroundColor: "unset",
                  padding: "0",
                }}
              >
                Aviso de privacidad
              </button>
            </p>
          </div>
          <div className="col-sm-5 col-xs-12">
            <ul className="list-inline d-flex align-items-center justify-content-md-end justify-content-center mb-md-0">
              <li className="me-3">
                <a
                  className="icon-rounded-circle-small bg-primary"
                  rel="noreferrer"
                  target="_blank"
                  href={footer?.fb ? `${footer.fb}` : "https://facebook.com"}
                >
                  <i
                    className="fab fa-facebook-f text-white"
                    aria-hidden="true"
                  />
                </a>
              </li>
              <li className="me-3">
                <a
                  className="icon-rounded-circle-small bg-success"
                  rel="noreferrer"
                  target="_blank"
                  href={footer?.tw ? `${footer.tw}` : "https://twitter.com"}
                >
                  <i className="fab fa-twitter text-white" aria-hidden="true" />
                </a>
              </li>
              <li className="me-3">
                <a
                  className="icon-rounded-circle-small bg-danger"
                  rel="noreferrer"
                  target="_blank"
                  href={footer?.yt ? `${footer.yt}` : "https://youtube.com"}
                >
                  <i className="fab fa-youtube text-white" aria-hidden="true" />
                </a>
              </li>
              <li className="me-3">
                <a
                  className="icon-rounded-circle-small bg-purple"
                  rel="noreferrer"
                  target="_blank"
                  href={
                    footer?.tiktok ? `${footer.tiktok}` : "https://tiktok.com"
                  }
                >
                  <i className="fab fa-tiktok text-white" aria-hidden="true" />
                </a>
              </li>
              <li className="me-3">
                <a
                  className="icon-rounded-circle-small bg-info-Public"
                  style={{ backgroundColor: "" }}
                  rel="noreferrer"
                  target="_blank"
                  href={
                    footer?.insta ? `${footer.insta}` : "https://instagram.com"
                  }
                >
                  <i
                    className="fab fa-instagram text-white"
                    aria-hidden="true"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyRight;
