import httpClient from "./HttpClient";
const prefix = "/planeaciones";

export default class ReporteService {
  static async create(noticia) {
    return (await httpClient.post(`${prefix}/`, noticia)).data;
  }

  static async update(noticia) {
    return (await httpClient.put(`${prefix}/${noticia.id}`, noticia)).data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

  static async list(limit, offset) {
    return (await httpClient.get(`${prefix}/${limit}/${offset}`)).data;
  }

  static async getInfo(data) {
    return (await httpClient.post(`${prefix}/info`, data)).data;
  }

  static async listSearch(data) {
    return (await httpClient.post(`${prefix}/search`, data)).data;
  }
}
