import { useContext, useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import AppointmentService from "../../../services/therapies/Appointment.service";
import CustomPaginationActionsTableCitasNotes from "../../utils/CustomTable/CustomPaginationActionsTableCitasNotes";
import { AlumnoContext } from "../../../context/AlumnoContext";

const TabHistorialCita = () => {
  const { currentAlumno } = useContext(AlumnoContext);
  const [citas, setCitas] = useState([]);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCitas = async () => {
    let alumno = { id: currentAlumno?.id };
    try {
      let data = await AppointmentService.listBy(limit, page * limit, alumno);

      if (data) {
        setCitas((old) => data.data);
        setTotal((old) => data.total);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getCitas();
  }, [page, limit, currentAlumno]);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
        {" "}
        <Typography>Hay {total} citas registradas</Typography>
      </Grid>
      <Grid xs={12} item>
        <CustomPaginationActionsTableCitasNotes
          citas={citas}
          page={page}
          total={total}
          rowsPerPage={limit}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default TabHistorialCita;
