import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({});

const Socioeconomico = ({ data }) => {
  return (
    <View style={styles.container} wrap={true}>
      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{` Tipo de vivienda: ${
          data?.vivienda ? data?.vivienda : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Cuenta con todos los servicios: ${
          data?.servicios ? data?.servicios : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Cuantas personas viven: ${
          data?.personas ? data?.personas : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`De que material es la casa: ${
          data?.materialCasa ? data?.materialCasa : "-"
        }`}</Text>
      </View>
    </View>
  );
};

export default Socioeconomico;
