import { useContext, useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import AppointmentService from "../../../services/therapies/Appointment.service";
import CustomPaginationActionsTableCitasNotes from "../../utils/CustomTable/CustomPaginationActionsTableCitasNotes";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "./printDocs/HistorialCitas";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import AlumnoService from "../../../services/Alumno.service";
import Preloader from "../../utils/PreloaderElement";

const TabHistorialCita = () => {
  const { id } = useParams();
  const [citas, setCitas] = useState([]);
  const [alumno, setAlumno] = useState({});

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCitas = async () => {
    let alumno = { id: id };
    try {
      let data = await AppointmentService.listBy(limit, page * limit, alumno);

      if (data) {
        setCitas((old) => data.data);
        setTotal((old) => data.total);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getAlumno = async () => {
    try {
      const getData = await AlumnoService.getById(id);
      if (getData[0]) {
        let data = getData[0];
        setAlumno(data);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const loadData = async () => {
    Promise.all([getAlumno(), getCitas()]).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, [page, limit, id]);

  return isLoading ? (
    <Preloader />
  ) : (
    <Grid container spacing={2}>
      <Grid xs={12}>
        {" "}
        <Typography>Hay {total} citas registradas</Typography>
      </Grid>
      <Grid xs={12}>
        <CustomPaginationActionsTableCitasNotes
          citas={citas}
          page={page}
          total={total}
          rowsPerPage={limit}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>

      <Grid xs={12}>
        <div className="my-2 col-sm-12">
          <a class="text-decoration-none" href="javascript:void(0)">
            <PictureAsPdfOutlinedIcon fontSize="small" />
            <PDFDownloadLink
              document={<MyDocument historial={citas} alumno={alumno} />}
              fileName={`historialCitas.pdf`}
              style={{ padding: "0.5rem" }}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Cargando..." : "Descargar PDF"
              }
            </PDFDownloadLink>
          </a>
        </div>
      </Grid>
    </Grid>
  );
};

export default TabHistorialCita;
