import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";

const ItemBlog = ({
  id,
  titulo,
  idAutor,
  fecha,
  categoria,
  imagenes,
  cuerpo,
}) => {
  const navigate = useNavigate();
  const [imag, setImag] = useState([]);
  const [autor, setAutor] = useState([]);
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  let autorNombre;

  useEffect(() => {
    getImagen();
    getAutor();
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(imagenes);
      setImag(result.result);
      imagen = result.result;
      console.log("Imagen " + imag);
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const getAutor = async () => {
    try {
      const result = await UserService.getById(id);
      setAutor(result.nombre);
      autorNombre = result.nombre;
      console.log("Nombre autor: " + autorNombre);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <li className="border-bottom p-3">
      <div className="media">
        <button
          className="me-2"
          style={{ border: "none", backgroundColor: "unset" }}
          onClick={() => navigate(`/blog/${id}`)}
        >
          <img
            className="rounded"
            src={imag}
            alt="blog-sm-img5.png"
            style={{ width: "100px", height: "60px", objectFit: "cover" }}
          />
        </button>
        <div className="media-body">
          <h5 className="mb-1">
            <button
              className="btn-link font-base text-hover-purple"
              style={{
                border: "none",
                backgroundColor: "unset",
                textAlign: "start",
                padding: "inherit",
              }}
              onClick={() => navigate(`/blog/${id}`)}
            >
              {titulo}
            </button>
          </h5>
          <time className="text-muted">{fecha.slice(0, 10)}</time>
        </div>
      </div>
    </li>
  );
};

export default ItemBlog;
