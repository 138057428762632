import { useState } from "react";
import { createContext } from "react";

export const NotaContext = createContext();

const NotaProvider = ({ children }) => {
  const [currentNota, setCurrentNota] = useState();
  const [currentTab, setCurrentTab] = useState(0);
  const [currentId, setCurrentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [changes, setChanges] = useState(false);

  const handleCurrentNota = async (cita) => {
    setCurrentNota(cita);
  };

  const removeNota = async () => {
    setCurrentNota(null);
  };

  const handleCurrentTab = async (tab) => {
    setCurrentTab(tab);
  };

  const handleCurrentId = async (id) => {
    setCurrentId(id);
  };

  const handleLoading = () => {
    setLoading((old) => !old);
  };

  const handleChanges = () => {
    setChanges((old) => !old);
  };

  return (
    <NotaContext.Provider
      value={{
        currentNota,
        handleCurrentNota,
        removeNota,
        currentTab,
        handleCurrentTab,
        currentId,
        handleCurrentId,
        loading,
        handleLoading,
        changes,
        handleChanges,
      }}
    >
      {children}
    </NotaContext.Provider>
  );
};

export default NotaProvider;
