import { Box, Button, TextField, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import styles from "../layout/assets/css/core.min.module.css";
import CustomSelectImage from "../../utils/CustomImageSelect/CustomSelectImage";
import { useEffect, useState } from "react";
import CustomDirectionInput from "../../utils/CustomImput/CustomDirectionInput";
import { toast } from "react-toastify";
import S3Service from "../../../services/S3.service";

import { useFormik } from "formik";
import { mixed, object, string } from "yup";
import { nanoid } from "nanoid";

const personaSchema = object({
  nombre: string("Escribe un nombre").required("El nombre es requerido"),
  edad: string().required("El responsable es requerido"),
  parentesco: string().required("Parentesco es requerido"),
  estadoCivil: string().nullable(),
  finado: string().required("Finado es requerido"),
  ocupacion: string().nullable(),
  horarioLaboral: string().nullable(),
  correo: string()
    .email("Correo invalido")
    .required("El correo es requerido")
    .matches(
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      "Correo invalido"
    ),
  telefonoCelular: string().nullable(),
  telefonoCasa: string().nullable(),
  telefonoTrabajo: string().nullable(),
  domicilio: object({
    calle: string("Escribe una calle"),
    codigoPostal: string("Escribe un código postal"),
    colonia: string("Escribe una colonia"),
    estado: string("Escribe un estado"),
    exterior: string("Escribe un #exterior"),
    interior: string("Escribe un #interior"),
    municipio: string("Escribe un municipio"),
  }).nullable(),
  file: mixed()
    .test("fileFormat", "Archivos permitidos: jpg, jpeg, png", (value) => {
      if (value) {
        const supportedFormats = ["jpg", "jpeg", "png"];
        return supportedFormats.includes(value.name.split(".").pop());
      }
      return true;
    })
    .test("fileSize", "La imagen debe ser menor a 3MB", (value) => {
      if (value) {
        return value.size <= 3145728;
      }
      return true;
    }),
});

const personaInitialValues = {
  nombre: "",
  edad: "",
  parentesco: "",
  estadoCivil: "",
  finado: "",
  ocupacion: "",
  horarioLaboral: "",
  correo: "",
  telefonoCelular: "",
  telefonoCasa: "",
  telefonoTrabajo: "",
  domicilio: {
    calle: "",
    codigoPostal: "",
    colonia: "",
    estado: "",
    exterior: "",
    interior: "",
    municipio: "",
  },
};

const FormAuthorizedPerson = ({
  personas,
  handlePersonas,
  close,
  personaDefault,
}) => {
  const [person, setPerson] = useState(personaInitialValues);
  const [imagen, setImagen] = useState(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: person,
    validationSchema: personaSchema,
    onSubmit: (values) => {
      handlePersona(values);
    },
  });

  const handleFile = (file) => {
    formik.setFieldValue("file", file);
  };

  const handlePersona = async (values) => {
    if (!values?.file && !values?.imagen) {
      toast.info("Debes agregar una imagen");
      return;
    } else if (
      !values?.telefonoCelular &&
      !values?.telefonoCasa &&
      !values?.telefonoTrabajo
    ) {
      toast.info("Debes agregar un numero de contacto");
      return;
    }

    let data = {
      nombre: values?.nombre,
      edad: values?.edad,
      parentesco: values?.parentesco,
      estadoCivil: values?.estadoCivil,
      finado: values?.finado,
      ocupacion: values?.ocupacion,
      horarioLaboral: values?.horarioLaboral,
      correo: values?.correo,
      telefonoCelular: values?.telefonoCelular,
      telefonoCasa: values?.telefonoCasa,
      telefonoTrabajo: values?.telefonoTrabajo,
      domicilio: values?.domicilio,
      imagen: values?.imagen,
      url: imagen,
      uuid: values.uuid ? values.uuid : nanoid(5),
    };

    let oldPersonas = personas || [];
    let findIndex = personas.findIndex((p) =>
      p.uuid
        ? p.uuid === data.uuid
        : p.nombre === person.nombre && p.correo === person.correo
    );

    if (values.file) {
      data.url = URL.createObjectURL(values.file);
      await S3Service.delete(values.imagen ? values.imagen : "img").catch(
        (error) => {
          console.log(error);
        }
      );
      const uploadPhoto = await S3Service.upload(values.file);
      data.imagen = uploadPhoto.result.data;
    }

    if (findIndex > -1) {
      oldPersonas[findIndex] = data;
    } else {
      oldPersonas.push(data);
    }

    handlePersonas(oldPersonas);
    setPerson(personaInitialValues);
    close();
  };

  useEffect(() => {
    if (personaDefault) {
      setPerson({
        ...personaDefault,
        uuid: personaDefault.uuid ? personaDefault.uuid : nanoid(5),
      });

      getImagen(personaDefault.imagen);
    } else {
      setPerson(personaInitialValues);
      setImagen(null);
    }
  }, [personaDefault]);

  useEffect(() => {
    if (Object.keys(formik.errors).length >= 1 && formik.dirty) {
      toast.info("Revisar los campos obligatorios");
    }
  }, [formik.submitCount]);

  const getImagen = async (key) => {
    try {
      if (key) {
        const result = await S3Service.get(key);
        setImagen(result.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={`${styles.row} ${styles.g4}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          PERSONA AUTORIZADA EXCLUSIVAMENTE PARA RECOGER AL ALUMNO (A)
        </div>
        <div className={`${styles.mb4}`}>
          <div className={`${styles.cardBody}`}>
            <div className={`${styles.row} ${styles.colSm12}`}>
              <div className={`${styles.colSm3}`}>
                <CustomSelectImage
                  url={imagen}
                  file={formik.values.file}
                  handleFile={handleFile}
                />
              </div>
              <div className={`${styles["col-sm-1"]}`}></div>
              <div className={`${styles["row"]}  ${styles["col-sm-8"]}`}>
                <div className={`${styles.colSm12} ${styles["my-4"]}`}>
                  <Typography>
                    Nombre{" "}
                    <Typography
                      sx={{ display: "inline", cursor: "pointer" }}
                      component="span"
                      variant="caption"
                      color="#9b242c"
                    >
                      Obligatorio
                    </Typography>
                  </Typography>
                  <TextField
                    variant="standard"
                    placeholder="Nombre completo"
                    fullWidth
                    id="nombre"
                    name="nombre"
                    value={formik.values.nombre}
                    onChange={(event) => {
                      formik.setFieldValue("nombre", event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nombre && Boolean(formik.errors.nombre)
                    }
                    helperText={formik.touched.nombre && formik.errors.nombre}
                  />
                </div>
                <div className={`${styles["colSm6"]}`}>
                  <Typography>
                    Edad{" "}
                    <Typography
                      sx={{ display: "inline", cursor: "pointer" }}
                      component="span"
                      variant="caption"
                      color="#9b242c"
                    >
                      Obligatorio
                    </Typography>
                  </Typography>
                  <TextField
                    variant="standard"
                    type="number"
                    fullWidth
                    id="edad"
                    name="edad"
                    value={formik.values.edad}
                    onChange={(event) => {
                      formik.setFieldValue("edad", event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.edad && Boolean(formik.errors.edad)}
                    helperText={formik.touched.edad && formik.errors.edad}
                  />
                </div>
                <div className={`${styles["colSm6"]}`}>
                  <Typography>
                    Parentesco{" "}
                    <Typography
                      sx={{ display: "inline", cursor: "pointer" }}
                      component="span"
                      variant="caption"
                      color="#9b242c"
                    >
                      Obligatorio
                    </Typography>
                  </Typography>

                  <TextField
                    variant="standard"
                    fullWidth
                    id="parentesco"
                    name="parentesco"
                    value={formik.values.parentesco}
                    onChange={(event) => {
                      formik.setFieldValue("parentesco", event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.parentesco &&
                      Boolean(formik.errors.parentesco)
                    }
                    helperText={
                      formik.touched.parentesco && formik.errors.parentesco
                    }
                  />
                </div>

                <div className={`${styles["colSm6"]}`}>
                  <div className={`${styles["my-4"]}`}>
                    <Typography>Estado civil</Typography>

                    <TextField
                      variant="standard"
                      fullWidth
                      id="estadoCivil"
                      name="estadoCivil"
                      value={formik.values.estadoCivil}
                      onChange={(event) => {
                        formik.setFieldValue("estadoCivil", event.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.estadoCivil &&
                        Boolean(formik.errors.estadoCivil)
                      }
                      helperText={
                        formik.touched.estadoCivil && formik.errors.estadoCivil
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["colSm6"]}`}>
                  <div className={`${styles["my-4"]}`}>
                    <Typography>
                      Vive o falleció{" "}
                      <Typography
                        sx={{ display: "inline", cursor: "pointer" }}
                        component="span"
                        variant="caption"
                        color="#9b242c"
                      >
                        Obligatorio
                      </Typography>
                    </Typography>
                    <ToggleButtonGroup
                      value={formik.values.finado}
                      exclusive
                      aria-label="Platform"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "finado",
                          e.target.value ? e.target.value : ""
                        );
                      }}
                    >
                      <ToggleButton value="Vivo">Vivo</ToggleButton>
                      <ToggleButton value="Finado">Finado</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
                <div className={`${styles["colSm6"]}`}>
                  <div className={`${styles["my-4"]}`}>
                    <Typography>Ocupación</Typography>

                    <TextField
                      variant="standard"
                      fullWidth
                      id="ocupacion"
                      name="ocupacion"
                      value={formik.values.ocupacion}
                      onChange={(event) => {
                        formik.setFieldValue("ocupacion", event.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.ocupacion &&
                        Boolean(formik.errors.ocupacion)
                      }
                      helperText={
                        formik.touched.ocupacion && formik.errors.ocupacion
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["colSm6"]}`}>
                  <div className={`${styles["my-4"]}`}>
                    <Typography>Horario laboral</Typography>

                    <TextField
                      variant="standard"
                      fullWidth
                      id="horarioLaboral"
                      name="horarioLaboral"
                      value={formik.values.horarioLaboral}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "horarioLaboral",
                          event.target.value
                        );
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.horarioLaboral &&
                        Boolean(formik.errors.horarioLaboral)
                      }
                      helperText={
                        formik.touched.horarioLaboral &&
                        formik.errors.horarioLaboral
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["colSm6"]}`}>
                  <div className={`${styles["my-4"]}`}>
                    <Typography>
                      Correo electrónico{" "}
                      <Typography
                        sx={{ display: "inline", cursor: "pointer" }}
                        component="span"
                        variant="caption"
                        color="#9b242c"
                      >
                        Obligatorio
                      </Typography>
                    </Typography>

                    <TextField
                      variant="standard"
                      fullWidth
                      id="correo"
                      name="correo"
                      value={formik.values.correo}
                      onChange={(event) => {
                        formik.setFieldValue("correo", event.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.correo && Boolean(formik.errors.correo)
                      }
                      helperText={formik.touched.correo && formik.errors.correo}
                    />
                  </div>
                </div>
                <div className={`${styles["colSm6"]}`}>
                  <div className={`${styles["my-4"]}`}>
                    <Typography>
                      Teléfono celular{" "}
                      <Typography
                        sx={{ display: "inline", cursor: "pointer" }}
                        component="span"
                        variant="caption"
                        color="#9b242c"
                      >
                        Obligatorio
                      </Typography>
                    </Typography>
                    <TextField
                      variant="standard"
                      fullWidth
                      id="telefonoCelular"
                      name="telefonoCelular"
                      type="tel"
                      placeholder="951 123 23 23"
                      value={formik.values.telefonoCelular}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "telefonoCelular",
                          event.target.value
                        );
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.telefonoCelular &&
                        Boolean(formik.errors.telefonoCelular)
                      }
                      helperText={
                        formik.touched.telefonoCelular &&
                        formik.errors.telefonoCelular
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["colSm6"]}`}>
                  <div className={`${styles["my-4"]}`}>
                    <Typography>Teléfono casa</Typography>
                    <TextField
                      variant="standard"
                      fullWidth
                      id="telefonoCasa"
                      name="telefonoCasa"
                      type="tel"
                      placeholder="951 123 23 23"
                      value={formik.values.telefonoCasa}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "telefonoCasa",
                          event.target.value
                        );
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.telefonoCasa &&
                        Boolean(formik.errors.telefonoCasa)
                      }
                      helperText={
                        formik.touched.telefonoCasa &&
                        formik.errors.telefonoCasa
                      }
                    />
                  </div>
                </div>
                <div className={`${styles["colSm6"]}`}>
                  <div className={`${styles["my-4"]}`}>
                    <Typography>Teléfono trabajo</Typography>

                    <TextField
                      variant="standard"
                      fullWidth
                      id="telefonoTrabajo"
                      name="telefonoTrabajo"
                      type="tel"
                      placeholder="951 123 23 23"
                      value={formik.values.telefonoTrabajo}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "telefonoTrabajo",
                          event.target.value
                        );
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.telefonoTrabajo &&
                        Boolean(formik.errors.telefonoTrabajo)
                      }
                      helperText={
                        formik.touched.telefonoTrabajo &&
                        formik.errors.telefonoTrabajo
                      }
                    />
                  </div>
                </div>

                <div className={`${styles["colSm12"]}`}>
                  <div className={`${styles["my-4"]}`}>
                    <CustomDirectionInput
                      direction={formik.values.domicilio}
                      handleDirection={(value) =>
                        formik.setFieldValue("domicilio", value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={`${styles.co12}`}>
                <div
                  className={`${styles.cardFooter} ${styles.borderLight}`}
                  style={{ textAlign: "right" }}
                >
                  <button
                    className={`${styles.btn} ${styles.btnPrimary}`}
                    onClick={() => close()}
                  >
                    <span className={`${styles.small}`}>Cancelar</span>
                  </button>
                  <button
                    className={`${styles.btn} ${styles.btnPrimary}`}
                    onClick={() => formik.submitForm()}
                  >
                    <svg
                      width="18px"
                      height="18px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    {"  "}
                    <span className={`${styles.small}`}>Agregar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAuthorizedPerson;
