import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/*Servicios*/
import NoticiaService from "../../../../services/Noticia.service";
import FooterService from "../../../../services/Footer.service";

//Componentes
import FooterItemBlog from "./FooterItemBlog";
import CopyRight from "./CopyRight";
import Contact from "./Contact";
import Pages from "./Pages";

import imgBack from "../../assets/img/background/footer-bg-img-1.png";
import logo from "../../assets/img/logo-corazon.png";

const FooterPublicLayout = () => {
  const [footer, setFooter] = useState({ undefined });
  const navigate = useNavigate();
  const [noticias, setNoticias] = useState([]);
  const footerText = footer?.Texto?.substring(0, 175) || "";

  useEffect(() => {
    getFooter();
    getNoticias();
    window.scrollTo(0, 0);
  }, []);

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(2, 0);
      setNoticias(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getFooter = async () => {
    try {
      const foot = await FooterService.getAll();
      setFooter(foot[0]);
      console.log(foot[0]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <footer className="footer-bg-img">
      {/* Footer Color Bar */}
      <div className="color-bar">
        <div className="container-fluid">
          <div className="row">
            <div className="col color-bar bg-warning" />
            <div className="col color-bar bg-danger" />
            <div className="col color-bar bg-success" />
            <div className="col color-bar bg-info" />
            <div className="col color-bar bg-purple" />
            <div className="col color-bar bg-pink" />
            <div className="col color-bar bg-warning d-none d-md-block" />
            <div className="col color-bar bg-danger d-none d-md-block" />
            <div className="col color-bar bg-success d-none d-md-block" />
            <div className="col color-bar bg-info d-none d-md-block" />
            <div className="col color-bar bg-purple d-none d-md-block" />
            <div className="col color-bar bg-pink d-none d-md-block" />
          </div>
        </div>
      </div>
      <div
        className="pt-8 pb-7  bg-repeat"
        style={{
          backgroundImage: `url(${imgBack})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-3 col-xs-12">
              <button
                className="mb-6 d-block"
                style={{ border: "none", backgroundColor: "unset" }}
                onClick={() => navigate(`/`)}
              >
                <img
                  className="img-fluid d-inline-block"
                  data-src="assets/img/logo-footer.png"
                  src={logo}
                  style={{ height: "5rem" }}
                  alt="FDC"
                />
              </button>
              <p className="mb-6" style={{ textAlign: "justify" }}>
                {footerText}
              </p>
            </div>
            <div className="col-sm-6 col-lg-3 col-xs-12">
              <h4 className="section-title-sm font-weight-bold text-white mb-6">
                Páginas
              </h4>
              <ul className="list-unstyled">
                <Pages />
              </ul>
            </div>
            <div className="col-sm-6 col-lg-3 col-xs-12">
              <h4 className="section-title-sm font-weight-bold text-white mb-6">
                Entradas recientes
              </h4>
              <ul className="list-unstyled list-item-border-bottom">
                {noticias.map((record, index) => {
                  return <FooterItemBlog key={index} data={record} />;
                })}
              </ul>
            </div>
            <div className="col-sm-6 col-lg-3 col-xs-12">
              <h4 className="section-title-sm font-weight-bold text-white mb-6">
                Contáctanos
              </h4>
              <ul className="list-unstyled">
                <Contact footer={footer} />
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Copy Right */}
      <CopyRight footer={footer} />
    </footer>
  );
};

export default FooterPublicLayout;
