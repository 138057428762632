import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

/*Servicios*/
import GoogleMaps from "simple-react-google-maps";
import FooterService from "../../../services/Footer.service";
import MensajeService from "../../../services/Mensaje.service";

/*Componentes*/

const Contacto = ({}) => {
  const google_apiKey = process.env.REACT_APP_GOOGLE_KEY_CAPTCHA;

  const [contactanos, setContactanos] = useState({ undefined });
  const [mensaje, setMensaje] = useState({ undefined });
  const [valido, setValido] = useState(false);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({ Direccion: "" });
  const [checked, setChecked] = useState(false);
  const [aceptar, setAceptar] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getContactanos();
    window.scrollTo(0, 0);
  }, []);

  const disabledSendBottom = () => {
    return !valido || !aceptar;
  };

  const getContactanos = async () => {
    setLoading(true);
    try {
      const foot = await FooterService.getAll();
      if (!foot[0]) {
        return;
      }
      setInfo(foot[0]);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  function onChange(value) {
    value ? setValido(true) : setValido(false);
  }

  const enviarMensaje = async () => {
    if (!mensaje.Nombre) {
      toast.info("Error, tienes que colocar tu nombre");
    } else if (!mensaje.Email) {
      toast.info("Error, tienes que colocar un correo");
    } else if (!mensaje.MensajeCuerpo) {
      toast.info("Error, tienes que colocar una mensaje");
    } else if (!aceptar) {
      toast.info(
        "Para poder enviar tu mensaje debes de aceptar  los terminos y condiciones"
      );
    } else {
      setLoading(true);
      try {
        const datosMensaje = {
          name: mensaje.Nombre,
          email: mensaje.Email,
          MensajeCuerpo: mensaje.MensajeCuerpo,
          phone: mensaje.Telefono,
        };
        await MensajeService.create(datosMensaje);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        const limpiar = {
          Nombre: "",
          Email: "",
          MensajeCuerpo: "",
          Telefono: "",
        };
        setMensaje(limpiar);
        setValido(false);
        toast.info("Gracias, mensaje enviado");
      }
    }
  };

  return (
    <section className="bg-light py-7 py-md-10" id="contacto">
      <div className="container">
        <div className="row wow fadeInUp">
          <div className="col-sm-6 col-xs-12">
            <div className="section-title align-items-baseline mb-4">
              <h2 className="text-danger px-0 mb-0">Contacto</h2>
            </div>
            <p className="text-dark font-size-15">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit anim id est laborum.
            </p>
            <ul className="list-unstyled">
              <li className="media align-items-center mb-3">
                <div className="icon-rounded-circle-small bg-primary me-2">
                  <i className="fas fa-map-marker-alt text-white" />
                </div>
                <div className="media-body">
                  <a
                    className="text-color"
                    target="_blank"
                    href={
                      info?.Direccion
                        ? `https://www.google.com/maps/search/?api=1&query=${info.Direccion.lat},${info.Direccion.long}5&zoom=20`
                        : "javascript:void(0);"
                    }
                  >
                    {info.Direccion?.Direccion}
                  </a>
                </div>
              </li>
              <li className="media align-items-center mb-3">
                <div className="icon-rounded-circle-small bg-success me-2">
                  <i className="fas fa-envelope text-white" />
                </div>
                <div className="media-body">
                  <p className="mb-0">
                    <a className="text-color" href={"mailTo:" + info.Correo}>
                      {info.Correo}
                    </a>
                  </p>
                </div>
              </li>
              <li className="media align-items-center mb-3">
                <div className="icon-rounded-circle-small bg-info me-2">
                  <i className="fas fa-phone-alt text-white" />
                </div>
                <div className="media-body">
                  <p className="mb-0">
                    <a className="text-color" href={"tel:" + info.Telefono}>
                      {info.Telefono}
                    </a>
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-xs-12">
            <form>
              <div className="form-group form-group-icon">
                <i className="fas fa-user" aria-hidden="true" />
                <input
                  type="text"
                  className="form-control border-primary"
                  placeholder="Nombre"
                  required=""
                  onChange={(e) =>
                    setMensaje({ ...mensaje, Nombre: e.target.value })
                  }
                  value={mensaje.Nombre}
                />
              </div>
              <div className="form-group form-group-icon">
                <i className="fas fa-envelope" aria-hidden="true" />
                <input
                  type="email"
                  className="form-control border-success"
                  placeholder="Correo electrónico"
                  required=""
                  onChange={(e) =>
                    setMensaje({ ...mensaje, Email: e.target.value })
                  }
                  value={mensaje.Email}
                />
              </div>
              <div className="form-group form-group-icon">
                <i className="fas fa-comments" aria-hidden="true" />
                <textarea
                  className="form-control border-info"
                  placeholder="Mensaje"
                  rows={6}
                  defaultValue={""}
                  onChange={(e) =>
                    setMensaje({
                      ...mensaje,
                      MensajeCuerpo: e.target.value,
                    })
                  }
                  value={mensaje.MensajeCuerpo}
                />
              </div>
              <div className="form-check">
                <input
                  required=""
                  className="form-check-input"
                  id="contact_gdpr"
                  name="contact_gdpr"
                  type="checkbox"
                  defaultValue={1}
                  onClick={() => {
                    setAceptar(!aceptar);
                    console.log(disabledSendBottom());
                  }}
                />
                <label className="form-check-label" htmlFor="contact_gdpr">
                  Acepto términos y condiciones{" "}
                  <button
                    className="text-decoration-none"
                    style={{
                      border: "none",
                      backgroundColor: "unset",
                      color: "#f0c24b",
                    }}
                    onClick={() => navigate(`/termsofservice`)}
                  >
                    (Términos y condiciones)
                  </button>
                </label>
              </div>
              <ReCAPTCHA
                sitekey={google_apiKey}
                onChange={onChange}
                style={{ transform: "scale(0.84)", transformOrigin: "0 0" }}
              />
            </form>
            <button
              className="btn btn-danger float-right text-uppercase"
              disabled={disabledSendBottom()}
              onClick={() => enviarMensaje()}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacto;
