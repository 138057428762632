import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import BreadCrumb from "./BreadCrumb";
import FormContact from "./FormContact";
import BannerContact from "./BannerContact";
import FooterService from "../../../services/Footer.service";
// import MapLibreUe from "../mapa/MapLibreUe";
import MapGoogle from "./MapGoogle/MapGoogle";

const Contactanos = () => {
  const [info, setInfo] = useState({
    Direccion: { Direccion: "", lat: "", long: "" },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init();
    getInfoContact();
    window.scrollTo(0, 0);
  }, []);

  const getInfoContact = async () => {
    try {
      const foot = await FooterService.getAll();
      if (!foot[0]) {
        return;
      }
      setInfo(foot[0]);
      setLoading(false);
    } catch (error) {}
  };

  return (
    <div className="main-wrapper contact-us">
      <BreadCrumb />
      <section className="py-8 py-md-10">
        <div className="container">
          <BannerContact />
          <div
            className="mb-10"
            id="map"
            style={{
              width: "100%",
              height: 420,
              backgroundColor: "red",
              marginTop: "-4%",
              position: "relative",
            }}
          >
            {/* <UbicationMap /> */}
            {!loading && (
              // <MapLibreUe
              //   positionAprox={[
              //     Number(info?.Direccion?.long),
              //     Number(info?.Direccion?.lat),
              //   ]}
              // />
              <MapGoogle
                positionApproximate={[
                  Number(info?.Direccion?.lat),
                  Number(info?.Direccion?.long),
                ]}
                text="FDC"
              />
            )}
          </div>
          <FormContact />
        </div>
      </section>
    </div>
  );
};

export default Contactanos;
