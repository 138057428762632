import React, { useEffect, useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import S3Service from "../../../services/S3.service";

const Alumno = ({ alumno }) => {
  const [image, setImage] = useState("");

  useEffect(() => {
    getAlumnoImage();
  }, [alumno]);

  const getAlumnoImage = async () => {
    try {
      if (alumno.imagen) {
        const image = await S3Service.get(alumno.imagen);
        setImage(image.result);
      }
    } catch (error) {
      console.log(error.mensaje);
    }
  };

  return (
    <ListItem disablePadding>
      <ListItemAvatar>
        <Avatar
          alt="perfil img"
          src={image ? image : "/static/images/avatar/1.jpg"}
        ></Avatar>
      </ListItemAvatar>
      <ListItemText secondary="Reporte individual" primary={alumno?.nombre} />
    </ListItem>
  );
};

export default Alumno;
