import { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { toast } from "react-toastify";

import ComnetarioService from "../../../services/Comentario.service";

const FormBlogMensaje = ({ blogId, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const { currentUser } = useContext(AuthContext);

  const sendMessage = async (event) => {
    event.preventDefault();

    if (!message) {
      toast.info("Error, tienes que colocar un texto");
      return;
    }
    setLoading(true);
    try {
      const newMessage = {
        name: currentUser.nombre,
        MensajeCuerpo: message,
        phone: phone,
        website: website,
        email: email,
        idBlog: blogId,
        imagenes: currentUser.imagen,
        IdUsuario: currentUser.id,
      };
      await ComnetarioService.create(newMessage);

      toast.info("Gracias, mensaje enviado");
      setLoading(false);
      setWebsite("");
      setEmail("");
      setMessage("");
      setPhone("");

      onSubmit();
    } catch (error) {
      console.log(error);
      toast.error("No se ha podido enviar tu mensaje");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={sendMessage}>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="form-group form-group-icon">
            <i className="fa fa-user" />
            <input
              type="text"
              className="form-control border-primary"
              placeholder="Telefono"
              required=""
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="form-group form-group-icon">
            <i className="fa fa-envelope" />
            <input
              type="email"
              className="form-control border-success"
              placeholder="Email address"
              required=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="form-group form-group-icon">
            <i className="fab fa-chrome" />
            <input
              type="text"
              className="form-control border-purple"
              placeholder="Website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group form-group-icon">
            <i className="fa fa-comments " />
            <textarea
              className="form-control border-info"
              placeholder="Write message"
              rows={6}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
      </div>
      <button className="btn btn-danger text-uppercase">submit</button>
    </form>
  );
};

export default FormBlogMensaje;
