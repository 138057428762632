import { useContext, useEffect, useState } from "react";
import styles from "../layout/assets/css/core.min.module.css";

import DetallesAlumno from "./DetallesAlumno";
import DetallesTabs from "./DetallesTabs";
import { CitaContext } from "../../../context/CitaContext";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AppointmentService from "../../../services/therapies/Appointment.service";
import AreaService from "../../../services/Area.service";
import Preloader from "../../utils/Preloader";

const DetallesCitaSeccion = () => {
  let today = new Date();
  const navigate = useNavigate();

  const { id } = useParams();
  const { currentUser } = useContext(AuthContext);
  const { currentCita, handleCurrentCita } = useContext(CitaContext);

  const [fechaCita, setFechaCita] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(today)
  );

  const [status, setStatus] = useState("Pendiente");
  const [detallesAdicionales, setDetallesAdicionales] = useState({});

  const [personas, setPersonas] = useState([]);
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState("N/A");

  const [historialClinico, setHistorialClinico] = useState({});

  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const handlePersonas = (newPersonas) => {
    setPersonas((old) => newPersonas);
  };

  const deletePersona = (nombre) => {
    let oldPersons = personas.filter((persona) => persona.nombre !== nombre);
    setPersonas(oldPersons);
  };

  const handleHistorialClinico = (newHistorialClinico) => {
    setHistorialClinico((old) => newHistorialClinico);
  };

  const handleArea = (event, newArea) => {
    setArea(newArea);
  };

  const handleFechaCita = (newFecha) => {
    let nuevaFechaCita = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(newFecha.toDate());
    setFechaCita(nuevaFechaCita);
  };

  const updateCita = async () => {
    if (!currentCita?.id) {
      toast.info("Error, tienes que seleccionar un alumno");
    } else if (!fechaCita) {
      toast.info("Error, Selecciona una fecha");
    } else {
      setLoading(true);
      try {
        const noticiaSubir = {
          cita: currentCita.id,
          area: area,
          status,
          detallesAdicionales,
          terapeuta: currentUser?.id,
          fechaCita,
        };

        await AppointmentService.update(noticiaSubir);

        toast.success("Cita actualizada!");
        navigate("/citas");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getAreas();
  }, []);

  const getAreas = async () => {
    try {
      const getData = await AreaService.getAll();
      if (getData) {
        setAreas(getData);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getCita();
  }, [id]);

  const getCita = async () => {
    setLoading(true);
    try {
      const data = await AppointmentService.getById(id);

      if (data) {
        handleCurrentCita(data);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Preloader />
  ) : (
    <>
      <DetallesAlumno
        alumno={currentCita?.alumno}
        fechaCita={fechaCita}
        handleFechacita={handleFechaCita}
      />

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div className={`${styles.mb4}`}>
            <div className={`${styles.row} ${styles["m-2"]}`}>
              <div className={`${styles["my-2"]} ${styles["col-sm-12"]}`}>
                <div
                  className={`${styles.borderLight}`}
                  style={{ textAlign: "right" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.row} ${styles.g4}`}>
        <DetallesTabs />
      </div>
    </>
  );
};

export default DetallesCitaSeccion;
