import httpClient from "./HttpClient";

export default class InformacionService {
  static async getCitasInfo(data) {
    return (await httpClient.post(`citas/info`, data)).data;
  }

  static async getUsersInfo(data) {
    return (await httpClient.post(`usuarios/info`, data)).data;
  }
}
