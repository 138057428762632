import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({});

const DesarrolloLenguaje = ({ data }) => {
  return (
    <View style={styles.container} wrap={true}>
      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Edad en la que balbuceó:${
          data?.edadBalbuceo ? data?.edadBalbuceo : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Edad en la que dijo la primera palabra: ${
          data?.edadPrimerPalabra ? data?.edadPrimerPalabra : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Edad en la que dijo su primera oración: ${
          data?.edadPrimerOracion ? data?.edadPrimerOracion : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Mastica: ${
          data?.mastica ? data?.mastica : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Actualmente como es su alimentación: ${
          data?.alimentacion ? data?.alimentacion : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Cuantas veces come al día: ${
          data?.comidasDia ? data?.comidasDia : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Su respiración es por: ${
          data?.respiracion ? data?.respiracion : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Ronca: ${
          data?.ronca ? data?.ronca : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Problemas para dormir: ${
          data?.problemasDormir ? data?.problemasDormir : "-"
        }`}</Text>
      </View>
    </View>
  );
};

export default DesarrolloLenguaje;
