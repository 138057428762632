import { Route, Routes, Navigate } from "react-router-dom";

// import FullPageLoading from "../utils/FullPageLoading";

//Private components
//#region PRIVATE
import PrivateLayout from "../components/private/layout";
//Carrusel
import CarruselTable from "../components/private/carrusel/CarruselTable";
import CrearCarrusel from "../components/private/carrusel/CrearCarrusel";
import EditarCarrusel from "../components/private/carrusel/EditarCarrusel";
import EliminarCarrusel from "../components/private/carrusel/EliminarCarrusel";
//Galeria
import GaleriaTable from "../components/private/galeria/GaleriaTable";
import CrearGaleria from "../components/private/galeria/CrearGaleria";
import EditarGaleria from "../components/private/galeria/EditarGaleria";
import EliminarGaleria from "../components/private/galeria/EliminarGaleria";

//Galeria
import PublicacionTable from "../components/private/youtube/PublicacionTable";
import CrearPublicacion from "../components/private/youtube/CrearPublicacion";
import EditarPublicacion from "../components/private/youtube/EditarPublicacion";
import EliminarPublicacion from "../components/private/youtube/EliminarPublicacion";
//Blog
import TablaBlog from "../components/private/blog/TablaBlog";
import CrearBlog from "../components/private/blog/CrearBlog";
import EditarBlog from "../components/private/blog/EditarBlog";
import EliminarBlog from "../components/private/blog/EliminarBlog";
//Categoria Blog
import TablaCategoriaBlog from "../components/private/categoria/TablaCategoriaBlog";
// Servicio
import TablaServicios from "../components/private/servicios/TablaServicios";
import CrearServicio from "../components/private/servicios/CrearServicio";
import EliminarServicio from "../components/private/servicios/EliminarServicio";
import EditarServicio from "../components/private/servicios/EditarServicio";
//Usuarios
import TablaUsuarios from "../components/private/usuarios/TablaUsuario";
import NuevoUsuario from "../components/private/usuarios/NuevoUsuario";
import EditarUsuario from "../components/private/usuarios/EditarUsuario";
import EliminarUsuario from "../components/private/usuarios/EliminarUsuario";

import TablaTerapeutas from "../components/private/terapeuta/TablaUsuario";
import NuevoTerapeuta from "../components/private/terapeuta/NuevoUsuario";
import EditarTerapeuta from "../components/private/terapeuta/EditarUsuario";
import EliminarTerapeuta from "../components/private/terapeuta/EliminarUsuario";

import TablaMaestros from "../components/private/maestro/TablaUsuario";
import NuevoMaestro from "../components/private/maestro/NuevoUsuario";
import EditarMaestro from "../components/private/maestro/EditarUsuario";
import EliminarMaestro from "../components/private/maestro/EliminarUsuario";

//Mensajes
import TablaMensaje from "../components/private/mensaje/TablaMensaje";
//Comentarios
import TablaComentarios from "../components/private/blog/comentarios/TablaComentarios";
//aviso
import TablaAviso from "../components/private/aviso/TablaAviso";
//footer
import TablaFooter from "../components/private/footer/TablaFooter";
//terminos
import TablaTerminos from "../components/private/terminos/TablaTerminos";
//index
import TablaIndex from "../components/private/index/TablaIndex";
//Contactanos
import TablaContactanos from "../components/private/contactanos/TablaContactanos";
//Nosotros
import TablaNosotros from "../components/private/nosotros/TablaContactanos";
//Counters
import TablaCounter from "../components/private/counter/TablaCounter";
//Alumnos
import AlumnoTable from "../components/private/alumno/Table";
import AlumnoTableTerapeutica from "../components/private/alumno/TableAreaTerapeutica";
import AlumnoTableEducativa from "../components/private/alumno/TableAreaEducativa";
import AlumnoCrear from "../components/private/alumno/Crear";
import AlumnoEditar from "../components/private/alumno/Editar";
import HistorialTable from "../components/private/historialClinico/Table";
import AreaTabla from "../components/private/area/Tabla";
import GrupoTabla from "../components/private/grupo/Tabla";
import AlumnoEliminar from "../components/private/alumno/Eliminar";
//#endregion

import PrivateRoute from "./PrivateRoute";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import Dashboard from "../components/private/dashboard/dashboard";
import CitaTable from "../components/private/cita/Table";
import CitaCrear from "../components/private/cita/Crear";
import DetallesCita from "../components/private/cita/Detalles";
import CrearResponsable from "../components/private/responsable/crearResponsable";
import SubAreasTabla from "../components/private/subArea/Tabla";
import DetallesNota from "../components/private/notas/Detalles";

import TablaReportes from "../components/private/reportes/Tabla";
import CrearReporte from "../components/private/reportes/Crear";
import EditarReporte from "../components/private/reportes/Editar";
import DetallesReporte from "../components/private/reportes/Detalles";

import TablaPlaneaciones from "../components/private/planeaciones/Tabla";
import CrearPlaneaciones from "../components/private/planeaciones/Crear";
import EditarPlaneaciones from "../components/private/planeaciones/Editar";
import DetallesPlaneaciones from "../components/private/planeaciones/Detalles";

import TablaMaterias from "../components/private/materias/Tabla";
import CrearMaterias from "../components/private/materias/Crear";
import EditarMaterias from "../components/private/materias/Editar";
import DetallesMaterias from "../components/private/materias/Detalles";

const PrivateRoutes = () => {
  const { checkRole } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={<PrivateLayout />}>
        <Route index element={<Dashboard />} />
        <Route
          path="carousel"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <CarruselTable />
            </PrivateRoute>
          }
        />
        <Route
          path="carousel/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <CrearCarrusel />
            </PrivateRoute>
          }
        />
        <Route
          path="carousel/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EditarCarrusel />
            </PrivateRoute>
          }
        />
        <Route
          path="carousel/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EliminarCarrusel />
            </PrivateRoute>
          }
        />
        <Route
          path="blog"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaBlog />
            </PrivateRoute>
          }
        />{" "}
        <Route
          path="blog/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <CrearBlog />
            </PrivateRoute>
          }
        />{" "}
        <Route
          path="blog/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EditarBlog />
            </PrivateRoute>
          }
        />{" "}
        <Route
          path="blog/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EliminarBlog />
            </PrivateRoute>
          }
        />{" "}
        <Route
          path="categoriaBlog"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaCategoriaBlog />
            </PrivateRoute>
          }
        />
        <Route
          path="servicio"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaServicios />
            </PrivateRoute>
          }
        />
        <Route
          path="servicio/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <CrearServicio />
            </PrivateRoute>
          }
        />
        <Route
          path="servicio/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EliminarServicio />
            </PrivateRoute>
          }
        />
        <Route
          path="servicio/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EditarServicio />
            </PrivateRoute>
          }
        />
        <Route
          path="usuario"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaUsuarios />
            </PrivateRoute>
          }
        />
        <Route
          path="usuario/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <NuevoUsuario />
            </PrivateRoute>
          }
        />
        <Route
          path="usuario/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EditarUsuario />
            </PrivateRoute>
          }
        />
        <Route
          path="usuario/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EliminarUsuario />
            </PrivateRoute>
          }
        />
        <Route
          path="terapeutas"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaTerapeutas />
            </PrivateRoute>
          }
        />
        <Route
          path="terapeutas/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <NuevoTerapeuta />
            </PrivateRoute>
          }
        />
        <Route
          path="terapeutas/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EditarTerapeuta />
            </PrivateRoute>
          }
        />
        <Route
          path="terapeutas/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EliminarTerapeuta />
            </PrivateRoute>
          }
        />
        <Route
          path="maestros"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaMaestros />
            </PrivateRoute>
          }
        />
        <Route
          path="maestros/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <NuevoMaestro />
            </PrivateRoute>
          }
        />
        <Route
          path="maestros/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EditarMaestro />
            </PrivateRoute>
          }
        />
        <Route
          path="maestros/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EliminarMaestro />
            </PrivateRoute>
          }
        />
        <Route
          path="aviso"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaAviso />
            </PrivateRoute>
          }
        />
        <Route
          path="footer"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaFooter />
            </PrivateRoute>
          }
        />
        <Route
          path="terminos"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaTerminos />
            </PrivateRoute>
          }
        />
        <Route
          path="index"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaIndex />
            </PrivateRoute>
          }
        />
        <Route
          path="contactanos"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaContactanos />
            </PrivateRoute>
          }
        />
        <Route
          path="nosotros"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaNosotros />
            </PrivateRoute>
          }
        />
        <Route
          path="mensajes"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaMensaje />
            </PrivateRoute>
          }
        />
        <Route
          path="comentarios"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaComentarios />
            </PrivateRoute>
          }
        />
        <Route
          path="galeria"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <GaleriaTable />
            </PrivateRoute>
          }
        />
        <Route
          path="galeria/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <CrearGaleria />
            </PrivateRoute>
          }
        />
        <Route
          path="galeria/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EditarGaleria />
            </PrivateRoute>
          }
        />
        <Route
          path="galeria/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EliminarGaleria />
            </PrivateRoute>
          }
        />
        <Route
          path="publicacion"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <PublicacionTable />
            </PrivateRoute>
          }
        />
        <Route
          path="publicacion/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <CrearPublicacion />
            </PrivateRoute>
          }
        />
        <Route
          path="publicacion/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EditarPublicacion />
            </PrivateRoute>
          }
        />
        <Route
          path="publicacion/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <EliminarPublicacion />
            </PrivateRoute>
          }
        />
        <Route
          path="counters"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <TablaCounter />
            </PrivateRoute>
          }
        />
        <Route
          path="subAreas"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <SubAreasTabla />
            </PrivateRoute>
          }
        />
        <Route
          path="grupos"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <GrupoTabla />
            </PrivateRoute>
          }
        />
        <Route
          path="area-educativa"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <AlumnoTableEducativa />
            </PrivateRoute>
          }
        />
        <Route
          path="area-terapeutica"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Terapeuta"])}
            >
              <AlumnoTableTerapeutica />
            </PrivateRoute>
          }
        />
        <Route
          path="alumnos/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Terapeuta", "Maestro"])}
            >
              <AlumnoCrear />
            </PrivateRoute>
          }
        />
        <Route
          path="alumnos/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Terapeuta", "Maestro"])}
            >
              <AlumnoEditar />
            </PrivateRoute>
          }
        />
        <Route
          path="alumnos/eliminar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Terapeuta", "Maestro"])}
            >
              <AlumnoEliminar />
            </PrivateRoute>
          }
        />
        <Route
          path="historial/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Terapeuta", "Maestro"])}
            >
              <HistorialTable />
            </PrivateRoute>
          }
        />
        <Route
          path="citas"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Terapeuta"])}
            >
              <CitaTable />
            </PrivateRoute>
          }
        />
        <Route
          path="citas/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador"])}
            >
              <CitaCrear />
            </PrivateRoute>
          }
        />
        <Route
          path="citas/detalles/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Terapeuta", "Maestro"])}
            >
              <DetallesCita />
            </PrivateRoute>
          }
        />
        <Route
          path="notas/detalles/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <DetallesNota />
            </PrivateRoute>
          }
        />
        <Route
          path="responsable/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <CrearResponsable />
            </PrivateRoute>
          }
        />
        <Route
          path="reportes"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <TablaReportes />
            </PrivateRoute>
          }
        />
        <Route
          path="reportes/nuevo/"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <CrearReporte />
            </PrivateRoute>
          }
        />
        <Route
          path="reportes/nuevo/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <CrearReporte />
            </PrivateRoute>
          }
        />
        <Route
          path="reportes/detalles/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <DetallesReporte />
            </PrivateRoute>
          }
        />
        <Route
          path="reportes/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <EditarReporte />
            </PrivateRoute>
          }
        />
        <Route
          path="planeaciones"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <TablaPlaneaciones />
            </PrivateRoute>
          }
        />
        <Route
          path="planeaciones/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <CrearPlaneaciones />
            </PrivateRoute>
          }
        />
        <Route
          path="planeaciones/nuevo/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <CrearPlaneaciones />
            </PrivateRoute>
          }
        />
        <Route
          path="planeaciones/detalles/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <DetallesPlaneaciones />
            </PrivateRoute>
          }
        />
        <Route
          path="planeaciones/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <EditarPlaneaciones />
            </PrivateRoute>
          }
        />
        <Route
          path="materiales"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <TablaMaterias />
            </PrivateRoute>
          }
        />
        <Route
          path="materiales/nuevo"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <CrearMaterias />
            </PrivateRoute>
          }
        />
        <Route
          path="materiales/nuevo/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <CrearMaterias />
            </PrivateRoute>
          }
        />
        <Route
          path="materiales/detalles/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <DetallesMaterias />
            </PrivateRoute>
          }
        />
        <Route
          path="materiales/editar/:id"
          element={
            <PrivateRoute
              redirectPath="/"
              isAllowed={checkRole(["Administrador", "Maestro"])}
            >
              <EditarMaterias />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate replace to="carousel" />} />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
