import { CircularProgress, Typography } from "@mui/material";
import styles from "../../private/layout/assets/css/core.min.module.css";

import DropZoneEdit from "../../DropZoneEdit";
import { useState } from "react";

const CustomSelectImagePerfil = ({ url, file, handleFile, errors }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const processFile = async (processFileImage) => {
    setProgress(0);
    setIsLoading(true);
    try {
      handleFile(processFileImage);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`${styles.cardBody}`}>
      <div>
        {errors ? (
          <Typography typography="caption" style={{ color: "red" }}>
            {errors}
          </Typography>
        ) : null}
      </div>

      {isLoading ? (
        <div className={`${styles.colLg12}`}>
          <div
            style={{
              textAlign: "center",
              height: "300px",
              alignContent: "center",
            }}
          >
            <CircularProgress
              sx={{ color: "#9b242c" }}
              variant="determinate"
              value={progress}
            />
          </div>
        </div>
      ) : file ? (
        <div>
          <div className={`${styles.colLg12}`}>
            <div style={{ textAlign: "center" }}>
              <img
                src={URL.createObjectURL(file)}
                alt="maestro previsualizado"
                style={{
                  height: 300,
                  objectFit: "contain",
                  width: "-webkit-fill-available",
                }}
              />
              <Typography
                variant="body1"
                component="span"
                style={{
                  width: "-webkit-fill-available",
                }}
              >
                {file?.name}
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div className={`${styles.colLg12}`}>
          <div style={{ textAlign: "center" }}>
            <img
              src={url ? url : "/assets/img/no-image.svg"}
              style={{
                height: 300,
                objectFit: "contain",
                width: "-webkit-fill-available",
              }}
            />
            <Typography
              variant="body1"
              component="span"
              style={{
                width: "-webkit-fill-available",
              }}
            >
              {url ? "" : "Imagen no seleccionada"}
            </Typography>
            {url ? null : (
              <Typography typography="caption" color="#9b242c">
                * Obligatorio
              </Typography>
            )}
          </div>
        </div>
      )}
      <div style={{ width: "-webkit-fill-available" }}>
        <DropZoneEdit saveFile={processFile} />
      </div>
    </div>
  );
};

export default CustomSelectImagePerfil;
