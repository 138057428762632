import React, { useEffect, useState, useContext } from "react";
import NosotrosService from "../../../services/Nosotros.service";
import Logo from "./assets/logoImagen.jpg";

const Nosotros = () => {
  const [loading, setLoading] = useState(true);
  const [nosotros, setNosotros] = useState([]);

  useEffect(() => {
    getNosotros();
    window.scrollTo(0, 0);
  }, []);

  const getNosotros = async () => {
    try {
      const data = await NosotrosService.getAll();
      setNosotros(data[0]);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <>
      <section
        className="py-7 py-md-10"
        id="nosotros"
        style={{ marginBottom: "-90px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-xs-12 order-md-1">
              <div className="image mb-4 mb-md-0">
                <img
                  className="img-fluid rounded"
                  src={Logo}
                  alt="features-img1.jpg"
                />
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="">
                <div className="section-title mb-4">
                  <h2 className="text-danger ps-0">Nosotros</h2>
                </div>
                <p
                  className="text-dark font-size-15 mb-4"
                  dangerouslySetInnerHTML={{
                    __html: nosotros?.Cuerpo,
                  }}
                  style={{ textAlign: "justify" }}
                ></p>
                <h3 className="text-danger ps-0">Misión</h3>
                <p
                  className="text-dark font-size-15 mb-4"
                  dangerouslySetInnerHTML={{
                    __html: nosotros?.Mision,
                  }}
                  style={{ textAlign: "justify" }}
                ></p>
                <h3 className="text-danger ps-0">Visión </h3>
                <p
                  className="text-dark font-size-15 mb-4"
                  dangerouslySetInnerHTML={{
                    __html: nosotros?.Vision,
                  }}
                  style={{ textAlign: "justify" }}
                ></p>
                {/* <ul className="list-unstyled mb-5">
                  <li className="d-flex align-items-baseline text-muted mb-2">
                    <i className="fa fa-check me-2" aria-hidden="true" />
                    Vestibulum iaculis quam ac libero volutpat placerat.
                  </li>
                  <li className="d-flex align-items-baseline text-muted mb-2">
                    <i className="fa fa-check me-2" aria-hidden="true" />
                    Phasellus sit amet risus fringilla, aliquet lacus vel,
                    suscipit lorem.
                  </li>
                  <li className="d-flex align-items-baseline text-muted mb-2">
                    <i className="fa fa-check me-2" aria-hidden="true" />
                    Etiam suscipit velit et tellus bibendum interdum.
                  </li>
                  <li className="d-flex align-items-baseline text-muted mb-2">
                    <i className="fa fa-check me-2" aria-hidden="true" />
                    Vestibulum iaculis quam ac libero volutpat placerat.
                  </li>
                  <li className="d-flex align-items-baseline text-muted mb-2">
                    <i className="fa fa-check me-2" aria-hidden="true" />
                    Etiam suscipit velit et tellus bibendum interdum.
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Nosotros;
