import React , { useEffect }  from "react";
import { Outlet } from "react-router-dom";
import { CartProvider } from "react-use-cart";

import FooterPublicLayout from "./Footer/FooterPublicLayout";
import HeaderPublicLayout from "./Header/HeaderPublicLayout";

const PublicLayout = () => {  
  useEffect(() => {
    require("../assets/css/kidz.css");
  }, []);

  return (
      <CartProvider>      
          <HeaderPublicLayout />
          <Outlet />
          <FooterPublicLayout />      
      </CartProvider>
  );
};

export default PublicLayout;
