import { useEffect, useState } from "react";
import Preloader from "../../utils/Preloader";
import styles from "../layout/assets/css/core.min.module.css";
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import InformacionService from "../../../services/Informacion.service";
import { toast } from "react-toastify";
import { FormatDate } from "../../utils/Format";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import PeopleIcon from "@mui/icons-material/People";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

const DashboardTeacher = () => {
  const navigate = useNavigate();
  const today = new Date();

  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    // getAlumnoImage();
    getInfo();
  }, []);

  const getInfo = async () => {
    try {
      const data = await InformacionService.getUsersInfo({
        fecha: today.toISOString(),
      });
      if (data) {
        setInfo(data);
      }
    } catch (error) {
      toast.error(error.mensaje);
    }
  };

  return loading ? (
    <Preloader />
  ) : (
    <main
      id={styles["middle"]}
      className={`${styles.flexFill} ${styles.mxAuto}`}
    >
      <header>
        <h1 className={`${styles.h4}`}>Inicio</h1>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb} ${styles.small}`}>
            <li
              className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
              aria-current="page"
            >
              Inicio
            </li>
          </ol>
        </nav>
      </header>

      <Grid container>
        <Grid sm={12} md={3} item>
          <List>
            <ListItem>
              <ListItemButton
                sx={{ color: "#9b242c" }}
                onClick={() => navigate("/alumnos/nuevo")}
              >
                Agregar alumno
              </ListItemButton>
            </ListItem>
            <ListItem></ListItem>
          </List>
        </Grid>
        <Grid sm={12} md={3} item>
          <List>
            <ListItem>
              <ListItemButton
                sx={{ color: "#9b242c" }}
                onClick={() => navigate("/citas/nuevo")}
              >
                Crear cita
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid sm={12} md={3} item>
          <List>
            <ListItem>
              <ListItemButton
                sx={{ color: "#9b242c" }}
                onClick={() => navigate("/terapeutas/nuevo")}
              >
                Crear terapeuta
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid sm={12} md={3} item>
          <List>
            <ListItem>
              <ListItemButton
                sx={{ color: "#9b242c" }}
                onClick={() => navigate("/maestros/nuevo")}
              >
                Crear maestro
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
      </Grid>

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div className={`${styles.section} ${styles.mb4}`}>
            <div className={`${styles.cardBody} ${styles["m-2"]}`}>
              <Grid container>
                <Grid md={12} item>
                  <Typography typography="h6">{FormatDate(today)}</Typography>
                </Grid>
                <Grid md={6} item>
                  <List>
                    <ListItem>
                      <ListItemAvatar sx={{ color: "#9b242c" }}>
                        <StickyNote2Icon />
                      </ListItemAvatar>
                      <ListItemText primary="Citas realizadas" />
                      <ListItemAvatar>{info?.citas}</ListItemAvatar>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar sx={{ color: "#9b242c" }}>
                        <NoteAltIcon />
                      </ListItemAvatar>
                      <ListItemText primary="Asistencia" />
                      <ListItemAvatar>
                        {info?.citas &&
                          (
                            (Number(info?.citasAtendidas) /
                              Number(info?.citas)) *
                            100
                          ).toFixed(2) + "%"}
                      </ListItemAvatar>
                    </ListItem>
                  </List>
                </Grid>
                <Grid md={6} item>
                  <List>
                    <ListItem>
                      <ListItemAvatar sx={{ color: "#9b242c" }}>
                        <RecentActorsIcon />
                      </ListItemAvatar>
                      <ListItemText primary="Alumnos registrados" />
                      <ListItemAvatar>
                        {info?.alumnosRegistrados}
                      </ListItemAvatar>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar sx={{ color: "#9b242c" }}>
                        <PeopleIcon />
                      </ListItemAvatar>
                      <ListItemText primary="Terapeutas registrados" />
                      <ListItemAvatar>
                        {info?.terapeutasRegistrados}
                      </ListItemAvatar>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar sx={{ color: "#9b242c" }}>
                        <PeopleAltIcon />
                      </ListItemAvatar>
                      <ListItemText primary="Maestros registrados" />
                      <ListItemAvatar>
                        {info?.maestrasRegistradas}
                      </ListItemAvatar>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>

      <div style={{ position: "fixed", bottom: 0, right: 0, color: "#e5e3df" }}>
        V.eebb1ff
      </div>
    </main>
  );
};

export default DashboardTeacher;
