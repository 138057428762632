import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import AppointmentService from "../../../services/therapies/Appointment.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";

export default function DialogCancelCita({ currentCita }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Button variant="text" color="error" onClick={handleClickOpen}>
        Marcar como cancelada
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const motivoCancel = formJson.motivoCancel;

            if (!motivoCancel) {
              toast.error("Motivo de cancelación requerido");
              return;
            }

            try {
              let citaRegisterData = {
                id: currentCita.id,
                duration: 1,
                status: "Cancelada",
                additionalDetails: {
                  motivoCancel: motivoCancel,
                },
              };
              await AppointmentService.update(citaRegisterData);
              toast.info("Cancelación registrada");
              navigate("/citas");
            } catch (error) {
              toast.error(error);
            }
            handleClose();
          },
        }}
      >
        <DialogContent>
          <DialogContentText>
            Para registrar la cancelación de una cita, escribe el motivo.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="motivoCancel"
            name="motivoCancel"
            label="Motivo cancelación"
            type="text"
            fullWidth
            variant="standard"
            sx={{
              "& .MuiInputLabel-root": {
                color: "#9b242c",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "#9b242c" }}>
            Regresar
          </Button>
          <Button
            type="submit"
            sx={{
              color: "#fff",
              backgroundColor: "#9b242c",
              "&:hover": {
                backgroundColor: "#df222f",
                borderColor: "#df222f",
                boxShadow: "none",
              },
            }}
          >
            Registrar cancelación
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
