import httpClient from "./HttpClient";
const prefix = "/mensaje";

export default class MensajeService {
  static async create(mensaje) {
    return (await httpClient.post(`${prefix}/`, mensaje)).data;
  }

  static async update(mensaje) {
    return (await httpClient.put(`${prefix}/${mensaje.id}`, mensaje)).data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async getByStatus(status) {
    return (await httpClient.get(`${prefix}-status/${status}`)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

  static async list(limit, offset) {
    return (await httpClient.get(`${prefix}/${limit}/${offset}`)).data;
  }
}
