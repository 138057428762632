import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthContext } from "../../../context/AuthContext";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
} from "@mui/material";
import OptionsTableAreaEducativa from "../OptionsTableAreaEducativa";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationActionsTable({
  alumnos,
  page,
  total,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const navigate = useNavigate();
  const { checkRole } = React.useContext(AuthContext);
  const [open, setOpen] = React.useState(-1);

  const handleClick = (index) => {
    if (index === open) {
      setOpen(-1);
    } else {
      setOpen(index);
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - alumnos?.length) : 0;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableBody>
          {alumnos?.map((row, index) => (
            <TableRow key={row.id + "-" + index}>
              <TableCell
                style={{ width: 80 }}
                align="right"
                component="th"
                scope="row"
              >
                <ListItemAvatar>
                  <Avatar
                    loading="lazy"
                    alt="Remy Sharp"
                    src={row.url ? row.url : "/static/images/avatar/1.jpg"}
                  />
                </ListItemAvatar>
              </TableCell>
              <TableCell>
                <ListItemText
                  primary={row.nombre}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline", cursor: "pointer" }}
                        component="span"
                        variant="caption"
                        color="text.primary"
                      >
                        Información:
                      </Typography>
                      <Typography
                        sx={{
                          display: "inline",
                          cursor: "pointer",
                          color: "green",
                          marginInline: "0.5rem",
                        }}
                        component="a"
                        variant="body2"
                        onClick={() => navigate("/historial/" + row.id)}
                      >
                        Historial
                      </Typography>
                      <ListItemText
                        secondary={
                          <React.Fragment>
                            {row?.grupo && (
                              <>
                                <Typography
                                  sx={{ display: "inline", cursor: "pointer" }}
                                  component="span"
                                  variant="caption"
                                  color="text.primary"
                                >
                                  Grupo:
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    cursor: "pointer",
                                    color: "green",
                                    marginInline: "0.5rem",
                                  }}
                                  component="a"
                                  variant="body2"
                                >
                                  {row?.grupo?.nombre}
                                </Typography>
                              </>
                            )}
                            {row?.subAreas[0] && (
                              <>
                                <Typography
                                  sx={{ display: "inline", cursor: "pointer" }}
                                  component="span"
                                  variant="caption"
                                  color="text.primary"
                                >
                                  Sub-Áreas:
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    cursor: "pointer",
                                    color: "green",
                                    marginInline: "0.5rem",
                                  }}
                                  component="a"
                                  variant="body2"
                                >
                                  {row?.subAreas?.map(
                                    (subAream) => subAream.nombre + ", "
                                  )}
                                </Typography>
                              </>
                            )}
                          </React.Fragment>
                        }
                      />
                    </React.Fragment>
                  }
                />
              </TableCell>
              <TableCell style={{ width: 320 }} align="right">
                {/* <OptionsTableAreaEducativa keyOption={row?.id} /> */}
                {checkRole(["Administrador"]) && (
                  <ButtonGroup
                    variant="text"
                    aria-label="Basic button group"
                    color="inherit"
                  >
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => navigate("/alumnos/editar/" + row.id)}
                      style={{ marginInline: "0.1rem" }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => navigate("/alumnos/eliminar/" + row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ButtonGroup>
                )}
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        display: "inline-flex",
                        width: "100%",
                        cursor: "pointer",
                        textAlign: "right",
                      }}
                      component="span"
                      variant="caption"
                      color="text.primary"
                    >
                      Creación de recursos:
                    </Typography>
                  }
                  secondary={
                    <ButtonGroup
                      variant="text"
                      aria-label="Basic button group"
                      color="inherit"
                    >
                      <Button
                        startIcon={
                          <DriveFileRenameOutlineIcon
                            sx={{ color: "#9b242c" }}
                          />
                        }
                        onClick={() => navigate("/notas/detalles/" + row?.id)}
                      >
                        Nota
                      </Button>
                      <Button
                        startIcon={<NoteAltIcon sx={{ color: "#9b242c" }} />}
                        onClick={() => navigate("/reportes/nuevo/" + row?.id)}
                      >
                        Reporte
                      </Button>
                      <Button
                        startIcon={
                          <AppRegistrationIcon sx={{ color: "#9b242c" }} />
                        }
                        onClick={() => navigate("/materiales/nuevo/" + row?.id)}
                      >
                        Material
                      </Button>
                    </ButtonGroup>
                  }
                />

                {/* <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                  }}
                  component="nav"
                > */}
                {/* <ListItemButton onClick={() => handleClick(index)}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Opciones" />
                    {open === index ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>

                  <Collapse in={open === index} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => navigate("/notas/detalles/" + row?.id)}
                      >
                        <ListItemIcon>
                          <DriveFileRenameOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Nota" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => navigate("/reportes/nuevo/" + row?.id)}
                      >
                        <ListItemIcon>
                          <NoteAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Reporte" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => navigate("/materiales/nuevo/" + row?.id)}
                      >
                        <ListItemIcon>
                          <AppRegistrationIcon />
                        </ListItemIcon>
                        <ListItemText primary="Material" />
                      </ListItemButton>
                    </List>
                  </Collapse> */}
                {/* </List> */}
              </TableCell>
              {/* {checkRole(["Administrador"]) && (
                <TableCell style={{ width: 160 }} align="right">
                  <div>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => navigate("/alumnos/editar/" + row.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => navigate("/alumnos/eliminar/" + row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </TableCell>
              )} */}
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 88.52 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              labelRowsPerPage="Items por pagina"
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={3}
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
