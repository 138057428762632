import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Typography, useTheme } from "@mui/material";

export default function DropZone({ saveFile }) {
  const theme = useTheme();
  const onDrop = useCallback((acceptedFiles) => {
    saveFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: [".jpg", ".png", ".jpeg", ".JPG", ".PNG", ".JPEG"],
    maxSize: 5242880,
  });

  return (
    <div
      {...getRootProps()}
      style={{
        height: "50px",
        borderColor: "withe",
        alignItems: "center",
        width: "-webkit-fill-available",
        border: "dashed",
        padding: "20px",
        margin: "30px",
        backgroundColor: "#969696",
        borderRadius: "10px",
        backgroundImage:
          "url(https://cdn-icons-png.flaticon.com/512/126/126477.png)",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      <input {...getInputProps()} />
      <Typography
        variant="subtitle2"
        textAlign="center"
        style={{ padding: 20 }}
      >
        {/* {isDragActive
          ? "Arroja aqui tus archivos"
          : "Arrastra aqui o click para agregar una foto"} */}
      </Typography>
    </div>
  );
}
