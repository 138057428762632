import { Chip } from "@mui/material";

const statusColor = {
  Inicial: "primary",
  Intermedio: "warning",
  Final: "success",
};

const CustomStatus = ({ status }) => {
  return (
    <div>
      <Chip
        variant="filled"
        color={statusColor[status] ? statusColor[status] : "default"}
        sx={{ width: "25px", height: "15px" }}
      />
    </div>
  );
};

export default CustomStatus;
