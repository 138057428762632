import React from "react";

import { Link, Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  rowcontent: {
    marginBottom: 2,
  },
  row: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
    gap: 5,
    flexWrap: "wrap",
  },
  blacktext: { fontWeight: "bold" },
});

const DatosCliente = ({ alumno }) => {
  const fechaFormat = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const nombre = day + "/" + month + "/" + year;
    return nombre;
  };

  return (
    <View style={[styles.rowcontent]}>
      <View style={styles.row}>
        <Text>Nombre:</Text>
        <Text>{" " + alumno?.nombre}</Text>
      </View>
      <View style={[styles.row, , styles.blacktext]}>
        <Text>
          {"Fecha nacimiento: " + fechaFormat(alumno?.fechaNacimiento)}
        </Text>
        <Text>{"Sexo: " + alumno?.sexo}</Text>
      </View>
      <View style={[styles.row, , styles.blacktext]}>
        <Text>
          {"Con quien vive: " +
            (alumno?.responsable === "Ambos"
              ? "Ambos padres"
              : alumno?.responsable)}
        </Text>
      </View>
      <View style={[styles.row, , styles.blacktext]}>
        <Text>Asignaciones</Text>
      </View>
      <View style={[styles.row, , styles.blacktext]}>
        <Text>
          {alumno?.subAreas && alumno?.subAreas?.length > 0
            ? "Sub-Areas: " + alumno?.subAreas?.map((subarea) => subarea.nombre)
            : null}
        </Text>
      </View>
      <View style={[styles.row, , styles.blacktext]}>
        <Text>{alumno?.grupo ? "Grupo: " + alumno?.grupo.nombre : null}</Text>
      </View>
    </View>
  );
};

export default DatosCliente;
