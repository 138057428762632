/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import {
  Avatar,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";

/* Servicios */
import AlumnoService from "../../../services/Alumno.service";
import S3Service from "../../../services/S3.service";

import styles from "../layout/assets/css/core.min.module.css";
import DetallesHistorialClinico from "./DetallesHistorialClinico";
import PreviewIcon from "@mui/icons-material/Preview";
import WarningIcon from "@mui/icons-material/Warning";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GroupsIcon from "@mui/icons-material/Groups";

const HistorialTable = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [alumno, setAlumno] = useState(null);
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const [filesArray, setFilesArray] = useState([]);
  const [open, setOpen] = useState(false);
  const [urlSelected, setUrlSelected] = useState("");
  const [elementTitle, setElementTitle] = useState("");

  const handleClickOpen = async (element) => {
    if (!element || !element.keyFile) {
      toast.info("No hay un archivo seleccionado.");
      return;
    }
    try {
      const result = await S3Service.get(element.keyFile);
      setUrlSelected((old) => result.result);
      setElementTitle((old) => element.name);
      setOpen(true);
    } catch (error) {
      toast.error("No hay un archivo disponible.");
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getHistorial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHistorial = async () => {
    setLoading(true);
    try {
      const getData = await AlumnoService.getById(id);
      if (getData[0]) {
        let data = getData[0];

        if (data.imagen) {
          const image = await S3Service.get(data.imagen);
          data.url = image.result;
        }
        setAlumno(data);
        setFilesArray((old) => data.archivos);
        setFechaNacimiento(
          new Intl.DateTimeFormat("az", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }).format(new Date(data.fechaNacimiento))
        );
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <main
          id={styles["middle"]}
          className={`${styles.flexFill} ${styles.mxAuto}`}
        >
          <header>
            <h1 className={`${styles.h4}`}>Historial</h1>
            <nav aria-label="breadcrumb">
              <ol className={`${styles.breadcrumb} ${styles.small}`}>
                <li className={`${styles.breadcrumbItem}`}>
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "unset",
                      color: "#9b242c",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Alumnos
                  </button>
                </li>
                <li
                  className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                  aria-current="page"
                >
                  Historial clínico
                </li>
              </ol>
            </nav>
          </header>

          <div
            className={`${styles.row} ${styles.g4}`}
            style={{ width: "100%", overflow: "auto" }}
          >
            <div className={`${styles.col}`}>
              <div className={`${styles.section} ${styles.mb4}`}>
                <div className={`${styles.cardBody}`}>
                  <div className={`${styles.cardBody} ${styles.pt1}`}>
                    {/* item list */}
                    <div className={`${styles.cardHeader} ${styles.p4}`}>
                      <div className={`${styles.row} ${styles.g3}`}>
                        {/* options */}
                        <div
                          className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                        ></div>
                        <div
                          className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
                        ></div>
                      </div>
                    </div>
                    <div
                      className={`${styles.row} ${styles.tableResponsiveMd}`}
                    >
                      <Dialog
                        open={open}
                        maxWidth="xl"
                        sx={{ height: "100%", width: "75%", left: "12.5%" }}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        fullScreen
                      >
                        <DialogTitle id="alert-dialog-title">
                          {elementTitle}
                        </DialogTitle>
                        <DialogContent>
                          {open && (
                            <CardMedia
                              component="iframe"
                              src={urlSelected}
                              height="100%"
                            />
                          )}
                        </DialogContent>
                        <DialogActions>
                          <button
                            className={`${styles.btn} ${styles.btnPrimary}`}
                            onClick={handleClose}
                          >
                            <span className={`${styles.small}`}>Cerrar</span>
                          </button>
                        </DialogActions>
                      </Dialog>
                      <div className={`${styles.row} ${styles.colSm12}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Alumno
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <div
                                className={`${styles.row} ${styles.colSm12}`}
                              >
                                <div className={`${styles.colSm3}`}>
                                  <div className={`${styles.colLg12}`}>
                                    <div className={`${styles.colLg12}`}>
                                      <div style={{ textAlign: "center" }}>
                                        <img
                                          src={
                                            alumno?.url
                                              ? alumno.url
                                              : "/assets/img/no-image.svg"
                                          }
                                          alt="perfil"
                                          style={{
                                            height: 300,
                                            objectFit: "contain",
                                            width: "-webkit-fill-available",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles["col-sm-1"]}`}></div>
                                <div className={`${styles["col-sm-8"]}`}>
                                  <div className={`${styles["col-sm-12"]}`}>
                                    <div className={`${styles["m-4"]}`}>
                                      <Typography>
                                        Nombre del alumno:{" "}
                                        <span
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {alumno?.nombre}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div className={`${styles["m-4"]}`}>
                                      <Typography>
                                        Fecha nacimiento:
                                        <span
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {fechaNacimiento
                                            ? fechaNacimiento
                                            : "-"}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div className={`${styles["m-4"]}`}>
                                      <Typography>
                                        Sexo:
                                        <span
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {alumno?.sexo}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div className={`${styles["m-4"]}`}>
                                      <Typography>Domicilio</Typography>
                                      <Box
                                        className={`${styles["row"]} ${styles.colSm12}`}
                                      >
                                        <div className={`${styles["colSm6"]}`}>
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              Calle:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {alumno?.domicilio?.calle ||
                                                  "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>

                                        <div className={`${styles["colSm3"]}`}>
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              # Interior:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {alumno?.domicilio?.interior ||
                                                  "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>

                                        <div className={`${styles["colSm3"]}`}>
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              # Exterior:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {alumno?.domicilio?.exterior ||
                                                  "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>

                                        <div
                                          className={`${styles["col-sm-8"]}`}
                                        >
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              Colonia:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {alumno?.domicilio?.colonia ||
                                                  "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>

                                        <div
                                          className={`${styles["col-sm-4"]}`}
                                        >
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              Código postal:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {alumno?.domicilio
                                                  ?.codigoPostal || "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>

                                        <div className={`${styles["colSm6"]}`}>
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              Delegación o municipio:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {alumno?.domicilio?.municipio ||
                                                  "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>

                                        <div className={`${styles["colSm6"]}`}>
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              Entidad federativa o estado:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {alumno?.domicilio?.estado ||
                                                  "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>
                                      </Box>
                                    </div>
                                    <div className={`${styles["m-4"]}`}>
                                      <Typography>
                                        Con quien vive?:{" "}
                                        <span
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {alumno?.responsable || "-"}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div className={`${styles["m-4"]}`}>
                                      <Typography>
                                        {/* <span
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {alumno?.viveConEspecificacion}
                                        </span> */}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`${styles.row} ${styles.colSm12}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Documentos
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <Grid container>
                                <Grid item xs={12} sm={12}>
                                  <List>
                                    <Divider component="li" />
                                    {filesArray?.length > 0 ? (
                                      filesArray.map((element, index) => {
                                        return (
                                          <ListItem
                                            secondaryAction={
                                              <IconButton
                                                onClick={() =>
                                                  handleClickOpen(element)
                                                }
                                              >
                                                <PreviewIcon />
                                              </IconButton>
                                            }
                                          >
                                            <ListItemIcon>
                                              <FontAwesomeIcon
                                                icon={faFilePdf}
                                              />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={element.name}
                                            />
                                          </ListItem>
                                        );
                                      })
                                    ) : (
                                      <ListItem>
                                        <ListItemIcon>
                                          <WarningIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Sin elementos" />
                                      </ListItem>
                                    )}
                                    <Divider component="li" />
                                  </List>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.row} ${styles.colSm12}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Asignaciones
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <Grid container>
                                <Grid item xs={12} sm={6}>
                                  {alumno?.grupo && (
                                    <>
                                      <ListItem>
                                        <ListItemText primary="Grupo" />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemAvatar>
                                          <Avatar
                                            sx={{
                                              backgroundColor: alumno?.grupo
                                                ?.color
                                                ? alumno?.grupo.color
                                                : "#f2f2f2",
                                            }}
                                          >
                                            <GroupsIcon />
                                          </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                          primary={alumno?.grupo?.nombre}
                                          secondary={
                                            <ListItemText
                                              primary={
                                                "Edad: " +
                                                alumno?.grupo?.rangoEdad
                                                  ?.rangoInicial +
                                                " - " +
                                                alumno?.grupo?.rangoEdad
                                                  ?.rangoFinal
                                              }
                                              secondary={
                                                alumno?.grupo?.area?.tipo
                                              }
                                            />
                                          }
                                        />
                                      </ListItem>
                                    </>
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  {alumno?.subAreas[0] && (
                                    <>
                                      <ListItem>
                                        <ListItemText primary="Sub-Áreas" />
                                      </ListItem>

                                      <ListItem>
                                        <ListItemText
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                          primary={alumno?.subAreas?.map(
                                            (element) => element.nombre + ", "
                                          )}
                                          secondary={
                                            <ListItemText
                                              secondary={
                                                alumno?.subAreas[0]?.area?.tipo
                                              }
                                            />
                                          }
                                        />
                                      </ListItem>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`${styles.row} ${styles.colSm12}`}>
                        <DetallesHistorialClinico />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default HistorialTable;
