import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import FooterService from "../../../../services/Footer.service";
import { AuthContext } from "../../../../context/AuthContext";
import useMediaQuery from "@mui/material/useMediaQuery";

const TopBar = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [footer, setFooter] = useState({ undefined });

  const matchesmd = useMediaQuery("(min-width:1200px)");

  const getFooter = async () => {
    try {
      const foot = await FooterService.getAll();
      setFooter(foot[0]);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const salir = () => {
    logout();
    navigate("/");
  };

  useEffect(() => {
    getFooter();
  }, []);

  return (
    <div className=" bg-stone  top-bar">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 d-none d-lg-block">
            <ul className="list-inline d-flex justify-content-xl-start align-items-center h-100 mb-0">
              <li
                style={{
                  minWidth: "16rem",
                  overflow: " hidden",
                  display: "grid",
                  gridTemplateColumns: "10% 90%",
                }}
              >
                <span className="bg-primary icon-header me-xl-2">
                  <i className="fas fa-envelope" aria-hidden="true" />
                </span>
                <a
                  href={"mailTo:" + footer?.Correo}
                  className="me-lg-4 me-xl-6 text-white opacity-80"
                >
                  {matchesmd ? footer?.Correo : footer?.Correo?.slice(0, 20)}
                </a>
              </li>
              <li
                style={{
                  minWidth: "11rem",
                  overflow: "hidden",
                  display: "grid",
                  gridTemplateColumns: "14% 86%",
                }}
              >
                <span className="bg-success icon-header me-xl-2">
                  <i className="fas fa-phone-alt" aria-hidden="true" />
                </span>
                <a
                  href={"tel:" + footer?.Telefono}
                  className="me-lg-4 me-xl-6 text-white opacity-80"
                >
                  {" "}
                  {footer?.Telefono}{" "}
                </a>
              </li>
              <li
                className="text-white"
                style={{
                  minWidth: "19rem",
                  overflow: " hidden",
                  display: "grid",
                  gridTemplateColumns: "10% 90%",
                }}
              >
                <span className="bg-pink icon-header">
                  <i className="far fa-clock" aria-hidden="true" />
                </span>
                <a
                  href="javascript:void(0)"
                  className="me-lg-4 me-xl-6 text-white opacity-80"
                >
                  {matchesmd
                    ? `Lunes-Viernes: ${footer?.semana}`
                    : `L-V: ${footer?.semana}`}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <ul className="list-inline d-flex mb-0 justify-content-xl-end justify-content-center align-items-center me-xl-2">
              <li className="text-white me-md-3 me-lg-2 me-xl-5">
                <span className="bg-purple icon-header me-1 me-md-2 me-lg-1 me-xl-2">
                  <i
                    className="fas fa-unlock-alt text-white font-size-13"
                    aria-hidden="true"
                  />
                </span>
                {!currentUser ? (
                  <>
                    <button
                      className="text-white font-weight-medium opacity-80"
                      style={{ border: "none", backgroundColor: "unset" }}
                      onClick={() => navigate(`/iniciarsesion`)}
                      data-bs-toggle="modal"
                      data-bs-target="#modal-login"
                    >
                      Iniciar sesión
                    </button>
                    <span className="text-white opacity-80"> o </span>
                    <button
                      className="text-white font-weight-medium opacity-80"
                      style={{ border: "none", backgroundColor: "unset" }}
                      onClick={() => navigate(`/iniciarsesion`)}
                      data-bs-toggle="modal"
                      data-bs-target="#modal-createAccount"
                    >
                      Registrarse
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="text-white font-weight-medium opacity-80"
                      style={{ border: "none", backgroundColor: "unset" }}
                      data-bs-toggle="modal"
                      data-bs-target="#modal-createAccount"
                    >
                      {currentUser.correo}
                    </button>
                    <button
                      className="text-white font-weight-medium opacity-80"
                      style={{ border: "none", backgroundColor: "unset" }}
                      onClick={() => salir()}
                      data-bs-toggle="modal"
                      data-bs-target="#modal-createAccount"
                    >
                      Salir
                    </button>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
