import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({});

const FactoresDesarrollo = ({ data }) => {
  return (
    <View style={styles.container} wrap={true}>
      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Tomó leche materna: ${
          data?.tomoLecheMaterna ? data?.tomoLecheMaterna : "-"
        }`}</Text>
      </View>
      {data?.tomoLecheMaterna === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Especifique hasta que edad: ${
            data?.especificaiontomoLechaMaterna
              ? data?.especificaiontomoLechaMaterna
              : "-"
          }`}</Text>
        </View>
      )}
      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Tomó formula: ${
          data?.tomoFormula ? data?.tomoFormula : "-"
        }`}</Text>
      </View>
      {data?.tomoFormula === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Especifique hasta que edad: ${
            data?.especificacintomoFormula
              ? data?.especificacintomoFormula
              : "-"
          }`}</Text>
        </View>
      )}
      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Tomo biberón: ${
          data?.tomoBiberon ? data?.tomoBiberon : "-"
        }`}</Text>
      </View>
      {data?.tomoFormula === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Especifique hasta que edad: ${
            data?.especificacionTomoBiberon
              ? data?.especificacionTomoBiberon
              : "-"
          }`}</Text>
        </View>
      )}
    </View>
  );
};

export default FactoresDesarrollo;
