/* Dependencies */
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./context/AuthContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FullPageLoading from "./utils/FullPageLoading";

import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";

function App() {
  const { currentUser, checkUser, checkRole } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      checkUser();
      setLoading(false);
    }, 500);
  });

  return (
    <BrowserRouter>
      <Routes>
        {loading ? (
          <Route path="/" element={<FullPageLoading />} />
        ) : currentUser &&
          checkRole(["Administrador", "Terapeuta", "Maestro"]) ? (
          <Route path="*" element={<PrivateRoutes />} />
        ) : (
          <Route path="*" element={<PublicRoutes />} />
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
