import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import DropZoneEdit from "../../DropZoneEdit";
import { TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import Preloader from "../../utils/Preloader";

import UserService from "../../../services/User.Service";
import S3Service from "../../../services/S3.service";

import styles from "../layout/assets/css/core.min.module.css";

import { useFormik } from "formik";
import { mixed, object, string } from "yup";
import CustomSelectImagePerfil from "../../utils/CustomImageSelect/CustomSelectImagePerfil";

const userSchema = object({
  nombre: string("Escribe un nombre").required("El nombre es requerido"),
  tipo: string().oneOf(
    ["Administrador", "Maestro", "Terapeuta"],
    "Tipo de usuario invalido"
  ),
  correo: string()
    .email("Correo invalido")
    .required("El correo es requerido")
    .matches(
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      "Correo invalido"
    ),
  contrasena: string("Escribe una contraseña")
    .min(6, "La contraseña debe ser mayor a 6 caracteres")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
      "La contraseña debe tener al menos una mayúscula, una minúscula y un número para ser segura"
    ),
  area: string().required(),
  file: mixed()
    .test("fileFormat", "Archivos permitidos: jpg, jpeg, png", (value) => {
      if (value) {
        const supportedFormats = ["jpg", "jpeg", "png"];
        return supportedFormats.includes(value.name.split(".").pop());
      }
      return true;
    })
    .test("fileSize", "La imagen debe ser menor a 3MB", (value) => {
      if (value) {
        return value.size <= 3145728;
      }
      return true;
    }),
});

const userInitialValues = {
  nombre: "",
  tipo: "Terapeuta",
  correo: "",
  contrasena: "Área terapéutica",
  area: "",
  file: undefined,
};

const EditarUsuario = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [usuario, setUsuario] = useState(userInitialValues);
  const [imagen, setImagen] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: usuario,
    validationSchema: userSchema,
    onSubmit: (values) => {
      actualizarUsuario(values);
    },
  });

  const getData = async () => {
    setLoading(true);
    try {
      const data = await UserService.getById(parseInt(id));

      if (data.imagen) {
        const imagen = await S3Service.get(data.imagen);
        setImagen(imagen.result);
      }
      let dataUser = {
        nombre: data.nombre,
        tipo: data.tipo,
        correo: data.correo,
        area: data.area?.tipo ? data.area.tipo : "N/A",
        file: undefined,
        contrasena: "",
        id: data.id,
        imagen: data.imagen,
      };
      setUsuario(dataUser);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const actualizarUsuario = async (values) => {
    setLoading(true);
    try {
      let data = {
        id: values.id,
        nombre: values.nombre,
        tipo: values.tipo,
        area: values.area,
      };

      if (values.contrasena) {
        data.contraseña = values.contrasena;
      }

      if (values.file) {
        await S3Service.delete(values.imagen ? values.imagen : "").catch(
          (error) => {
            console.log(error);
          }
        );
        const uploadPhoto = await S3Service.upload(values.file);
        data.imagen = uploadPhoto.result.data;
      }

      await UserService.update(data);

      navigate("/terapeutas");

      toast.success("Terapeuta actualizado con éxito");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(formik.errors).length >= 1 && formik.dirty) {
      toast.info("Revisar los campos obligatorios");
    }
  }, [formik.submitCount]);

  return loading ? (
    <Preloader />
  ) : (
    <main
      id={styles["middle"]}
      className={`${styles.flexFill} ${styles.mxAuto}`}
    >
      <header>
        <h1 className={`${styles.h4}`}>Editar terapeuta</h1>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb} ${styles.small}`}>
            <li className={`${styles.breadcrumbItem}`}>
              <button
                style={{
                  border: "none",
                  backgroundColor: "unset",
                  color: "#9b242c",
                }}
                onClick={() => navigate("/terapeutas")}
              >
                Terapeutas
              </button>
            </li>
            <li
              className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
              aria-current="page"
            >
              Editar
            </li>
          </ol>
        </nav>
      </header>
      <form className="form-validate">
        <div className={`${styles.row} ${styles.g4}`}>
          <div className={`${styles.col}`}>
            <div
              className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
            >
              Terapeuta
            </div>
            <div className={`${styles.section} ${styles.mb4}`}>
              <div className={`${styles.cardBody}`}>
                <CustomSelectImagePerfil
                  url={imagen}
                  file={formik.values.file}
                  handleFile={(file) => {
                    formik.setFieldValue("file", file);
                  }}
                  errors={formik.errors.file}
                />
                <div className={`${styles.row} ${styles.g3}`}>
                  <div className={`${styles.colSm12}`}>
                    <div className={`${styles.formFloating}`}>
                      <TextField
                        fullWidth
                        id="nombre"
                        name="nombre"
                        label="Nombre"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.nombre && Boolean(formik.errors.nombre)
                        }
                        helperText={
                          formik.touched.nombre && formik.errors.nombre
                        }
                      />
                    </div>
                  </div>

                  <div className={`${styles.colSm6}`}>
                    <div className={`${styles.formFloating}`}>
                      <TextField
                        fullWidth
                        id="correo"
                        name="correo"
                        label="Correo"
                        value={formik.values.correo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.correo && Boolean(formik.errors.correo)
                        }
                        helperText={
                          formik.touched.correo && formik.errors.correo
                        }
                        disabled
                      />
                    </div>
                  </div>
                  <div className={`${styles.colSm6}`}>
                    <div className={`${styles.formFloating}`}>
                      <TextField
                        fullWidth
                        id="contrasena"
                        name="contrasena"
                        label="contraseña"
                        type="password"
                        value={formik.values.contrasena}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.contrasena &&
                          Boolean(formik.errors.contrasena)
                        }
                        helperText={
                          formik.touched.contrasena && formik.errors.contrasena
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className={`${styles.co12}`}>
        <div
          className={`${styles.cardFooter} ${styles.borderLight}`}
          style={{ textAlign: "right" }}
        >
          <button
            className={`${styles.btn} ${styles.btnPrimary}`}
            onClick={() => formik.submitForm()}
            disabled={formik.isSubmitting || !formik.dirty}
          >
            <svg
              width="18px"
              height="18px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className={`${styles.small}`}>Editar</span>
          </button>
        </div>
      </div>
    </main>
  );
};

export default EditarUsuario;
