export const formatDirection = (direccion) => {
  const {
    calle,
    exterior,
    interior,
    colonia,
    municipio,
    estado,
    codigoPostal,
  } = direccion;
  const direccionFormateada = `${calle}, #Exterior ${exterior}, #Interior ${interior}, ${colonia}, C.P. ${codigoPostal}, ${municipio}, ${estado}.`;
  return direccionFormateada;
};

export const FormatDateTime = (date) => {
  const fechaActual = new Date(date);
  const opcionesFecha = {
    weekday: "long", // Día de la semana completo (ejemplo: "lunes")
    year: "numeric", // Año en formato de cuatro dígitos (ejemplo: "2024")
    month: "long", // Nombre completo del mes (ejemplo: "marzo")
    day: "numeric", // Día del mes (ejemplo: "4")
  };

  const opcionesHora = {
    hour: "numeric", // Hora en formato de 12 horas (ejemplo: "3 PM")
    minute: "numeric", // Minutos (ejemplo: "30")
  };

  const fechaFormateada =
    fechaActual.toLocaleDateString("es-ES", opcionesFecha) +
    " a las " +
    fechaActual.toLocaleTimeString("es-ES", opcionesHora);

  return fechaFormateada;
};

export const FormatDate = (date) => {
  const fechaActual = new Date(date);
  const opcionesFecha = {
    weekday: "long", // Día de la semana completo (ejemplo: "lunes")
    year: "numeric", // Año en formato de cuatro dígitos (ejemplo: "2024")
    month: "long", // Nombre completo del mes (ejemplo: "marzo")
    day: "numeric", // Día del mes (ejemplo: "4")
  };

  const fechaFormateada = fechaActual.toLocaleDateString(
    "es-ES",
    opcionesFecha
  );

  return fechaFormateada;
};

export const FormatTime = (date) => {
  const fechaActual = new Date(date);

  const opcionesHora = {
    hour: "numeric", // Hora en formato de 12 horas (ejemplo: "3 PM")
    minute: "numeric", // Minutos (ejemplo: "30")
  };

  const fechaFormateada = fechaActual.toLocaleTimeString("es-ES", opcionesHora);

  return fechaFormateada;
};
