import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";

const BlogItem = ({
  id,
  titulo,
  idAutor,
  fechaU,
  categoria,
  imagenes,
  cuerpo,
}) => {
  const navigate = useNavigate();
  const [imag, setImag] = useState([]);
  const [autor, setAutor] = useState([]);
  const [fecha, setFecha] = useState("");
  const monthNames = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  let autorNombre;

  useEffect(() => {
    getImagen();
    getAutor();
    getTiempo();
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(imagenes);
      setImag(result.result);
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const getAutor = async () => {
    try {
      const result = await UserService.getById(idAutor);
      setAutor(result.nombre);
    } catch (error) {
      toast.error(error);
    }
  };

  const getTiempo = async () => {
    try {
      //Tiempo
      const date = new Date(fechaU);

      // Obtener los componentes de fecha y hora
      const year = date.getFullYear();
      const monthIndex = date.getMonth();
      const day = ("0" + date.getDate()).slice(-2);
      // Formatear el texto de salida
      setFecha(`${day} de ${monthNames[monthIndex]} de ${year}`);
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const cortarTexto = () => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(cuerpo, "text/html");
    return doc.body.innerText;
  };

  return (
    <div className="col-md-6 col-lg-4">
      <div className="card" style={{ height: "455px" }}>
        <div className="position-relative">
          <div
            style={{
              height: "200px",
              width: "100%",
              backgroundImage: `url(${imag})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
            onClick={() => navigate(`/blog/${id}`)}
          >
            {/* <img
              className="card-img-top"
              src={imag}
              alt="Card blog"
              style={{ height: "200px", objectFit: "cover" }}
            /> */}
          </div>
          <div className="card-img-overlay p-0">
            <span className="badge bg-danger badge-rounded m-4">
              {" "}
              <i className="far fa-calendar" aria-hidden="true" />
              {"  "}
              {fecha}
            </span>
          </div>
        </div>
        <div className="card-body border-top-5 px-3 rounded-bottom border-danger">
          <h3 className="card-title text-capitalize">
            <button
              className="text-danger d-block text-truncate"
              style={{
                border: "none",
                backgroundColor: "unset",
                width: "100%",
              }}
              onClick={() => navigate(`/blog/${id}`)}
            >
              {titulo}
            </button>
          </h3>
          <ul
            className="list-unstyled d-flex flex-md-column  mb-1"
            style={{ height: "50px" }}
          >
            <li className="me-2">
              <button
                className="text-muted"
                style={{ border: "none", backgroundColor: "unset" }}
                onClick={() => navigate(`/blog/${id}`)}
              >
                <i className="fa fa-user me-2" aria-hidden="true" />
                {autor}
              </button>
            </li>
            <li className="me-2">
              <button
                className="text-muted"
                style={{ border: "none", backgroundColor: "unset" }}
                onClick={() => navigate(`/blog/${id}`)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-tag-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
                {"   "}
                {categoria}
              </button>
            </li>
          </ul>
          <p
            style={{
              height: "90px",
              overflow: "hidden",
              padding: "0",
              margin: "0",
            }}
          >
            {" "}
            <span
              dangerouslySetInnerHTML={{
                __html: String(cortarTexto()).substring(0, 150).concat("..."),
              }}
              style={{
                textAlign: "justify",
              }}
            ></span>
          </p>
          <button
            className="btn btn-link text-hover-danger ps-0"
            style={{ border: "none", backgroundColor: "unset" }}
            onClick={() => navigate(`/blog/${id}`)}
          >
            <i className="fa fa-angle-double-right me-1" aria-hidden="true" />{" "}
            Leer más
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
