import { useNavigate } from "react-router-dom";

const BreadCrumb = () => {
  const navigate = useNavigate();

  return (
    <section
      className="breadcrumb-bg"
      style={{
        backgroundImage: "url(assets/img/background/page-title-bg-img.jpg)",
        height: "250px",
      }}
    >
      <div className="container" style={{ height: "80px" }}>
        <div className="breadcrumb-holder" style={{ height: "80px" }}>
          <div>
            <h1 className="breadcrumb-title">Servicios</h1>
            <ul className="breadcrumb breadcrumb-transparent">
              <li className="breadcrumb-item">
                <button
                  className="text-white"
                  style={{ border: "none", backgroundColor: "unset" }}
                  onClick={() => navigate(`/`)}
                >
                  Inicio
                </button>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Servicios
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BreadCrumb;
