import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import AppointmentService from "../../../services/therapies/Appointment.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";

export default function DialogEliminarCita({ currentCita }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const eliminarCita = async () => {
    try {
      await AppointmentService.remove(currentCita.id);
      toast.success("Cita eliminada");
      navigate("/citas");
    } catch (error) {
      toast.error(error);
    }

    handleClose();
  };

  return (
    <Fragment>
      <Button
        variant="text"
        color="error"
        onClick={handleClickOpen}
        sx={{ fontSize: "0.75rem" }}
      >
        Eliminar cita
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>seguro de eliminar esta cita !</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleClose} sx={{ color: "#9b242c" }}>
            Regresar
          </Button>
          <Button
            type="submit"
            sx={{
              color: "#fff",
              backgroundColor: "#9b242c",
              "&:hover": {
                backgroundColor: "#df222f",
                borderColor: "#df222f",
                boxShadow: "none",
              },
            }}
            onClick={eliminarCita}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
