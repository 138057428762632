const Contact = ({ footer }) => {
  return (
    <>
      <li className="media align-items-center mb-3">
        <div className="icon-rounded-circle-small bg-primary me-2">
          <i className="fas fa-map-marker-alt text-white" />
        </div>
        <div className="media-body">
          <p className="mb-0">
            <a
              className="text-color"
              target="_blank"
              href={
                footer?.Direccion
                  ? `https://www.google.com/maps/search/?api=1&query=${footer?.Direccion?.lat},${footer?.Direccion?.long}5&zoom=20`
                  : "javascript:void(0);"
              }
            >
              {footer?.Direccion?.Direccion}
            </a>
          </p>
        </div>
      </li>
      <li
        className="media align-items-center mb-3"
        style={{
          width: "100%",
          display: "-webkit-inline-box",
        }}
      >
        <div className="icon-rounded-circle-small bg-success me-2">
          <i className="fas fa-envelope text-white" />
        </div>
        <div className="media-body">
          <p className="mb-0">
            <a className="text-color" href={"mailTo:" + footer?.Correo}>
              {footer?.Correo}
            </a>
          </p>
        </div>
      </li>
      <li className="media align-items-center mb-3">
        <div className="icon-rounded-circle-small bg-info me-2">
          <i className="fas fa-phone-alt text-white" />
        </div>
        <div className="media-body">
          <p className="mb-0">
            <a className="text-color" href={"tel:" + footer?.Telefono}>
              {footer?.Telefono}
            </a>
          </p>
        </div>
      </li>
    </>
  );
};

export default Contact;
