import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserService from "../../../services/User.Service";
import "aos/dist/aos.css";
import SignUpForm from "./SingnUpForm";
import TextField from "@mui/material/TextField";

const IniciarSesion = () => {
  const { login } = useContext(AuthContext);
  const [datos, setDatos] = useState({ correo: "", contraseña: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const iniciarSesion = () => {
    if (datos) {
      const data = {
        contraseña: datos.contraseña,
        correo: datos.correo,
      };
      try {
        setLoading(true);
        const promise = UserService.login(data).then((response) => {
          if (response === "Correo o contraseña incorrectas") {
            toast.error("Correo o contraseña incorrectas");
          } else {
            login(response.user, response.token);
            toast.success("Inicio de sesión exitoso");
            navigate("/");
          }
        });
        toast.promise(promise, {
          pending: "Espere por favor..",
          error: "Revise sus datos de acceso",
        });
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="main-wrapper sign-up-or-login">
      {/* ====================================BREADCRUMB===================================== */}
      <section
        className="breadcrumb-bg"
        style={{
          backgroundImage: "url(assets/img/background/page-title-bg-img.jpg)",
          height: "250px",
        }}
      >
        <div className="container" style={{ height: "80px" }}>
          <div className="breadcrumb-holder" style={{ height: "80px" }}>
            <div>
              <h1 className="breadcrumb-title">Iniciar sesión/Registrarse</h1>
              <ul className="breadcrumb breadcrumb-transparent">
                <li className="breadcrumb-item">
                  <a
                    className="text-white"
                    href="#"
                    onClick={() => navigate(`/`)}
                  >
                    Inicio
                  </a>
                </li>
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Iniciar sesión/Registrarse
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* ====================================
———	SIGN-UP OR LOG-IN
===================================== */}
      <section className="py-8 py-md-10">
        <div className="container">
          <div className="row">
            <SignUpForm />
            <div className="col-sm-6 col-lg-4">
              <div className="mb-4 mb-sm-0">
                <div className="bg-primary rounded-top p-2">
                  <h3 className="text-white font-weight-bold mb-0 ms-2">
                    Iniciar sesión
                  </h3>
                </div>
                <div className="border rounded-bottom-sm border-top-0">
                  <div className="p-3">
                    <form role="form">
                      <div className="form-group form-group-icon">
                        <TextField
                          label="Correo electrónico"
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            setDatos({
                              ...datos,
                              correo: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="form-group form-group-icon">
                        <TextField
                          label="Contreseña"
                          type="password"
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            setDatos({
                              ...datos,
                              contraseña: e.target.value,
                            })
                          }
                        />
                      </div>
                    </form>
                    <div className="form-group">
                      <button
                        className="btn btn-danger text-uppercase w-100"
                        onClick={() => iniciarSesion()}
                      >
                        Iniciar sesión
                      </button>
                    </div>
                    <div className="form-group text-center text-secondary mb-0">
                      {/*   <button
                        className="text-danger"
                        style={{ border: "none", backgroundColor: "unset" }}
                        onClick={() => navigate("/forgotpassword")}
                      >
                        Olvidó la contraseña?
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IniciarSesion;
