import styles from "./assets/css/core.min.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";

const MenuElementSubText = ({ subtitle, roles }) => {
  const { checkRole } = useContext(AuthContext);

  return !roles || checkRole(roles) ? (
    <li className={`${styles.navTitle} ${styles.m0}`}>
      <p
        style={{
          fontSize: "11px",
          borderBottom: "1px dashed lightgray",
          margin: 0,
        }}
      >
        {subtitle}
      </p>
    </li>
  ) : null;
};

export default MenuElementSubText;
