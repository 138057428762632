import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";
import NoticiaService from "../../../services/Noticia.service";
import { getAutoHeightDuration } from "@mui/material/styles/createTransitions";
import BlogCard from "./BlogCard";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";
import ReCAPTCHA from "react-google-recaptcha";
import ComnetarioService from "../../../services/Comentario.service";
import BlogMensaje from "./BlogMensaje";
import EntradaSimilar from "./EntradaSimilar";
import ItemBlog from "./ItemBlog";
import portada from "../assets/img/background/page-title-bg-img.jpg";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TumblrShareButton,
} from "react-share";
import FormBlogMensaje from "./FormBlogMensaje";

const BlogEntrada = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [noticia, setNoticia] = useState({});
  const [categorias, setCategorias] = useState([]);
  const [noticias, setNoticias] = useState([]);

  const [autor, setAutor] = useState("Ad");
  const [fecha, setFecha] = useState("");
  const [imag, setImagen] = useState(null);
  const [loading, setLoading] = useState(true);
  const [aleatorio, setAleatorio] = useState(true);
  const [nuevo, setNuevo] = useState(false);
  const [popular, setPopular] = useState(false);
  const [tag, setTag] = useState(false);
  const [filtro, setFiltro] = useState(undefined);
  const [tabUno, setTabUno] = useState("active");
  const [tabDos, setTabDos] = useState("");
  const [tabUnoCuerpo, setTabUnoCuerpo] = useState("show active");
  const [tabDosCuerpo, setTabDosCuerpo] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [valido, setValido] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [mensajes, setMensajes] = useState([]);
  const [totalMensajes, setTotalMensajes] = useState([]);
  const [ultimasNoticias, setultimasNoticias] = useState([]);
  const [Entradas, setEntradas] = useState([]);
  const [buscar, setBuscar] = useState("");
  const [total, setTotal] = useState(0);
  const [hashtag, setHashtag] = useState([]);
  const [onSumit, setOnSumit] = useState(false);

  const monthNames = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    getNoticia();
    getNoticias();
    getMensajes();
    getAutor();
    getTiempo();
    setOnSumit(false);
  }, [id, onSumit]);

  function onChange(value) {
    console.log("Captcha value:", value);
    setValido(false);
  }

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(4, 0);
      console.log("NOTICIIAAS" + data.data);
      setultimasNoticias(data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getNoticia = async () => {
    try {
      const data = await NoticiaService.getById(id);
      S3Service.get(data[0].imagen).then((dataimagen) => {
        document.getElementById(`imagen${data[0].imagen}`).src =
          dataimagen.result;
        setImagen(dataimagen.result);
      });
      setNoticia(data[0]);
      setFiltro(data[0].categoria);
      //Hashtag
      setHashtag([
        data[0].categoria.replace(/\s+/g, ""),
        "FundaciónCorazónDown",
      ]);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      getEntradasFiltroCategoria();
    }
  };

  const getMensajes = async () => {
    try {
      const data = await ComnetarioService.getByIdBlog(id);
      console.log("Mensajes" + data.total);
      setTotalMensajes(data.total);
      setMensajes(data.data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const activarMensaje = () => {
    if (currentUser === undefined) {
      return (
        <>
          <div className="clearfix mt-5">
            <div
              style={{
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              {/* Not logged in */}
              <span className="d-block text-muted small mb-1">
                Para dejar un comentario debes{" "}
                <button
                  style={{
                    border: "none",
                    backgroundColor: "unset",
                    color: "#f0c24b",
                  }}
                  onClick={() => navigate(`/iniciarsesion`)}
                >
                  INICIAR SESIÓN.
                </button>
              </span>
            </div>
          </div>
        </>
      );
    } else {
      return <FormBlogMensaje blogId={noticia.id} onSubmit={onUpdate} />;
    }
  };

  const borrarComentario = async (idBlog) => {
    try {
      const dataBorrar = await ComnetarioService.remove(idBlog);
      const data = await ComnetarioService.getByIdBlog(id);
      console.log("Mensajes" + data.total);
      setTotalMensajes(data.total);
      setMensajes(data.data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const getEntradasFiltroCategoria = async () => {
    setLoading(true);
    setEntradas([]);
    try {
      console.log("el filtro es :" + filtro);
      const data = await NoticiaService.filtroCategoria(filtro, 3, 0);
      setEntradas(data.data);
      console.log("------------ entradas:" + data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      console.log(Entradas);
    }
  };

  const getTiempo = async () => {
    try {
      //Tiempo
      const data = await NoticiaService.getById(id);
      console.log("!!!!!!!!!!!!!!!" + String(data[0].updateAt)); // Salida: "17 de mayo de 2023 12:50:43"
      //Tiempo
      const date = new Date(data[0].updateAt);

      // Obtener los componentes de fecha y hora
      const year = date.getFullYear();
      const monthIndex = date.getMonth();
      const day = ("0" + date.getDate()).slice(-2);
      // Formatear el texto de salida
      setFecha(`${day} de ${monthNames[monthIndex]} de ${year}`);
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const getAutor = async () => {
    try {
      const data = await NoticiaService.getById(id);
      const result = await UserService.getById(data[0].usuarioId);
      console.log("autor+++++" + result.nombre);
      setAutor(result.nombre);
    } catch (error) {
      toast.error(error);
    }
  };

  const onUpdate = () => {
    setOnSumit(true);
  };

  return (
    <div className="main-wrapper blog-grid-left-sidebar">
      {/* ====================================
    ———	BREADCRUMB
    ===================================== */}
      <section
        className="breadcrumb-bg"
        style={{
          backgroundImage: `url(${portada})`,
        }}
      >
        <div className="container">
          <div className="breadcrumb-holder">
            <div>
              <h1 className="breadcrumb-title">{noticia.titulo}</h1>
              <ul className="breadcrumb breadcrumb-transparent">
                <li className="breadcrumb-item">
                  <button
                    className="text-white"
                    style={{ border: "none", backgroundColor: "unset" }}
                    onClick={() => navigate(`/`)}
                  >
                    Inicio
                  </button>
                </li>
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  <button
                    className="text-white"
                    style={{ border: "none", backgroundColor: "unset" }}
                    onClick={() => navigate(`/blog`)}
                  >
                    Blog
                  </button>
                </li>
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  {noticia.titulo}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* ====================================
  ———	BLOG DETAILS
  ===================================== */}
      <section className="py-8 py-md-10">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-9 order-md-1">
              <div className="card">
                <div className="position-relative">
                  <img
                    className="card-img-top"
                    alt="Card blog"
                    id={`imagen${noticia.imagen}`}
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                    }}
                  />
                  <div className="card-img-overlay">
                    <span className="badge badge-rounded bg-primary">
                      {" "}
                      <i className="far fa-calendar" aria-hidden="true" />
                      {"  "}
                      {fecha}
                    </span>
                  </div>
                </div>
                <div className="card-body border-top-5 px-3 rounded-bottom border-primary">
                  <h3 className="card-title text-primary mb-4">
                    {noticia.titulo}
                  </h3>
                  <ul className="list-unstyled d-flex mb-6">
                    <li className="">
                      <p
                        className="text-muted d-inline-block me-3"
                        style={{ cursor: "default" }}
                      >
                        <i className="fa fa-user me-2" aria-hidden="true" />
                        {autor}
                      </p>
                    </li>
                    <li className="me-3">
                      <a
                        className="text-muted d-inline-block"
                        href="javascrip:void(0);"
                        onClick={() => navigate("/blog?" + noticia.categoria)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-tag-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                        {"   "}
                        {noticia.categoria}
                      </a>
                    </li>
                  </ul>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: noticia.cuerpoNoticia,
                    }}
                    style={{ textAlign: "justify" }}
                  ></p>
                  <div className="">
                    <ul className="list-inline d-flex mb-0">
                      <li className="me-2">
                        <button
                          className="icon-rounded-circle-small bg-primary"
                          style={{ border: "none", backgroundColor: "unset" }}
                        >
                          <FacebookShareButton
                            quote={
                              "Leí una entrada increíble en este blog, Lee aquí: "
                            }
                            title={
                              "Leí una entrada increíble en este blog, Lee aquí: "
                            }
                            hashtag={"#" + noticia.categoria}
                            id="btn-fb"
                            url="https://www.npmjs.com/package/react-share"
                          >
                            <i
                              className="fab fa-facebook-f text-white"
                              aria-hidden="true"
                            />
                          </FacebookShareButton>
                        </button>
                      </li>
                      <li className="me-2">
                        <button
                          className="icon-rounded-circle-small bg-danger"
                          style={{ border: "none", backgroundColor: "unset" }}
                        >
                          <TwitterShareButton
                            title={
                              "Leí una entrada increíble en este blog, Lee aquí: "
                            }
                            hashtags={hashtag}
                            id="btn-fb"
                            url={window.location.href}
                          >
                            <i
                              className="fab fa-twitter text-white"
                              aria-hidden="true"
                            />
                          </TwitterShareButton>
                        </button>
                      </li>
                      <li className="me-2">
                        <button
                          className="icon-rounded-circle-small bg-info"
                          style={{ border: "none", backgroundColor: "unset" }}
                        >
                          <PinterestShareButton
                            quote={noticia.titulo + " leer más en: "}
                            id="btn-fb"
                            url={window.location.href}
                          >
                            <i
                              className="fab fa-pinterest-p text-white"
                              aria-hidden="true"
                            />
                          </PinterestShareButton>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="bg-light shadow-sm rounded px-3 pt-3 pb-6 mb-4">
                <h3 className="mb-4 text-danger font-weight-bold">
                  {mensajes.length} Comentarios
                </h3>
                {mensajes.map((record, index) => {
                  return (
                    <BlogMensaje
                      key={index}
                      id={record.id}
                      nombre={record.nombre}
                      createdAt={record.createdAt}
                      imagenes={record.imagenes}
                      borrarComentario={(data) => borrarComentario(data)}
                      borrar={
                        currentUser?.id == record.IdUsuario ? true : false
                      }
                      MensajeCuerpo={record.MensajeCuerpo}
                    />
                  );
                })}
              </div>
              <div className="bg-light shadow-sm rounded p-3">
                <h3 className="mb-4 text-danger font-weight-bold">
                  Deja un comentario
                </h3>
                {activarMensaje()}
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="mb-4">
                <h3 className="bg-purple rounded-top font-weight-bold text-white mb-0 py-2 px-4">
                  Últimas entradas
                </h3>
                <div className="border border-top-0 rounded">
                  <ul className="list-unstyled mb-0">
                    {ultimasNoticias.map((record, index) => {
                      return (
                        <ItemBlog
                          key={index}
                          id={record.id}
                          titulo={record.titulo}
                          idAutor={record.usuarioId}
                          fecha={record.createdAt}
                          categoria={record.categoria}
                          imagenes={record.imagen}
                          cuerpo={record.cuerpoNoticia}
                        />
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogEntrada;
