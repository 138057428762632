import { useNavigate } from "react-router-dom";

const Pages = () => {
    const navigate = useNavigate();

  return (
    <>
        <li className="mb-4">
            <button style={{border:'none', backgroundColor:'unset', color: 'white'}} onClick={() => navigate(`/`)}>
                <i
                className="fas fa-angle-double-right me-2"
                aria-hidden="true"
                />
                Inicio
            </button>
            </li>
            <li className="mb-4">
            <button style={{border:'none', backgroundColor:'unset', color: 'white'}} onClick={() => navigate(`/contactanos`)}>
                <i
                className="fas fa-angle-double-right me-2"
                aria-hidden="true"
                />
                Contáctanos
            </button>
            </li>
            <li className="mb-4">
            <button style={{border:'none', backgroundColor:'unset', color: 'white'}} onClick={() => navigate(`/servicios`)}>
                <i
                className="fas fa-angle-double-right me-2"
                aria-hidden="true"
                />
                Servicios
            </button>
            </li>
            <li className="mb-4">
            <button style={{border:'none', backgroundColor:'unset', color: 'white'}} onClick={() => navigate(`/blog`)}>
                <i
                className="fas fa-angle-double-right me-2"
                aria-hidden="true"
                />
                Blog
            </button>
        </li>
    </>
  )
}

export default Pages