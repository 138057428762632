import styles from "./assets/css/core.min.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";

const MenuElement = ({ nameMenu, icon, activeMenu, url, navegar, roles }) => {
  const { checkRole } = useContext(AuthContext);

  const styleActive = (name) => {
    if (name === activeMenu) {
      return `${styles.navItem} ${styles.active}`;
    }
    return `${styles.navItem}`;
  };

  return !roles || checkRole(roles) ? (
    <li className={styleActive(url)}>
      <a
        className={`${styles.navLink}`}
        href="javascript:void(0);"
        onClick={() => navegar(url)}
      >
        {icon}
        <span>{nameMenu}</span>
        <span className={`${styles.groupIcon} ${styles.floatEnd}`}>
          <i className={`${styles.fi} ${styles.fiArrowEnd}`} />
          <i className={`${styles.fi} ${styles.fiArrowDown}`} />
        </span>
      </a>
    </li>
  ) : null;
};

export default MenuElement;
