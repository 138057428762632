import styles from "../layout/assets/css/core.min.module.css";
import { TextField, Typography } from "@mui/material";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import WarningIcon from "@mui/icons-material/Warning";

import CustomDirectionInput from "../../utils/CustomImput/CustomDirectionInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { esES } from "@mui/x-date-pickers/locales";
import "dayjs/locale/es-mx";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import S3Service from "../../../services/S3.service";
import { formatDirection } from "../../utils/Format";

const FormCita = ({ alumno, handleAlumno, fechaCita, handleFechacita }) => {
  let today = new Date();
  const [imageProfile, setImageProfile] = useState("");

  const [fechaNacimiento, setFechaNacimiento] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(today)
  );

  useEffect(() => {
    getAlumnoImage();
  }, [alumno]);

  const getAlumnoImage = async () => {
    try {
      if (alumno.imagen) {
        const image = await S3Service.get(alumno.imagen);
        setImageProfile(image.result);
      }
      setFechaNacimiento(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(new Date(alumno?.fechaNacimiento))
      );
    } catch (error) {
      toast.error(error.mensaje);
    }
  };

  return (
    <div className={`${styles.row} ${styles.g4}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          Fecha de cita
        </div>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={
                esES.components.MuiLocalizationProvider.defaultProps.localeText
              }
              adapterLocale="es-mx"
            >
              <StaticDateTimePicker
                showDaysOutsideCurrentMonth={true}
                disablePast={true}
                orientation="landscape"
                onChange={handleFechacita}
                value={dayjs(fechaCita)}
                slotProps={{
                  actionBar: {
                    actions: [],
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormCita;
