import styles from "../../layout/assets/css/core.min.module.css";

import { Box, Button, TextField, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState } from "react";

const SectionConductual = ({ conductual, handleConductual }) => {
  const handleEnojo = (event, value) => {
    handleConductual({
      ...conductual,
      enojo: value,
    });
  };

  const handleMiente = (event, value) => {
    handleConductual({
      ...conductual,
      miente: value,
    });
  };

  const handleEduacionSexual = (event, value) => {
    handleConductual({
      ...conductual,
      educacionSexual: value,
    });
  };

  return (
    <div className={`${styles.row} ${styles.colSmm12}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          Conductual
        </div>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Que le gusta hacer a su hijo (a):</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleConductual({
                    ...conductual,
                    actividadesPositivas: e.target.value,
                  })
                }
                value={conductual?.actividadesPositivas}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Que no le gusta hacer a su hijo (a):</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleConductual({
                    ...conductual,
                    actividadesNegadas: e.target.value,
                  })
                }
                value={conductual?.actividadesNegadas}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm2"]}`}>
              <Typography>Se enoja con facilidad:</Typography>
              <ToggleButtonGroup
                value={conductual?.enojo ? conductual.enojo : ""}
                exclusive
                aria-label="Platform"
                onChange={handleEnojo}
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["col-sm-10"]}`}>
              <Typography>Porque?</Typography>
              <TextField
                sx={{ marginY: 2 }}
                fullWidth
                variant="standard"
                placeholder="..."
                disabled={conductual?.enojo === "No"}
                onChange={(e) =>
                  handleConductual({
                    ...conductual,
                    especificacionEnojo: e.target.value,
                  })
                }
                value={conductual?.especificacionEnojo}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Su hijo (a) miente:</Typography>
              <ToggleButtonGroup
                value={conductual?.miente ? conductual.miente : ""}
                exclusive
                aria-label="Platform"
                onChange={handleMiente}
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Su hijo (a) tiene educación sexual:</Typography>
              <ToggleButtonGroup
                value={
                  conductual?.educacionSexual ? conductual.educacionSexual : ""
                }
                exclusive
                aria-label="Platform"
                onChange={handleEduacionSexual}
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm12"]}`}>
              <Typography>Como describe a su hijo:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleConductual({
                    ...conductual,
                    descripcion: e.target.value,
                  })
                }
                value={conductual?.descripcion}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionConductual;
