import httpClient from "./HttpClient";
const prefix = "/galeria";

export default class GaleriaService {
  static async create(galeria) {
    return (await httpClient.post(`${prefix}/`, galeria)).data;
  }

  static async update(galeria) {
    return (await httpClient.put(`${prefix}/${galeria.id}`, galeria)).data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

  static async getActive() {
    return (await httpClient.get(`${prefix}Active/`)).data;
  }

  static async list(limit, offset) {
    return (await httpClient.get(`${prefix}/${limit}/${offset}`)).data;
  }
}
