import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Components
import Slide from "./Slide";
import Servicios from "./Servicios";
import Blogs from "./Blogs";
import Counters from "./Counters";
import Galeria from "./Galeria";
import Contacto from "./Contacto";
import Nosotros from "./Nosotros";
import PostVideo from "./PostVideo";
// import Search from "./Search";
// import MejoresOfertas from "./MejoresOfertas";
// import Noticias from "./Noticias";
//import NuestrosPaquetes from "./NuestrosPaquetes";

import IndexService from "../../../services/Index.service";

const Portada = () => {
  const [informacion, setInformacion] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getInformacion();
    window.scrollTo(0, 0);
  }, []);

  const getInformacion = async () => {
    try {
      const data = await IndexService.get();
      setInformacion(data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="main-wrapper home">
        <Slide />
        {/* ====================================
          ———	BOX SECTION
          ===================================== */}
        <section
          className="d-none d-sm-block section-top"
          style={{ marginBottom: "-150px" }}
        >
          <div className="container">
            <div className="row wow fadeInUp">
              <div className="col-sm-3">
                <a href="#servicios"></a>
                <div className="card bg-primary card-hover">
                  <a href="#servicios"></a>
                  <div className="card-body text-center p-0">
                    <a href="#servicios">
                      <div className="card-icon-border-large border-primary">
                        <i className="far fa-file-alt" aria-hidden="true" />
                      </div>
                      <h2 className="text-white font-size-32 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">
                        Servicios
                      </h2>
                    </a>
                    <a
                      href="#servicios"
                      className="btn-scroll-down d-block pb-4 pb-lg-5"
                    >
                      <i className="fas fa-chevron-down" aria-hidden="true" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <a href="#blog"></a>
                <div className="card bg-info-Public card-hover">
                  <a href="#blog"></a>
                  <div className="card-body text-center p-0">
                    <a href="#blog">
                      <div className="card-icon-border-large border-info">
                        <i className="far fa-copy" aria-hidden="true" />
                      </div>
                      <h2 className="text-white font-size-32 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">
                        Blog
                      </h2>
                    </a>
                    <a
                      href="#blog"
                      className="btn-scroll-down d-block pb-4 pb-lg-5"
                    >
                      <i className="fas fa-chevron-down" aria-hidden="true" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <a href="#gallery_home"></a>
                <div className="card bg-danger card-hover">
                  <a href="#gallery_home"></a>
                  <div className="card-body text-center p-0">
                    <a href="#nosotros">
                      <div className="card-icon-border-large border-danger">
                        <i className="far fa-image" aria-hidden="true" />
                      </div>
                      <h2 className="text-white font-size-32 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">
                        Nosotros
                      </h2>
                    </a>
                    <a
                      href="#gallery_home"
                      className="btn-scroll-down d-block pb-4 pb-lg-5"
                    >
                      <i className="fas fa-chevron-down" aria-hidden="true" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <a href="#contacto"></a>
                <div className="card bg-success card-hover">
                  <a href="#contacto"></a>
                  <div className="card-body text-center p-0">
                    <a href="#contacto">
                      <div className="card-icon-border-large border-success">
                        <i className="far fa-calendar" aria-hidden="true" />
                      </div>
                      <h2 className="text-white font-size-32 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">
                        Contacto
                      </h2>
                    </a>
                    <a
                      href="#contacto"
                      className="btn-scroll-down d-block pb-4 pb-lg-5"
                    >
                      <i className="fas fa-chevron-down" aria-hidden="true" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ====================================
          ———	NOSOTROS
          ===================================== */}
        <Nosotros />
        {/* ====================================
          ———	SERVICIOS
          ===================================== */}
        <Servicios subtitle={informacion?.nuestrosPaquetes} />
        {/* ====================================
          ———	CALL TO ACTION
          ===================================== */}
        <section
          className="py-9 bg-parallax"
          style={{
            backgroundImage: "url(assets/img/background/background-img-1.jpg)",
          }}
        >
          <div className="container">
            <div className="wow fadeInUp">
              <div className="section-title justify-content-center">
                <h2 className="text-white text-center">
                  ¿Necesitas más información?
                </h2>
              </div>
              <div className="text-center">
                <div
                  className="text-white font-size-18 mb-0"
                  style={{
                    fontWeight: "900",
                    mixBlendMode: "plus-lighter",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: String(informacion?.contactanos),
                  }}
                ></div>
                <a
                  href="#"
                  onClick={() => navigate(`/contactanos`)}
                  className="btn btn-danger shadow-sm text-uppercase mt-4"
                >
                  <i className="fas fa-phone-alt me-2" aria-hidden="true" />
                  Contacto
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* ====================================
          ———	BLOG SECTION
          ===================================== */}
        <Blogs subtitle={informacion?.blog} />
        {/* ====================================
          ———	COUNTER-UP SECTION
          ===================================== */}
        <Counters />

        {/* ====================================
          ———	GALLERY
          ===================================== */}
        <Galeria />

        <PostVideo subtitle={informacion?.nuestrasPublicaciones} />
        {/* ====================================
          ———	CONTACT SECTION
          ===================================== */}
        <Contacto />
      </div>
    </>
  );
};

export default Portada;
