import styles from "../layout/assets/css/core.min.module.css";
import { TextField, Typography } from "@mui/material";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import WarningIcon from "@mui/icons-material/Warning";

import CustomDirectionInput from "../../utils/CustomImput/CustomDirectionInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { esES } from "@mui/x-date-pickers/locales";
import "dayjs/locale/es-mx";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import S3Service from "../../../services/S3.service";
import { formatDirection } from "../../utils/Format";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import AppointmentService from "../../../services/therapies/Appointment.service";
import Preloader from "../../utils/PreloaderElement";

const SectionCalendarM = () => {
  const today = new Date();
  const [info, setInfo] = useState(null);
  const [fechaCita, setFechaCita] = useState(today);
  const [loading, setLoading] = useState(true);

  const handleFechaCita = (newFecha) => {
    setFechaCita(newFecha.toDate());
  };

  useEffect(() => {
    // getAlumnoImage();
    getInfo();
  }, [fechaCita]);

  const getInfo = async () => {
    setLoading(true);
    try {
      const data = await AppointmentService.getInfoM({
        fecha: fechaCita.toISOString(),
      });
      if (data) {
        setInfo(data);
      }
    } catch (error) {
      toast.error(error.mensaje);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`${styles.row} ${styles.g4}`}>
      <div className={`${styles.col}`}>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            <div className={`${styles["col-md-4"]}`}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={
                  esES.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
                adapterLocale="es-mx"
              >
                <StaticDatePicker
                  showDaysOutsideCurrentMonth={true}
                  label="Fecha de cita:"
                  onChange={handleFechaCita}
                  value={dayjs(fechaCita)}
                  slotProps={{
                    actionBar: {
                      actions: [],
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className={`${styles["col-md-4"]}`}>
              <div className={`${styles["my-4"]}`}></div>
            </div>
            <div className={`${styles["col-md-4"]}`}>
              <div className={`${styles["my-4"]}`}>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    backgroundColor: "background.paper",
                  }}
                >
                  {loading ? (
                    <Preloader />
                  ) : (
                    <>
                      <ListItem>
                        <ListItemText primary="Reportes:" />
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: "#9b242c" }}>
                            {info?.reportes}
                          </Avatar>
                        </ListItemAvatar>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Planeaciones:" />
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: "#9b242c" }}>
                            {info?.planeaciones}
                          </Avatar>
                        </ListItemAvatar>
                      </ListItem>
                      <ListItem>
                        {/* <ListItemText primary="Materias:" />
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: "#9b242c" }}>
                            {info?.materias}
                          </Avatar>
                        </ListItemAvatar> */}
                      </ListItem>{" "}
                    </>
                  )}
                </List>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionCalendarM;
