import styles from "../../layout/assets/css/core.min.module.css";

import { Box, Button, TextField, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState } from "react";

const SectionSocioAfectivo = ({ socioAfectivo, handleSocioAfectivo }) => {
  return (
    <div className={`${styles.row} ${styles.colSmm12}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          Socio – afectivo
        </div>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>
                Número de personas que integran la familia:
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="#"
                onChange={(e) =>
                  handleSocioAfectivo({
                    ...socioAfectivo,
                    integrantesFamilia: e.target.value,
                  })
                }
                value={socioAfectivo?.integrantesFamilia}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Como se lleva con su mamá:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleSocioAfectivo({
                    ...socioAfectivo,
                    relacionMadre: e.target.value,
                  })
                }
                value={socioAfectivo?.relacionMadre}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Como se lleva con su papá:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder=".."
                onChange={(e) =>
                  handleSocioAfectivo({
                    ...socioAfectivo,
                    relacionPadre: e.target.value,
                  })
                }
                value={socioAfectivo?.relacionPadre}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Con quién pasa más tiempo su hijo (a):</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleSocioAfectivo({
                    ...socioAfectivo,
                    mayorConvivencia: e.target.value,
                  })
                }
                value={socioAfectivo?.mayorConvivencia}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>
                Con quien juega el niño en sus ratos libres:
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleSocioAfectivo({
                    ...socioAfectivo,
                    companeroJuego: e.target.value,
                  })
                }
                value={socioAfectivo?.companeroJuego}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Quien ejerce la autoridad en casa:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleSocioAfectivo({
                    ...socioAfectivo,
                    autoridad: e.target.value,
                  })
                }
                value={socioAfectivo?.autoridad}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Con quien duerme el niño:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleSocioAfectivo({
                    ...socioAfectivo,
                    companeroSiesta: e.target.value,
                  })
                }
                value={socioAfectivo?.companeroSiesta}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Cuantas veces se baña al día:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="#"
                onChange={(e) =>
                  handleSocioAfectivo({
                    ...socioAfectivo,
                    banoDia: e.target.value,
                  })
                }
                value={socioAfectivo?.banoDia}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Cuantas veces se cambia de muda de ropa: </Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="#"
                onChange={(e) =>
                  handleSocioAfectivo({
                    ...socioAfectivo,
                    cambiosDia: e.target.value,
                  })
                }
                value={socioAfectivo?.cambiosDia}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Como apoya a su hijo (a) en casa:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleSocioAfectivo({
                    ...socioAfectivo,
                    apoyoOfrecido: e.target.value,
                  })
                }
                value={socioAfectivo?.apoyoOfrecido}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSocioAfectivo;
