import styles from "./assets/css/core.min.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";

const MenuElementSubTextEnd = ({ subtitle, roles }) => {
  const { checkRole } = useContext(AuthContext);

  return !roles || checkRole(roles) ? (
    <li className={`${styles.navTitle} ${styles.mb1}`}>
      <p
        className={`${styles.mb0} ${styles.smaller} ${styles.textMuted} ${styles.textUppercase}`}
        style={{
          fontSize: "11px !important",
          borderTop: "1px dashed lightgray",
        }}
      ></p>
    </li>
  ) : null;
};

export default MenuElementSubTextEnd;
