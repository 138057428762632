import { useEffect, useState } from "react";
import AvisoPrivacidadService from "../../../services/Aviso.service";
import Sidebar from "./Sidebar";
import Title from "./Title";

const AvisoPrivacidad = () => {
  const [aviso, setAviso] = useState([]);

  useEffect(() => {
    getAviso();
    window.scrollTo(0, 0);
  }, []);

  const getAviso = async () => {
    try {
      const data = await AvisoPrivacidadService.get();
      setAviso(data[0]);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <div className="main-wrapper packages-grid">
      <Title title={aviso?.titulo} />
      <div className="container pb-7">
        <div className="row g-4">
          <div className="col-12 col-lg-8 order-1 order-lg-2">
            <article className="article-format">
              <h2 className="h4 fw-bold">{aviso?.titulo}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: aviso?.cuerpo,
                }}
                style={{ textAlign: "justify" }}
              ></p>
            </article>
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default AvisoPrivacidad;
