import { useState } from "react";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { Button, Divider, IconButton, InputBase, Paper } from "@mui/material";

const Busqueda = ({ searchFunction, placeholder }) => {
  const [value, setValue] = useState("");
  const [state, setState] = useState(false);

  const buscar = () => {
    searchFunction(value);
    if (value) {
      setState(true);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }

    if (!value) {
      vaciar();
    } else {
      buscar();
    }
  };

  const vaciar = () => {
    searchFunction("");
    setValue("");
    setState(false);
  };

  return (
    <Paper
      component="div"
      sx={{
        margin: "0.3rem 0.5rem",
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        maxWidth: "100%",
      }}
    >
      {!state ? (
        <IconButton sx={{ p: "10px" }} aria-label="menu">
          <svg
            class="z-index-1 start-0 text-primary"
            width="18px"
            height="18px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </IconButton>
      ) : (
        <IconButton
          sx={{ p: "10px" }}
          aria-label="menu"
          onClick={() => vaciar()}
        >
          <CleaningServicesIcon style={{ color: "#9b242c" }} />
        </IconButton>
      )}
      <InputBase
        type="text"
        sx={{ ml: 1, flex: 1 }}
        inputProps={{ "aria-label": "buscar alumno" }}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        onKeyDown={handleKeyDown}
      />
      {/* <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton> */}
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <Button
        color="primary"
        sx={{ p: "10px" }}
        aria-label="directions"
        onClick={() => buscar()}
        style={{
          color: "#9b242c",
        }}
      >
        Buscar
      </Button>
    </Paper>
  );
};

export default Busqueda;
