import {
  Grid,
  ListItemText,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { FormatDateTime } from "../../utils/Format";
import { useEffect, useState } from "react";
import styles from "../layout/assets/css/core.min.module.css";
import { toast } from "react-toastify";
import PlaneacionService from "../../../services/Planeacion.service";
import Preloader from "../../utils/PreloaderElement";
import DropComponent from "../filesDrop";
import S3Service from "../../../services/S3.service";
import { useNavigate } from "react-router-dom";
import GrupoService from "../../../services/Grupo.service";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ListItem from "@mui/material/ListItem";

const Crear = () => {
  const today = new Date();
  const navigate = useNavigate();

  const [fechaCreacion, setFechaCreacion] = useState("1/1/2024");
  const [fechaEdicion, setFechaEdicion] = useState("1/1/2024");
  const [nota, setNota] = useState("");
  const [tituloreporte, setTituloReporte] = useState("");
  const [filesArray, setFilesArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const [grupos, setGrupos] = useState([]);
  const [grupoSelected, setGrupoSelected] = useState(null);

  const handleGrupo = (event, newArea) => {
    setGrupoSelected(newArea);
  };

  const registerCita = async () => {
    setLoading(true);

    try {
      if (!tituloreporte) {
        toast.info("El titulo es necesario.");
        return;
      } else if (!nota) {
        toast.info("Notas esta vacía.");
        return;
      } else if (!grupoSelected) {
        toast.info("Grupo no seleccionado");
        return;
      }
      let citaRegisterData = {
        titulo: tituloreporte,
        notas: nota,
        fechaCreacion,
        fechaEdicion,
        archivos: [],
        tipo: "Grupal",
      };

      if (grupoSelected) {
        citaRegisterData.grupo = grupoSelected;
        citaRegisterData.tipo = "Grupal";
      }

      let imagenesArray = []; //Array para los id del s3
      for (const file of filesArray) {
        if (!file.keyFile) {
          const resultFile = await S3Service.upload(file);
          imagenesArray.push({
            keyFile: resultFile.result.data,
            name: file.name,
            size: file.size,
            type: file.type,
          });
        } else {
          imagenesArray.push({
            keyFile: file.keyFile,
            name: file.name,
            size: file.size,
            type: file.type,
          });
        }
      }

      citaRegisterData.archivos = imagenesArray;

      await PlaneacionService.create(citaRegisterData);
      toast.success("Planeación registrada");

      setTituloReporte("");
      setNota("");
      setFilesArray((old) => []);
      navigate("/planeaciones");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFechaCreacion(today.toISOString());
    setFechaEdicion(today.toISOString());

    getGrupos();
  }, []);

  const getGrupos = async () => {
    try {
      const getData = await GrupoService.getAll();
      if (getData && getData[0]) {
        setGrupos(getData);
        setGrupoSelected(getData[0]?.id);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main
      id={styles["middle"]}
      className={`${styles.flexFill} ${styles.mxAuto}`}
    >
      <header>
        <h1 className={`${styles.h4}`}>Nueva planeación</h1>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb} ${styles.small}`}>
            <li className={`${styles.breadcrumbItem}`}>
              <button
                style={{
                  border: "none",
                  backgroundColor: "unset",
                  color: "#9b242c",
                }}
                onClick={() => navigate("/planeaciones")}
              >
                Planeaciones
              </button>
            </li>
            <li
              className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
              aria-current="page"
            >
              Nuevo
            </li>
          </ol>
        </nav>
      </header>
      <div className={`${styles.row}`}>
        <div className={`${styles.section} ${styles.mb1}`}>
          <div className={`${styles["card-body"]}`}>
            {loading ? (
              <Preloader />
            ) : (
              <>
                <div
                  className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                >
                  Grupo
                </div>
                <div className={`${styles["m-4"]}`}>
                  <ToggleButtonGroup
                    value={grupoSelected}
                    exclusive
                    onChange={handleGrupo}
                    aria-label="Platform"
                    sx={{ flexWrap: "wrap" }}
                  >
                    {grupos?.map((grupoOption) => (
                      <ToggleButton value={grupoOption.id}>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                typography="button"
                                sx={{
                                  color: grupoOption?.color,
                                  fontWeight: "bold",
                                }}
                              >
                                {grupoOption.nombre}
                              </Typography>
                            }
                            secondary={
                              <ListItemText
                                primary={
                                  "Edad: " +
                                  grupoOption.rangoEdad?.rangoInicial +
                                  " - " +
                                  grupoOption.rangoEdad?.rangoFinal
                                }
                              />
                            }
                          />
                        </ListItem>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </div>

                <Grid container>
                  <Grid xs={12} md={8}>
                    <ListItemText
                      primary="Fecha:"
                      secondary={FormatDateTime(fechaCreacion)}
                    />
                  </Grid>
                  <Grid xs={12} md={4}></Grid>
                  <Grid xs={12} md={12}>
                    <TextField
                      fullWidth
                      label="Titulo de la planeación"
                      value={tituloreporte}
                      onChange={(event) => setTituloReporte(event.target.value)}
                    />
                  </Grid>
                  <Grid xs={12} md={6}></Grid>
                  <Grid xs={12} md={12}>
                    <Typography>Notas:</Typography>
                    <TextareaAutosize
                      minRows={10}
                      style={{
                        width: "100%",
                        padding: "0.5rem",
                        backgroundColor: "#ececec",
                      }}
                      value={nota}
                      onChange={(event) => setNota(event.target.value)}
                    />
                  </Grid>
                  <Grid xs={12} md={12}>
                    <Typography>Archivos:</Typography>

                    <div style={{ width: "100%" }}>
                      <DropComponent
                        filesDefault={filesArray}
                        subirArchivo={(data) => setFilesArray(data)}
                      />
                    </div>
                  </Grid>
                  <Grid xs={12}>
                    <>
                      <div
                        className={`${styles.cardFooter} ${styles.borderLight}`}
                        style={{ textAlign: "right" }}
                      >
                        <button
                          className={`${styles.btn} ${styles.btnPrimary}`}
                          onClick={() => {
                            registerCita();
                          }}
                        >
                          <span className={`${styles.small}`}>
                            Registrar planeación
                          </span>
                        </button>
                      </div>
                    </>
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Crear;
