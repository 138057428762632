/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

/* Components & dependencies */
import DropZoneEdit from "../../DropZoneEdit";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";
/*Services */
import GaleriaService from "../../../services/PostVideo.service";
import S3Service from "../../../services/S3.service";
import YoutubeEmbed from "./YoutubeEmbed";

import styles from "../layout/assets/css/core.min.module.css";

const EditarPublicacion = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [galeria, setGaleria] = useState();

  useEffect(() => {
    loadCarruselItem();
  }, []);

  const loadCarruselItem = async () => {
    setLoading(true);
    try {
      const result = await GaleriaService.getById(parseInt(id));
      if (result.imagen) {
        const imagen = await S3Service.get(result.imagen);
        setImagen(imagen.result);
      }
      setGaleria(result);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(true);
      return;
    }
  };

  const updateCarrusel = async () => {
    setLoading(true);
    try {
      if (!galeria?.titulo) {
        toast.info("Necesitas agregar un titulo");
        return;
      }
      if (!obtenerIdVideo(galeria?.link)) {
        toast.info("Necesitas agregar una url valida");
        return;
      }
      const dataCarrusel = galeria;
      if (file) {
        await S3Service.delete(dataCarrusel.imagen);
        const uploadPhoto = await S3Service.upload(file);
        dataCarrusel.imagen = uploadPhoto.result.data;
        await GaleriaService.update(dataCarrusel);
      } else {
        await GaleriaService.update(dataCarrusel);
      }
      setLoading(false);
      navigate("/publicacion");
      toast.success("Publicacionl actualizada");
    } catch (error) {
      toast.error(error);
      setLoading(true);
    }
  };

  const obtenerIdVideo = (url) => {
    if (!url) {
      return false;
    }
    try {
      let parts = new URL(url); // Crear un objeto URL a partir de la cadena
      if (parts.search) {
        // La URL tiene una cadena de consulta, como https://www.youtube.com/watch?v=jkQ41Xjn-K0
        let qs = new URLSearchParams(parts.search); // Crear un objeto URLSearchParams a partir de la cadena de consulta
        if (qs.has("v") && qs.get("v").length === 11) {
          // El parámetro v tiene el ID del video
          return qs.get("v");
        } else {
          // No se encontró el parámetro v
          return false;
        }
      } else if (parts.pathname) {
        // La URL tiene una ruta, como https://youtu.be/jkQ41Xjn-K0
        let path = parts.pathname.split("/").filter((p) => p); // Dividir la ruta por / y eliminar los elementos vacíos
        return path[0];
      } else {
        // La URL no tiene ni cadena de consulta ni ruta
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
            style={{ width: "100%", overflow: "auto" }}
          >
            {/* PAGE TITLE */}
            <header>
              <h1 className={`${styles.h4}`}>Nuevo Item</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li className={`${styles.breadcrumbItem}`}>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "unset",
                        color: "#9b242c",
                      }}
                      onClick={() => navigate("/publicacion")}
                    >
                      Publicaciones
                    </button>
                  </li>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Nuevo
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className={`${styles.row} ${styles.g4}`}>
                <div className={`${styles.col}`}>
                  {/* Shipping address */}
                  <div
                    className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                  >
                    Publicación
                  </div>
                  <div className={`${styles.section} ${styles.mb4}`}>
                    <div className={`${styles.cardBody}`}>
                      <div className={`${styles.row} ${styles.g3}`}>
                        <div className={`${styles.colSm12}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Título"
                              value={galeria?.titulo}
                              onChange={(e) =>
                                setGaleria({
                                  ...galeria,
                                  titulo: e.target.value,
                                })
                              }
                            />
                            <label>Titulo</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm12}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Url"
                              value={galeria?.link}
                              onChange={(e) =>
                                setGaleria({
                                  ...galeria,
                                  link: e.target.value,
                                })
                              }
                            />
                            <label>Url video:</label>
                          </div>
                          <p style={{ paddingInline: "1rem" }}>
                            Ejemplo: https://www.youtube.com/watch?v=rvp063t9vmE
                          </p>
                        </div>
                        {/* {file ? (
                          <>
                            <div style={{ textAlign: "center" }}>
                              <img
                                src={URL.createObjectURL(file)}
                                style={{
                                  height: 300,
                                  objectFit: "contain",
                                }}
                              />
                              <Typography
                                variant="body1"
                                component="span"
                                style={{
                                  width: "-webkit-fill-available",
                                }}
                              >
                                {file.name}
                              </Typography>
                            </div>
                          </>
                        ) : null} */}
                        {/* <DropZoneEdit
                          saveFile={async (file) => {
                            setFile(file);
                            var reader = new FileReader();
                            var url = reader.readAsDataURL(file);
                          }}
                        /> */}
                        <div>
                          <YoutubeEmbed
                            embedId={obtenerIdVideo(galeria?.link)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className={`${styles.co12}`}>
              <div
                className={`${styles.cardFooter} ${styles.borderLight}`}
                style={{ textAlign: "right" }}
              >
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => navigate("/publicacion")}
                >
                  <span className={`${styles.small}`}>Cancelar</span>
                </button>
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => updateCarrusel()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>{" "}
                  {"  "}
                  <span className={`${styles.small}`}>Guardar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default EditarPublicacion;
