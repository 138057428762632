import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import styles from "../layout/assets/css/core.min.module.css";
import TabCita from "./TabCita";
import TabHistorialCita from "./TabHistorialCita";
import TabHistorialClinico from "./TabHistorialClinico";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: "80%",
    width: "100%",
    backgroundColor: "#9b242c",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#ffff",
      fontWeight: 700,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      }
    </div>
  );
}

export default function DetallesTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={`${styles.col}`}>
      <div
        className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
      >
        <StyledTabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          sx={{ bgcolor: "#323333" }}
        >
          <StyledTab label="Cita" />
          <StyledTab label="Historial citas" />
          <StyledTab label="Historial clínico" />
        </StyledTabs>
      </div>
      <div className={`${styles.section} ${styles.mb4}`}>
        <div className={`${styles.cardBody}`}>
          <div className={`${styles.tableResponsiveMd}`}>
            <div className={`${styles["m-4"]}`}>
              <Box>
                <CustomTabPanel value={value} index={0}>
                  <TabCita />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <TabHistorialCita />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <TabHistorialClinico />
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
