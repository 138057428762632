import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";

import S3Service from "../../../services/S3.service";
import AlumnoService from "../../../services/Alumno.service";
import GrupoService from "../../../services/Grupo.service";

import styles from "../layout/assets/css/core.min.module.css";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import WarningIcon from "@mui/icons-material/Warning";
import FormAuthorizedPerson from "./FormAuthorizedPerson";
import FormAlumno from "./FormAlumno";
import SubAreaService from "../../../services/SubArea.service";
import SectionAntecedentesPerinatales from "../historialClinico/Secciones/SectionAntecedentesPerinatales";
import SectionFactoresDesarrollo from "../historialClinico/Secciones/SectionFactoresDesarrollo";
import SectionAntecedentesMedicos from "../historialClinico/Secciones/SectionAntecedentesMedicos";
import SectionDesarrolloSicomotor from "../historialClinico/Secciones/SectionDesarrolloSicomotor";
import SectionLenguaje from "../historialClinico/Secciones/SectionLenguaje";
import SectionSocioAfectivo from "../historialClinico/Secciones/SectionSocioAfectivo";
import SectionConductual from "../historialClinico/Secciones/SectionConductual";
import SectionAntecendentes from "../historialClinico/Secciones/SectionAntecedentes";
import SectionSocioeconomico from "../historialClinico/Secciones/SectionSocieconomico";
import SectionAcademico from "../historialClinico/Secciones/SectionAcademico";

import { useFormik } from "formik";
import { mixed, object, string } from "yup";
import DropComponent from "../filesDrop";

const label = { inputProps: { "aria-label": "Color switch demo" } };

const alumnoSchema = object({
  alumno: object({
    nombre: string("Escribe un nombre").required("El nombre es requerido"),
    fechaNacimiento: string().required("La fecha de nacimiento es requerida"),
    sexo: string().required("Seleccione un sexo"),
    domicilio: object({
      calle: string("Escribe una calle"),
      codigoPostal: string("Escribe un código postal"),
      colonia: string("Escribe una colonia"),
      estado: string("Escribe un estado"),
      exterior: string("Escribe un #exterior"),
      interior: string("Escribe un #interior"),
      municipio: string("Escribe un municipio"),
    }),
    responsable: string().required("Con quien vive es requerido"),
    file: mixed()
      .required("Requiere un imagen")
      .test("fileFormat", "Archivos permitidos: jpg, jpeg, png", (value) => {
        if (value) {
          const supportedFormats = ["jpg", "jpeg", "png"];
          return supportedFormats.includes(value.name.split(".").pop());
        }
        return true;
      })
      .test("fileSize", "La imagen debe ser menor a 3MB", (value) => {
        if (value) {
          return value.size <= 3145728;
        }
        return true;
      }),
  }),
  antecedentesPerinatales: object({
    duracionEmbarazo: string().test(
      "duracionEmbarazo",
      "Especifica la duración en un valor numérico y unidades de tiempo validas ",
      (value) => {
        const regexDigito = /\D*\d+\D*/i;
        const regexMedida = /\D*meses|semanas\D*/i;
        if (!value) return true;
        return regexDigito.test(value) && regexMedida.test(value);
      }
    ),
    parto: string("Especifica un método de parto"),
    lloroNacer: string("Especifica si la persona lloro al nacer"),
    pesoNacimiento: string().test(
      "peso",
      "Especifica el peso en kg",
      (value) => {
        const regexDigito = /\D*\d+\D*/i;
        const regexMedida = /\D*kg\D*/i;
        if (!value) return true;
        return regexDigito.test(value) && regexMedida.test(value);
      }
    ),
    tamano: string().test("tamano", "Especifica el tamaño en cm", (value) => {
      const regexDigito = /\D*\d+\D*/i;
      const regexMedida = /\D*cm\D*/i;
      if (!value) return true;
      return regexDigito.test(value) && regexMedida.test(value);
    }),
    apgar: string().test(
      "apgar",
      "Especifica la calificación apgar (valor/10)",
      (value) => {
        const regexMedida = /\D*\d\/\d\D*/i;

        if (!value) return true;
        return regexMedida.test(value);
      }
    ),
    embarazoNumero: string().test(
      "embarazoNumero",
      "Especifica el número de embarazo",
      (value) => {
        const regexDigito = /\D*\d+\D*/i;
        if (!value) return true;
        return regexDigito.test(value);
      }
    ),
  }),
});

const alumnoInitialValues = {
  alumno: {
    nombre: "",
    fechaNacimiento: "2024-01-01",
    sexo: "Masculino",
    domicilio: "",
    responsable: "Ambos",
    file: undefined,
  },
  antecedentesPerinatales: {
    duracionEmbarazo: "",
    parto: "",
    lloroNacer: "",
    pesoNacimiento: "",
    tamano: "",
    apgar: "",
    embarazoNumero: "",
  },
};

const AlumnoCrear = () => {
  const { currentUser } = useContext(AuthContext);
  const [imagen, setImagen] = useState(null);

  const [currentPerson, setCurrentPerson] = useState(null);
  const [filesArray, setFilesArray] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);

  const formik = useFormik({
    initialValues: alumnoInitialValues,
    validationSchema: alumnoSchema,
    onSubmit: (values) => {
      saveAlumno(values);
    },
  });

  useEffect(() => {
    if (Object.keys(formik.errors).length >= 1 && formik.dirty) {
      toast.info("Revisar los campos obligatorios");
    }
  }, [formik.submitCount]);

  const [
    antecedentesPerinatalesDuracionEmbarazo,
    setAntecedentesPerinatalesDuracionEmbarazo,
  ] = useState("");
  const [antecedentesPerinatalesParto, setAntecedentesPerinatalesParto] =
    useState("");
  const [
    antecedentesPerinatalesLloroNacer,
    setAntecedentesPerinatalesLloroNacer,
  ] = useState("Si");
  const [
    antecedentesPerinatalesPesoNacimiento,
    setAntecedentesPerinatalesPesoNacimiento,
  ] = useState("0 kg");
  const [antecedentesPerinatalesTamano, setAntecedentesPerinatalesTamano] =
    useState("0 cm");
  const [antecedentesPerinatalesApgar, setAntecedentesPerinatalesApgar] =
    useState("");
  const [
    antecedentesPerinatalesEmbarazoNumero,
    setAntecedentesPerinatalesEmbarazoNumero,
  ] = useState("");

  const [factoresDesarrollo, setFactoresDesarrollo] = useState({});
  const [antecedentesMedicos, setAntecedentesMedicos] = useState({});
  const [desarrolloSicomotor, setDesarrolloSicomotor] = useState({});
  const [desarrolloLenguaje, setDesarrolloLenguaje] = useState({});
  const [socioAfectivo, setSocioAfectivo] = useState({});
  const [conductual, setConductual] = useState({});
  const [antecedentesHeredofamiliares, setAntecedentesHeredofamiliares] =
    useState({});
  const [historialAcademico, setHistorialAcademico] = useState({});

  const [socioEconomicoVivienda, setSocioEconomicoVivienda] = useState("");
  const [socioEconomico, setSocioEconomico] = useState({});

  const [personas, setPersonas] = useState([]);
  const [area, setArea] = useState(null);
  const [areaTerapeutica, setAreaTerapeutica] = useState(false);
  const [areaEducativa, setAreaEducativa] = useState(false);

  const [grupos, setGrupos] = useState([]);
  const [grupo, setGrupo] = useState("");

  const [subAreas, setSubAreas] = useState([]);
  const [subAreasSelected, setSubAreasSelected] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openAddAuthorizedPerson, setOpenAddAuthorizedPerson] = useState(false);
  const navigate = useNavigate();

  const handleCurrenPerson = (person) => {
    setOpenAddAuthorizedPerson(true);
    setCurrentPerson(person);
  };

  const handlePersonas = (newPersonas) => {
    setPersonas((old) => newPersonas);
  };

  const toggleDrawerAddAuthorizedPerson = (newOpen) => () => {
    setOpenAddAuthorizedPerson(newOpen);
    if (!newOpen) {
      setCurrentPerson(null);
    }
  };

  const deletePersona = (nombre, imagen) => {
    let oldPersons = personas.filter((persona) => persona.uuid !== nombre);
    setPersonas(oldPersons);
  };
  const handleChangeAreaTerapeutica = (event) => {
    setAreaTerapeutica(event.target.checked);
  };
  const handleChangeAreaEducativa = (event) => {
    setAreaEducativa(event.target.checked);
  };

  const handleValue = (event, newValues) => {
    setSubAreasSelected(newValues);
  };

  const handleGrupo = (event, newArea) => {
    setGrupo(newArea);
  };

  const eliminarArchivo = async (archivo) => {
    try {
      if (archivo.keyFile) {
        setFilesToDelete((old) => [...old, archivo]);
      }
    } catch (error) {
      toast.error("Error,archivo no disponible.");
    }
  };

  const saveAlumno = async (values) => {
    if (personas?.length < 1) {
      toast.info("Error, tienes que agregar un responsable");
      return;
    } else if (areaEducativa && (grupo === "" || grupo === "N/A")) {
      toast.info("Error, tienes que seleccionar un grupo.");
      return;
    } else if (areaTerapeutica && subAreasSelected.length < 1) {
      toast.info("Error, tienes que seleccionar una sub-área.");
      return;
    } else if (!areaTerapeutica && !areaEducativa) {
      toast.info("Error, debe asignarse un grupo o sub-área");
      return;
    } else {
      setLoading(true);
      try {
        let fechaFormat = values.alumno.fechaNacimiento.split("-");
        let newFechaNacimiento = new Date(
          `${fechaFormat[1]}-${fechaFormat[2]}-${fechaFormat[0]}`
        );

        let storeFilesArray = []; //Array para los id del s3
        for (const file of filesArray) {
          if (!file.keyFile) {
            const resultFile = await S3Service.upload(file);
            storeFilesArray.push({
              keyFile: resultFile.result.data,
              name: file.name,
              size: file.size,
              type: file.type,
            });
          } else {
            storeFilesArray.push({
              keyFile: file.keyFile,
              name: file.name,
              size: file.size,
              type: file.type,
            });
          }
        }

        let historialCreado = {
          antecedentesPerinatales: values.antecedentesPerinatales,
          factoresDesarrollo,
          antecedentesMedicos,
          desarrolloSicomotor,
          desarrolloLenguaje,
          socioAfectivo,
          conductual,
          antecedentesHeredofamiliares,
          socioEconomico,
          historialAcademico,
        };

        const noticiaSubir = {
          nombre: values.alumno.nombre,
          fechaNacimiento: newFechaNacimiento.toISOString(),
          sexo: values.alumno.sexo,
          domicilio: values.alumno.domicilio,
          responsable: values.alumno.responsable,
          viveConEspecificacion: "",
          usuarioId: currentUser.id,
          historialClinico: historialCreado,
          area: area,
          grupo: grupo,
          subareas: subAreasSelected,
          archivos: storeFilesArray,
        };

        if (!areaEducativa) {
          noticiaSubir.grupo = -1;
        }

        if (!areaTerapeutica) {
          noticiaSubir.subareas = [];
        }

        if (values.alumno.file) {
          const uploadPhoto = await S3Service.upload(values.alumno.file);
          noticiaSubir.imagen = uploadPhoto.result.data;
        }

        await personas?.map(async (persona) => {
          if (persona.file) {
            const uploadPhoto = await S3Service.upload(persona.file);
            persona.imagen = uploadPhoto.result.data;
            persona.file = null;
            console.log(persona);
          }
        });

        if (personas) {
          noticiaSubir.personas = personas;
        }

        await AlumnoService.create(noticiaSubir);

        toast.success("Alumno registrado con éxito!");
        navigate(-1);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getGrupos();
    getSubAreas();
  }, []);

  const getGrupos = async () => {
    try {
      const getData = await GrupoService.getAll();
      if (getData && getData[0]) {
        setGrupos(getData);
        setGrupo(getData[0]?.id);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getSubAreas = async () => {
    try {
      const getData = await SubAreaService.getAll();
      if (getData && getData[0]) {
        setSubAreas(getData);
        setSubAreasSelected([getData[0]]);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return loading ? (
    <Preloader />
  ) : (
    <main
      id={styles["middle"]}
      className={`${styles.flexFill} ${styles.mxAuto}`}
    >
      <header>
        <h1 className={`${styles.h4}`}>Nuevo alumno</h1>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb} ${styles.small}`}>
            <li className={`${styles.breadcrumbItem}`}>
              <button
                style={{
                  border: "none",
                  backgroundColor: "unset",
                  color: "#9b242c",
                }}
                onClick={() => navigate(-1)}
              >
                Regresar
              </button>
            </li>
            <li
              className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
              aria-current="page"
            >
              Registrar alumno
            </li>
          </ol>
        </nav>
      </header>

      <FormAlumno formik={formik} imagen={imagen} />

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div
            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
          >
            Asignaciones
          </div>
          <div className={`${styles.section} ${styles.mb4}`}>
            <div className={`${styles.cardBody}`}>
              <Typography typography="caption" color="#9b242c">
                * Obligatorio (Debe tener una asignación)
              </Typography>
              <div className={`${styles["m-4"]}`}>
                <label>Área Terapéutica</label>
                <Switch
                  {...label}
                  color="error"
                  checked={areaTerapeutica}
                  onChange={handleChangeAreaTerapeutica}
                />
                <label>Área Educativa</label>
                <Switch
                  {...label}
                  color="error"
                  checked={areaEducativa}
                  onChange={handleChangeAreaEducativa}
                />
              </div>
              {areaEducativa && (
                <>
                  <div className={`${styles["m-4"]}`}>
                    <p>Grupo</p>
                  </div>
                  <div className={`${styles["m-4"]}`}>
                    <ToggleButtonGroup
                      value={grupo}
                      exclusive
                      onChange={handleGrupo}
                      aria-label="Platform"
                      sx={{ flexWrap: "wrap" }}
                    >
                      {grupos?.map((grupoOption, index) => (
                        <ToggleButton key={index} value={grupoOption.id}>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography
                                  typography="button"
                                  sx={{
                                    color: grupoOption?.color,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {grupoOption.nombre}
                                </Typography>
                              }
                              secondary={
                                <ListItemText
                                  primary={
                                    "Edad: " +
                                    grupoOption.rangoEdad?.rangoInicial +
                                    " - " +
                                    grupoOption.rangoEdad?.rangoFinal
                                  }
                                />
                              }
                            />
                          </ListItem>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </div>
                </>
              )}
              {areaTerapeutica && (
                <>
                  <div className={`${styles["m-4"]}`}>
                    <p>Sub-Áreas</p>
                  </div>
                  <div className={`${styles["m-4"]}`}>
                    <Autocomplete
                      multiple
                      id="size-small-standard-multi"
                      size="small"
                      options={subAreas}
                      getOptionLabel={(option) => option.nombre}
                      value={subAreasSelected}
                      onChange={handleValue}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Sub-Áreas"
                          placeholder="Seleccionar Sub-Áreas"
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Box>
        <SectionAntecedentesPerinatales formik={formik} />

        <SectionFactoresDesarrollo
          factoresDesarrollo={factoresDesarrollo}
          handleFactoresDesarrollo={setFactoresDesarrollo}
        />
        <SectionAntecedentesMedicos
          antecedentesMedicos={antecedentesMedicos}
          handleAntecedentesMedicos={setAntecedentesMedicos}
        />
        <SectionDesarrolloSicomotor
          desarrolloSicomotor={desarrolloSicomotor}
          handleDesarrolloSicomotor={setDesarrolloSicomotor}
        />
        <SectionLenguaje
          lenguaje={desarrolloLenguaje}
          handleLenguaje={setDesarrolloLenguaje}
        />
        <SectionSocioAfectivo
          socioAfectivo={socioAfectivo}
          handleSocioAfectivo={setSocioAfectivo}
        />
        <SectionConductual
          conductual={conductual}
          handleConductual={setConductual}
        />
        <SectionAntecendentes
          antecedentes={antecedentesHeredofamiliares}
          handleAntecedentes={setAntecedentesHeredofamiliares}
        />
        <SectionSocioeconomico
          socioEconomico={socioEconomico}
          handleSocioEconomico={setSocioEconomico}
          socioEconomicoVivienda={socioEconomicoVivienda}
          setSocioEconomicoVivienda={setSocioEconomicoVivienda}
        />
        <SectionAcademico
          academico={historialAcademico}
          handleAcademico={setHistorialAcademico}
        />
      </Box>

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div
            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
          >
            Documentos
          </div>
          <div className={`${styles.section} ${styles.mb4}`}>
            <div
              className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}
            >
              <div style={{ width: "100%" }}>
                <DropComponent
                  filesDefault={filesArray}
                  subirArchivo={(data) => setFilesArray(data)}
                  eliminarArchivo={eliminarArchivo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div
            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
          >
            Personas autorizadas para recoger al alumno (a)
          </div>
          <div className={`${styles.section} ${styles.mb4}`}>
            <Typography typography="caption" color="#9b242c">
              * Obligatorio (Debe tener una persona autorizada para recoger al
              alumno)
            </Typography>
            <div
              className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}
            >
              <div className={`${styles["my-2"]} ${styles["col-sm-12"]}`}>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    minHeight: "10rem",
                  }}
                >
                  <Divider component="li" />
                  {personas?.length < 1 && (
                    <>
                      <ListItem sx={{ color: "#9b242c" }}>
                        <ListItemAvatar>
                          <Avatar>
                            <WarningIcon sx={{ color: "#9b242c" }} />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Responsable no registrado" />
                      </ListItem>
                      <Divider component="li" />
                    </>
                  )}
                  {personas?.map((persona, index) => (
                    <>
                      <ListItem
                        key={index}
                        secondaryAction={
                          <div>
                            <IconButton
                              edge="end"
                              aria-label="edit"
                              onClick={() => handleCurrenPerson(persona)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() =>
                                deletePersona(persona.uuid, persona.imagen)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt="Person"
                            src={
                              persona.url
                                ? persona.url
                                : "/static/images/avatar/1.jpg"
                            }
                          ></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={persona.nombre}
                          secondary={persona.parentesco}
                        />
                      </ListItem>
                      <Divider component="li" key={index + "divider"} />
                    </>
                  ))}
                </List>
              </div>
              <div className={`${styles["my-2"]} ${styles["col-sm-12"]}`}>
                <div
                  className={`${styles.cardFooter} ${styles.borderLight}`}
                  style={{ textAlign: "right" }}
                >
                  <Button
                    color="error"
                    onClick={toggleDrawerAddAuthorizedPerson(true)}
                  >
                    <Typography
                      sx={{
                        color: "#9b242c",
                        paddingInline: "0.5rem",
                      }}
                    >
                      Agregar
                    </Typography>
                    <AddCircleIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openAddAuthorizedPerson}
        onClose={toggleDrawerAddAuthorizedPerson(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "85%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            overflowY: "auto",
          }}
        >
          <FormAuthorizedPerson
            personaDefault={currentPerson}
            personas={personas}
            handlePersonas={handlePersonas}
            close={toggleDrawerAddAuthorizedPerson(false)}
          />
        </Box>
      </Modal>
      {/* </Drawer> */}

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div className={`${styles.mb4}`}>
            <div className={`${styles.row} ${styles["m-2"]}`}>
              <div className={`${styles["my-2"]} ${styles["col-sm-12"]}`}>
                <div
                  className={`${styles.borderLight}`}
                  style={{ textAlign: "right" }}
                >
                  <button
                    className={`${styles.btn} ${styles.btnPrimary}`}
                    onClick={() => formik.submitForm()}
                  >
                    <svg
                      width="18px"
                      height="18px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    {"  "}
                    <span className={`${styles.small}`}>Guardar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AlumnoCrear;
