import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/*Servicios*/
import S3Service from "../../../services/S3.service";
import NoticiaService from "../../../services/Noticia.service";

/*Componentes*/
import Blog from "./Blog";
import FullPageLoading from "../../../utils/FullPageLoading";

const Portada_Blog = ({ subtitle }) => {
  const [noticias, setNoticias] = useState([]);
  const [noticia, setNoticia] = useState([]);
  const [imagen, setImagen] = useState(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    setImagen("");
    window.scrollTo(0, 0);
    getNoticias();
  }, []);

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(3, 0);
      setNoticia(data.data[0]);
      setNoticias(data.data);
      getImage();
    } catch (error) {
      console.log(error);
    }
  };

  const getImage = async () => {
    try {
      S3Service.get(noticia.imagen).then((dataimagen) => {
        // document.getElementById(`imagen123`).src = dataimagen.result;
        setImagen(dataimagen.result);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="pt-9 pb-7" id="blog">
      <div className="container">
        <div className="section-title justify-content-center mb-4 wow fadeInUp">
          <span className="shape shape-left bg-info" />
          <h2 className="text-danger">Últimas entradas</h2>
          <span className="shape shape-right bg-info" />
        </div>
        <div
          className="font-size-18 mb-md-8 text-center"
          dangerouslySetInnerHTML={{
            __html: String(subtitle ? subtitle : ""),
          }}
        ></div>
        <div className="row wow fadeInUp">
          {noticias.map((record, index) => {
            return <Blog key={index} data={record} />;
          })}
        </div>
        <div className="btn-aria text-center mt-4 wow fadeInUp">
          <button
            onClick={() => navigate(`/blog`)}
            className="btn btn-danger text-uppercase"
          >
            Ver más
          </button>
        </div>
      </div>
    </section>
  );
};

export default Portada_Blog;
