import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  Typography,
} from "@mui/material";
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import HistorialService from "../../../services/Historial.service";

import styles from "../layout/assets/css/core.min.module.css";
import AlumnoService from "../../../services/Alumno.service";
import S3Service from "../../../services/S3.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import WarningIcon from "@mui/icons-material/Warning";
import GroupsIcon from "@mui/icons-material/Groups";

const AlumnoEliminar = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [historial, setHistorial] = useState({});
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  const [filesArray, setFilesArray] = useState([]);

  const onUpdate = () => {
    setUpdate(!update);
  };

  const removeFiles = async () => {
    try {
      for (const file of filesArray) {
        if (file.keyFile) {
          await S3Service.delete(file.keyFile);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAlumno = async () => {
    setLoading(true);
    try {
      await AlumnoService.remove(id);
      await removeFiles();
      toast.info("Alumno eliminado");
      navigate(-1);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHistorial();
  }, [page, limit, update]);

  const getHistorial = async () => {
    setLoading(true);
    try {
      const getData = await HistorialService.getByAlumno(id);
      if (getData[0]) {
        let data = getData[0];
        setFilesArray((old) => data.alumno?.archivos || []);

        if (data.alumno.imagen) {
          const image = await S3Service.get(data.alumno.imagen);
          data.alumno.url = image.result;
        }
        setHistorial(data);
        setFechaNacimiento(
          new Intl.DateTimeFormat("az", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }).format(new Date(data.alumno.fechaNacimiento))
        );
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <main
          id={styles["middle"]}
          className={`${styles.flexFill} ${styles.mxAuto}`}
        >
          <header>
            <h1 className={`${styles.h4}`}>Historial</h1>
            <nav aria-label="breadcrumb">
              <ol className={`${styles.breadcrumb} ${styles.small}`}>
                <li className={`${styles.breadcrumbItem}`}>
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "unset",
                      color: "#9b242c",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Alumnos
                  </button>
                </li>
                <li
                  className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                  aria-current="page"
                >
                  Eliminar
                </li>
              </ol>
            </nav>
          </header>

          <div
            className={`${styles.row} ${styles.g4}`}
            style={{ width: "100%", overflow: "auto" }}
          >
            <div className={`${styles.col}`}>
              <div className={`${styles.section} ${styles.mb4}`}>
                <div className={`${styles.cardBody}`}>
                  <div className={`${styles.cardBody} ${styles.pt1}`}>
                    {/* item list */}
                    <div className={`${styles.cardHeader} ${styles.p4}`}>
                      <div className={`${styles.row} ${styles.g3}`}>
                        {/* options */}
                        <div
                          className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                        ></div>
                        <div
                          className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
                        ></div>
                      </div>
                    </div>
                    <div
                      className={`${styles.row} ${styles.tableResponsiveMd}`}
                    >
                      <div className={`${styles.row} ${styles.colSm12}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Alumno
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <div
                                className={`${styles.row} ${styles.colSm12}`}
                              >
                                <div className={`${styles.colSm3}`}>
                                  <div className={`${styles.colLg12}`}>
                                    <div className={`${styles.colLg12}`}>
                                      <div style={{ textAlign: "center" }}>
                                        <img
                                          src={
                                            historial?.alumno?.url
                                              ? historial?.alumno?.url
                                              : "/assets/img/no-image.svg"
                                          }
                                          alt="perfil img"
                                          style={{
                                            height: 300,
                                            objectFit: "contain",
                                            width: "-webkit-fill-available",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles["col-sm-1"]}`}></div>
                                <div className={`${styles["col-sm-8"]}`}>
                                  <div className={`${styles["col-sm-12"]}`}>
                                    <div className={`${styles["m-4"]}`}>
                                      <Typography>
                                        Nombre del alumno:{" "}
                                        <span
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {historial?.alumno?.nombre || "-"}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div className={`${styles["m-4"]}`}>
                                      <Typography>
                                        Fecha nacimiento:
                                        <span
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {fechaNacimiento
                                            ? fechaNacimiento
                                            : "-"}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div className={`${styles["m-4"]}`}>
                                      <Typography>
                                        Sexo:
                                        <span
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {historial?.alumno?.sexo || "-"}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div className={`${styles["m-4"]}`}>
                                      <Typography>Domicilio</Typography>
                                      <Box
                                        className={`${styles["row"]} ${styles.colSm12}`}
                                      >
                                        <div className={`${styles["colSm6"]}`}>
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              Calle:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {historial?.alumno?.domicilio
                                                  ?.calle || "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>

                                        <div className={`${styles["colSm3"]}`}>
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              # Interior:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {historial?.alumno?.domicilio
                                                  ?.interior || "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>

                                        <div className={`${styles["colSm3"]}`}>
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              # Exterior:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {historial?.alumno?.domicilio
                                                  ?.exterior || "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>

                                        <div
                                          className={`${styles["col-sm-8"]}`}
                                        >
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              Colonia:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {historial?.alumno?.domicilio
                                                  ?.colonia || "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>

                                        <div
                                          className={`${styles["col-sm-4"]}`}
                                        >
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              Código postal:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {historial?.alumno?.domicilio
                                                  ?.codigoPostal || "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>

                                        <div className={`${styles["colSm6"]}`}>
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              Delegación o municipio:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {historial?.alumno?.domicilio
                                                  ?.municipio || "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>

                                        <div className={`${styles["colSm6"]}`}>
                                          <div className={`${styles["my-4"]}`}>
                                            <Typography>
                                              Entidad federativa o estado:{" "}
                                              <span
                                                style={{
                                                  color: "#9b242c",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {historial?.alumno?.domicilio
                                                  ?.estado || "-"}
                                              </span>
                                            </Typography>
                                          </div>
                                        </div>
                                      </Box>
                                    </div>
                                    <div className={`${styles["m-4"]}`}>
                                      <Typography>
                                        Con quien vive?:{" "}
                                        <span
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {historial?.alumno?.responsable ||
                                            "-"}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div className={`${styles["m-4"]}`}>
                                      <Typography>
                                        <span
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {historial?.alumno
                                            ?.viveConEspecificacion || "-"}
                                        </span>
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.row} ${styles.colSm12}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Documentos
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <Grid container>
                                <Grid item xs={12} sm={12}>
                                  <List>
                                    <Divider component="li" />
                                    {filesArray?.length > 0 ? (
                                      filesArray.map((element, index) => {
                                        return (
                                          <ListItem>
                                            <ListItemIcon>
                                              <FontAwesomeIcon
                                                icon={faFilePdf}
                                              />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={element.name || "-"}
                                            />
                                          </ListItem>
                                        );
                                      })
                                    ) : (
                                      <ListItem>
                                        <ListItemIcon>
                                          <WarningIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Sin elementos" />
                                      </ListItem>
                                    )}
                                    <Divider component="li" />
                                  </List>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.row} ${styles.colSm12}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Asignaciones
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <Grid container>
                                <Grid item xs={12} sm={6}>
                                  {historial?.alumno?.grupo && (
                                    <>
                                      <ListItem>
                                        <ListItemText primary="Grupo" />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemAvatar>
                                          <Avatar
                                            sx={{
                                              backgroundColor: historial?.alumno
                                                ?.grupo?.color
                                                ? historial?.alumno?.grupo.color
                                                : "#f2f2f2",
                                            }}
                                          >
                                            <GroupsIcon />
                                          </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                          primary={
                                            historial?.alumno?.grupo?.nombre
                                          }
                                          secondary={
                                            <ListItemText
                                              primary={
                                                "Edad: " +
                                                historial?.alumno?.grupo
                                                  ?.rangoEdad?.rangoInicial +
                                                " - " +
                                                historial?.alumno?.grupo
                                                  ?.rangoEdad?.rangoFinal
                                              }
                                              secondary={
                                                historial?.alumno?.grupo?.area
                                                  ?.tipo
                                              }
                                            />
                                          }
                                        />
                                      </ListItem>
                                    </>
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  {historial?.alumno?.subAreas[0] && (
                                    <>
                                      <ListItem>
                                        <ListItemText primary="Sub-Áreas" />
                                      </ListItem>

                                      <ListItem>
                                        <ListItemText
                                          style={{
                                            color: "#9b242c",
                                            fontWeight: "bold",
                                          }}
                                          primary={historial?.alumno?.subAreas?.map(
                                            (element) => element.nombre + ", "
                                          )}
                                          secondary={
                                            <ListItemText
                                              secondary={
                                                historial?.alumno?.subAreas[0]
                                                  ?.area?.tipo
                                              }
                                            />
                                          }
                                        />
                                      </ListItem>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.row} ${styles.colSm6}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Antecedentes perinatales{" "}
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Semanas o meses que duró el embarazo:{" "}
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesPerinatales
                                      ?.duracionEmbarazo || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Parto:{" "}
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesPerinatales
                                      ?.parto || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Lloró al nacer:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesPerinatales
                                      ?.lloroNacer || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Peso al nacer:{" "}
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesPerinatales
                                      ?.pesoNacimiento || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Midió:{" "}
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesPerinatales
                                      ?.tamano || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Calificación APGAR:{" "}
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesPerinatales
                                      ?.apgar || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Embarazo Número:{" "}
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesPerinatales
                                      ?.embarazoNumero || "-"}
                                  </span>
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.row} ${styles.colSm6}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Factores del desarrollo
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Tomó leche materna:{" "}
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.factoresDesarrollo
                                      ?.tomoLecheMaterna || "-"}
                                  </span>
                                </Typography>
                                {historial?.factoresDesarrollo
                                  ?.tomoLecheMaterna === "Si" && (
                                  <Typography variant="overline">
                                    Especifique hasta que edad:{" "}
                                    {historial?.factoresDesarrollo
                                      ?.especificaiontomoLechaMaterna || "-"}
                                  </Typography>
                                )}
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Tomó formula:{" "}
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.factoresDesarrollo
                                      ?.tomoFormula || "-"}
                                  </span>
                                </Typography>
                                {historial?.factoresDesarrollo?.tomoFormula ===
                                  "Si" && (
                                  <Typography variant="overline">
                                    Especifique hasta que edad:{" "}
                                    {historial?.factoresDesarrollo
                                      ?.especificacintomoFormula || "-"}
                                  </Typography>
                                )}
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Tomo biberón:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.factoresDesarrollo
                                      ?.tomoBiberon || "-"}
                                  </span>
                                </Typography>
                                {historial?.factoresDesarrollo?.tomoBiberon ===
                                  "Si" && (
                                  <Typography variant="overline">
                                    Especifique hasta que edad:{" "}
                                    {historial?.factoresDesarrollo
                                      ?.especificacionTomoBiberon || "-"}
                                  </Typography>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.row} ${styles.colSm6}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Antecedentes médicos
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Otras patologías que tenga su hijo (a):
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesMedicos
                                      ?.otrosPatologias || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Ha sido hospitalizado:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesMedicos
                                      ?.hospitalizado || "-"}
                                  </span>
                                </Typography>
                                {historial?.antecedentesMedicos
                                  ?.hospitalizado === "Si" && (
                                  <Typography variant="overline">
                                    Cuánto tiempo y porque:
                                    {historial?.antecedentesMedicos
                                      ?.especificacionHospitalizado || "-"}
                                  </Typography>
                                )}
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Tiene todas sus vacunas:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesMedicos?.vacunas ||
                                      "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Padece alergias:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesMedicos?.alergias ||
                                      "-"}
                                  </span>
                                </Typography>
                                {historial?.antecedentesMedicos?.alergias ===
                                  "Si" && (
                                  <Typography variant="overline">
                                    ¿Cuáles?:
                                    {historial?.antecedentesMedicos
                                      ?.especificaionAlergias || "-"}
                                  </Typography>
                                )}
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Le han hecho radiografías:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesMedicos
                                      ?.radiografias || "-"}
                                  </span>
                                </Typography>
                                {historial?.antecedentesMedicos
                                  ?.radiografias === "Si" && (
                                  <Typography variant="overline">
                                    Ultima fecha:
                                    {historial?.antecedentesMedicos
                                      ?.especificacionRadiografias || "-"}
                                  </Typography>
                                )}
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Le han hecho ecocardiogramas:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesMedicos
                                      ?.ecocardiogramas || "-"}
                                  </span>
                                </Typography>
                                {historial?.antecedentesMedicos
                                  ?.ecocardiogramas === "Si" && (
                                  <Typography variant="overline">
                                    Ultima fecha:
                                    {historial?.antecedentesMedicos
                                      ?.especificacionEcocardiogramas || "-"}
                                  </Typography>
                                )}
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Le han hecho audiometrías:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesMedicos
                                      ?.audiometrias || "-"}
                                  </span>
                                </Typography>
                                {historial?.antecedentesMedicos
                                  ?.audiometrias === "Si" && (
                                  <Typography variant="overline">
                                    Ultima fecha:
                                    {historial?.antecedentesMedicos
                                      ?.especificacionAudiometrias || "-"}
                                  </Typography>
                                )}
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Le han hecho potenciales:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesMedicos
                                      ?.potenciales || "-"}
                                  </span>
                                </Typography>
                                {historial?.antecedentesMedicos?.potenciales ===
                                  "Si" && (
                                  <Typography variant="overline">
                                    Ultima fecha:
                                    {historial?.antecedentesMedicos
                                      ?.especificacionPotenciales || "-"}
                                  </Typography>
                                )}
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Otros:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesMedicos?.otros ||
                                      "-"}
                                  </span>
                                </Typography>
                                <Typography variant="overline">
                                  Fecha:
                                  {historial?.antecedentesMedicos?.otrosFecha ||
                                    "-"}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.row} ${styles.colSm6}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Desarrollo psicomotor
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en que sostuvo la cabeza:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloSicomotor
                                      ?.edadSostenerCabeza || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en la que sonrió:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloSicomotor
                                      ?.edadSonreir || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en la que gateo:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloSicomotor
                                      ?.edadGatear || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en la que se sentó:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloSicomotor
                                      ?.edadSentar || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en la que caminó con ayuda:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloSicomotor
                                      ?.edadCaminarAyuda || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en la que caminó solo:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloSicomotor
                                      ?.edadCaminarSolo || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en la que controló esfínteres en el día:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloSicomotor
                                      ?.edadControlEsfinteresDay || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en la que controló esfínteres en la
                                  noche:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloSicomotor
                                      ?.edadControlEsfinteresNight || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en la que comió solo:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloSicomotor
                                      ?.edadComerSolo || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en la que se bañó solo:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloSicomotor
                                      ?.edadBanoSolo || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en la que se vistió solo:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloSicomotor
                                      ?.edadVestirSolo || "-"}
                                  </span>
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.row} ${styles.colSm6}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Desarrollo del lenguaje
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en la que balbuceó:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloLenguaje
                                      ?.edadBalbuceo || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en la que dijo la primera palabra:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloLenguaje
                                      ?.edadPrimerPalabra || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Edad en la que dijo su primera oración:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloLenguaje
                                      ?.edadPrimerOracion || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Mastica:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloLenguaje?.mastica ||
                                      "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Actualmente como es su alimentación:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloLenguaje
                                      ?.alimentacion || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Cuantas veces come al día:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloLenguaje
                                      ?.comidasDia || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Su respiración es por:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloLenguaje
                                      ?.respiracion || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Ronca:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloLenguaje?.ronca ||
                                      "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Problemas para dormir:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.desarrolloLenguaje
                                      ?.problemasDormir || "-"}
                                  </span>
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.row} ${styles.colSm6}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Socio – afectivo
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Número de personas que integran la familia:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioAfectivo
                                      ?.integrantesFamilia || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Como se lleva con su mamá:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioAfectivo?.relacionMadre ||
                                      "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Como se lleva con su papá:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioAfectivo?.relacionPadre ||
                                      "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Con quién pasa más tiempo su hijo (a):
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioAfectivo
                                      ?.mayorConvivencia || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Con quien juega el niño en sus ratos libres:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioAfectivo?.companeroJuego ||
                                      "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Quien ejerce la autoridad en casa:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioAfectivo?.autoridad || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Con quien duerme el niño:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioAfectivo
                                      ?.companeroSiesta || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Cuantas veces se baña al día:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioAfectivo?.banoDia || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Cuantas veces se cambia de muda de ropa:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioAfectivo?.cambiosDia ||
                                      "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Como apoya a su hijo (a) en casa:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioAfectivo?.apoyoOfrecido ||
                                      "-"}
                                  </span>
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.row} ${styles.colSm6}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Conductual
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Que le gusta hacer a su hijo (a):
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.conductual
                                      ?.actividadesPositivas || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Que no le gusta hacer a su hijo (a):
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.conductual
                                      ?.actividadesNegadas || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Se enoja con facilidad:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.conductual?.enojo || "-"}
                                  </span>
                                </Typography>
                                {historial?.conductual?.enojo === "Si" && (
                                  <Typography variant="overline">
                                    Porque?:
                                    {historial?.conductual?.pasatiempo || "-"}
                                  </Typography>
                                )}
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Su hijo (a) miente:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.conductual?.miente || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Su hijo (a) tiene educación sexual:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.conductual?.educacionSexual ||
                                      "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Como describe a su hijo:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.conductual?.descripcion || "-"}
                                  </span>
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.row} ${styles.colSm6}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Antecedentes heredofamiliares
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Padecen de enfermedades crónicas
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Sus abuelos maternos:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesHeredofamiliares
                                      ?.maternos || "-"}
                                  </span>
                                </Typography>
                                {historial?.antecedentesHeredofamiliares
                                  ?.maternos === "Si" && (
                                  <Typography variant="overline">
                                    Cual?:
                                    {historial?.antecedentesHeredofamiliares
                                      ?.especificacionMaternos || "-"}
                                  </Typography>
                                )}
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Sus abuelos paternos:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesHeredofamiliares
                                      ?.paternos || "-"}
                                  </span>
                                </Typography>
                                {historial?.antecedentesHeredofamiliares
                                  ?.paternos === "Si" && (
                                  <Typography variant="overline">
                                    Cual?:
                                    {historial?.antecedentesHeredofamiliares
                                      ?.especificacionPaternos || "-"}
                                  </Typography>
                                )}
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Mamá:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesHeredofamiliares
                                      ?.madre || "-"}
                                  </span>
                                </Typography>
                                {historial?.antecedentesHeredofamiliares
                                  ?.madre === "Si" && (
                                  <Typography variant="overline">
                                    Cual?:
                                    {historial?.antecedentesHeredofamiliares
                                      ?.especificacionMadre || "-"}
                                  </Typography>
                                )}
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Papá:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesHeredofamiliares
                                      ?.padre || "-"}
                                  </span>
                                </Typography>
                                {historial?.antecedentesHeredofamiliares
                                  ?.padre === "Si" && (
                                  <Typography variant="overline">
                                    Cual?:
                                    {historial?.antecedentesHeredofamiliares
                                      ?.especificacionPadre || "-"}
                                  </Typography>
                                )}
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Hermanos:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.antecedentesHeredofamiliares
                                      ?.hermanos || "-"}
                                  </span>
                                </Typography>
                                {historial?.antecedentesHeredofamiliares
                                  ?.hermanos === "Si" && (
                                  <Typography variant="overline">
                                    Cual?:
                                    {historial?.antecedentesHeredofamiliares
                                      ?.especificacionHermanos || "-"}
                                  </Typography>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.row} ${styles.colSm6}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Socioeconómico
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Tipo de vivienda:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioEconomico?.vivienda || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Cuenta con todos los servicios:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioEconomico?.servicios ||
                                      "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  Cuantas personas viven:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioEconomico?.personas || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  De que material es la casa:
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.socioEconomico?.materialCasa ||
                                      "-"}
                                  </span>
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.row} ${styles.colSm12}`}>
                        <div className={`${styles.col}`}>
                          <div
                            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                          >
                            Historial académico
                          </div>
                          <div className={`${styles.section} ${styles.mb4}`}>
                            <div className={`${styles.cardBody}`}>
                              <div className={`${styles["m-2"]}`}>
                                <Typography>
                                  ¿Ha estado en alguna otra
                                  institución/escuela/fundación antes?
                                </Typography>
                                <Typography>
                                  <span
                                    style={{
                                      color: "#9b242c",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {historial?.historialAcademico
                                      ?.academicoText || "-"}
                                  </span>
                                </Typography>
                              </div>
                              <div className={`${styles["m-2"]}`}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>No.</TableCell>
                                      <TableCell>
                                        Institución / Escuela / Fundación
                                      </TableCell>
                                      <TableCell>
                                        Periodo de estancia en la institución
                                      </TableCell>
                                      <TableCell>
                                        ¿Cuál fue el motivo de su salida?
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {historial?.historialAcademico?.historial?.map(
                                      (academia, index) => (
                                        <TableRow>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>
                                            {academia.nombre}
                                          </TableCell>
                                          <TableCell>
                                            {academia.periodo}
                                          </TableCell>
                                          <TableCell>
                                            {academia.motivoSalida}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                    {(historial?.historialAcademico?.historial
                                      ?.length === 0 ||
                                      !historial?.historialAcademico
                                        ?.historial) && (
                                      <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>N/A</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.co12}`}>
                <div
                  className={`${styles.cardFooter} ${styles.borderLight}`}
                  style={{ textAlign: "right" }}
                >
                  <button
                    className={`${styles.btn} ${styles.btnPrimary}`}
                    onClick={() => deleteAlumno()}
                  >
                    <span className={`${styles.small}`}>Eliminar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default AlumnoEliminar;
