import styles from "../../layout/assets/css/core.min.module.css";

import { Box, Button, TextField, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const SectionAntecedentesMedicos = ({
  antecedentesMedicos,
  handleAntecedentesMedicos,
}) => {
  const handleHospitalizado = (event, value) => {
    handleAntecedentesMedicos({
      ...antecedentesMedicos,
      hospitalizado: value,
    });
  };

  const handleVacunas = (event, value) => {
    handleAntecedentesMedicos({
      ...antecedentesMedicos,
      vacunas: value,
    });
  };

  const handleAlergias = (event, value) => {
    handleAntecedentesMedicos({
      ...antecedentesMedicos,
      alergias: value,
    });
  };

  const handleRadiografias = (event, value) => {
    handleAntecedentesMedicos({
      ...antecedentesMedicos,
      radiografias: value,
    });
  };

  const handleEcocardiogramas = (event, value) => {
    handleAntecedentesMedicos({
      ...antecedentesMedicos,
      ecocardiogramas: value,
    });
  };

  const handleAudiometrias = (event, value) => {
    handleAntecedentesMedicos({
      ...antecedentesMedicos,
      audiometrias: value,
    });
  };

  const handlePotenciales = (event, value) => {
    handleAntecedentesMedicos({
      ...antecedentesMedicos,
      potenciales: value,
    });
  };

  return (
    <div className={`${styles.row} ${styles.colSmm12}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          Antecedentes médicos{" "}
        </div>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            <div className={`${styles["my-4"]} ${styles["col-md-12"]}`}>
              <Typography>Otras patologías que tenga su hijo (a): </Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleAntecedentesMedicos({
                    ...antecedentesMedicos,
                    otrosPatologias: e.target.value,
                  })
                }
                value={antecedentesMedicos?.otrosPatologias}
              />
            </div>

            <div className={`${styles["my-4"]} ${styles["col-md-12"]}`}>
              <Typography>Ha sido hospitalizado:</Typography>
              <ToggleButtonGroup
                value={
                  antecedentesMedicos?.hospitalizado
                    ? antecedentesMedicos.hospitalizado
                    : ""
                }
                exclusive
                onChange={handleHospitalizado}
                aria-label="Platform"
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-4"]} ${styles["col-md-12"]}`}>
              <TextField
                fullWidth
                variant="standard"
                placeholder="Cuánto tiempo y porque:"
                disabled={antecedentesMedicos?.hospitalizado !== "Si"}
                onChange={(e) =>
                  handleAntecedentesMedicos({
                    ...antecedentesMedicos,
                    especificacionHospitalizado: e.target.value,
                  })
                }
                value={antecedentesMedicos?.especificacionHospitalizado}
              />
            </div>

            <div className={`${styles["my-4"]} ${styles["col-md-12"]}`}>
              <Typography>Tiene todas sus vacunas:</Typography>
              <ToggleButtonGroup
                value={
                  antecedentesMedicos?.vacunas
                    ? antecedentesMedicos.vacunas
                    : ""
                }
                exclusive
                onChange={handleVacunas}
                aria-label="Platform"
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div className={`${styles["my-4"]} ${styles["col-md-12"]}`}>
              <Typography>Padece alergias:</Typography>
              <ToggleButtonGroup
                value={
                  antecedentesMedicos?.alergias
                    ? antecedentesMedicos.alergias
                    : ""
                }
                exclusive
                onChange={handleAlergias}
                aria-label="Platform"
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-4"]} ${styles["col-md-12"]}`}>
              <TextField
                fullWidth
                variant="standard"
                placeholder="¿Cuáles?:"
                disabled={antecedentesMedicos?.alergias !== "Si"}
                onChange={(e) =>
                  handleAntecedentesMedicos({
                    ...antecedentesMedicos,
                    especificaionAlergias: e.target.value,
                  })
                }
                value={antecedentesMedicos?.especificaionAlergias}
              />
            </div>

            <div className={`${styles["my-4"]} ${styles["col-md-6"]}`}>
              <Typography>Le han hecho radiografías:</Typography>
              <ToggleButtonGroup
                value={
                  antecedentesMedicos?.radiografias
                    ? antecedentesMedicos.radiografias
                    : ""
                }
                exclusive
                onChange={handleRadiografias}
                aria-label="Platform"
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-4"]} ${styles["col-md-6"]}`}>
              <TextField
                fullWidth
                type="date"
                variant="standard"
                placeholder="Fecha"
                disabled={antecedentesMedicos?.radiografias !== "Si"}
                onChange={(e) =>
                  handleAntecedentesMedicos({
                    ...antecedentesMedicos,
                    especificacionRadiografias: e.target.value,
                  })
                }
                value={
                  antecedentesMedicos?.especificacionRadiografias
                    ? antecedentesMedicos?.especificacionRadiografias
                    : "2024-01-01"
                }
              />
            </div>

            <div className={`${styles["my-4"]} ${styles["col-md-6"]}`}>
              <Typography>Le han hecho ecocardiogramas:</Typography>
              <ToggleButtonGroup
                value={
                  antecedentesMedicos?.ecocardiogramas
                    ? antecedentesMedicos.ecocardiogramas
                    : ""
                }
                exclusive
                onChange={handleEcocardiogramas}
                aria-label="Platform"
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-4"]} ${styles["col-md-6"]}`}>
              <TextField
                fullWidth
                type="date"
                variant="standard"
                placeholder="Fecha"
                disabled={antecedentesMedicos?.ecocardiogramas !== "Si"}
                onChange={(e) =>
                  handleAntecedentesMedicos({
                    ...antecedentesMedicos,
                    especificacionEcocardiogramas: e.target.value,
                  })
                }
                value={
                  antecedentesMedicos?.especificacionEcocardiogramas
                    ? antecedentesMedicos?.especificacionEcocardiogramas
                    : "2024-01-01"
                }
              />
            </div>

            <div className={`${styles["my-4"]} ${styles["col-md-6"]}`}>
              <Typography>Le han hecho audiometrías:</Typography>
              <ToggleButtonGroup
                value={
                  antecedentesMedicos?.audiometrias
                    ? antecedentesMedicos.audiometrias
                    : ""
                }
                exclusive
                onChange={handleAudiometrias}
                aria-label="Platform"
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-4"]} ${styles["col-md-6"]}`}>
              <TextField
                fullWidth
                type="date"
                variant="standard"
                placeholder="Fecha"
                disabled={antecedentesMedicos?.audiometrias !== "Si"}
                onChange={(e) =>
                  handleAntecedentesMedicos({
                    ...antecedentesMedicos,
                    especificacionAudiometrias: e.target.value,
                  })
                }
                value={
                  antecedentesMedicos?.especificacionAudiometrias
                    ? antecedentesMedicos?.especificacionAudiometrias
                    : "2024-01-01"
                }
              />
            </div>

            <div className={`${styles["my-4"]} ${styles["col-md-6"]}`}>
              <Typography>Le han hecho potenciales:</Typography>
              <ToggleButtonGroup
                value={
                  antecedentesMedicos?.potenciales
                    ? antecedentesMedicos.potenciales
                    : ""
                }
                exclusive
                onChange={handlePotenciales}
                aria-label="Platform"
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-4"]} ${styles["col-md-6"]}`}>
              <TextField
                fullWidth
                type="date"
                variant="standard"
                placeholder="Fecha"
                disabled={antecedentesMedicos?.potenciales !== "Si"}
                onChange={(e) =>
                  handleAntecedentesMedicos({
                    ...antecedentesMedicos,
                    especificacionPotenciales: e.target.value,
                  })
                }
                value={
                  antecedentesMedicos?.especificacionPotenciales
                    ? antecedentesMedicos?.especificacionPotenciales
                    : "2024-01-01"
                }
              />
            </div>

            <div className={`${styles["my-4"]} ${styles["col-md-12"]}`}>
              <Typography>Otros:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="Otros:"
                onChange={(e) =>
                  handleAntecedentesMedicos({
                    ...antecedentesMedicos,
                    otros: e.target.value,
                  })
                }
                value={antecedentesMedicos?.otros}
              />
            </div>
            <div className={`${styles["my-4"]} ${styles["col-md-12"]}`}>
              <TextField
                fullWidth
                type="date"
                variant="standard"
                placeholder="Fecha otros"
                onChange={(e) =>
                  handleAntecedentesMedicos({
                    ...antecedentesMedicos,
                    otrosFecha: e.target.value,
                  })
                }
                value={
                  antecedentesMedicos?.otrosFecha
                    ? antecedentesMedicos?.otrosFecha
                    : "2024-01-01"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionAntecedentesMedicos;
