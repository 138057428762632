/* React */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { Grid, TextField, Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";

/* Servicios */
import S3Service from "../../../services/S3.service";
import AlumnoService from "../../../services/Alumno.service";
import AreaService from "../../../services/Area.service";
import AppointmentService from "../../../services/therapies/Appointment.service";

import styles from "../layout/assets/css/core.min.module.css";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import WarningIcon from "@mui/icons-material/Warning";
import FormCita from "./FormCita";
import CustomPaginationActionsTableSelect from "../../utils/CustomTable/CustomPaginationActionsTableSelect";
import TerapeutaSelect from "./TerapeutaSelect";
import Busqueda from "./Busqueda";

const CitaCrear = () => {
  const { currentUser, checkRole } = useContext(AuthContext);
  let today = new Date();

  const [alumnoSelect, setAlumnoSelect] = useState({});
  const [fechaCita, setFechaCita] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(today)
  );
  const [fechaCitaDate, setFechaCitaDate] = useState(new Date());
  const [duracion, setDuracion] = useState(1);
  const [motivoCita, setMotivoCita] = useState("Consulta");
  const [status, setStatus] = useState("Pendiente");
  const [detallesAdicionales, setDetallesAdicionales] = useState({});

  const [file, setFile] = useState(undefined);
  const [personas, setPersonas] = useState([]);
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState("N/A");

  const [historialClinico, setHistorialClinico] = useState({});

  const [openAddAuthorizedPerson, setOpenAddAuthorizedPerson] = useState(false);
  const navigate = useNavigate();

  const [alumnos, setAlumnos] = useState([]);
  const [terapeuta, setTerapeuta] = useState(null);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [update, setUpdate] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");
  const [search, setSearch] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAlumnoSelect = (newAlumno) => {
    toast.info(`${newAlumno.nombre} fue seleccionad@.`);
    setAlumnoSelect((old) => newAlumno);
  };

  const handleTerapeuta = (terapeuta) => {
    setTerapeuta(terapeuta);
  };

  const handlePersonas = (newPersonas) => {
    setPersonas((old) => newPersonas);
  };

  const toggleDrawerAddAuthorizedPerson = (newOpen) => () => {
    setOpenAddAuthorizedPerson(newOpen);
  };

  const deletePersona = (nombre) => {
    let oldPersons = personas.filter((persona) => persona.nombre !== nombre);
    setPersonas(oldPersons);
  };

  const handleHistorialClinico = (newHistorialClinico) => {
    setHistorialClinico((old) => newHistorialClinico);
  };

  const handleArea = (event, newArea) => {
    setArea(newArea);
  };

  const handleFechaCita = (newFecha) => {
    let nuevaFechaCita = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(newFecha.toDate());
    setFechaCita(nuevaFechaCita);
    setFechaCitaDate(newFecha.toDate());
  };

  const crearCita = async () => {
    const horaInicio = new Date(fechaCitaDate.getTime());
    const horaFin = new Date(fechaCitaDate.getTime());

    horaInicio.setHours(7, 0, 0, 0);
    horaFin.setHours(23, 0, 0, 0);

    if (!alumnoSelect?.id) {
      toast.info("Error, tienes que seleccionar un alumno");
    } else if (!fechaCita) {
      toast.info("Error, Selecciona una fecha");
    } else if (
      fechaCitaDate.getTime() <= horaInicio.getTime() ||
      fechaCitaDate.getTime() >= horaFin.getTime()
    ) {
      toast.info("Error, Selecciona un horario valido para tu cita");
    } else if (checkRole(["Administrador"]) && !terapeuta?.id) {
      toast.info("Error, tienes que seleccionar un terapeuta");
    } else {
      setLoading(true);
      try {
        let fechaCitaDate = new Date(fechaCita);
        const noticiaSubir = {
          alumno: alumnoSelect.id,
          area: area,
          duracion,
          motivoCita,
          status,
          detallesAdicionales,
          fechaCita: fechaCitaDate.toISOString(),
        };

        if (checkRole(["Administrador"])) {
          noticiaSubir.terapeuta = terapeuta?.id;
        } else {
          noticiaSubir.terapeuta = currentUser?.id;
        }

        await AppointmentService.create(noticiaSubir);

        toast.success("Cita registrada!");
        navigate("/citas");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getAreas();
  }, []);

  const getAreas = async () => {
    setLoading(true);
    try {
      const getData = await AreaService.getAll();
      if (getData) {
        setAreas(getData);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    searchFunctionPage(filterSearch);
  }, [page, limit, update]);

  const getAlumnos = async () => {
    setLoadingTable(true);
    try {
      const data = await AlumnoService.list(limit, page * limit);

      if (data) {
        data.data.forEach(async (element) => {
          const image = await S3Service.get(element.imagen);
          element.url = image.result;
        });

        setAlumnos(data.data);
        setTotal(data.total);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoadingTable(false);
    }
  };

  const searchFunctionPage = async (searchEntry) => {
    try {
      setLoadingTable(true);
      let searchData = {
        search: searchEntry,
        limit: limit,
        offset: limit * page,
      };
      const data = await AlumnoService.listByAlumnoSearch(searchData);

      if (data) {
        data.data.forEach(async (element) => {
          const image = await S3Service.get(element.imagen);
          element.url = image.result;
        });

        setAlumnos(data.data);
        setTotal(data.total);
      }
      setSearch(true);
    } catch (error) {
      setSearch(false);
      toast.error(error);
    } finally {
      setLoadingTable(false);
    }
  };

  const searchFunction = async (searchEntry) => {
    setFilterSearch(searchEntry);
    setPage(0);
    try {
      setLoadingTable(true);
      let searchData = {
        search: searchEntry,
        limit: limit,
        offset: limit * page,
      };
      const data = await AlumnoService.listByAlumnoSearch(searchData);

      if (data) {
        data.data.forEach(async (element) => {
          const image = await S3Service.get(element.imagen);
          element.url = image.result;
        });

        setAlumnos(data.data);
        setTotal(data.total);
      }
      setSearch(true);
    } catch (error) {
      setSearch(false);
      toast.error(error);
    } finally {
      setLoadingTable(false);
    }
  };

  return loading ? (
    <Preloader />
  ) : (
    <main
      id={styles["middle"]}
      className={`${styles.flexFill} ${styles.mxAuto}`}
    >
      <header>
        <h1 className={`${styles.h4}`}>Nueva cita</h1>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb} ${styles.small}`}>
            <li className={`${styles.breadcrumbItem}`}>
              <button
                style={{
                  border: "none",
                  backgroundColor: "unset",
                  color: "#9b242c",
                }}
                onClick={() => navigate("/citas")}
              >
                Citas
              </button>
            </li>
            <li
              className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
              aria-current="page"
            >
              Crear cita
            </li>
          </ol>
        </nav>
      </header>

      <FormCita
        alumno={alumnoSelect}
        fechaCita={fechaCita}
        handleFechacita={handleFechaCita}
      />

      {checkRole(["Administrador"]) && (
        <div className={`${styles.row} ${styles.g4}`}>
          <div className={`${styles.col}`}>
            <div
              className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
            >
              Seleccionar terapeuta
            </div>
            <div className={`${styles.section} ${styles.mb4}`}>
              <div className={`${styles.cardBody}`}>
                <div className={`${styles["m-4"]}`}>
                  <div className={`${styles.tableResponsiveMd}`}>
                    <Grid container>
                      <Grid md={6}>
                        <List>
                          <ListItem>
                            <TerapeutaSelect
                              terapeuta={terapeuta}
                              handleTerapeuta={handleTerapeuta}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid md={6}>
                        {terapeuta?.id ? (
                          <List>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar
                                  loading="lazy"
                                  alt="Remy Sharp"
                                  src={
                                    terapeuta?.url
                                      ? terapeuta?.url
                                      : "/static/images/avatar/1.jpg"
                                  }
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={terapeuta?.nombre}
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{
                                        display: "inline",
                                        cursor: "pointer",
                                      }}
                                      component="span"
                                      variant="caption"
                                      color="text.primary"
                                    >
                                      Area:
                                    </Typography>
                                    <Typography
                                      sx={{
                                        display: "inline",
                                        cursor: "pointer",
                                        color: "green",
                                        marginInline: "0.5rem",
                                      }}
                                      component="a"
                                      variant="body2"
                                    >
                                      {terapeuta?.area?.tipo}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                          </List>
                        ) : (
                          <List>
                            <ListItem>
                              <Typography
                                sx={{ color: "#9b242c", textAlign: "center" }}
                              >
                                <WarningIcon /> Terapeuta no seleccionado
                              </Typography>
                            </ListItem>
                          </List>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div
            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
          >
            Seleccionar alumno
          </div>
          <div className={`${styles.section} ${styles.mb4}`}>
            <div className={`${styles.cardBody}`}>
              <div className={`${styles["m-4"]}`}>
                <div className={`${styles["py-4"]}`}>
                  {checkRole(["Administrador"]) && (
                    <div className={`${styles.row} ${styles.g3}`}>
                      <div
                        className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                      >
                        <Busqueda
                          searchFunction={searchFunction}
                          placeholder="Nombre / area / grupo"
                        />
                      </div>
                      <div
                        className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
                      >
                        <a
                          href="javascript:void(0)"
                          className={`${styles.w100} ${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
                          onClick={() => navigate("/alumnos/nuevo")}
                        >
                          <svg
                            width="18px"
                            height="18px"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <line x1={12} y1={5} x2={12} y2={19} />
                            <line x1={5} y1={12} x2={19} y2={12} />
                          </svg>
                          <span>Nuevo</span>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                <div className={`${styles.tableResponsiveMd}`}>
                  <CustomPaginationActionsTableSelect
                    alumnoSelect={alumnoSelect}
                    onChangeAlumnoSelect={handleAlumnoSelect}
                    alumnos={alumnos}
                    page={page}
                    total={total}
                    rowsPerPage={limit}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div
            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
          >
            Motivo de cita
          </div>
          <div className={`${styles.section} ${styles.mb4}`}>
            <div className={`${styles.cardBody}`}>
              <div className={`${styles["m-4"]}`}>
                <div className={`${styles["py-4"]}`}></div>
                <div className={`${styles.tableResponsiveMd}`}>
                  <TextField
                    fullWidth
                    label="Motivo"
                    onChange={(event) => setMotivoCita(event.target.value)}
                    value={motivoCita}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div className={`${styles.mb4}`}>
            <div className={`${styles.row} ${styles["m-2"]}`}>
              <div className={`${styles["my-2"]} ${styles["col-sm-12"]}`}>
                <div
                  className={`${styles.borderLight}`}
                  style={{ textAlign: "right" }}
                >
                  <button
                    className={`${styles.btn} ${styles.btnPrimary}`}
                    onClick={crearCita}
                  >
                    <svg
                      width="18px"
                      height="18px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    {"  "}
                    <span className={`${styles.small}`}>Crear cita</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CitaCrear;
