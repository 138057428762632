import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import styles from "../layout/assets/css/core.min.module.css";
import CitaProvider from "../../../context/CitaContext";
import DetallesCitaSeccion from "./DetallesCitaSeccion";

const DetallesCita = () => {
  const navigate = useNavigate();

  return (
    <main
      id={styles["middle"]}
      className={`${styles.flexFill} ${styles.mxAuto}`}
    >
      <header>
        <h1 className={`${styles.h4}`}>Detalles cita</h1>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb} ${styles.small}`}>
            <li className={`${styles.breadcrumbItem}`}>
              <button
                style={{
                  border: "none",
                  backgroundColor: "unset",
                  color: "#9b242c",
                }}
                onClick={() => navigate("/citas")}
              >
                Citas
              </button>
            </li>
            <li
              className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
              aria-current="page"
            >
              Detalles cita
            </li>
          </ol>
        </nav>
      </header>

      <CitaProvider>
        <DetallesCitaSeccion />
      </CitaProvider>
    </main>
  );
};

export default DetallesCita;
