import NavBar from "./NavBar";
import TopBar from "./TopBar";

const HeaderPublicLayout = () => {
  return (
    <>
      {/* ====================================
  ——— HEADER
  ===================================== */}
      <header className="header" id="pageTop">
        {/* Top Color Bar */}
        <div className="color-bars">
          <div className="container-fluid">
            <div className="row">
              <div className="col color-bar bg-warning d-none d-md-block" />
              <div className="col color-bar bg-success d-none d-md-block" />
              <div className="col color-bar bg-danger d-none d-md-block" />
              <div className="col color-bar bg-info d-none d-md-block" />
              <div className="col color-bar bg-purple d-none d-md-block" />
              <div className="col color-bar bg-pink d-none d-md-block" />
              <div className="col color-bar bg-warning" />
              <div className="col color-bar bg-success" />
              <div className="col color-bar bg-danger" />
              <div className="col color-bar bg-info" />
              <div className="col color-bar bg-purple" />
              <div className="col color-bar bg-pink" />
            </div>
          </div>
        </div>
        {/* Top Bar*/}
        <TopBar />
        {/* Navbar */}
        <NavBar />
      </header>
    </>
  );
};

export default HeaderPublicLayout;
