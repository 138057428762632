/* React */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";

/* Servicios */
import S3Service from "../../../services/S3.service";
import ServicioService from "../../../services/Servicio.service";

import Preloader from "../../utils/Preloader";

import styles from "../layout/assets/css/core.min.module.css";

const EliminarServicio = () => {
  //#region variables
  const { id } = useParams();
  const navigate = useNavigate();
  const [servicio, setServicio] = useState({});
  const [loading, setLoading] = useState(true);
  const [imagen, setImagen] = useState("");
  useEffect(() => {
    getPago();
  }, []);

  const getPago = async () => {
    setLoading(true);
    try {
      const data = await ServicioService.getById(id);
      setServicio(data);
      if (data.imagenes) {
        const result = await S3Service.get(data.imagenes[0]);
        setImagen(result.result);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deletePago = async () => {
    setLoading(true);
    try {
      console.log("ELIMINAR");

      const pagoBorrado = await ServicioService.remove(id);

      toast.info("Pago eliminada");
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setLoading(false);
      navigate("/servicio");
    }
  };
  //#endregion

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {" "}
            {/* PAGE TITLE */}
            <header>
              <h1 className={`${styles.h4}`}>Eliminar servicio</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li className={`${styles.breadcrumbItem}`}>
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/servicio")}
                    >
                      servicios
                    </a>
                  </li>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Eliminar
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className={`${styles.row} ${styles.g4}`}>
                <div className={`${styles.col}`}>
                  {/* Shipping address */}
                  <div
                    className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                  >
                    {" "}
                    Servicio
                  </div>
                  <div className={`${styles.section} ${styles.mb4}`}>
                    <div className={`${styles.cardBody}`}>
                      <div className={`${styles.row} ${styles.g3}`}>
                        {servicio.imagenes ? (
                          <div>
                            <div className={`${styles.colLg12}`}>
                              <div style={{ textAlign: "center" }}>
                                <img
                                  src={String(imagen)}
                                  style={{
                                    height: 300,
                                    objectFit: "contain",
                                    width: "-webkit-fill-available",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Título"
                              value={servicio?.titulo}
                              disabled={true}
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Título"
                              value={servicio?.subtitulo}
                              disabled={true}
                            />
                            <label>Subtítulo</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Título"
                              value={servicio?.tiempo}
                              disabled={true}
                            />
                            <label>Tiempo</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Título"
                              value={servicio?.descuento}
                              disabled={true}
                            />
                            <label>Descuento</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm12}`}>
                          <div className={`${styles.formFloating}`}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: servicio.descripcion,
                              }}
                              style={{
                                textAlign: "justify",
                                marginRight: "10%",
                              }}
                            ></p>
                          </div>

                          <div>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: servicio.incluye,
                              }}
                              style={{
                                textAlign: "justify",
                                marginRight: "10%",
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className={`${styles.co12}`}>
              <div
                className={`${styles.cardFooter} ${styles.borderLight}`}
                style={{ textAlign: "right" }}
              >
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => deletePago()}
                >
                  <span className={`${styles.small}`}>Eliminar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default EliminarServicio;
