/* React */
import { useNavigate } from "react-router-dom";
import { useEffect, useState, MouseEvent, ChangeEvent } from "react";

/* Components & dependencies */
import { Button, TablePagination } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FullPageLoading from "../../FullPageLoading";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import LinearProgress from "@mui/material/LinearProgress";

import Preloader from "../../utils/Preloader";

/* Service */
import ServicioService from "../../../services/Servicio.service";

import styles from "../layout/assets/css/core.min.module.css";

const TablaServicios = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [servicios, setServicios] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    loadServicios();
  }, [page, limit]);

  const loadServicios = async () => {
    setLoading(true);
    try {
      const results = await ServicioService.list(limit, page * limit);
      setServicios(results.data);
      setTotal(results.total);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {/* PAGE TITLE */}
            <header>
              <h1 className={`${styles.h4}`}>SERVICIOS</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Hay {servicios.length} actualmente.
                  </li>
                </ol>
              </nav>
            </header>
            <>
              {/* user list */}
              <div
                className={`${styles.section} ${styles.p0}`}
                style={{ width: "100%", overflow: "auto" }}
              >
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <div
                      className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                    >
                      <form
                        className={`${styles.positionRelative} ${styles.dFlex} ${styles.alignItemsCenter}`}
                        method="get"
                        action="#"
                      ></form>
                    </div>
                    {/* options */}
                    <div
                      className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
                    >
                      <button
                        onClick={() => navigate(`nuevo`)}
                        className={`${styles.w100} ${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
                      >
                        <svg
                          width="18px"
                          height="18px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={5} y1={12} x2={19} y2={12} />
                        </svg>
                        <span>Nuevo</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  {/* item list */}
                  <div className={`${styles.tableResponsiveMd}`}>
                    <table
                      className={`${styles.table} ${styles.tableAlignMiddle}`}
                      style={{ width: "1000px", marginLeft: "10px" }}
                    >
                      <thead>
                        <tr>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 250 }}
                          >
                            TÍTULO
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 600 }}
                          >
                            DESCRIPCIÓN
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 200, marginLeft: "20px" }}
                          >
                            <a
                              href="#!"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                />{" "}
                                HORARIO
                              </span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 200, marginLeft: "20px" }}
                          >
                            <a
                              href="#!"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                />{" "}
                                EDADES
                              </span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 200, marginLeft: "20px" }}
                          >
                            <a
                              href="#!"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                />{" "}
                                CREACIÓN
                              </span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 250 }}
                          >
                            <a
                              href="#!"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                />{" "}
                                EDICIÓN
                              </span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 20 }}
                          >
                            <a
                              href="#!"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by price"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}></span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 20 }}
                          >
                            <a
                              href="#!"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by price"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}></span>
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {servicios.map((record, index) => {
                          const regex = /<[^>]'>/gim;
                          return (
                            <tr key={`${record.id}_${record.titulo}`}>
                              <td>
                                {record?.titulo.replace(regex, "").slice(0, 30)}
                              </td>
                              <td>
                                <p
                                  className={`${styles.lead}`}
                                  dangerouslySetInnerHTML={{
                                    __html: String(record.descripcion).slice(
                                      0,
                                      100
                                    ),
                                  }}
                                  style={{
                                    textAlign: "justify",
                                    fontWeight: "light",
                                    fontSize: "1rem",
                                  }}
                                ></p>
                              </td>
                              <td>{record?.horario}</td>
                              <td>{record?.edades}</td>
                              <td>
                                <i
                                  className="fa fa-calendar fa-1"
                                  aria-hidden="true"
                                />{" "}
                                {String(record.createdAt).slice(0, 10)}
                                <br />{" "}
                                <i
                                  className="fa fa-clock-o"
                                  aria-hidden="true"
                                />{" "}
                                {String(record.createdAt).slice(11, 16)}
                              </td>
                              <td>
                                <i
                                  className="fa fa-calendar fa-1"
                                  aria-hidden="true"
                                />{" "}
                                {String(record.updateAt).slice(0, 10)}
                                <br />{" "}
                                <i
                                  className="fa fa-clock-o"
                                  aria-hidden="true"
                                />{" "}
                                {String(record.updateAt).slice(11, 16)}
                              </td>

                              <td>
                                <button
                                  onClick={() =>
                                    navigate(`editar/${record.id}`)
                                  }
                                  className={`${styles.btn} ${styles.btnSm} ${styles.linkNormal}`}
                                  style={{
                                    border: "none",
                                    backgroundColor: "unset",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-pencil-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                  </svg>
                                </button>
                              </td>
                              <td>
                                <button
                                  onClick={() =>
                                    navigate(`eliminar/${record.id}`)
                                  }
                                  className={`${styles.btn} ${styles.btnSm} ${styles.linkNormal}`}
                                  style={{
                                    border: "none",
                                    backgroundColor: "unset",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-trash-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <TablePagination
                    style={{ color: "var(--color-dark-variant)" }}
                    component="div"
                    labelRowsPerPage="Items por pagina"
                    count={total}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TablaServicios;
