import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo-corazon.png";
import NavMenu from "./NavMenu";
import useMediaQuery from "@mui/material/useMediaQuery";

const NavBar = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:768px)");

  return (
    <nav
      className="navbar navbar-expand-md navbar-white"
      style={
        !matches
          ? {
              position: "fixed",
              width: "100%",
              bottom: "0",
              paddingInlineEnd: "1rem",
              height: "4rem",
            }
          : {}
      }
    >
      <div className="container">
        <button
          className="navbar-brand"
          style={
            matches
              ? { border: "none", backgroundColor: "unset" }
              : { border: "none", backgroundColor: "unset", height: "4rem" }
          }
          onClick={() => navigate("/")}
        >
          <img
            className="d-inline-block"
            src={logo}
            alt="Kidz School"
            style={matches ? { height: "4rem" } : {}}
          />
        </button>
        <div className="collapse navbar-collapse" id="navbarContent">
          <ul className="navbar-nav ms-lg-auto">
            <li className="nav-item dropdown bg-primary">
              <button
                className={"nav-link dropdown-toggle "}
                style={{
                  border: "none",
                  backgroundColor: "unset",
                }}
                onClick={() => {
                  navigate(`/`);
                }}
              >
                <i className="fas fa-home nav-icon" aria-hidden="true" />
                <span>Inicio</span>
              </button>
            </li>
            <li className="nav-item dropdown mega-dropdown bg-success">
              <button
                className={"nav-link dropdown-toggle "}
                style={{
                  border: "none",
                  backgroundColor: "unset",
                }}
                onClick={() => {
                  navigate(`/contactanos`);
                }}
              >
                <i className="far fa-file-alt nav-icon" aria-hidden="true" />
                <span style={{ width: "auto", paddingInline: "1rem" }}>
                  Contactanos
                </span>
              </button>
            </li>
            <li className="nav-item dropdown mega-dropdown bg-info-Public">
              <button
                className={"nav-link dropdown-toggle "}
                style={{
                  border: "none",
                  backgroundColor: "unset",
                }}
                onClick={() => {
                  navigate(`/servicios`);
                }}
              >
                <i className="fas fa-edit nav-icon" aria-hidden="true" />
                <span>Servicios</span>
              </button>
            </li>
            <li className="nav-item dropdown bg-purple">
              <button
                className={"nav-link dropdown-toggle "}
                style={{
                  border: "none",
                  backgroundColor: "unset",
                }}
                onClick={() => {
                  navigate(`/blog`);
                }}
              >
                <i className="fas fa-list-ul nav-icon" aria-hidden="true" />
                <span>Blog</span>
              </button>
            </li>
          </ul>
        </div>
        {!matches && <NavMenu />}
      </div>
    </nav>
  );
};

export default NavBar;
