import React from "react";
import CountUp from "react-countup";

const Counter = ({ title, value, btnColor }) => {
  return (
    <div className="col-sm-3 col-xs-12" style={{ cursor: "default" }}>
      <div className="text-center text-white mb-5">
        <div className="counter-value" data-count={1000}>
          <CountUp
            end={Number(value)}
            duration={0.4}
            style={{ fontSize: "3.5rem" }}
          />
        </div>
        <span
          className="d-inline-block text-uppercase font-weight-medium rounded-sm shadow-sm mt-1 py-2 px-3"
          style={{ backgroundColor: btnColor }}
        >
          {title}
        </span>
      </div>
    </div>
  );
};

export default Counter;
