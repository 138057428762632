/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import InvertColorsIcon from "@material-ui/icons/InvertColors";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import MUIRichTextEditor from "mui-rte";

/* Servicios */
import IndexService from "../../../services/Index.service";

import styles from "../layout/assets/css/core.min.module.css";

const TablaIndex = () => {
  const contentHTML = convertFromHTML("");
  const [index, setIndex] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const [contactanos, setcontactanos] = useState(
    JSON.stringify(
      convertToRaw(
        ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        )
      )
    )
  );
  const [blog, setblog] = useState(
    JSON.stringify(
      convertToRaw(
        ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        )
      )
    )
  );
  const [nuestrosPaquetes, setNuestrosPaquetes] = useState(
    JSON.stringify(
      convertToRaw(
        ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        )
      )
    )
  );
  const [nuestrasPublicaciones, setNuestrasPublicaciones] = useState(
    JSON.stringify(
      convertToRaw(
        ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        )
      )
    )
  );
  const myTheme = createTheme({});

  useEffect(() => {
    getAviso();
  }, []);

  const getAviso = async () => {
    setLoading(true);
    try {
      const idx = await IndexService.get();
      setIndex(idx[0]);
      //Mejores Ofertas
      const contentHTML_contentcontactanos = convertFromHTML(
        idx[0].contactanos
      );
      const state_contentcontactanos = ContentState.createFromBlockArray(
        contentHTML_contentcontactanos.contentBlocks,
        contentHTML_contentcontactanos.entityMap
      );
      const contentcontactanos = JSON.stringify(
        convertToRaw(state_contentcontactanos)
      );
      setcontactanos(contentcontactanos);
      //Ultimas Noticias
      const contentHTML_blog = convertFromHTML(idx[0].blog);
      const state_blog = ContentState.createFromBlockArray(
        contentHTML_blog.contentBlocks,
        contentHTML_blog.entityMap
      );
      const contentblog = JSON.stringify(convertToRaw(state_blog));
      setblog(contentblog);
      //Nuestros Paquetes
      const contentHTML_nuestrosPaquetes = convertFromHTML(
        idx[0].nuestrosPaquetes
      );
      const state_nuestrosPaquetes = ContentState.createFromBlockArray(
        contentHTML_nuestrosPaquetes.contentBlocks,
        contentHTML_nuestrosPaquetes.entityMap
      );
      const contentNuestrosPaquetes = JSON.stringify(
        convertToRaw(state_nuestrosPaquetes)
      );
      setNuestrosPaquetes(contentNuestrosPaquetes);

      const contentHTML_nuestrasPublicacionse = convertFromHTML(
        idx[0].nuestrasPublicaciones
      );
      const state_nuestrasPublicaciones = ContentState.createFromBlockArray(
        contentHTML_nuestrasPublicacionse.contentBlocks,
        contentHTML_nuestrasPublicacionse.entityMap
      );
      const contentNuestrasPublicaciones = JSON.stringify(
        convertToRaw(state_nuestrasPublicaciones)
      );
      setNuestrasPublicaciones(contentNuestrasPublicaciones);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateAviso = async () => {
    if (!index.contactanos) {
      toast.info("Error, tienes que agregar texto a mejores ofertas");
    } else if (!index.blog) {
      toast.info("Error, tienes que agregar texto a ultimas noticias");
    } else {
      setLoading(true);
      try {
        const dataIndex = index;
        dataIndex.id = 0;
        const updateIndex = await IndexService.update(dataIndex);
        toast.success("Inicio actualizado con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        getAviso();
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {" "}
            {/* PAGE TITLE */}
            <header>
              <h1 className={`${styles.h4}`}>PÁGINA DE INICIO</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Editar el texto "Contáctanos" y "Blog" de la página de
                    inicio.
                  </li>
                </ol>
              </nav>
            </header>
            <>
              {/* user list */}
              <div className={`${styles.section} ${styles.p0}`}>
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <h4>Contáctanos</h4>
                  </div>
                </div>
                <form className="form-validate">
                  <div className={`${styles.row} ${styles.g4}`}>
                    <div className={`${styles.col}`}>
                      {/* Shipping address */}

                      <div className={`${styles.section} ${styles.mb4}`}>
                        <div className={`${styles.cardBody}`}>
                          <div className={`${styles.row} ${styles.g3}`}>
                            <div className={`${styles.colSm12}`}>
                              <div className={`${styles.formFloating}`}>
                                <ThemeProvider theme={myTheme}>
                                  <MUIRichTextEditor
                                    label="Descripcion del paquete"
                                    controls={[
                                      "italic",
                                      "underline",
                                      "strikethrough",
                                      "highlight",
                                      "undo",
                                      "redo",
                                      "link",
                                      "numberList",
                                      "bulletList",
                                      "quote",
                                      "code",
                                      "clear",
                                    ]}
                                    inlineToolbar={true}
                                    onChange={(value) => {
                                      console.log(value.getCurrentContent());
                                      setIndex({
                                        ...index,
                                        contactanos: stateToHTML(
                                          value.getCurrentContent()
                                        ),
                                      });
                                    }}
                                    defaultValue={contactanos}
                                  />
                                </ThemeProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              {/* user list */}
              <div className={`${styles.section} ${styles.p0}`}>
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <h4>Blog</h4>
                  </div>
                </div>
                <form className="form-validate">
                  <div className={`${styles.row} ${styles.g4}`}>
                    <div className={`${styles.col}`}>
                      {/* Shipping address */}

                      <div className={`${styles.section} ${styles.mb4}`}>
                        <div className={`${styles.cardBody}`}>
                          <div className={`${styles.row} ${styles.g3}`}>
                            <div className={`${styles.colSm12}`}>
                              <div className={`${styles.formFloating}`}>
                                <ThemeProvider theme={myTheme}>
                                  <MUIRichTextEditor
                                    label="Descripcion del paquete"
                                    controls={[
                                      "italic",
                                      "underline",
                                      "strikethrough",
                                      "highlight",
                                      "undo",
                                      "redo",
                                      "link",
                                      "numberList",
                                      "bulletList",
                                      "quote",
                                      "code",
                                      "clear",
                                    ]}
                                    inlineToolbar={true}
                                    onChange={(value) => {
                                      console.log(value.getCurrentContent());
                                      setIndex({
                                        ...index,
                                        blog: stateToHTML(
                                          value.getCurrentContent()
                                        ),
                                      });
                                    }}
                                    defaultValue={blog}
                                  />
                                </ThemeProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className={`${styles.section} ${styles.p0}`}>
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <h4>Nuestros servicios</h4>
                  </div>
                </div>
                <form className="form-validate">
                  <div className={`${styles.row} ${styles.g4}`}>
                    <div className={`${styles.col}`}>
                      {/* Shipping address */}

                      <div className={`${styles.section} ${styles.mb4}`}>
                        <div className={`${styles.cardBody}`}>
                          <div className={`${styles.row} ${styles.g3}`}>
                            <div className={`${styles.colSm12}`}>
                              <div className={`${styles.formFloating}`}>
                                <ThemeProvider theme={myTheme}>
                                  <MUIRichTextEditor
                                    label="Descripcion"
                                    controls={[
                                      "italic",
                                      "underline",
                                      "strikethrough",
                                      "highlight",
                                      "undo",
                                      "redo",
                                      "link",
                                      "numberList",
                                      "bulletList",
                                      "quote",
                                      "code",
                                      "clear",
                                    ]}
                                    inlineToolbar={true}
                                    onChange={(value) => {
                                      console.log(value.getCurrentContent());
                                      setIndex({
                                        ...index,
                                        nuestrosPaquetes: stateToHTML(
                                          value.getCurrentContent()
                                        ),
                                      });
                                    }}
                                    defaultValue={nuestrosPaquetes}
                                  />
                                </ThemeProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className={`${styles.section} ${styles.p0}`}>
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <h4>Nuestras publicaciones</h4>
                  </div>
                </div>
                <form className="form-validate">
                  <div className={`${styles.row} ${styles.g4}`}>
                    <div className={`${styles.col}`}>
                      {/* Shipping address */}

                      <div className={`${styles.section} ${styles.mb4}`}>
                        <div className={`${styles.cardBody}`}>
                          <div className={`${styles.row} ${styles.g3}`}>
                            <div className={`${styles.colSm12}`}>
                              <div className={`${styles.formFloating}`}>
                                <ThemeProvider theme={myTheme}>
                                  <MUIRichTextEditor
                                    label="Descripcion del paquete"
                                    controls={[
                                      "italic",
                                      "underline",
                                      "strikethrough",
                                      "highlight",
                                      "undo",
                                      "redo",
                                      "link",
                                      "numberList",
                                      "bulletList",
                                      "quote",
                                      "code",
                                      "clear",
                                    ]}
                                    inlineToolbar={true}
                                    onChange={(value) => {
                                      console.log(value.getCurrentContent());
                                      setIndex({
                                        ...index,
                                        nuestrasPublicaciones: stateToHTML(
                                          value.getCurrentContent()
                                        ),
                                      });
                                    }}
                                    defaultValue={nuestrasPublicaciones}
                                  />
                                </ThemeProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className={`${styles.co12}`}>
                <div
                  className={`${styles.cardFooter} ${styles.borderLight}`}
                  style={{ textAlign: "right" }}
                >
                  <button
                    className={`${styles.btn} ${styles.btnPrimary}`}
                    onClick={() => updateAviso()}
                  >
                    <svg
                      width="18px"
                      height="18px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    {"  "}
                    <span className={`${styles.small}`}>Guardar</span>
                  </button>
                </div>
              </div>
            </>
          </main>
        </>
      )}
    </>
  );
};

export default TablaIndex;
