import { Box, TextField, Typography } from "@mui/material";
import styles from "../../private/layout/assets/css/core.min.module.css";

const CustomDirectionInput = ({ direction, handleDirection }) => {
  return (
    <>
      <Typography>Domicilio</Typography>
      <Box className={`${styles["row"]}`}>
        <div className={`${styles["col-md-8"]}`}>
          <div className={`${styles["my-4"]}`}>
            <Typography>Calle</Typography>
            <TextField
              fullWidth
              variant="standard"
              placeholder="Calle"
              onChange={(e) =>
                handleDirection({
                  ...direction,
                  calle: e.target.value,
                })
              }
              value={direction?.calle}
            />
          </div>
        </div>

        <div className={`${styles["col-md-2"]}`}>
          <div className={`${styles["my-4"]}`}>
            <Typography>#Interior</Typography>
            <TextField
              fullWidth
              variant="standard"
              placeholder="..."
              onChange={(e) =>
                handleDirection({
                  ...direction,
                  interior: e.target.value,
                })
              }
              value={direction?.interior}
            />
          </div>
        </div>

        <div className={`${styles["col-md-2"]}`}>
          <div className={`${styles["my-4"]}`}>
            <Typography>#Exterior</Typography>
            <TextField
              fullWidth
              variant="standard"
              placeholder="..."
              onChange={(e) =>
                handleDirection({
                  ...direction,
                  exterior: e.target.value,
                })
              }
              value={direction?.exterior}
            />
          </div>
        </div>

        <div className={`${styles["col-sm-8"]}`}>
          <div className={`${styles["my-4"]}`}>
            <Typography>Colonia</Typography>
            <TextField
              fullWidth
              variant="standard"
              placeholder="Colonia"
              onChange={(e) =>
                handleDirection({
                  ...direction,
                  colonia: e.target.value,
                })
              }
              value={direction?.colonia}
            />
          </div>
        </div>

        <div className={`${styles["col-sm-4"]}`}>
          <div className={`${styles["my-4"]}`}>
            <Typography>Código postal</Typography>
            <TextField
              fullWidth
              variant="standard"
              placeholder="Código postal"
              onChange={(e) =>
                handleDirection({
                  ...direction,
                  codigoPostal: e.target.value,
                })
              }
              value={direction?.codigoPostal}
            />
          </div>
        </div>

        <div className={`${styles["col-md-6"]}`}>
          <div className={`${styles["my-4"]}`}>
            <Typography>Delegación o municipio</Typography>
            <TextField
              fullWidth
              variant="standard"
              placeholder="Delegación o municipio"
              onChange={(e) =>
                handleDirection({
                  ...direction,
                  municipio: e.target.value,
                })
              }
              value={direction?.municipio}
            />
          </div>
        </div>

        <div className={`${styles["col-md-6"]}`}>
          <div className={`${styles["my-4"]}`}>
            <Typography>Entidad federativa o estado</Typography>
            <TextField
              fullWidth
              variant="standard"
              placeholder="Entidad federativa o estado"
              onChange={(e) =>
                handleDirection({
                  ...direction,
                  estado: e.target.value,
                })
              }
              value={direction?.estado}
            />
          </div>
        </div>
      </Box>
    </>
  );
};

export default CustomDirectionInput;
