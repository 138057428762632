import { useEffect, useState } from "react";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Autocomplete, Button, TextField } from "@mui/material";
import dayjs from "dayjs";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es-mx";
import { esES } from "@mui/x-date-pickers/locales";
import styles from "../layout/assets/css/core.min.module.css";

const Filtrar = ({ filtroValue, filtros, handleFiltros }) => {
  const today = new Date();
  const primerDiaDelMes = new Date(today.getFullYear(), today.getMonth(), 1);

  const [value, setValue] = useState([]);
  const [seleccionarRango, setSeleccionarRango] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(
    dayjs(
      new Intl.DateTimeFormat("az", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(primerDiaDelMes)
    )
  );
  const [fechaFinal, setFechaFinal] = useState(
    dayjs(
      new Intl.DateTimeFormat("az", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(today)
    )
  );
  const [state, setState] = useState(false);
  const [listFiltros, setListFiltros] = useState([
    { title: "Fecha", tipo: "Fecha", propiedad: "fecha" },
  ]);

  const vaciar = () => {
    handleFiltros([]);
  };

  const handleValue = (event, newValues) => {
    let filtero = {
      fecha: "",
      rangoInicio: "",
      rangoFinal: "",
    };

    let updatedValues = newValues.reduce((acc, newValue) => {
      let existingValueIndex = acc.findIndex(
        (value) => value.tipo === newValue.tipo
      );
      if (existingValueIndex !== -1) {
        // Reemplazar el valor anterior con el nuevo
        acc[existingValueIndex] = newValue;
      } else {
        acc.push(newValue);
      }
      return acc;
    }, []);
    setSeleccionarRango(false);

    updatedValues.forEach((element) => {
      if (element.tipo === "Fecha") {
        setSeleccionarRango(true);
        filtero.rangoInicio = fechaInicio;
        filtero.rangoFinal = fechaFinal;
      }
      filtero[element.propiedad] = element.title;
    });

    handleFiltros(updatedValues, filtero);
  };

  const addFiiltro = (newFiltro) => {
    handleFiltros([...filtros, newFiltro]);
  };

  const removeFiltro = (deleteFiltro) => {
    let oldFiltros = filtros.filter((f) => f !== deleteFiltro);
    handleFiltros(oldFiltros);
  };

  const handleFechaInicio = (newFecha) => {
    let nuevaFechaEntrega = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(newFecha.toDate());
    let newFiltrosFecha = {
      ...filtroValue,
      rangoInicio: newFecha.toDate().toISOString(),
    };
    setFechaInicio(nuevaFechaEntrega);
    handleFiltros(filtros, newFiltrosFecha);
  };

  const handleFechaFinal = (newFecha) => {
    let nuevaFechaEntrega = new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(newFecha.toDate());
    let newFiltrosFecha = {
      ...filtroValue,
      rangoFinal: newFecha.toDate().toISOString(),
    };
    setFechaFinal(nuevaFechaEntrega);
    handleFiltros(filtros, newFiltrosFecha);
  };

  return (
    <div class="card-header p-4 pt-0">
      {/* <div class="row g-3">
        <div class="order-2 order-md-1 col">
          <div class="position-relative d-flex align-items-center">
            {!state ? null : (
              <a
                href="javascript:void(0);"
                class="z-index-1 position-absolute start-0 ms-3 "
                style={{ cursor: "pointer" }}
                onClick={() => vaciar()}
              >
                <CleaningServicesIcon
                  style={{ width: "17px", height: "17px" }}
                />
              </a>
            )}

            <div class="dropdown w-100">
              <Autocomplete
                multiple
                id="size-small-standard-multi"
                size="small"
                options={listFiltros}
                getOptionLabel={(option) => option.title}
                value={filtros}
                groupBy={(option) => option.tipo}
                onChange={handleValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filtrar"
                    placeholder="Filtrar por"
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div> */}
      <div class="row g-3 mt-0">
        <div class="order-2 order-md-1 col">
          <p
            style={{
              fontSize: "12px",
              fontWeight: 400,
              color: "rgba(0, 0, 0, 0.6)",
              margin: "0.5rem",
            }}
          >
            Periodo de búsqueda:
          </p>
          <div class="position-relative d-flex align-items-center">
            {/* <!-- search icon --> */}
            {/*Input */}
            <div className={`${styles["d-flex-align-middle"]}`}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={
                  esES.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
                adapterLocale="es-mx"
              >
                <DatePicker
                  label="Fecha inicio:"
                  ampm
                  value={dayjs(fechaInicio)}
                  onChange={handleFechaInicio}
                  sx={{ width: "100%", margin: "0.5rem" }}
                />
                <DatePicker
                  label="Fecha final:"
                  ampm
                  value={dayjs(fechaFinal)}
                  onChange={handleFechaFinal}
                  sx={{ width: "100%", margin: "0.5rem" }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filtrar;
