import React, { useEffect, useState } from "react";
import CountService from "../../../services/Count.service";
import Counter from "./Counter";

const Counters = ({ clientesFelices }) => {
  const [counts, setCountns] = useState([]);

  useEffect(() => {
    getCounts();
    window.scrollTo(0, 0);
  }, []);

  const getCounts = async () => {
    try {
      const countData = await CountService.getAll();
      setCountns(countData);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const randomColor = () => Math.floor(Math.random() * 16777215).toString(16);

  return (
    <section
      className="py-9 pb-8 bg-parallax"
      style={{
        backgroundImage: "url(assets/img/background/countup-bg-img.jpg)",
      }}
    >
      <div className="container">
        <div className="sectionTitleSmall text-center mb-7 wow fadeInUp">
          <h2 className="font-weight-bold text-white">Contador</h2>
          <p className="text-white font-size-15">
            Lorem ipsum dolor sit amet consectetur adipisicing elit sed do
            eiusmod
          </p>
        </div>
        <div className="row wow fadeInUp" id="counter">
          {counts.map((count, index) => (
            <Counter
              key={index}
              title={count.name}
              value={count.value}
              btnColor={`#ea7066`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Counters;
