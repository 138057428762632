import React, { Component } from "react";
import Uploader from "./Uploader";
import Preview from "./Preview";
import "./uploader.css";
import { Typography, useTheme } from "@mui/material";

import { toast } from "react-toastify";


class DropComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesPreviewUrls: [],
      imagenesDefault: props.imagenesDefault
    };
  }
  

  imagesPreviewUrls = (result) => {
    const { subirImagen } = this.props;
    let arrayM = this.state.imagesPreviewUrls;

    arrayM.push(result);
    this.setState({
      imagesPreviewUrls: arrayM,
    });

    subirImagen(arrayM);
  };

  deleteImage = (id) => {
    try {
    
      const { imagesPreviewUrls } = this.state;
      if (imagesPreviewUrls.length > 0) {
        const filterImages = imagesPreviewUrls.filter(
          (image) => image.id !== id
        );
        this.setState({
          imagesPreviewUrls: filterImages,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    
    const { imagesPreviewUrls, imagenesDefault } = this.state;
    const { subirImagen } = this.props;
    subirImagen(imagesPreviewUrls);
    return (
      <div>
        <Uploader imagenesDefault={imagenesDefault} imagesPreviewUrls={this.imagesPreviewUrls} />

        {/* <button onClick={subirImagen(imagesPreviewUrls)} /> */}
        {imagesPreviewUrls.length > 0 ? (
          <Preview
            imagesPreviewUrls={imagesPreviewUrls}
            deleteImage={this.deleteImage}
          />
        ) : null}
        <Typography
        variant="subtitle2"
        textAlign="center"
        style={{ padding: 20 }}
      >
        {" "}
        *Tipos de imágenes aceptadas: ".jpg", ".png", ".jpeg"
        {/* {isDragActive
      ? "Arroja aqui tus archivos"
      : "Arrastra aqui o click para agregar una foto"} */}
      </Typography>
      </div>
    );
  }
}

export default DropComponent;
