import { useContext, useEffect, useState } from "react";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Autocomplete, Button, TextField } from "@mui/material";
import dayjs from "dayjs";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es-mx";
import { esES } from "@mui/x-date-pickers/locales";

import SubAreaService from "../../../services/SubArea.service";
import GrupoService from "../../../services/Grupo.service";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";

const Filtrar = ({ filtroValue, filtros, handleFiltros, area }) => {
  const today = new Date();
  const { currentUser, checkRole } = useContext(AuthContext);

  const [grupos, setGrupos] = useState([]);
  const [subAreas, setSubAreas] = useState([]);

  const [seleccionarRango, setSeleccionarRango] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(
    dayjs(
      new Intl.DateTimeFormat("az", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(today)
    )
  );
  const [fechaFinal, setFechaFinal] = useState(
    dayjs(
      new Intl.DateTimeFormat("az", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(today)
    )
  );
  const [state, setState] = useState(false);
  const [listFiltros, setListFiltros] = useState([]);

  const vaciar = () => {
    handleFiltros([]);
  };

  const handleValue = (event, newValues) => {
    let filtero = {
      grupo: [],
      subarea: [],
    };

    let updatedValues = newValues.reduce((acc, newValue) => {
      let existingValueIndex = acc.findIndex(
        (value) => value.title === newValue.title
      );
      if (existingValueIndex !== -1) {
        // Reemplazar el valor anterior con el nuevo
        acc[existingValueIndex] = newValue;
      } else {
        acc.push(newValue);
      }
      return acc;
    }, []);
    setSeleccionarRango(false);

    updatedValues.forEach((element) => {
      filtero[element.propiedad].push(element.title);
    });

    console.log("updatedValues");
    console.log(updatedValues);
    console.log("filtero");
    console.log(filtero);

    handleFiltros(updatedValues, filtero);
  };

  const addFiiltro = (newFiltro) => {
    handleFiltros([...filtros, newFiltro]);
  };

  const removeFiltro = (deleteFiltro) => {
    let oldFiltros = filtros.filter((f) => f !== deleteFiltro);
    handleFiltros(oldFiltros);
  };

  useEffect(() => {
    checkRole(["Administrador", "Terapeuta"]) &&
      area === "Terapeuta" &&
      getSubAreas();

    checkRole(["Administrador", "Maestro"]) &&
      area === "Maestro" &&
      getGrupos();
  }, []);

  const addOptionsGrupo = (grupoList) => {
    let listGupos = [];

    grupoList.forEach((g) =>
      listGupos.push({
        title: g.nombre,
        tipo: "Grupo",
        propiedad: "grupo",
      })
    );

    setListFiltros((old) => [...old, ...listGupos]);
  };

  const addOptionsArea = (grupoList) => {
    let listGupos = [];

    grupoList.forEach((g) =>
      listGupos.push({
        title: g.nombre,
        tipo: "Sub-Area",
        propiedad: "subarea",
      })
    );
    setListFiltros((old) => [...old, ...listGupos]);
  };

  const getGrupos = async () => {
    try {
      const getData = await GrupoService.getAll();
      if (getData) {
        setGrupos(getData);
        addOptionsGrupo(getData);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getSubAreas = async () => {
    try {
      const getData = await SubAreaService.getAll();
      if (getData) {
        setSubAreas(getData);
        addOptionsArea(getData);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div class="card-header p-4">
      <div class="row g-3">
        <div class="order-2 order-md-1 col">
          <div class="position-relative d-flex align-items-center">
            {/* <!-- search icon --> */}

            {!state ? null : (
              <a
                href="javascript:void(0);"
                class="z-index-1 position-absolute start-0 ms-3 "
                style={{ cursor: "pointer" }}
                onClick={() => vaciar()}
              >
                <CleaningServicesIcon
                  style={{ width: "17px", height: "17px" }}
                />
              </a>
            )}

            {/*Input */}
            <div class="dropdown w-100">
              <Autocomplete
                multiple
                id="size-small-standard-multi"
                size="small"
                options={listFiltros}
                getOptionLabel={(option) => option.title}
                value={filtros}
                groupBy={(option) => option.tipo}
                onChange={handleValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filtrar"
                    placeholder="Filtrar por"
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filtrar;
