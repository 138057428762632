/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../../../../context/AuthContext";

/* Components & dependencies */
import FullPageLoading from "../../../FullPageLoading";
import Carousel from "react-bootstrap/Carousel";
import { useCart } from "react-use-cart";
import BreadCrumb from "./BreadCrumb";

/* services */
import S3Service from "../../../../services/S3.service";
import ServicioService from "../../../../services/Servicio.service";
import ImageLine from "./ImageLine";
import useMediaQuery from "@mui/material/useMediaQuery";

const ServicioIndividual = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [servicio, setServicio] = useState({});
  const [imagenes, setImagenes] = useState([]);
  const [imagenesDias, setImagenesDias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [precio, setPrecio] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [totalMin, setTotalMin] = useState(0);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const matches = useMediaQuery("(min-width:600px)");

  let cargaPrecio = 0;

  const {
    items,
    addItem,
    removeItem,
    removeLineItem,
    clearCart,
    lineItemsCount,
  } = useCart();

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const vaciarCart = async () => {
    items.map((record, index) => {
      removeItem(record.id);
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      const result = await ServicioService.getById(parseInt(id));
      setServicio(result);

      for (const img of result.imagenes) {
        const imagen = await S3Service.get(img);
        setImagenes((imagenes) => [...imagenes, imagen.result]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const idUnico = (length) => {
    try {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    } catch (error) {
      CSSConditionRule.log(error);
    }
  };

  const locale = "en-gb";

  const handleChangeCarouselIndex = (newIndex) => {
    setCarouselIndex(newIndex);
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <div className="main-wrapper course-single">
          <BreadCrumb titulo={servicio.titulo} />
          <section className="py-6 py-md-10">
            <div>
              <div className="container">
                <div
                  className="container"
                  style={{ backgroundColor: "white", borderRadius: "10px" }}
                >
                  <div className="section">
                    <div className="container">
                      <div
                        className="row"
                        style={{
                          backgroundColor: "#f8f8f8",
                          borderRadius: "0.57rem",
                        }}
                      >
                        <div className="col-12 col-lg-8">
                          <div
                            style={{
                              height: "5rem",
                              display: "flex",
                              marginBottom: "0.5rem",
                            }}
                          >
                            <ImageLine
                              imagenes={imagenes}
                              selectedIndex={carouselIndex}
                            />
                          </div>
                          <div className="swiper-container swiper-btn-group swiper-btn-group-end text-white p-0 overflow-hidden">
                            <div className="swiper-wrapper h-100">
                              <Carousel
                                slide={false}
                                nextLabel={""}
                                prevLabel={""}
                                onSelect={handleChangeCarouselIndex}
                                indicators={false}
                              >
                                {servicio.imagenes.map((record, index) => {
                                  return (
                                    <Carousel.Item key={index}>
                                      <div
                                        className="d-block w-100"
                                        style={
                                          matches
                                            ? {
                                                height: "600px",
                                              }
                                            : {
                                                height: "25rem",
                                              }
                                        }
                                      >
                                        <img
                                          src={imagenes[index]}
                                          alt="imagen"
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </Carousel.Item>
                                  );
                                })}
                              </Carousel>
                            </div>
                            {/* Add Arrows */}
                            <div className="swiper-button-next swiper-button-white" />
                            <div className="swiper-button-prev swiper-button-white" />
                          </div>
                          {/* /SWIPER */}
                        </div>
                        <div className="col-12 col-lg-4 mb-5 card-body border-top-5 px-3 rounded-bottom border-primary">
                          <div className="p-4 p-0-xs shadow-md shadow-none-xs rounded">
                            <h3 className="card-title text-primary mb-4">
                              {servicio.titulo}
                            </h3>
                            <h2 className="h4 fw-bold m-0">
                              {servicio.subtitulo}
                            </h2>
                            <h6 className="fw-normal mb-4">
                              Horario: {servicio.horario}
                            </h6>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: servicio.descripcion,
                              }}
                              style={{ textAlign: "justify" }}
                            ></p>
                            {servicio.precios.map((precio, index) => (
                              <div
                                className="border-start border-primary border-3 py-1 px-3 my-5"
                                key={index}
                              >
                                <h2 className="mb-0 h5">
                                  Precio {precio.user}
                                </h2>
                                <p className="mb-0 text-gray-500">
                                  ${precio.precio}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default ServicioIndividual;
