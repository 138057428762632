/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";

/* Servicios */
import NoticiaService from "../../../services/Noticia.service";
import S3Service from "../../../services/S3.service";

import styles from "../layout/assets/css/core.min.module.css";

const CrearCarrusel = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [noticia, setNoticia] = useState({});
  const [loading, setLoading] = useState(true);
  const [imagen, setImagen] = useState("");
  useEffect(() => {
    getNoticia();
  }, []);

  const getNoticia = async () => {
    setLoading(true);
    try {
      const data = await NoticiaService.getById(parseInt(id));
      setNoticia(data[0]);
      if (data[0].imagen) {
        const imagenS3 = await S3Service.get(data[0].imagen);
        setImagen(imagenS3.result);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteNoticia = async () => {
    setLoading(true);
    console.log(noticia);
    try {
      console.log("ELIMINAR");
      if (noticia.imagen) {
        const imagen = await S3Service.delete(noticia.imagen);
        console.log("---------se borro" + imagen.result);
      }
      const noticiaBorrada = await NoticiaService.remove(id);
      toast.info("Noticia eliminada");
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setLoading(false);
      navigate("/blog");
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {" "}
            {/* PAGE TITLE */}
            <header>
              <h1 className={`${styles.h4}`}>Eliminar entrada</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li className={`${styles.breadcrumbItem}`}>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "unset",
                        color: "#9b242c",
                      }}
                      onClick={() => navigate("/blog")}
                    >
                      blog
                    </button>
                  </li>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Eliminar
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className={`${styles.row} ${styles.g4}`}>
                <div className={`${styles.col}`}>
                  <div
                    className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                  >
                    Eliminar imágen
                  </div>
                  {/* Shipping address */}
                  <div className={`${styles.section} ${styles.mb4}`}>
                    <div className={`${styles.cardBody}`}>
                      <div className={`${styles.row} ${styles.g3}`}>
                        {noticia.imagen ? (
                          <div>
                            <div className={`${styles.colLg12}`}>
                              <div style={{ textAlign: "center" }}>
                                <img
                                  src={String(imagen)}
                                  style={{
                                    height: 300,
                                    objectFit: "contain",
                                    width: "-webkit-fill-available",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className={`${styles.colSm12}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Título"
                              disabled={true}
                              value={noticia.titulo}
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm12}`}>
                          <div className={`${styles.formFloating}`}>
                            <div>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: noticia.cuerpoNoticia,
                                }}
                                style={{
                                  textAlign: "justify",
                                  marginRight: "10%",
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Frase celebre"
                              value={noticia.fraseCelebre}
                            />
                            <label>Frace celebre</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Frase celebre"
                              value={noticia.autorFraseCelebre}
                            />
                            <label>Autor</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Frase celebre"
                              onChange={(e) => {
                                setNoticia({
                                  ...noticia,
                                  categoria: e.target.value,
                                });
                              }}
                              value={noticia.categoria}
                              defaultValue={noticia.categoria}
                            />
                            <label>Categoria</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className={`${styles.co12}`}>
              <div
                className={`${styles.cardFooter} ${styles.borderLight}`}
                style={{ textAlign: "right" }}
              >
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => deleteNoticia()}
                >
                  <span className={`${styles.small}`}>Eliminar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearCarrusel;
