import styles from "../../layout/assets/css/core.min.module.css";

import { Box, Button, Divider, TextField, Typography } from "@mui/material";

const SectionDesarrolloSicomotor = ({
  desarrolloSicomotor,
  handleDesarrolloSicomotor,
}) => {
  return (
    <div className={`${styles.row} ${styles.colSmm12}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          Desarrollo psicomotor
        </div>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Edad en que sostuvo la cabeza:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleDesarrolloSicomotor({
                    ...desarrolloSicomotor,
                    edadSostenerCabeza: e.target.value,
                  })
                }
                value={desarrolloSicomotor?.edadSostenerCabeza}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Edad en la que sonrió:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleDesarrolloSicomotor({
                    ...desarrolloSicomotor,
                    edadSonreir: e.target.value,
                  })
                }
                value={desarrolloSicomotor?.edadSonreir}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Edad en la que gateo:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleDesarrolloSicomotor({
                    ...desarrolloSicomotor,
                    edadGatear: e.target.value,
                  })
                }
                value={desarrolloSicomotor?.edadGatear}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Edad en la que se sentó:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleDesarrolloSicomotor({
                    ...desarrolloSicomotor,
                    edadSentar: e.target.value,
                  })
                }
                value={desarrolloSicomotor?.edadSentar}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Edad en la que caminó con ayuda:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleDesarrolloSicomotor({
                    ...desarrolloSicomotor,
                    edadCaminarAyuda: e.target.value,
                  })
                }
                value={desarrolloSicomotor?.edadCaminarAyuda}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Edad en la que caminó solo:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleDesarrolloSicomotor({
                    ...desarrolloSicomotor,
                    edadCaminarSolo: e.target.value,
                  })
                }
                value={desarrolloSicomotor?.edadCaminarSolo}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>
                Edad en la que controló esfínteres en el día:
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleDesarrolloSicomotor({
                    ...desarrolloSicomotor,
                    edadControlEsfinteresDay: e.target.value,
                  })
                }
                value={desarrolloSicomotor?.edadControlEsfinteresDay}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>
                Edad en la que controló esfínteres en la noche:
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleDesarrolloSicomotor({
                    ...desarrolloSicomotor,
                    edadControlEsfinteresNight: e.target.value,
                  })
                }
                value={desarrolloSicomotor?.edadControlEsfinteresNight}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Edad en la que comió solo:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleDesarrolloSicomotor({
                    ...desarrolloSicomotor,
                    edadComerSolo: e.target.value,
                  })
                }
                value={desarrolloSicomotor?.edadComerSolo}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Edad en la que se bañó solo:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleDesarrolloSicomotor({
                    ...desarrolloSicomotor,
                    edadBanoSolo: e.target.value,
                  })
                }
                value={desarrolloSicomotor?.edadBanoSolo}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Edad en la que se vistió solo:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleDesarrolloSicomotor({
                    ...desarrolloSicomotor,
                    edadVestirSolo: e.target.value,
                  })
                }
                value={desarrolloSicomotor?.edadVestirSolo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionDesarrolloSicomotor;
