import React from "react";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import S3Service from "../../../services/S3.service";

import logo from "./logo-corazon.png";
import styles from "./assets/css/core.min.module.css";

const Header = ({ changeStateMenu }) => {
  const { currentUser, logout } = useContext(AuthContext);
  let navigate = useNavigate();
  const [imag, setImag] = useState();

  useEffect(() => {
    getImagen();
  }, []);

  const salir = () => {
    logout();
    navigate("/");
  };

  const getImagen = async () => {
    try {
      if (currentUser.imagen != undefined) {
        const result = await S3Service.get(currentUser.imagen);
        setImag(result.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const displayMenu = () => {
    let element;
    element = document.querySelector("#aside_responsive");
    element.style.display = "block";
  };

  return (
    <>
      {/*  header  */}
      <header
        id={styles["header"]}
        className={`${styles.dFlex} ${styles.alignItemsCenter} ${styles.shadowXs}`}
      >
        {/* Navbar */}

        <div className={`${styles.containerFluid} ${styles.positionRelative} `}>
          <nav
            className={`${styles.navbar} ${styles.navbarExpandLg}  ${styles.navbarLight}  ${styles.justifyContentBetween} `}
          >
            {/* logo, navigation toggler */}
            <div className={styles.alignItemsStart}>
              {/* sidebar toggler */}
              <button
                className={`btn-sidebar-toggle ${styles.h100} ${styles.dInlineBlock}  ${styles.dLgNone}  ${styles.justifyContentCenter} ${styles.alignItemsCenter} ${styles.p2} `}
                style={{ border: "none", backgroundColor: "unset" }}
                onClick={() => {
                  changeStateMenu("open");
                }}
              >
                <span>
                  <svg width={25} height={25} viewBox="0 0 20 20">
                    <path d="M 19.9876 1.998 L -0.0108 1.998 L -0.0108 -0.0019 L 19.9876 -0.0019 L 19.9876 1.998 Z" />
                    <path d="M 19.9876 7.9979 L -0.0108 7.9979 L -0.0108 5.9979 L 19.9876 5.9979 L 19.9876 7.9979 Z" />
                    <path d="M 19.9876 13.9977 L -0.0108 13.9977 L -0.0108 11.9978 L 19.9876 11.9978 L 19.9876 13.9977 Z" />
                    <path d="M 19.9876 19.9976 L -0.0108 19.9976 L -0.0108 17.9976 L 19.9876 17.9976 L 19.9876 19.9976 Z" />
                  </svg>
                </span>
              </button>
              {/* logo : mobile only */}
              <button
                className={`${styles.navbarBrand} ${styles.dInlineBlock}  ${styles.dLgNone}  ${styles.mx2}`}
                style={{ border: "none", backgroundColor: "unset" }}
                onClick={() => navigate("/")}
              >
                <img src={logo} height={38} alt="..." />
              </button>
            </div>
            {/* navbar : navigation */}
            <div
              className={`${styles.collapse} ${styles.navbarCollapse}`}
              id={styles["navbarMainNav"]}
            >
              {/* navbar : mobile menu */}
              <div className={`${styles.navbarXs} ${styles.dNone}`}>
                {/* close button */}
                <button
                  className={`${styles.navbarToggler} ${styles.pt0}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarMainNav"
                  aria-controls="navbarMainNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <svg width={20} viewBox="0 0 20 20">
                    <path d="M 20.7895 0.977 L 19.3752 -0.4364 L 10.081 8.8522 L 0.7869 -0.4364 L -0.6274 0.977 L 8.6668 10.2656 L -0.6274 19.5542 L 0.7869 20.9676 L 10.081 11.679 L 19.3752 20.9676 L 20.7895 19.5542 L 11.4953 10.2656 L 20.7895 0.977 Z" />
                  </svg>
                </button>
                {/* logo */}
                <a className={`${styles.navbarBrand}`} href="index.html">
                  <img
                    src="assets/images/logo/logo_dark.svg"
                    width={110}
                    height={38}
                    alt="..."
                  />
                </a>
              </div>
              {/* /navbar : mobile menu */}
              {/* Dropdowns */}
              <ul className={`${styles.navbarNav} ${styles.alignItemsCenter}`}>
                <li className={`${styles.navItem}`}>
                  <a
                    className={`${styles.navLink}`}
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id={styles["mainMegaNav"]}
                  >
                    {currentUser.nombre}
                    <br />
                    <span className={`${styles.textMuted}`}>Bienvenido</span>
                  </a>
                </li>
              </ul>
              {/* /Dropdowns */}
            </div>
            {/* /navbar : navigation */}
            {/* options */}
            <ul
              className={`${styles.listInline} ${styles.listUnstyled} ${styles.mb0} ${styles.dFlex} ${styles.alignItemsEnd}`}
            >
              {/* account */}
              <li
                className={`${styles.listInlineItem} ${styles.mx1} ${styles.dropdown}`}
              >
                {/* has avatar */}
                <a
                  href="#"
                  id={styles["dropdownAccountOptions"]}
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                  aria-haspopup="true"
                  aria-label="Account options"
                >
                  <img
                    src={
                      currentUser.imagen == undefined
                        ? require("./assets/user.png")
                        : imag
                    }
                    className={`${styles.roundedCircle} ${styles.shadow}`}
                    alt="Profile Picture"
                    style={{ height: "3rem" }}
                  />
                </a>
                {/* no avatar */}
                <div
                  aria-labelledby="dropdownAccountOptions"
                  className={`${styles.dropdownMenu} ${styles.dropdownMenuClean} ${styles.dropdownMenuNavbarAutopos} ${styles.dropdownMenuInvert} ${styles.dropdownFadeindown} ${styles.p0} ${styles.mt2} ${styles.w300}`}
                >
                  {/* user detail */}
                </div>
              </li>
              {/* navigation toggler (mobile) */}
              <li
                className={`${styles.listInlineItem} ${styles.dInlineBlock} ${styles.dLgNone}`}
              ></li>
            </ul>
            {/* /options */}
          </nav>
        </div>
        {/* /Navbar */}
      </header>
      {/* /Header */}
    </>
  );
};

export default Header;
