import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

import Preloader from "../../utils/PreloaderElement";
import { toast } from "react-toastify";

import styles from "../layout/assets/css/core.min.module.css";
import S3Service from "../../../services/S3.service";
import AppointmentService from "../../../services/therapies/Appointment.service";
import CustomPaginationActionsTableCitas from "../../utils/CustomTable/CustomPaginationActionsTableCitas";
import Filtrar from "./Filtrar";

const CitaTable = () => {
  const today = new Date();
  const primerDiaDelMes = new Date(today.getFullYear(), today.getMonth(), 1);

  const navigate = useNavigate();
  const { checkRole } = useContext(AuthContext);

  const [alumnos, setAlumnos] = useState([]);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const [filterSearch, setFilterSearch] = useState("");
  const [filtros, setFiltros] = useState([
    { propiedad: "fecha", tipo: "Fecha", title: "Fecha" },
  ]);
  const [filtero, setFiltero] = useState({
    fecha: "Fecha",
    rangoInicio: primerDiaDelMes.toISOString(),
    rangoFinal: today.toISOString(),
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onUpdate = () => {
    setUpdate(!update);
  };

  const handleFiltro = (newfiltros, newFiltero) => {
    setFiltros(newfiltros);
    setFiltero(newFiltero);
    onUpdate();
  };

  useEffect(() => {
    searchFunctionPage(filterSearch);
  }, [page, limit, update]);

  const searchFunctionPage = async (search) => {
    try {
      setLoading(true);
      let searchData = {
        search: search,
        limit: limit,
        offset: limit * page,
        filters: filtero,
      };
      const data = await AppointmentService.listSearch(searchData);

      if (data && data.data) {
        data.data.forEach(async (element) => {
          const image = await S3Service.get(element.alumno?.imagen);
          element.alumno.url = image.result;
        });

        setAlumnos(data.data);
        setTotal(data.total);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <main
        id={styles["middle"]}
        className={`${styles.flexFill} ${styles.mxAuto}`}
      >
        <header>
          <h1 className={`${styles.h4}`}>Citas</h1>
          <nav aria-label="breadcrumb">
            <ol className={`${styles.breadcrumb} ${styles.small}`}>
              <li
                className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                aria-current="page"
              >
                Hay {total} actualmente.
              </li>
            </ol>
          </nav>
        </header>

        <div
          className={`${styles.row} ${styles.g4}`}
          style={{ width: "100%", overflow: "auto" }}
        >
          <div className={`${styles.col}`}>
            <div className={`${styles.section} ${styles.mb4}`}>
              <div className={`${styles.cardBody}`}>
                <div className={`${styles.cardBody} ${styles.pt1}`}>
                  <div className={`${styles["py-4"]}`}>
                    <div className={`${styles.row} ${styles.g3}`}>
                      <div
                        className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                      >
                        <Filtrar
                          filtroValue={filtero}
                          filtros={filtros}
                          handleFiltros={handleFiltro}
                        />
                      </div>
                      <div
                        className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
                      >
                        {checkRole(["Administrador"]) && (
                          <a
                            href="javascript:void(0)"
                            className={`${styles.w100} ${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
                            onClick={() => navigate("/citas/nuevo")}
                          >
                            <svg
                              width="18px"
                              height="18px"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <line x1={12} y1={5} x2={12} y2={19} />
                              <line x1={5} y1={12} x2={19} y2={12} />
                            </svg>
                            <span>Nuevo</span>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.tableResponsiveMd}`}>
                    {loading ? (
                      <Preloader />
                    ) : (
                      <CustomPaginationActionsTableCitas
                        alumnos={alumnos}
                        page={page}
                        total={total}
                        rowsPerPage={limit}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CitaTable;
