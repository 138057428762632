/* React */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/PreloaderElement";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

/* Servicios */
import CategoriaBlogService from "../../../services/CategoriaBlog.service";

import styles from "../layout/assets/css/core.min.module.css";
import CustomPaginationActionsTable from "../../utils/CustomTable/CustomPaginationActionsTableTerapeuta";
import AlumnoService from "../../../services/Alumno.service";
import S3Service from "../../../services/S3.service";
import Filtrar from "./Filtrar";
import Busqueda from "./Busqueda";

const TableAreaTerapeutica = () => {
  const navigate = useNavigate();
  const { currentUser, checkRole } = useContext(AuthContext);
  const [alumnos, setAlumnos] = useState([]);
  const [alumnosEducativa, setAlumnosEducativa] = useState([]);
  const [area, setArea] = useState("");
  const [showMenu, setShownMenu] = useState(null);
  const [search, setSearch] = useState(false);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const [filtros, setFiltros] = useState([]);
  const [filtero, setFiltero] = useState({
    grupo: "",
    subarea: "",
  });
  const [filterSearch, setFilterSearch] = useState("");

  const handleFiltro = (newfiltros, newFiltero) => {
    setFiltros(newfiltros);
    setFiltero(newFiltero);
    onUpdate();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onUpdate = () => {
    setUpdate(!update);
  };

  useEffect(() => {
    searchFunctionPage(filterSearch);
  }, [page, limit, update]);

  const getAlumnos = async () => {
    setLoading(true);
    try {
      const data = await AlumnoService.listBy(limit, page * limit, {
        area: "Área terapéutica",
        filtros: filtero,
      });

      data.data.forEach(async (element) => {
        const image = await S3Service.get(element.imagen);
        element.url = image.result;
      });

      setAlumnos(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const searchFunctionPage = async (searchEntry) => {
    try {
      setLoading(true);
      let searchData = {
        search: searchEntry,
        area: "Área terapéutica",
        filtros: filtero,
      };
      const data = await AlumnoService.listBy(limit, page * limit, searchData);

      if (data) {
        data.data.forEach(async (element) => {
          const image = await S3Service.get(element.imagen);
          element.url = image.result;
        });

        setAlumnos(data.data);
        setTotal(data.total);
      }
      setSearch(true);
    } catch (error) {
      setSearch(false);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const searchFunction = async (searchEntry) => {
    setFilterSearch(searchEntry);
    setPage(0);
    try {
      setLoading(true);
      let searchData = {
        search: searchEntry,
        area: "Área terapéutica",
        filtros: filtero,
      };
      const data = await AlumnoService.listBy(limit, page * limit, searchData);

      if (data) {
        data.data.forEach(async (element) => {
          const image = await S3Service.get(element.imagen);
          element.url = image.result;
        });

        setAlumnos(data.data);
        setTotal(data.total);
      }
      setSearch(true);
    } catch (error) {
      setSearch(false);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <main
        id={styles["middle"]}
        className={`${styles.flexFill} ${styles.mxAuto}`}
      >
        <header>
          <h1 className={`${styles.h4}`}>Área terapéutica</h1>
          <nav aria-label="breadcrumb">
            <ol className={`${styles.breadcrumb} ${styles.small}`}>
              <li
                className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                aria-current="page"
              >
                Hay {total} actualmente.
              </li>
            </ol>
          </nav>
        </header>

        <div
          className={`${styles.row} ${styles.g4}`}
          style={{ width: "100%", overflow: "auto" }}
        >
          <div className={`${styles.row}`}>
            <div className={`${styles.section} ${styles.mb4}`}>
              <div className={`${styles.cardBody}`}>
                <div className={`${styles.cardBody} ${styles.pt1}`}>
                  <div className={`${styles["py-4"]}`}>
                    <div className={`${styles.row} ${styles.g3}`}>
                      <div
                        className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                      >
                        <Busqueda
                          searchFunction={searchFunction}
                          placeholder="Nombre"
                        />
                        <div
                          style={{
                            padding: "1rem",
                            width: "100%",
                            height: "0.2rem",
                          }}
                        />
                        <Filtrar
                          filtroValue={filtero}
                          filtros={filtros}
                          handleFiltros={handleFiltro}
                          area="Terapeuta"
                        />
                      </div>
                      <div
                        className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
                      >
                        {checkRole(["Administrador"]) && (
                          <a
                            href="javascript:void(0)"
                            className={`${styles.w100} ${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
                            onClick={() => navigate("/alumnos/nuevo")}
                          >
                            <svg
                              width="18px"
                              height="18px"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <line x1={12} y1={5} x2={12} y2={19} />
                              <line x1={5} y1={12} x2={19} y2={12} />
                            </svg>
                            <span>Nuevo</span>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <Preloader />
          ) : (
            checkRole(["Administrador", "Terapeuta"]) && (
              <div className={`${styles.row}`}>
                <div
                  className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                >
                  Area Terapéutica
                </div>
                <div className={`${styles.section} ${styles.mb4}`}>
                  <div className={`${styles.cardBody}`}>
                    <div className={`${styles.cardBody} ${styles.pt1}`}>
                      <div className={`${styles.tableResponsiveMd}`}>
                        <CustomPaginationActionsTable
                          alumnos={alumnos}
                          page={page}
                          total={total}
                          rowsPerPage={limit}
                          handleChangePage={handleChangePage}
                          handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </main>
    </>
  );
};

export default TableAreaTerapeutica;
