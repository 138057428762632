/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Modal,
} from "@mui/material";
import {
  CModal,
  CButton,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import Button from "@mui/material/Button";
import { Badge } from "@mui/material";
import { Delete, Visibility } from "@material-ui/icons";
import { grey, lightBlue, red, purple } from "@mui/material/colors";

// import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import Preloader from "../../utils/PreloaderElement";
import { toast } from "react-toastify";

/* Servicios */
import MensajeService from "../../../services/Mensaje.service";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styles from "../layout/assets/css/core.min.module.css";

const MensajeDetalles = ({ id, data, onSubmit, statusDefault }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [mensaje, setMensaje] = useState(data);
  const [visible, setVisible] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: grey[500],
      },
      secondary: {
        main: lightBlue[400],
      },
      success: {
        main: "#536dfe",
      },
    },
  });

  useEffect(() => {
    loadMensaje();
  }, []);

  const visibleCheck = (valor) => {
    valor && leidoMensaje();
    setVisible(valor);
  };

  const loadMensaje = async () => {
    setLoading(true);
    try {
      const result = await MensajeService.getById(id);
      setMensaje(result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const estado = (state) => {
    if (state === "SinAbrir") {
      return (
        <>
          <ThemeProvider theme={theme}>
            <Badge
              badgeContent={"SinAbrir"}
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ marginTop: "5%", backgroundColor: "#686868" }}
            ></Badge>
          </ThemeProvider>
        </>
      );
    }
    if (state === "Leido") {
      return (
        <>
          <ThemeProvider theme={theme}>
            <Badge
              badgeContent={"Leído"}
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ marginTop: "5%" }}
            ></Badge>
          </ThemeProvider>
        </>
      );
    }
    if (state === "EnProceso") {
      return (
        <>
          <ThemeProvider theme={theme}>
            <Badge
              badgeContent={"EnProceso"}
              color="warning"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ marginTop: "5%" }}
            ></Badge>
          </ThemeProvider>
        </>
      );
    }
    if (state === "Cerrado") {
      return (
        <>
          <ThemeProvider theme={theme}>
            <Badge
              badgeContent={"Cerrado"}
              color="info"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ marginTop: "5%" }}
            ></Badge>
          </ThemeProvider>
        </>
      );
    }
    if (state === "Revisar") {
      return (
        <>
          <ThemeProvider theme={theme}>
            <Badge
              badgeContent={"Revisar"}
              color="error"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ marginTop: "5%" }}
            ></Badge>
          </ThemeProvider>
        </>
      );
    }
  };

  const terminarPago = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de terminar la venta?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Terminar",
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          mensaje.status = "Terminado";
          MensajeService.update(mensaje)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const leidoMensaje = async () => {
    try {
      if (mensaje.status === "SinAbrir") {
        mensaje.status = "Leido";
        await MensajeService.update(mensaje)
          .then((response) => {})
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const enprocesoMensaje = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de cambiar el estado a: EN PROCESO ?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Sí",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          mensaje.status = "EnProceso";
          MensajeService.update(mensaje)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          toast.success("Estado de mensaje actualizado.");
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const cerradoMensaje = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de cambiar el estado a: CERRADO ?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Sí",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          mensaje.status = "Cerrado";
          MensajeService.update(mensaje)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          toast.success("Estado de mensaje actualizado.");
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const revisarMensaje = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de cambiar el estado a: REVISAR ?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Sí",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          mensaje.status = "Revisar";
          MensajeService.update(mensaje)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          toast.success("Estado de mensaje actualizado.");
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const eliminarMensaje = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de eliminar el mensaje?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          MensajeService.remove(id)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });

          onSubmit();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {loading && mensaje != undefined ? (
        <Preloader />
      ) : (
        <tr key={id} style={{ borderBottom: "1px solid lightgray" }}>
          <th scope="row">{id}</th>
          <td>{mensaje.nombre}</td>
          <td>{mensaje.correo}</td>
          <td>{estado(mensaje.status)}</td>
          <td>
            <IconButton
              aria-label="ver"
              onClick={() => {
                visibleCheck(!visible);
              }}
              style={{ marginTop: "-4%" }}
            >
              <Visibility />
            </IconButton>
          </td>
          <td>
            <IconButton
              aria-label="delete"
              onClick={() => eliminarMensaje(id)}
              style={{ marginTop: "-4%" }}
            >
              <Delete />
            </IconButton>
          </td>
        </tr>
      )}
      <Modal
        open={visible}
        onClose={() => visibleCheck(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 100 }}
      >
        <TableContainer
          component={Paper}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "85%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            overflowY: "auto",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontWeight: "bold" }}>
                    Nombre:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {mensaje.nombre}
                    </span>
                  </p>
                  <button
                    style={{
                      border: "none",
                      padding: "0.5rem",
                    }}
                    type="button"
                    className={styles["btn-close"]}
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setVisible(false)}
                  ></button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p style={{ fontWeight: "bold" }}>
                    Email:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {mensaje.correo}
                    </span>
                  </p>
                  {mensaje.Telefono && (
                    <p style={{ fontWeight: "bold" }}>
                      Teléfono:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {mensaje.Telefono}
                      </span>
                    </p>
                  )}
                  {mensaje.asunto && (
                    <p style={{ fontWeight: "bold" }}>
                      Asunto:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {mensaje.asunto}
                      </span>
                    </p>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <textarea
                    value={mensaje?.MensajeCuerpo}
                    style={{
                      height: "357px",
                      width: "100%",
                      padding: "0.5rem",
                      border: "none",
                      resize: "none",
                    }}
                  ></textarea>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p style={{ fontWeight: "bold" }}>Estado mensaje: </p>

                  <div className="row">
                    <Button
                      style={{ width: "auto" }}
                      variant={
                        mensaje?.status === "EnProceso" ? "contained" : ""
                      }
                      color="warning"
                      onClick={() => enprocesoMensaje(id)}
                    >
                      En proceso
                    </Button>
                    <Button
                      aria-label="delete"
                      color="info"
                      style={{ width: "auto" }}
                      variant={mensaje?.status === "Cerrado" ? "contained" : ""}
                      onClick={() => cerradoMensaje(id)}
                    >
                      Cerrado
                    </Button>
                    <Button
                      variant={mensaje?.status === "Revisar" ? "contained" : ""}
                      color="error"
                      style={{ width: "auto" }}
                      onClick={() => revisarMensaje(id)}
                    >
                      Revisar
                    </Button>
                    {"  "}
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Modal>
      {/* <ThemeProvider theme={theme}>
        <CModal
          scrollable
          visible={visible}
          onClose={() => visibleCheck(false)}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "85%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            overflowY: "auto",
          }}
        >
          <CModalBody>
            
          </CModalBody>
        </CModal>
      </ThemeProvider> */}
    </>
  );
};

export default MensajeDetalles;
