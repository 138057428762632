import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TablePagination } from "@mui/material";

/*Servicios*/
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";

/*Componentes*/
import FullPageLoading from "../../../utils/FullPageLoading";
import { toast } from "react-toastify";

const Blog = ({ data }) => {
  const navegar = useNavigate();
  const [usuario, setUsuario] = useState({});
  const [imagen, setImagen] = useState({});
  const [autor, setAutor] = useState([]);
  const [fecha, setFecha] = useState("");

  const regex = /<[^>]'>/gim;
  const monthNames = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  useEffect(() => {
    setImagen("");
    window.scrollTo(0, 0);
    getImage();
    getAutor();
    getTiempo();
  }, []);

  const getAutor = async () => {
    try {
      const result = await UserService.getById(data.usuarioId);
      setAutor(result.nombre);
    } catch (error) {
      toast.error(error);
    }
  };

  const getImage = async () => {
    try {
      S3Service.get(data.imagen).then((dataimagen) => {
        document.getElementById(`imagen${data.imagen}`).src = dataimagen.result;
        setImagen(dataimagen.result);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getTiempo = async () => {
    try {
      //Tiempo
      const date = new Date(data.updateAt);

      // Obtener los componentes de fecha y hora
      const year = date.getFullYear();
      const monthIndex = date.getMonth();
      const day = ("0" + date.getDate()).slice(-2);
      // Formatear el texto de salida
      setFecha(`${day} de ${monthNames[monthIndex]} de ${year}`);
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const cortarTexto = () => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(data.cuerpoNoticia, "text/html");
    return doc.body.innerText;
  };

  return (
    <div className="col-md-4">
      <div className="card" style={{ height: "455px" }}>
        <div className="position-relative">
          <a
            href="javascript:void(0);"
            onClick={() => navegar(`/blog/${data.id}`)}
          >
            <img
              className="card-img-top"
              data-src="assets/img/blog/blog-img3.jpg"
              id={`imagen${data.imagen}`}
              src={imagen}
              alt="Card image"
              style={{ height: "200px", objectFit: "cover" }}
            />
          </a>
          <div className="card-img-overlay p-0">
            <span className="badge bg-danger badge-rounded m-4">
              {" "}
              <i className="far fa-calendar" aria-hidden="true" />
              {"  "}
              {fecha}
            </span>
          </div>
        </div>
        <div className="card-body border-top-5 px-3 border-danger">
          <h3 className="card-title">
            <a
              className="text-danger text-capitalize d-block text-truncate"
              href="javascript:void(0);"
              onClick={() => navegar(`/blog/${data.id}`)}
            >
              {data.titulo}
            </a>
          </h3>
          <ul className="list-unstyled d-flex flex-md-column flex-lg-row">
            <li className="me-2">
              <a
                className="text-muted"
                href="javascript:void(0);"
                onClick={() => navegar(`/blog/${data.id}`)}
              >
                <i className="fa fa-user me-2" aria-hidden="true" />
                {autor}
              </a>
            </li>
            <li className="me-2">
              <a
                className="text-muted"
                href="javascript:void(0);"
                onClick={() => navegar(`/blog/${data.id}`)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-tag-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
                {"   "}
                {data.categoria}
              </a>
            </li>
          </ul>
          <p
            dangerouslySetInnerHTML={{
              __html: String(cortarTexto()).substring(0, 150).concat("..."),
            }}
            style={{
              textAlign: "justify",
              height: "90px",
              overflow: "hidden",
              padding: "0",
              margin: "0",
            }}
          ></p>
          <a
            className="btn btn-link text-hover-danger ps-0"
            href="javascript:void(0);"
            onClick={() => navegar(`/blog/${data.id}`)}
          >
            <i className="fas fa-angle-double-right me-1" aria-hidden="true" />{" "}
            Leer más
          </a>
        </div>
      </div>
    </div>
  );
};

export default Blog;
