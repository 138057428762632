import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Typography } from "@material-ui/core";
import DropZone from "../../utils/DropZoneDos";
import ReCAPTCHA from "react-google-recaptcha";
import { validarContrasena } from "../../utils/Validator";
import TextField from "@mui/material/TextField";

import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";

const SignUpForm = () => {
  const google_apiKey = process.env.REACT_APP_GOOGLE_KEY_CAPTCHA;

  const [registro, setRegistro] = useState({
    contraseña: "",
    contraseñaConfirma: "",
  });
  const [file, setFile] = useState(undefined);
  const [valido, setValido] = useState(null);
  const [aceptar, setAceptar] = useState(false);
  const [loading, setLoading] = useState(false);
  const statusContrasena = registro.contraseña
    ? validarContrasena(registro.contraseña)
    : "";
  const contrasenaConincide =
    statusContrasena === "La contraseña es segura" && "success";
  const errorContrasena =
    statusContrasena !== "La contraseña es segura" && statusContrasena;

  const navigate = useNavigate();

  const registrarse = async () => {
    if (!registro.nombre) {
      toast.info("Error, tienes que agregar un nombre");
      return;
    }
    if (!registro.correo) {
      toast.info("Error, tienes que colocar un correo");
      return;
    }
    if (!file) {
      toast.info("Error, tienes que colocar una imagen");
      return;
    }
    if (!registro.contraseña) {
      toast.info("Error, tienes que colocar una contraseña");
      return;
    }
    if (!registro.contraseñaConfirma) {
      toast.info("Error, tienes que rellenar todos los campos");
      return;
    }
    let contrasenaValidada = validarContrasena(registro.contraseña);
    if (contrasenaValidada !== "La contraseña es segura") {
      toast.info(contrasenaValidada);
      return;
    }
    if (registro.contraseñaConfirma !== registro.contraseña) {
      toast.info("Error, las contraseñas no coinciden");
      return;
    }
    if (!valido) {
      toast.info("Error, no has comprobado tu identidad");

      let captchaVerify = document.getElementById("capchaId").children.length;
      if (captchaVerify < 1) {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      return;
    }
    if (!aceptar) {
      toast.info("Error, no has aceptado los términos de servicio");
      return;
    }

    setLoading(true);
    try {
      const registroSubir = {
        ...registro,
        nombre: registro.nombre,
        tipo: "usuario",
        area: "usuario",
      };

      if (file) {
        const uploadPhoto = await S3Service.upload(file);
        registroSubir.imagen = uploadPhoto.result.data;
        await UserService.create(registroSubir);
      } else {
        await UserService.create(registroSubir);
      }
      navigate("/");
      toast.success("Te has registrado con exito!");
    } catch (error) {
      toast.error(error.message ? error.message : "Error durante el registro");
    } finally {
      setLoading(false);
    }
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setValido(value);
  }

  return (
    <div className="col-sm-6 offset-lg-2 col-lg-4">
      <div className="mb-4 mb-sm-0">
        <div className="bg-primary rounded-top p-2">
          <h3 className="text-white font-weight-bold mb-0 ms-2">Registrarse</h3>
        </div>
        <div className="border rounded-bottom-sm border-top-0">
          <div className="p-3">
            <form action="#" method="POST" role="form">
              <div className="form-group form-group-icon">
                <div className="form-floating mb-3">
                  {file ? (
                    <div className="row">
                      <div className="col-lg-12">
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={URL.createObjectURL(file)}
                            style={{
                              height: "200px",
                              width: "200px",
                              objectFit: "contain",
                              width: "-webkit-fill-available",
                              borderRadius: "50%",
                              padding: "5%",
                            }}
                            alt=""
                          />
                          <Typography
                            variant="body1"
                            component="span"
                            style={{
                              width: "-webkit-fill-available",
                            }}
                          >
                            {file.name}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p style={{ width: "100%", textAlign: "center" }}>
                      Subir imagen de perfil
                    </p>
                  )}
                  <div style={{ width: "-webkit-fill-available" }}>
                    <div style={{ width: "100%" }}>
                      <DropZone
                        saveFile={async (file) => {
                          setFile(file);
                          var reader = new FileReader();
                          var url = reader.readAsDataURL(file);
                        }}
                        style={{ backgroundColor: "red" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group form-group-icon">
                <TextField
                  label="Nombre"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setRegistro({
                      ...registro,
                      nombre: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group form-group-icon">
                <TextField
                  label="Correo electrónico"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setRegistro({
                      ...registro,
                      correo: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group form-group-icon">
                <TextField
                  style={{ width: "100%" }}
                  error={errorContrasena}
                  color={contrasenaConincide}
                  label="Contraseña"
                  type="password"
                  defaultValue=""
                  helperText={statusContrasena}
                  onChange={(e) =>
                    setRegistro({
                      ...registro,
                      contraseña: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group form-group-icon">
                <TextField
                  style={{ width: "100%" }}
                  error={registro.contraseña !== registro.contraseñaConfirma}
                  label="Confirmar contraseña"
                  defaultValue=""
                  type="password"
                  helperText={
                    registro.contraseña !== registro.contraseñaConfirma
                      ? "Las contraseñas no coinciden"
                      : ""
                  }
                  onChange={(e) =>
                    setRegistro({
                      ...registro,
                      contraseñaConfirma: e.target.value,
                    })
                  }
                />
              </div>
              {/* GDPR CONSENT */}
              <div className="mb-3 border p-3 rounded">
                <div
                  className="small mb-3 pb-3 border-bottom text-dark"
                  style={{ overflow: "hidden" }}
                >
                  <ReCAPTCHA
                    id="capchaId"
                    sitekey={google_apiKey}
                    onChange={onChange}
                    style={{
                      borderRight: "1px solid lightgrey",
                      overflow: "hidden",
                    }}
                  />
                </div>
                <div className="form-check">
                  <input
                    required=""
                    className="form-check-input"
                    id="contact_gdpr"
                    name="contact_gdpr"
                    type="checkbox"
                    defaultValue={1}
                    onClick={() => {
                      setAceptar(!aceptar);
                    }}
                  />
                  <label className="form-check-label" htmlFor="contact_gdpr">
                    Acepto términos y condiciones{" "}
                    <button
                      className="link-normal text-primary"
                      style={{ border: "none", backgroundColor: "unset" }}
                      onClick={() => navigate(`/termsofservice`)}
                    >
                      (Términos y condiciones)
                    </button>
                    .
                  </label>
                </div>
              </div>
              {/* /GDPR CONSENT */}
            </form>
            <div className="form-group">
              <button
                className="btn btn-danger text-uppercase w-100"
                onClick={() => {
                  registrarse();
                }}
              >
                Registrarse
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
