/* React */
import React, { useState } from "react";

import Preloader from "../../utils/Preloader";

import styles from "../layout/assets/css/core.min.module.css";
import SectionCalendar from "./SectionCalendarM";

const DashboardTeacher = () => {
  const [loading, setLoading] = useState(false);

  return loading ? (
    <Preloader />
  ) : (
    <main
      id={styles["middle"]}
      className={`${styles.flexFill} ${styles.mxAuto}`}
    >
      <header>
        <h1 className={`${styles.h4}`}>Inicio</h1>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb} ${styles.small}`}>
            <li
              className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
              aria-current="page"
            >
              Inicio
            </li>
          </ol>
        </nav>
      </header>

      <SectionCalendar />

      {/* <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div
            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
          >
            Citas de {FormatDate(fechaCita)}
          </div>
          <div className={`${styles.section} ${styles.mb4}`}>
            <div className={`${styles.cardBody}`}>
              <div className={`${styles["m-4"]}`}>
                <div className={`${styles["py-4"]}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <div
                      className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                    ></div>
                    <div
                      className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
                    >
                      <a
                        href="javascript:void(0)"
                        className={`${styles.w100} ${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
                        onClick={() => navigate("/citas/nuevo")}
                      >
                        <svg
                          width="18px"
                          height="18px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={5} y1={12} x2={19} y2={12} />
                        </svg>
                        <span>Crear cita</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className={`${styles.tableResponsiveMd}`}>
                  <CustomPaginationActionsTableCitas
                    alumnoSelect={alumnoSelect}
                    onChangeAlumnoSelect={handleAlumnoSelect}
                    alumnos={alumnos}
                    page={page}
                    total={total}
                    rowsPerPage={limit}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </main>
  );
};

export default DashboardTeacher;
