import { useNavigate } from "react-router-dom";

const Title = ({title}) => {
  const navigate = useNavigate();
  
  return (
    <div className="container py-5">
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb small">
            <li className="breadcrumb-item">
            <button style={{border:'none', backgroundColor:'unset'}} onClick={() => navigate(`/`)} >Inicio</button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            {title}
            </li>
        </ol>
        </nav>
        <h1 className="h2 fw-bold">{title}</h1>
    </div>
  )
}

export default Title