import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "./assets/css/core.min.module.css";
import NumbersIcon from "@mui/icons-material/Numbers";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Diversity3Icon from "@mui/icons-material/Group";
import InterestsIcon from "@mui/icons-material/Interests";
import logo from "./logo-corazon.png";
import MenuElement from "./MenuElement";
import MenuElementSubtitle from "./MenuElementSubtitle";
import FeedIcon from "@mui/icons-material/Feed";
import GroupsIcon from "@mui/icons-material/Groups";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BadgeIcon from "@mui/icons-material/Badge";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MenuElementSubText from "./MenuElementSubText";
import MenuElementSubTextEnd from "./MenuElementSubTextEnd";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";

const Slide = ({ stateMenu, changeStateMenu }) => {
  let navigate = useNavigate();

  const { logout } = useContext(AuthContext);
  const [active, setActive] = useState("");
  const matches = useMediaQuery("(min-width:1000px)");
  const matchessm = useMediaQuery("(min-width:700px)");

  const closeMenu = () => {
    let element;
    element = document.querySelector("#aside_responsive");
    element.style.display = "none";
  };

  const styleActive = (name) => {
    if (name === active) {
      return `${styles.navItem} ${styles.active}`;
    }
    return `${styles.navItem}`;
  };

  const salir = () => {
    logout();
    navigate("/");
  };

  const navegar = (direccion) => {
    if (direccion === "Dashboard") {
      navigate("/");
      setActive(direccion);
      return;
    }
    navigate(direccion);
    setActive(direccion);
    changeStateMenu("default");
  };

  useEffect(() => {
    let url = window.location.href;
    let urlArray = url.split("/");
    let path = urlArray[3].split("#");
    setActive(path[0]);
  }, [window.location.href]);

  return (
    <>
      {/* sidebar */}
      <aside
        id="aside_responsive"
        className={`aside-hide-xs ${styles.dFlex} ${styles.asideStart} ${styles.bgWhite} ${styles.shadowSm} ${styles.flexColumn} ${styles.px2} ${styles.hAuto}`}
        style={
          stateMenu === "open" && !matches
            ? !matchessm
              ? { margin: "auto", width: "100%" }
              : { margin: "auto" }
            : {}
        }
      >
        <div
          style={
            stateMenu === "open" && !matches
              ? {
                  textAlign: "center",
                  padding: "0.5rem",
                  backgroundColor: "lightgray",
                  width: "100%",
                }
              : { display: "none" }
          }
          onClick={() => changeStateMenu("default")}
        >
          Cerrar Menu
        </div>
        {/* sidebar : logo */}
        <div
          className={`${styles.py2} ${styles.px3} ${styles.mb3} ${styles.mt1}`}
        >
          <a href="javascript:void(0);" onClick={() => navigate("/")}>
            <img src={logo} width={200} height={90} alt="..." />
          </a>
        </div>
        {/* /sidebar : logo */}
        {/* sidebar : navigation */}
        <div
          className={`${styles.asideWrapper} ${styles.scrollableVertical} ${styles.scrollableStyledLight} ${styles.alignSelfBaseline}  ${styles.h100} ${styles.w100}`}
        >
          <nav
            className={`${styles.navDeep} ${styles.navDeepSm} ${styles.navDeepLight}`}
          >
            <ul className={`${styles.nav} ${styles.flexColumn}`}>
              <MenuElement
                nameMenu="Inicio"
                icon={
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className={"bi bi-house-fill"}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                    />
                  </svg>
                }
                activeMenu={active}
                url="/"
                navegar={navegar}
                roles={["Administrador", "Terapeuta", "Maestro"]}
              />
              <MenuElementSubtitle subtitle="Administración" />
              <MenuElement
                nameMenu="Usuarios"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-people"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                  </svg>
                }
                activeMenu={active}
                url="usuario"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Terapeutas"
                icon={<BadgeIcon fontSize="small" />}
                activeMenu={active}
                url="terapeutas"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Citas"
                icon={<FeedIcon fontSize="small" />}
                activeMenu={active}
                url="citas"
                navegar={navegar}
                roles={["Administrador", "Terapeuta"]}
              />

              <MenuElement
                nameMenu="Maestros"
                icon={<AssignmentIndIcon fontSize="small" />}
                activeMenu={active}
                url="maestros"
                navegar={navegar}
                roles={["Administrador"]}
              />

              <MenuElementSubText
                subtitle="Alumnos"
                roles={["Administrador", "Terapeuta", "Maestro"]}
              />
              <MenuElement
                nameMenu="Área terapéutica"
                icon={<Diversity3Icon fontSize="small" />}
                activeMenu={active}
                url="area-terapeutica"
                navegar={navegar}
                roles={["Administrador", "Terapeuta"]}
              />
              <MenuElement
                nameMenu="Área educativa"
                icon={<Diversity3Icon fontSize="small" />}
                activeMenu={active}
                url="area-educativa"
                navegar={navegar}
                roles={["Administrador", "Maestro"]}
              />
              <MenuElementSubTextEnd
                roles={["Administrador", "Terapeuta", "Maestro"]}
              />

              <MenuElement
                nameMenu="Reportes"
                icon={<AssessmentIcon fontSize="small" />}
                activeMenu={active}
                url="reportes"
                navegar={navegar}
                roles={["Administrador", "Maestro"]}
              />
              <MenuElement
                nameMenu="Planeaciones"
                icon={<AssignmentIcon fontSize="small" />}
                activeMenu={active}
                url="planeaciones"
                navegar={navegar}
                roles={["Administrador", "Maestro"]}
              />
              <MenuElement
                nameMenu="Materiales"
                icon={<HomeRepairServiceIcon fontSize="small" />}
                activeMenu={active}
                url="materiales"
                navegar={navegar}
                roles={["Administrador", "Maestro"]}
              />

              <MenuElementSubtitle
                subtitle="Pagina"
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Carrusel"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={styles["bi bi-images"]}
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                  </svg>
                }
                activeMenu={active}
                url="carousel"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Galería"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={styles["bi bi-images"]}
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                    <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                  </svg>
                }
                activeMenu={active}
                url="galeria"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Publicación"
                icon={<YouTubeIcon fontSize="small" />}
                activeMenu={active}
                url="publicacion"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Blog"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-blockquote-left"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm5 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm-5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm.79-5.373c.112-.078.26-.17.444-.275L3.524 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282c.024-.203.065-.37.123-.498a1.38 1.38 0 0 1 .252-.37 1.94 1.94 0 0 1 .346-.298zm2.167 0c.113-.078.262-.17.445-.275L5.692 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282a1.75 1.75 0 0 1 .118-.492c.058-.13.144-.254.257-.375a1.94 1.94 0 0 1 .346-.3z" />
                  </svg>
                }
                activeMenu={active}
                url="blog"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Servicios"
                icon={
                  <svg
                    width="18px"
                    height="18px"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3z"
                    />
                    <path d="M13 3V2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1z" />
                  </svg>
                }
                activeMenu={active}
                url="servicio"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElementSubtitle
                subtitle="Catálogos"
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Sub-Áreas"
                icon={<AccountTreeIcon fontSize="small" />}
                activeMenu={active}
                url="subAreas"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Grupos"
                icon={<GroupsIcon fontSize="small" />}
                activeMenu={active}
                url="grupos"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Categorías del Blog"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-table"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                  </svg>
                }
                activeMenu={active}
                url="categoriaBlog"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Mensajes"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-chat-left-text"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                  </svg>
                }
                activeMenu={active}
                url="mensajes"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Contadores"
                icon={<NumbersIcon fontSize="small" />}
                activeMenu={active}
                url="counters"
                navegar={navegar}
                roles={["Administrador"]}
              />

              <MenuElementSubtitle
                subtitle="Datos generales"
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Página de inicio"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-pencil-square"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                }
                activeMenu={active}
                url="index"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Nosotros"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-pencil-square"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                }
                activeMenu={active}
                url="nosotros"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Aviso de privacidad"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-pencil-square"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                }
                activeMenu={active}
                url="aviso"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Términos y condiciones"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-pencil-square"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                }
                activeMenu={active}
                url="terminos"
                navegar={navegar}
                roles={["Administrador"]}
              />
              <MenuElement
                nameMenu="Footer"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-pencil-square"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                }
                activeMenu={active}
                url="footer"
                navegar={navegar}
                roles={["Administrador"]}
              />
            </ul>
          </nav>
        </div>
        {/* /sidebar : navigation */}
        {/* sidebar : footer */}
        <div
          className={`${styles.dFlex} ${styles.alignSelfBaseline} ${styles.w100} ${styles.py3} ${styles.px3} ${styles.borderTop} ${styles.borderLight} ${styles.small}`}
        >
          <p
            className={`${styles.dInlineGrid} ${styles.gapAuto3} ${styles.mb0} ${styles.textMuted}`}
          >
            <span className="material-icons-sharp"> logout </span>
            <a
              href="javascrip:void(0);"
              onClick={() => salir()}
              className={`${styles.linkNormal} ${styles.textDashed}`}
            >
              Logout
            </a>
          </p>
        </div>
        {/* /sidebar : footer */}
      </aside>
      {/* /sidebar */}
    </>
  );
};
export default Slide;
