import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//servicio
import S3Service from "../../../services/S3.service";

const Servicio = ({ servicio, descuento }) => {
  const navigate = useNavigate();
  let precioFinal = 0;
  const [precio, setPrecio] = useState(0);
  const [imagenCard, setImagenCard] = useState();
  const [textoBody, setTexttoBody] = useState();

  let precios = 0;
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";

  if (descuento > 0) {
    const descuentoProps = descuento;
    const precioOriginal = precio;
    precioFinal = precioOriginal - 100 / (precioOriginal / descuentoProps);
  }

  useEffect(() => {
    if (servicio.imagenes.length > 0) {
      getImagen();
    }
    cortarTexto();
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(servicio.imagenes[0]);

      setImagenCard(result.result);
    } catch (error) {
      console.error(error);
    }
  };

  const cortarTexto = () => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(servicio.descripcion, "text/html");
    return doc.body.innerText;
  };

  return (
    <>
      <div className="col-sm-6 col-lg-3 col-xs-12">
        <div
          className="card"
          style={{
            height: "29rem",
            overflow: "hidden",
            display: "grid",
            gridTemplateRows: "35% 57% 8%",
          }}
        >
          <button
            onClick={() => navigate(`/servicios/${servicio.id}`)}
            className="position-relative"
            style={{
              height: "100%",
              width: "100%",
              backgroundImage: `url(${imagenCard})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              border: "none",
            }}
          >
            {/* <img
              className="card-img-top lazyload"
              data-src="assets/img/courses/courses-img2.jpg"
              src={imagenCard}
              alt="Card service"
              style={{ height: "11.6rem" }}
            /> */}
            <div className="card-img-overlay">
              <span
                className="badge bg-danger badge-rounded-circle"
                style={{
                  transform: "rotateZ(348deg)",
                  fontSize: "1.2rem",
                  position: "absolute",
                }}
              >
                ${servicio.precios[0]?.precio}
              </span>
            </div>
          </button>
          <div className="card-body border-top-5 px-3 border-success">
            <h3 className="card-title">
              <button
                className="text-bg-info text-capitalize d-block text-truncate"
                style={{
                  border: "none",
                  backgroundColor: "unset",
                  color: "#f0c24b",
                  width: "100%",
                  textAlign: "start",
                  marginInlineStart: "-0.5rem",
                }}
                onClick={() => navigate(`/servicios/${servicio.id}`)}
              >
                {servicio.titulo}
              </button>
            </h3>
            <ul className="list-unstyled text-muted">
              <li className="mb-1">
                <i className="far fa-calendar me-2" aria-hidden="true" />
                Edades: {servicio.edades} años
              </li>
              <li>
                <i className="far fa-clock me-2" aria-hidden="true" />
                Horarios: {servicio.horario}
              </li>
            </ul>
            <p
              style={{
                height: "122px",
                padding: "0",
                margin: "0",
                overflow: "hidden",
              }}
            >
              {" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: String(cortarTexto()).substring(0, 125).concat("..."),
                }}
                style={{
                  textAlign: "justify",
                  height: "50px",
                }}
              ></span>
            </p>
          </div>
          <div className="d-block">
            <button
              onClick={() => navigate(`/servicios/${servicio.id}`)}
              className="btn btn-link text-hover-success ps-2 ps-lg-0"
              style={{ border: "none", backgroundColor: "unset" }}
            >
              <i
                className="fas fa-angle-double-right me-1"
                aria-hidden="true"
              />{" "}
              Saber más
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Servicio;
