import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import DropZoneEdit from "../../DropZoneEdit";
import { MenuItem, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import Preloader from "../../utils/Preloader";
import styles from "../layout/assets/css/core.min.module.css";

import UserService from "../../../services/User.Service";
import S3Service from "../../../services/S3.service";

import { useFormik } from "formik";
import { mixed, object, string } from "yup";
import CustomSelectImagePerfil from "../../utils/CustomImageSelect/CustomSelectImagePerfil";

const userSchema = object({
  nombre: string("Escribe un nombre").required("El nombre es requerido"),
  tipo: string().oneOf(
    ["Administrador", "Maestro", "Terapeuta"],
    "Tipo de usuario invalido"
  ),
  correo: string()
    .email("Correo invalido")
    .required("El correo es requerido")
    .matches(
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      "Correo invalido"
    ),
  contrasena: string("Escribe una contraseña")
    .min(6, "La contraseña debe ser mayor a 6 caracteres")
    .required("La contraseña es requerida")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
      "La contraseña debe tener al menos una mayúscula, una minúscula y un número para ser segura"
    ),
  area: string().required(),
  file: mixed()
    .required("Requiere un imagen")
    .test("fileFormat", "Archivos permitidos: jpg, jpeg, png", (value) => {
      if (value) {
        const supportedFormats = ["jpg", "jpeg", "png"];
        return supportedFormats.includes(value.name.split(".").pop());
      }
      return true;
    })
    .test("fileSize", "La imagen debe ser menor a 3MB", (value) => {
      if (value) {
        return value.size <= 3145728;
      }
      return true;
    }),
});

const userInitialValues = {
  nombre: "",
  tipo: "Administrador",
  correo: "",
  contrasena: "",
  area: "N/A",
  file: undefined,
};

const CrearCarrusel = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: userInitialValues,
    validationSchema: userSchema,
    onSubmit: (values) => {
      saveUsuario(values);
    },
  });

  const saveUsuario = async (usuario) => {
    setLoading(true);
    try {
      const datosUsuario = {
        nombre: usuario.nombre,
        tipo: usuario.tipo,
        correo: usuario.correo,
        contraseña: usuario.contrasena,
        area: usuario.area,
      };

      if (usuario.file) {
        const uploadPhoto = await S3Service.upload(usuario.file);
        datosUsuario.imagen = uploadPhoto.result.data;
        await UserService.create(datosUsuario);
      } else {
        await UserService.create(datosUsuario);
      }

      toast.success("Usuario creado con éxito!");
      if (datosUsuario.tipo === "Terapeuta") {
        navigate("/terapeutas");
      } else if (datosUsuario.tipo === "Maestro") {
        navigate("/maestros");
      } else {
        navigate("/usuario");
      }
    } catch (error) {
      toast.error(error.message ? error.message : error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(formik.errors).length >= 1 && formik.dirty) {
      toast.info("Revisar los campos obligatorios");
    }
  }, [formik.submitCount]);

  return loading ? (
    <Preloader />
  ) : (
    <main
      id={styles["middle"]}
      className={`${styles.flexFill} ${styles.mxAuto}`}
    >
      <header>
        <h1 className={`${styles.h4}`}>Nuevo usuario</h1>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb} ${styles.small}`}>
            <li className={`${styles.breadcrumbItem}`}>
              <button
                style={{
                  border: "none",
                  backgroundColor: "unset",
                  color: "#9b242c",
                }}
                onClick={() => navigate("/usuario")}
              >
                usuarios
              </button>
            </li>
            <li
              className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
              aria-current="page"
            >
              Nuevo
            </li>
          </ol>
        </nav>
      </header>
      <form className="form-validate">
        <div className={`${styles.row} ${styles.g4}`}>
          <div className={`${styles.col}`}>
            <div
              className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
            >
              Usuario
            </div>
            <div className={`${styles.section} ${styles.mb4}`}>
              <div className={`${styles.cardBody}`}>
                <CustomSelectImagePerfil
                  file={formik.values.file}
                  handleFile={async (file) => {
                    formik.setFieldValue("file", file);
                  }}
                  errors={formik.errors.file}
                />
                <div className={`${styles.row} ${styles.g3}`}>
                  <div className={`${styles.colSm6}`}>
                    <div className={`${styles.formFloating}`}>
                      <TextField
                        fullWidth
                        id="nombre"
                        name="nombre"
                        label="Nombre"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.nombre && Boolean(formik.errors.nombre)
                        }
                        helperText={
                          formik.touched.nombre && formik.errors.nombre
                        }
                      />
                    </div>
                  </div>
                  <div className={`${styles.colSm6}`}>
                    <TextField
                      id="tipo"
                      name="tipo"
                      label="Tipo"
                      select
                      fullWidth
                      value={formik.values.tipo}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                      helperText={formik.touched.tipo && formik.errors.tipo}
                    >
                      <MenuItem value="Administrador">Administrador</MenuItem>
                      <MenuItem value="Terapeuta">Terapeuta</MenuItem>
                      <MenuItem value="Maestro">Maestro</MenuItem>
                    </TextField>
                  </div>
                  <div className={`${styles.colSm6}`}>
                    <div className={`${styles.formFloating}`}>
                      <TextField
                        fullWidth
                        id="correo"
                        name="correo"
                        label="Correo"
                        value={formik.values.correo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.correo && Boolean(formik.errors.correo)
                        }
                        helperText={
                          formik.touched.correo && formik.errors.correo
                        }
                      />
                    </div>
                  </div>
                  <div className={`${styles.colSm6}`}>
                    <div className={`${styles.formFloating}`}>
                      <TextField
                        fullWidth
                        id="contrasena"
                        name="contrasena"
                        label="contraseña"
                        type="password"
                        value={formik.values.contrasena}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.contrasena &&
                          Boolean(formik.errors.contrasena)
                        }
                        helperText={
                          formik.touched.contrasena && formik.errors.contrasena
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className={`${styles.co12}`}>
        <div
          className={`${styles.cardFooter} ${styles.borderLight}`}
          style={{ textAlign: "right" }}
        >
          <button
            className={`${styles.btn} ${styles.btnPrimary}`}
            onClick={() => formik.submitForm()}
          >
            <svg
              width="18px"
              height="18px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            {"  "}
            <span className={`${styles.small}`}>Añadir</span>
          </button>
        </div>
      </div>
    </main>
  );
};

export default CrearCarrusel;
