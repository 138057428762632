import httpClient from "./HttpClient";
const prefix = "/count";

export default class CountService {
  static async create(count) {
    return (await httpClient.post(`${prefix}/`, count)).data;
  }

  static async update(count) {
    return (await httpClient.put(`${prefix}/${count.id}`, count)).data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

  static async list(limit, offset) {
    return (await httpClient.get(`${prefix}/${limit}/${offset}`)).data;
  }
}
