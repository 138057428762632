import { useState } from "react";
import { createContext } from "react";

export const AlumnoContext = createContext();

const AlumnoProvider = ({ children }) => {
  const [currentAlumno, setCurrentAlumno] = useState();

  const handleCurrentAlumno = async (alumno) => {
    setCurrentAlumno(alumno);
  };

  const removeAlumno = async () => {
    setCurrentAlumno(null);
  };

  return (
    <AlumnoContext.Provider
      value={{
        currentAlumno,
        handleCurrentAlumno,
        removeAlumno,
      }}
    >
      {children}
    </AlumnoContext.Provider>
  );
};

export default AlumnoProvider;
