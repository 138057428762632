import styles from "../../layout/assets/css/core.min.module.css";

import {
  Box,
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Drawer from "@mui/material/Drawer";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

const SectionAcademico = ({ academico, handleAcademico }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const [nuevaAcademia, setNuevaacademia] = useState({});
  const [academicoText, setAcademicoText] = useState("");

  const toggleDrawerAdd = (newOpen) => () => {
    setOpenAdd(newOpen);
  };

  const agregarElemento = () => {
    if (
      !nuevaAcademia?.nombre ||
      !nuevaAcademia?.periodo ||
      !nuevaAcademia?.motivoSalida
    ) {
      toast.info("Rellena todos los campos.");
      return;
    }

    let oldAcademias = academico?.historial || [];
    let index = oldAcademias.findIndex((aca) =>
      aca.uuid
        ? aca.uuid === nuevaAcademia?.uuid
        : nuevaAcademia.uuid === aca?.nombre + "-" + aca?.periodo
    );

    if (index > -1) {
      oldAcademias[index] = {
        nombre: nuevaAcademia.nombre,
        periodo: nuevaAcademia.periodo,
        motivoSalida: nuevaAcademia.motivoSalida,
        uuid: nuevaAcademia.uuid
          ? nuevaAcademia.uuid
          : nuevaAcademia?.nombre + "-" + nuevaAcademia?.periodo,
      };
    } else {
      oldAcademias.push({
        nombre: nuevaAcademia.nombre,
        periodo: nuevaAcademia.periodo,
        motivoSalida: nuevaAcademia.motivoSalida,
        uuid: nuevaAcademia.uuid
          ? nuevaAcademia.uuid
          : nuevaAcademia?.nombre + "-" + nuevaAcademia?.periodo,
      });
    }
    
    handleAcademico({ ...academico, historial: oldAcademias });
    setNuevaacademia({});
    setOpenAdd(false);
  };

  const handleAcademicoText = (event, newText) => {
    handleAcademico({ ...academico, academicoText: event.target.value });
    setAcademicoText(newText);
  };

  const deleteAcademia = (nombre) => {
    let oldAcademias = academico?.historial?.filter(
      (academia) => academia.nombre !== nombre
    );
    handleAcademico({ ...academico, historial: oldAcademias });
  };

  const editAcademia = (academia) => {
    setNuevaacademia({
      nombre: academia?.nombre,
      periodo: academia?.periodo,
      motivoSalida: academia?.motivoSalida,
      uuid: academia.uuid
        ? academia.uuid
        : academia?.nombre + "-" + academia?.periodo,
    });
    toggleDrawerAdd(true)();
  };

  return (
    <div className={`${styles.row} ${styles.colSmm12}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          Historial académico
        </div>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            <div className={`${styles["my-2"]} ${styles["col-sm-12"]}`}>
              <Typography>
                ¿Ha estado en alguna otra institución/escuela/fundación antes?
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={handleAcademicoText}
                value={academico?.academicoText}
              />
            </div>
            <Drawer
              anchor="right"
              open={openAdd}
              onClose={toggleDrawerAdd(false)}
            >
              <Box sx={{ width: 250, margin: 2 }}>
                <Typography>Agregar historial academico</Typography>
                <TextField
                  fullWidth
                  label="Nombre"
                  variant="standard"
                  placeholder="..."
                  onChange={(e) =>
                    setNuevaacademia({
                      ...nuevaAcademia,
                      nombre: e.target.value,
                    })
                  }
                  value={nuevaAcademia.nombre}
                />
                <TextField
                  fullWidth
                  label="Periodo"
                  variant="standard"
                  placeholder="..."
                  onChange={(e) =>
                    setNuevaacademia({
                      ...nuevaAcademia,
                      periodo: e.target.value,
                    })
                  }
                  value={nuevaAcademia.periodo}
                />
                <TextField
                  fullWidth
                  label="Motivo de salida"
                  variant="standard"
                  placeholder="..."
                  onChange={(e) =>
                    setNuevaacademia({
                      ...nuevaAcademia,
                      motivoSalida: e.target.value,
                    })
                  }
                  value={nuevaAcademia.motivoSalida}
                />
                <div className={`${styles.co12} ${styles["my-5"]}`}>
                  <div
                    className={`${styles.cardFooter} ${styles.borderLight}`}
                    style={{ textAlign: "right" }}
                  >
                    <button
                      className={`${styles.btn} ${styles.btnPrimary}`}
                      onClick={() => setOpenAdd(false)}
                    >
                      <span className={`${styles.small}`}>Cancelar</span>
                    </button>
                    <button
                      className={`${styles.btn} ${styles.btnPrimary}`}
                      onClick={() => agregarElemento()}
                    >
                      <svg
                        width="18px"
                        height="18px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12" />
                      </svg>
                      {"  "}
                      <span className={`${styles.small}`}>Agregar</span>
                    </button>
                  </div>
                </div>
              </Box>
            </Drawer>

            <div className={`${styles["my-2"]} ${styles["col-sm-12"]}`}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Institución / Escuela / Fundación</TableCell>
                    <TableCell>Periodo de estancia en la institución</TableCell>
                    <TableCell>¿Cuál fue el motivo de su salida?</TableCell>
                    <TableCell sx={{ width: 10 }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {academico?.historial?.map((academia, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{academia.nombre}</TableCell>
                      <TableCell>{academia.periodo}</TableCell>
                      <TableCell>{academia.motivoSalida}</TableCell>
                      <TableCell sx={{ width: 10 }}>
                        <div style={{ display: "flex" }}>
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => editAcademia(academia)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => deleteAcademia(academia.nombre)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>

            <div className={`${styles.co12}`}>
              <div
                className={`${styles.cardFooter} ${styles.borderLight}`}
                style={{ textAlign: "right" }}
              >
                <Button color="error" onClick={toggleDrawerAdd(true)}>
                  <Typography
                    sx={{
                      color: "#9b242c",
                      paddingInline: "0.5rem",
                    }}
                  >
                    Agregar
                  </Typography>
                  <AddCircleIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionAcademico;
