import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import DatosCliente from "./DatosCliente";
import AntecedentesPerinatales from "./AntecedentesPerinatales";
import FactoresDesarrollo from "./FactoresDesarrollo";
import AntecedentesMedicos from "./AntecedentesMedicos";
import DesarrolloSicomotor from "./DesarrolloSicomotor";
import DesarrolloLenguaje from "./DesarrolloLenguaje";
import SocioAfectivo from "./SocioAfectivo";
import Conductual from "./Conductual";
import AntecedentesHeredofamiliares from "./AntecedentesHeredofamiliares";
import Socioeconomico from "./Socioeconomico";
import HistorialAcademico from "./HistorialAcademico";

// Create styles
const styles = StyleSheet.create({
  page: {
    margin: 10,
    fontSize: 7,
  },
  footer: {
    marginTop: 2,
    textAlign: "center",
  },
  table: {
    // display: "table",
    width: "100%",
    borderStyle: "dashed",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    borderStyle: "dashed",
    borderColor: "#7b818d",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableRowsub: {
    flexDirection: "row",
    paddingTop: 4,
    paddingBottom: 2,
  },
  tableCol: {
    width: "50%",
  },
  tableCell: {
    marginTop: 1,
  },
  tableColA: {
    width: "29%",
  },
  tableColindex: {
    width: "12%",
  },
  logo: {
    height: 10,
    backgroundColor: "#9b242c",
    width: "100%",
    color: "#ffffff",
    paddingLeft: 10,
    paddingTop: 1,
    marginBottom: 10,
  },
});
// Create Document Component
const MyDocument = ({ historial }) => {
  return (
    <Document pdfVersion="1.7">
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View style={styles.logo}>
          <Text>{`Fundación corazón down <<juntos nos fortalecemos>>`}</Text>
        </View>
        <DatosCliente alumno={historial?.alumno} />

        <View style={styles.tableRowsub} wrap={true}>
          <Text style={{ width: "100%", textAlign: "center" }}>
            Historial clínico
          </Text>
        </View>
        <View style={styles.table} wrap={true}>
          {/* header */}
          <View style={styles.tableRow} wrap={false}>
            <View style={[styles.tableCol, styles.headerCol]}>
              <Text style={[styles.tableCell]}>Antecedentes Perinatales</Text>
            </View>
            <View style={[styles.tableCol, styles.headerCol]}>
              <Text style={[styles.tableCell]}>Factores de Desarrollo</Text>
            </View>
          </View>
          {/* body */}
          <View style={styles.tableRow} wrap={false}>
            <View style={styles.tableCol}>
              <AntecedentesPerinatales
                data={historial?.antecedentesPerinatales}
              />
            </View>
            <View style={styles.tableCol}>
              <FactoresDesarrollo data={historial?.factoresDesarrollo} />
            </View>
          </View>

          <View style={styles.tableRow} wrap={false}>
            <View style={[styles.tableCol, styles.headerCol]}>
              <Text style={[styles.tableCell]}>Antecedentes médicos</Text>
            </View>
            <View style={[styles.tableCol, styles.headerCol]}>
              <Text style={[styles.tableCell]}>Desarrollo psicomotor</Text>
            </View>
          </View>

          <View style={styles.tableRow} wrap={false}>
            <View style={styles.tableCol}>
              <AntecedentesMedicos data={historial?.antecedentesMedicos} />
            </View>
            <View style={styles.tableCol}>
              <DesarrolloSicomotor data={historial?.desarrolloSicomotor} />
            </View>
          </View>

          <View style={styles.tableRow} wrap={false}>
            <View style={[styles.tableCol, styles.headerCol]}>
              <Text style={[styles.tableCell]}>Desarrollo del lenguaje</Text>
            </View>
            <View style={[styles.tableCol, styles.headerCol]}>
              <Text style={[styles.tableCell]}>Socio – afectivo</Text>
            </View>
          </View>

          <View style={styles.tableRow} wrap={false}>
            <View style={styles.tableCol}>
              <DesarrolloLenguaje data={historial?.desarrolloLenguaje} />
            </View>
            <View style={styles.tableCol}>
              <SocioAfectivo data={historial?.socioAfectivo} />
            </View>
          </View>

          <View style={styles.tableRow} wrap={false}>
            <View style={[styles.tableCol, styles.headerCol]}>
              <Text style={[styles.tableCell]}>Conductual</Text>
            </View>
            <View style={[styles.tableCol, styles.headerCol]}>
              <Text style={[styles.tableCell]}>
                Antecedentes heredofamiliares
              </Text>
            </View>
          </View>

          <View style={styles.tableRow} wrap={false}>
            <View style={styles.tableCol}>
              <Conductual data={historial?.conductual} />
            </View>
            <View style={styles.tableCol}>
              <AntecedentesHeredofamiliares
                data={historial?.antecedentesHeredofamiliares}
              />
            </View>
          </View>

          <View style={styles.tableRow} wrap={false}>
            <View style={[styles.tableCol, styles.headerCol]}>
              <Text style={[styles.tableCell]}>Socioeconómico</Text>
            </View>
            <View style={[styles.tableCol, styles.headerCol]}>
              <Text style={[styles.tableCell]}></Text>
            </View>
          </View>
          <View style={styles.tableRow} wrap={false}>
            <View style={styles.tableCol}>
              <Socioeconomico data={historial?.socioEconomico} />
            </View>
            <View style={styles.tableCol}></View>
          </View>

          <View
            style={[styles.tableRowsub, styles.tableRow, { width: "100%" }]}
          >
            <View style={[styles.headerCol]}>
              <Text
                style={[
                  styles.tableCell,
                  { width: "100%", textAlign: "center", marginTop: 2 },
                ]}
              >
                Historial académico
              </Text>
            </View>
          </View>
          <View wrap={false}>
            <HistorialAcademico data={historial?.historialAcademico} />
          </View>

          <View style={styles.table} wrap={true}>
            {/* header */}
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.tableColindex, styles.headerCol]}>
                <Text style={[styles.tableCell]}>No.</Text>
              </View>
              <View style={[styles.tableColA, styles.headerCol]}>
                <Text style={[styles.tableCell]}>
                  Institución / Escuela / Fundación
                </Text>
              </View>
              <View style={[styles.tableColA, styles.headerCol]}>
                <Text style={[styles.tableCell]}>
                  Periodo de estancia en la institución
                </Text>
              </View>
              <View style={[styles.tableColA, styles.headerCol]}>
                <Text style={[styles.tableCell]}>
                  ¿Cuál fue el motivo de su salida?
                </Text>
              </View>
            </View>
            {/* body */}
            {!historial?.historialAcademico?.historial && (
              <View style={styles.tableRow} wrap={false}>
                <View style={styles.tableColindex}></View>
                <View style={styles.tableColA}>
                  <Text>N/A</Text>
                </View>
                <View style={styles.tableColA}></View>
                <View style={styles.tableColA}></View>
              </View>
            )}
            {historial?.historialAcademico?.historial?.map((item, index) => (
              <View style={styles.tableRow} wrap={false}>
                <View style={styles.tableColindex}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={styles.tableColA}>
                  <Text>{item.nombre}</Text>
                </View>
                <View style={styles.tableColA}>
                  <Text>{item.periodo}</Text>
                </View>
                <View style={styles.tableColA}>
                  <Text>{item.motivoSalida}</Text>
                </View>
              </View>
            ))}
          </View>

          <View style={styles.tableRowsub}>
            <View style={[styles.headerCol]}>
              <Text
                style={[
                  styles.tableCell,
                  { width: "100%", textAlign: "center", marginTop: 2 },
                ]}
              >
                Personas autorizadas para recoger al alumno (a)
              </Text>
            </View>
          </View>

          <View style={styles.table} wrap={true}>
            {/* body */}
            {!historial?.alumno?.personas && (
              <View style={styles.tableRow} wrap={false}>
                <View style={styles.tableColindex}></View>
                <View style={styles.tableColA}>
                  <Text>N/A</Text>
                </View>
                <View style={styles.tableColA}></View>
                <View style={styles.tableColA}></View>
              </View>
            )}
            {historial?.alumno?.personas?.map((item, index) => (
              <View style={styles.tableRow} wrap={false}>
                <View style={styles.tableColA}>
                  <Text>{item.nombre}</Text>
                </View>
                <View style={styles.tableColindex}>
                  <Text>{item.parentesco}</Text>
                </View>
                <View style={styles.tableColA}>
                  <Text>{"Edad: " + item.edad}</Text>
                  <Text>{"Correo: " + item.correo}</Text>
                </View>
                <View style={styles.tableColA}>
                  <Text>
                    {item.telefonoCasa ? "Casa: " + item.telefonoCasa : "-"}
                  </Text>
                  <Text>
                    {item.telefonoCelular
                      ? "Celular: " + item.telefonoCelular
                      : "-"}
                  </Text>
                  <Text>
                    {item.telefonoTrabajo
                      ? "Trabajo: " + item.telefonoTrabajo
                      : "-"}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <View style={[styles.tableRowsub]}>
          <Text
            style={{
              width: "100%",
              textAlign: "center",
              paddingTop: 2,
              color: "#c2c2c2",
            }}
          >
            Fundación corazón down
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
