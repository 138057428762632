export const validarCorreo = (correo) => {
  // letras, números, puntos, guiones y guiones bajos
  // seguidos de un arroba, luego un dominio con al menos dos caracteres, y finalmente una extensión con 2 a 4 caracteres
  var regex = /^[a-z0-9_.-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;
  // Comprobar si el correo cumple la expresión regular
  if (regex.test(correo)) {
    // Devolver verdadero si el correo es válido
    return true;
  } else {
    // Devolver falso si el correo no es válido
    return false;
  }
};

export const validarContrasena = (contrasena) => {
  // Crear la expresión regular
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[;:.,!¡?¿@#$%^&\-*_+=()[\]{}])\S{8,}$/;
  // Crear un objeto con los mensajes de error
  const mensajes = {
    longitud: "La contraseña debe tener al menos 8 caracteres",
    minuscula: "La contraseña debe tener al menos una letra minúscula",
    mayuscula: "La contraseña debe tener al menos una letra mayúscula",
    numero: "La contraseña debe tener al menos un número",
    simbolo:
      "La contraseña debe tener al menos un símbolo especial ;:.,!¡?¿@#$%^&\\-*_+=()[\\]{}]",
    espacio: "La contraseña no debe tener espacios en blanco",
    desconocido: "La contraseña no es válida por algún motivo desconocido",
    valido: "La contraseña es segura",
  };
  // Comprobar si la contraseña cumple la expresión regular
  const valido = regex.test(contrasena);
  // Devolver el mensaje correspondiente según el resultado
  return valido
    ? mensajes.valido
    : contrasena.length < 8
    ? mensajes.longitud
    : !contrasena.match(/[a-z]/)
    ? mensajes.minuscula
    : !contrasena.match(/[A-Z]/)
    ? mensajes.mayuscula
    : !contrasena.match(/\d/)
    ? mensajes.numero
    : !contrasena.match(/[;:.,!¡?¿@#$%^&\-*_+=()[\]{}]/)
    ? mensajes.simbolo
    : contrasena.match(/\s/)
    ? mensajes.espacio
    : mensajes.desconocido;
};

export const validarEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};

export const validarNumeroTelefono = (numero) => {
  const numeroLimpio = numero.replace(/\D/g, "");

  if (numeroLimpio.length !== 10) {
    return false;
  }
  return true;
};

export const validarDireccion = (direccion) => {
  return /.{1,}/.test(direccion);
};

export const validarContrasenaSimple = (contrasena) => {
  const longitudValida = /.{6,}/.test(contrasena);
  const sinEspacios = !/\s/.test(contrasena);
  const contieneNumeros = /\d/.test(contrasena); // Agregamos esta condición para verificar si hay al menos un número

  if (longitudValida && sinEspacios && contieneNumeros) {
    return true;
  } else {
    return false;
  }
};

// Función flecha para validar un nombre completo
export const validarNombreCompleto = (nombre) => {
  // Verificar longitud mínima y máxima (por ejemplo, entre 5 y 50 caracteres)
  const longitudValida = /.{1,50}/.test(nombre);

  // Verificar espacios en blanco al principio o al final
  const sinEspaciosExtremos = /^[^\s].*[^\s]$/.test(nombre);

  // Verificar caracteres alfabéticos y espacios (opcionalmente)
  const caracteresValidos = /^[a-zA-Z\s-]+$/.test(nombre);

  // Comprobar si cumple con los criterios
  if (longitudValida && sinEspaciosExtremos && caracteresValidos) {
    console.log("El nombre completo es válido.");
    return true;
  } else {
    console.log("El nombre completo no cumple con los criterios.");
    return false;
  }
};
