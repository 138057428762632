import httpClient from "./HttpClient";
const prefix = "/comentario";

export default class ComentarioService {
  static async create(comentario) {
    return (await httpClient.post(`${prefix}/`, comentario)).data;
  }

  static async update(comentario) {
    return (await httpClient.put(`${prefix}/${comentario.id}`, comentario))
      .data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async getByIdBlog(id) {
    return (await httpClient.get(`${prefix}-blog/${id}`)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

  static async list(limit, offset) {
    return (await httpClient.get(`${prefix}/${limit}/${offset}`)).data;
  }
}
