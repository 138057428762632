import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({});

const HistorialAcademico = ({ data }) => {
  return (
    <View style={styles.container} wrap={true}>
      <View style={styles.row} wrap={false}>
        <Text
          style={[styles.text]}
        >{`¿Ha estado en alguna otra institución/escuela/fundación antes?`}</Text>
      </View>
      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`${
          data?.academicoText ? data?.academicoText : "-"
        }`}</Text>
      </View>
    </View>
  );
};

export default HistorialAcademico;
