import { useState } from "react";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";

import { toast } from "react-toastify";
import styles from "../layout/assets/css/core.min.module.css";

const FormCategoria = ({
  createCategoria,
  showFormCategoria,
  updateCategoria,
  categoria,
}) => {
  const [newCategoria, setNewCategoria] = useState(categoria.tipo);

  const handleNewCategoria = (event) => {
    setNewCategoria(event.target.value);
  };

  const saveCategoria = () => {
    const newTipo = {
      tipo: newCategoria,
    };
    if (!newTipo.tipo) {
      toast.info("Error, tienes que colocar nombre a la categoria.");
    }

    if (!categoria.id) {
      createCategoria(newTipo);
    } else {
      updateCategoria({ ...newTipo, id: categoria.id });
    }

    setNewCategoria("");
  };

  return (
    <div
      className={styles.formControl + " " + styles["animate-slidein"]}
      style={{ position: "absolute", zIndex: "1", maxWidth: "21rem" }}
    >
      <div className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}>
        <div
          className={`${styles.positionRelative} ${styles.dFlex} ${styles.alignItemsCenter}`}
        >
          <div className={`${styles.dropdown} ${styles.w100}`}>
            <input
              id="inputNameCount"
              className={`${styles.formControl} ${styles.shadowNone} ${styles.formControlSm} ${styles.border0} ${styles.ps5} ${styles.bgGray100}`}
              value={newCategoria}
              onChange={handleNewCategoria}
              placeholder="Categoria"
            />
          </div>
        </div>
      </div>
      {/* options */}
      <div
        className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
      >
        <button
          onClick={() => saveCategoria()}
          className={`${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
        >
          <svg
            width="18px"
            height="18px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1={12} y1={5} x2={12} y2={19} />
            <line x1={5} y1={12} x2={19} y2={12} />
          </svg>
          Guardar
        </button>
        <button
          id="calcelNewCountButton"
          className={`${styles.btn} ${styles.btnSm} ${styles["btn-secondary"]}`}
          onClick={showFormCategoria}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default FormCategoria;
