/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

/* Components & dependencies */
import DropZoneEdit from "../../DropZoneEdit";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";
/*Services */
import CarruselService from "../../../services/Carrusel.service";
import S3Service from "../../../services/S3.service";

import styles from "../layout/assets/css/core.min.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";

const CrearCarrusel = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(undefined);
  const [carrusel, setCarrusel] = useState();
  const matches = useMediaQuery("(min-width:769px)");

  const crearItemCarrusel = async () => {
    setLoading(true);
    try {
      const dataCarrusel = carrusel;
      if (file) {
        const uploadPhoto = await S3Service.upload(file);
        dataCarrusel.imagen = uploadPhoto.result.data;
        await CarruselService.create(dataCarrusel);
        navigate("/carousel");
      } else {
        await CarruselService.create(dataCarrusel);
        navigate("/carousel");
      }
      toast.success("Item de carrusel creado con exito");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {/* PAGE TITLE */}
            <header>
              <h1 className={`${styles.h4}`}>Nuevo Item</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li className={`${styles.breadcrumbItem}`}>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "unset",
                        color: "#9b242c",
                      }}
                      onClick={() => navigate("/carousel")}
                    >
                      Carrusel
                    </button>
                  </li>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Nuevo
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className={`${styles.row} ${styles.g4}`}>
                <div className={`${styles.col}`}>
                  {/* Shipping address */}
                  <div
                    className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                  >
                    Imagen Item
                  </div>
                  <div className={`${styles.section} ${styles.mb4}`}>
                    <div className={`${styles.cardBody}`}>
                      <div className={`${styles.row} ${styles.g3}`}>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Título"
                              value={carrusel?.titulo}
                              onChange={(e) =>
                                setCarrusel({
                                  ...carrusel,
                                  titulo: e.target.value,
                                })
                              }
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              value={carrusel?.subtitulo}
                              onChange={(e) =>
                                setCarrusel({
                                  ...carrusel,
                                  subtitulo: e.target.value,
                                })
                              }
                              placeholder="Mensaje"
                            />
                            <label>Mensaje</label>
                          </div>
                        </div>
                        <div className={matches ? `${styles.col9}` : ""}>
                          <div
                            className={`${styles.formFloating} ${styles.my3}`}
                          >
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              value={carrusel?.url}
                              onChange={(e) =>
                                setCarrusel({
                                  ...carrusel,
                                  url: e.target.value,
                                })
                              }
                              placeholder="Url"
                            />
                            <label
                              className={`${styles.w100} ${styles.textTruncate}`}
                              htmlFor="shipping__address_1"
                            >
                              Url
                            </label>
                          </div>
                        </div>
                        <div className={matches ? `${styles.col3}` : ""}>
                          <div
                            className={`${styles.formFloating} ${styles.my3}`}
                          >
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              value={carrusel?.boton}
                              onChange={(e) =>
                                setCarrusel({
                                  ...carrusel,
                                  boton: e.target.value,
                                })
                              }
                              placeholder="Texto del botón. Ej:ver más"
                            />
                            <label
                              className={`${styles.w100} ${styles.textTruncate}`}
                              htmlFor="shipping__address_1"
                            >
                              Texto botón
                            </label>
                          </div>
                        </div>
                        {file ? (
                          <>
                            <div style={{ textAlign: "center" }}>
                              <img
                                src={URL.createObjectURL(file)}
                                style={{
                                  height: 300,
                                  objectFit: "contain",
                                  width: "-webkit-fill-available",
                                }}
                              />
                              <Typography
                                variant="body1"
                                component="span"
                                style={{
                                  width: "-webkit-fill-available",
                                }}
                              >
                                {file.name}
                              </Typography>
                            </div>
                          </>
                        ) : null}
                        <DropZoneEdit
                          saveFile={async (file) => {
                            setFile(file);
                            var reader = new FileReader();
                            var url = reader.readAsDataURL(file);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className={`${styles.co12}`}>
              <div
                className={`${styles.cardFooter} ${styles.borderLight}`}
                style={{ textAlign: "right" }}
              >
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => crearItemCarrusel()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>{" "}
                  {"  "}
                  <span className={`${styles.small}`}>Guardar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearCarrusel;
