import { useState } from "react";
import { createContext } from "react";

export const CitaContext = createContext();

const CitaProvider = ({ children }) => {
  const [currentCita, setCurrentCita] = useState();

  const handleCurrentCita = async (cita) => {
    setCurrentCita(cita);
  };

  const removeCita = async () => {
    setCurrentCita(null);
  };

  return (
    <CitaContext.Provider
      value={{
        currentCita,
        handleCurrentCita,
        removeCita,
      }}
    >
      {children}
    </CitaContext.Provider>
  );
};

export default CitaProvider;
