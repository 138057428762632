import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import Titulo from "./Titulo";
import Pagination from "./Pagination";
import BlogItem from "./BlogItem";
import BlogCard from "./BlogCard";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";
import NoticiaService from "../../../services/Blog.service";
import ItemBlog from "./ItemBlog";
import BlogTabs from "./BlogTabs";
import BlogCategorias from "./BlogCategorias";
import BlogTabsMovil from "./BlogTabsMovil";
import { TablePagination } from "@mui/material";
import ReactPaginate from "react-paginate";

//imagenes

const BlogGrid = () => {
  const [loading, setLoading] = useState(true);
  const [Entradas, setEntradas] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [precio, setPrecio] = useState("normal");
  const [categorias, setCategorias] = useState([]);
  const [tag, setTag] = useState(false);
  const [noticias, setNoticias] = useState([]);
  const [tabUno, setTabUno] = useState("active");
  const [tabDos, setTabDos] = useState("");
  const [tabUnoCuerpo, setTabUnoCuerpo] = useState("show active");
  const [tabDosCuerpo, setTabDosCuerpo] = useState("");
  const [buscar, setBuscar] = useState("");
  const [ultimasNoticias, setultimasNoticias] = useState([]);
  const navigate = useNavigate();
  const [filtroI, setFiltroI] = useState(false);

  useEffect(() => {
    let url = window.location.href;
    let urlArray = url.split("/");
    let path = urlArray[urlArray.length - 1].split("?");

    if (path.length === 2) {
      getEntradasFiltroCategoria(path[1]);
      setFiltroI(true);
    } else {
      getEntradas();
      setFiltroI(false);
    }

    AOS.init();
    getCategorias();
    getNoticias();
    getUltimasNoticias();
    window.scrollTo(0, 0);
  }, [page, limit]);

  const getEntradas = async () => {
    setLoading(true);
    setEntradas([]);
    try {
      const data = await NoticiaService.list(limit, page * limit);
      setEntradas(data.data);
      setTotal(data.total);
      setNumPages(Math.ceil(data.total / limit));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getEntradasFiltroCategoria = async (filtro) => {
    setLoading(true);
    setEntradas([]);
    try {
      console.log("el filtro es:" + filtro);
      const data = await NoticiaService.filtroCategoria(
        filtro,
        limit,
        page * limit
      );
      console.log("total: " + data.total);
      setEntradas(data.data);
      setTotal(data.total);
      setNumPages(Math.ceil(data.total / limit));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      console.log(Entradas);
    }
  };

  const getEntradasFiltroBuscar = async () => {
    setLoading(true);
    setEntradas([]);
    try {
      if (buscar === "") {
        const data = await NoticiaService.list(limit, page * limit);
        setEntradas(data.data);
        setTotal(data.total);
        setNumPages(Math.ceil(data.total / limit));
      } else {
        console.log("el texto del filtro es:" + buscar);
        const data = await NoticiaService.filtroBuscar(buscar);
        console.log("total: " + data.total);
        setEntradas(data.data);
        setTotal(data.total);
        setNumPages(Math.ceil(data.total / limit));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      console.log(Entradas);
    }
  };

  const getCategorias = async () => {
    setLoading(true);
    try {
      const data = await CategoriaBlogService.getAll();
      setCategorias(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(4, 0);
      setultimasNoticias(data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage.selected);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUltimasNoticias = async () => {
    try {
      const data = await NoticiaService.list(4, 0);
      setNoticias(data.data);
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      <div className="main-wrapper blog-grid-left-sidebar">
        {/* ====================================
  ———	BREADCRUMB
  ===================================== */}
        <section
          className="breadcrumb-bg"
          style={{
            backgroundImage: "url(assets/img/background/page-title-bg-img.jpg)",
            height: "250px",
          }}
        >
          <div className="container" style={{ height: "80px" }}>
            <div className="breadcrumb-holder" style={{ height: "80px" }}>
              <div>
                <h1 className="breadcrumb-title">BLOG</h1>
                <ul className="breadcrumb breadcrumb-transparent">
                  <li className="breadcrumb-item">
                    <button
                      className="text-white"
                      style={{ border: "none", backgroundColor: "unset" }}
                      onClick={() => navigate(`/`)}
                    >
                      Inicio
                    </button>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Blog
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* ====================================
———	BLOG GRID LEFT SIDEBAR
===================================== */}
        <section className="py-8 py-md-10">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-lg-9 order-md-1">
                <div className="row">
                  {Entradas.map((record, index) => {
                    return (
                      <BlogItem
                        key={index}
                        id={record.id}
                        titulo={record.titulo}
                        idAutor={record.usuarioId}
                        fechaU={record.updateAt}
                        categoria={record.categoria}
                        imagenes={record.imagen}
                        cuerpo={record.cuerpoNoticia}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="col-md-4 col-lg-3">
                <form className="" action="search-result.html">
                  <div className="card shadow-none bg-transparent">
                    <h3 className="card-header bg-primary font-weight-bold rounded-top text-white">
                      Buscar
                    </h3>
                    <div className="card-body border border-top-0 rounded-bottom">
                      <div className="input-group border-bottom pb-3 pt-4">
                        <input
                          type="text"
                          className="form-control border-0"
                          placeholder="Buscar..."
                          aria-describedby="basic-addon2"
                          onChange={(e) => {
                            setBuscar(e.target.value.toUpperCase());
                          }}
                          onKeyPress={(ev) => {
                            if (ev.key === "Enter") {
                              getEntradasFiltroBuscar();
                            }
                          }}
                        />
                        <span
                          className="input-group-addon"
                          id="basic-addon2"
                          style={{ width: "45%" }}
                        >
                          <input
                            className="btn btn-sm btn-primary text-uppercase text-white shadow-sm"
                            defaultValue="Search"
                            style={{ width: "100%" }}
                            onClick={() => {
                              getEntradasFiltroBuscar();
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="card shadow-none bg-transparent">
                  <h4 className="card-header font-weight-bold bg-success rounded-top text-white">
                    Categorías
                  </h4>
                  <div className="card-body border border-top-0 rounded-bottom">
                    <div
                      className="list-unstyled mb-0"
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      <a
                        className="badge-dark"
                        href="javascript:void(0);"
                        style={{
                          padding: "0.5rem",
                          fontSize: "inherit",
                          borderRadius: "2rem",
                          backgroundColor: "#f0c24b30",
                          margin: "0.2rem",
                          fontWeight: "600",
                        }}
                        onClick={() => {
                          navigate("/blog");
                          getEntradas();
                        }}
                      >
                        Todo
                      </a>
                      {categorias.map((record, index) => {
                        return (
                          <a
                            key={index}
                            href="javascript:void(0);"
                            className="badge-dark"
                            style={{
                              padding: "0.5rem",
                              fontSize: "inherit",
                              borderRadius: "2rem",
                              backgroundColor: "#f0c24b30",
                              margin: "0.2rem",
                              fontWeight: "600",
                            }}
                            onClick={() => {
                              getEntradasFiltroCategoria(record.tipo);
                              setTag(true);
                            }}
                          >
                            {record.tipo}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <h3 className="bg-purple rounded-top font-weight-bold text-white mb-0 py-2 px-4">
                    Últimas entradas
                  </h3>
                  <div className="border border-top-0 rounded">
                    <ul className="list-unstyled mb-0">
                      {ultimasNoticias.map((record, index) => {
                        return (
                          <ItemBlog
                            key={index}
                            id={record.id}
                            titulo={record.titulo}
                            idAutor={record.usuarioId}
                            fecha={record.createdAt}
                            categoria={record.categoria}
                            imagenes={record.imagen}
                            cuerpo={record.cuerpoNoticia}
                          />
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            {/* ==================================== PAGINATION ===================================== */}
            <section className="py-5">
              <div className="container">
                <nav aria-label="Page navigation example">
                  <nav aria-label="Page navigation example">
                    <ReactPaginate
                      nextLabel="next ►"
                      onPageChange={handleChangePage}
                      pageRangeDisplayed={5}
                      marginPagesDisplayed={4}
                      pageCount={numPages}
                      previousLabel="◄ previous"
                      pageClassName="page-item"
                      pageLinkClassName="d-sm-block m-sm-3 fs-5"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link page-item"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination justify-content-center align-items-center"
                      activeClassName="text-underline"
                      renderOnZeroPageCount={null}
                    />
                  </nav>
                </nav>
              </div>
            </section>
          </div>
        </section>
      </div>{" "}
      {/* element wrapper ends */}
    </>
  );
};

export default BlogGrid;
