import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import TherapistService from "../../../services/therapies/Therapist.service";
import { toast } from "react-toastify";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import S3Service from "../../../services/S3.service";

const TerapeutaSelect = ({ terapeuta, handleTerapeuta }) => {
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);

  const [terapeutas, setTerapeutas] = useState([]);
  const [value, setValue] = useState(null);

  const handleValue = (event, newValue) => {
    setValue(newValue);
    handleTerapeuta(newValue);
  };

  useEffect(() => {
    getTerapeutas();
  }, [update]);

  const getTerapeutas = async () => {
    setLoading(true);
    try {
      const data = await TherapistService.getAll();

      if (data) {
        data.map(async (element) => {
          const image = await S3Service.get(element?.imagen);
          element.url = image.result;
        });
        setTerapeutas(data);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };
  return !loading ? (
    <Autocomplete
      value={value}
      onChange={handleValue}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-terapeuta"
      options={terapeutas}
      getOptionLabel={(option) => {
        return option.nombre;
      }}
      renderOption={(props, option) => <li {...props}>{option.nombre}</li>}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label="Seleccionar terapeuta" />
      )}
    />
  ) : null;
};

export default TerapeutaSelect;
