import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import FooterService from "../../../services/Footer.service";
import useMediaQuery from "@mui/material/useMediaQuery";

const BannerContact = () => {
  const [info, setInfo] = useState({ undefined });
  const matches = useMediaQuery("(min-width:1200px)");
  const matchessm = useMediaQuery("(min-width:992px)");
  const styleElement = matches
    ? {
        justifyContent: "center",
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "26% 74%",
      }
    : matchessm
    ? {
        justifyContent: "center",
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "32% 68%",
      }
    : {
        alignItems: "center",
        flexDirection: "row !important",
        display: "grid",
        width: "100%",
        gridTemplateColumns: "20% 80%",
      };

  const styleElementT = matches
    ? {
        justifyContent: "center",
        alignItems: "center",
      }
    : matchessm
    ? {
        justifyContent: "center",
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "42% 58%",
      }
    : {
        alignItems: "center",
        flexDirection: "row !important",
        display: "grid",
        width: "100%",
        gridTemplateColumns: "20% 80%",
      };

  const getInfoContact = async () => {
    try {
      const foot = await FooterService.getAll();
      setInfo(foot[0]);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getInfoContact();
  }, []);

  return (
    <div className="mb-9">
      <div className="row" style={matchessm ? {} : { flexDirection: "column" }}>
        <div
          className={matchessm ? "col-sm-4 col-xs-12" : ""}
          style={
            matchessm
              ? { display: "flex", justifyContent: "center" }
              : { display: "flex", justifyContent: "flex-start" }
          }
        >
          <div
            className="media flex-md-column flex-lg-row"
            style={styleElement}
          >
            <div className="icon-rounded-circle-large shadow-sm mb-md-2 me-md-0 me-2 me-lg-2 bg-primary">
              <i
                className="fas fa-map-marker-alt text-white"
                aria-hidden="true"
              />
            </div>
            <div>
              <h3 className="media-heading text-primary mt-0 font-weight-bold">
                Dirección:
              </h3>
              <a
                className="text-color font-weight-medium"
                target="_blank"
                href={
                  info?.Direccion
                    ? `https://www.google.com/maps/search/?api=1&query=${info.Direccion.lat},${info.Direccion.long}5&zoom=20`
                    : "javascript:void(0);"
                }
              >
                {info?.Direccion?.Direccion}
              </a>
            </div>
          </div>
        </div>
        <div
          className={matchessm ? "col-sm-4 col-xs-12" : ""}
          style={
            matchessm
              ? { display: "flex", justifyContent: "center" }
              : {
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "0.5rem",
                }
          }
        >
          <div
            className="media flex-md-column flex-lg-row "
            style={styleElementT}
          >
            <div className="icon-rounded-circle-large shadow-sm me-2 mb-md-2 me-md-0 me-lg-2 bg-success">
              <i className="fas fa-phone-alt text-white" aria-hidden="true" />
            </div>
            <div>
              <h3 className="media-heading text-success mt-0 font-weight-bold">
                Teléfono:
              </h3>
              <a
                className="text-muted font-weight-medium"
                href={"tel:" + info?.Telefono}
              >
                {info?.Telefono}
              </a>
            </div>
          </div>
        </div>
        <div
          className={matchessm ? "col-sm-4 col-xs-12" : ""}
          style={
            matchessm
              ? { display: "flex", justifyContent: "center" }
              : {
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "0.5rem",
                }
          }
        >
          <div
            className="media flex-md-column flex-lg-row  mb-md-0"
            style={styleElement}
          >
            <div className="icon-rounded-circle-large shadow-sm me-2 mb-md-2 me-md-0 me-lg-2 bg-danger">
              <i className="far fa-envelope text-white" aria-hidden="true" />
            </div>
            <div>
              <h3 className="media-heading text-danger">Correo electrónico:</h3>
              <p className="font-weight-medium">
                <a className="text-muted" href={"mailTo:" + info?.Correo}>
                  {info?.Correo}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerContact;
