import { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import DashboardTherapies from "./DashboardTherapies";
import DashboardTeacher from "./DashboardTeacher";
import DashboardAdministrador from "./DashboardAdministrator";

const Dashboard = () => {
  const { currentUser } = useContext(AuthContext);

  return currentUser.tipo === "Terapeuta" ? (
    <DashboardTherapies />
  ) : currentUser.tipo === "Maestro" ? (
    <DashboardTeacher />
  ) : (
    <DashboardAdministrador />
  );
};

export default Dashboard;
