import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  table: {
    // display: "table",
    width: "100%",
    borderStyle: "dashed",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    borderStyle: "dashed",
    borderColor: "#7b818d",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "50%",
  },
  tableCell: {
    marginTop: 1,
  },
});

const AntecedentesPerinatales = ({ data }) => (
  <View style={styles.container} wrap={true}>
    <View style={styles.row} wrap={false}>
      <Text style={[styles.text]}>{`Semanas o meses que duró el embarazo: ${
        data?.duracionEmbarazo ? data?.duracionEmbarazo : "-"
      }`}</Text>
    </View>
    <View style={styles.row} wrap={false}>
      <Text style={[styles.text]}>{`Parto: ${
        data?.parto ? data?.parto : "-"
      }`}</Text>
      <Text></Text>
    </View>
    <View style={styles.row} wrap={false}>
      <Text style={[styles.text]}>{`Lloró al nacer: ${
        data?.lloroNacer ? data?.lloroNacer : "-"
      }`}</Text>
    </View>
    <View style={styles.row} wrap={false}>
      <Text style={[styles.text]}>{`Peso al nacer: ${
        data?.pesoNacimiento ? data?.pesoNacimiento : "-"
      }`}</Text>
    </View>
    <View style={styles.row} wrap={false}>
      <Text style={[styles.text]}>{`Midió: ${
        data?.tamano ? data?.tamano : "-"
      }`}</Text>
    </View>
    <View style={styles.row} wrap={false}>
      <Text style={[styles.text]}>{`Calificación APGAR: ${
        data?.apgar ? data?.apgar : "-"
      }`}</Text>
    </View>
    <View style={styles.row} wrap={false}>
      <Text style={[styles.text]}>{`Embarazo Número: ${
        data?.embarazoNumero ? data?.embarazoNumero : "-"
      }`}</Text>
    </View>
  </View>
);

export default AntecedentesPerinatales;
