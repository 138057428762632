import React from "react";

const PreloaderElement = () => {
  require("./utils.css");
  return (
    <div
      className="preloader"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "inherit",
      }}
    >
      <div className="lds-ripple">
        <div className="lds-pos"></div>
        <div className="lds-pos"></div>
      </div>
    </div>
  );
};

export default PreloaderElement;
