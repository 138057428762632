import styles from "../../layout/assets/css/core.min.module.css";

import { Box, Button, TextField, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const SectionFactoresDesarrollo = ({
  factoresDesarrollo,
  handleFactoresDesarrollo,
}) => {
  const handleTomoLechaMaterna = (event, value) => {
    handleFactoresDesarrollo({
      ...factoresDesarrollo,
      tomoLecheMaterna: value,
    });
  };

  const handleTomoFormula = (event, value) => {
    handleFactoresDesarrollo({
      ...factoresDesarrollo,
      tomoFormula: value,
    });
  };

  const handleTomoBiberon = (event, value) => {
    handleFactoresDesarrollo({
      ...factoresDesarrollo,
      tomoBiberon: value,
    });
  };

  return (
    <div className={`${styles.row} ${styles.colSmm12}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          Factores del desarrollo
        </div>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            <div className={`${styles["my-2"]} ${styles["col-md-2"]}`}>
              <Typography>Tomó leche materna:</Typography>
              <ToggleButtonGroup
                value={
                  factoresDesarrollo?.tomoLecheMaterna
                    ? factoresDesarrollo.tomoLecheMaterna
                    : ""
                }
                exclusive
                onChange={handleTomoLechaMaterna}
                aria-label="Platform"
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["col-md-10"]}`}>
              <TextField
                fullWidth
                variant="standard"
                placeholder="Especifique hasta que edad"
                disabled={factoresDesarrollo?.tomoLecheMaterna === "No"}
                onChange={(e) =>
                  handleFactoresDesarrollo({
                    ...factoresDesarrollo,
                    especificaiontomoLechaMaterna: e.target.value,
                  })
                }
                value={factoresDesarrollo?.especificaiontomoLechaMaterna}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm2"]}`}>
              <Typography>Tomó formula:</Typography>
              <ToggleButtonGroup
                value={
                  factoresDesarrollo?.tomoFormula
                    ? factoresDesarrollo.tomoFormula
                    : ""
                }
                exclusive
                onChange={handleTomoFormula}
                aria-label="Platform"
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["col-sm-10"]}`}>
              <TextField
                fullWidth
                variant="standard"
                placeholder="Especifique hasta que edad"
                disabled={factoresDesarrollo?.tomoFormula === "No"}
                onChange={(e) =>
                  handleFactoresDesarrollo({
                    ...factoresDesarrollo,
                    especificacintomoFormula: e.target.value,
                  })
                }
                value={factoresDesarrollo?.especificacintomoFormula}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm2"]}`}>
              <Typography>Tomo biberón:</Typography>
              <ToggleButtonGroup
                value={
                  factoresDesarrollo?.tomoBiberon
                    ? factoresDesarrollo.tomoBiberon
                    : ""
                }
                exclusive
                onChange={handleTomoBiberon}
                aria-label="Platform"
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["col-sm-10"]}`}>
              <TextField
                fullWidth
                variant="standard"
                placeholder="Especifique hasta que edad"
                disabled={factoresDesarrollo?.tomoBiberon === "No"}
                onChange={(e) =>
                  handleFactoresDesarrollo({
                    ...factoresDesarrollo,
                    especificacionTomoBiberon: e.target.value,
                  })
                }
                value={factoresDesarrollo?.especificacionTomoBiberon}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFactoresDesarrollo;
