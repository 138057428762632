import {
  Grid,
  ListItemText,
  IconButton,
  TextareaAutosize,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CardMedia,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
} from "@mui/material";
import { FormatDateTime } from "../../utils/Format";
import { useContext, useEffect, useState } from "react";
import styles from "../layout/assets/css/core.min.module.css";
import { toast } from "react-toastify";
import PlaneacionService from "../../../services/Planeacion.service";
import Preloader from "../../utils/PreloaderElement";
import DropComponent from "../imagesDrop";
import S3Service from "../../../services/S3.service";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faFilePdf, faFile } from "@fortawesome/free-solid-svg-icons";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import PreviewIcon from "@mui/icons-material/Preview";
import WarningIcon from "@mui/icons-material/Warning";
import Alumno from "./Alumno";
import Grupo from "./Grupo";
import { AuthContext } from "../../../context/AuthContext";

const Detalles = () => {
  const { id } = useParams();
  const today = new Date();
  const navigate = useNavigate();
  const { checkRole } = useContext(AuthContext);

  const [reporte, setReporte] = useState(null);
  const [imagenesCarrusel, setImagenesCarrusel] = useState([]);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [urlSelected, setUrlSelected] = useState("");
  const [elementTitle, setElementTitle] = useState("");

  const handleClickOpen = async (element) => {
    if (!element || !element.keyFile) {
      toast.info("No hay un archivo seleccionado.");
      return;
    }
    try {
      const result = await S3Service.get(element.keyFile);
      setUrlSelected((old) => result.result);
      setElementTitle((old) => element.name);
      setOpen(true);
    } catch (error) {
      toast.error("No hay un archivo disponible.");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const eliminarReporte = () => {
    console.log("eliminando");
    setLoading(true);

    try {
      Swal.fire({
        icon: "warning",
        title: "¿Esta seguro de eliminar planeación?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await PlaneacionService.remove(reporte?.id);
          toast.success("Planeación eliminada");
          navigate("/planeaciones");
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      toast.error("Error, no se ha eliminado");
      setLoading(false);
    }
  };

  useEffect(() => {
    getReporte();
  }, [id]);

  const getReporte = async () => {
    setLoading(true);

    try {
      const data = await PlaneacionService.getById(parseInt(id));
      if (data) {
        setReporte(data);
        setImagenesCarrusel((old) => data.archivos);
      }
    } catch (error) {
      toast.error("Error, no disponible.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <main
      id={styles["middle"]}
      className={`${styles.flexFill} ${styles.mxAuto}`}
    >
      <header>
        <h1 className={`${styles.h4}`}>Planeación</h1>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb} ${styles.small}`}>
            <li className={`${styles.breadcrumbItem}`}>
              <button
                style={{
                  border: "none",
                  backgroundColor: "unset",
                  color: "#9b242c",
                }}
                onClick={() => navigate("/planeaciones")}
              >
                Planeaciones
              </button>
            </li>
            <li
              className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
              aria-current="page"
            >
              Detalles
            </li>
          </ol>
        </nav>
      </header>
      <div className={`${styles.row}`}>
        <div className={`${styles.section} ${styles.mb1}`}>
          <div className={`${styles["card-body"]}`}>
            {loading ? (
              <Preloader />
            ) : (
              <Grid container rowGap={2}>
                <Dialog
                  open={open}
                  maxWidth="xl"
                  sx={{ height: "100%", width: "75%", left: "12.5%" }}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  fullScreen
                >
                  <DialogTitle id="alert-dialog-title">
                    {elementTitle}
                  </DialogTitle>
                  <DialogContent>
                    {open && (
                      <CardMedia
                        component="iframe"
                        src={urlSelected}
                        height="100%"
                      />
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                      Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
                <Grid xs={12}>
                  {reporte.tipo === "Individual" ? (
                    <Alumno alumno={reporte?.alumno} />
                  ) : (
                    <Grupo grupo={reporte?.grupo} />
                  )}
                </Grid>
                <Grid xs={12} md={8}>
                  <ListItemText
                    primary="Fecha:"
                    secondary={FormatDateTime(reporte?.fechaCreacion)}
                  />
                </Grid>
                <Grid xs={12} md={4}>
                  <ListItemText
                    primary="Ultima edición:"
                    secondary={FormatDateTime(reporte?.fechaEdicion)}
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <Typography typography="h4">{reporte?.titulo}</Typography>
                </Grid>
                <Grid xs={12} md={6}></Grid>
                <Grid xs={12} md={12}>
                  <Typography>Notas:</Typography>
                  <TextareaAutosize
                    minRows={10}
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      backgroundColor: "#ececec",
                    }}
                    contentEditable={false}
                    value={reporte?.notas}
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <Typography>Archivos:</Typography>

                  <div style={{ width: "100%" }}>
                    <List>
                      <Divider component="li" />
                      {imagenesCarrusel.length > 0 ? (
                        imagenesCarrusel.map((element, index) => {
                          return (
                            <ListItem
                              secondaryAction={
                                <IconButton
                                  onClick={() => handleClickOpen(element)}
                                >
                                  <PreviewIcon />
                                </IconButton>
                              }
                            >
                              <ListItemIcon>
                                <FontAwesomeIcon icon={faFilePdf} />
                              </ListItemIcon>
                              <ListItemText primary={element.name} />
                            </ListItem>
                          );
                        })
                      ) : (
                        <ListItem>
                          <ListItemIcon>
                            <WarningIcon />
                          </ListItemIcon>
                          <ListItemText primary="Sin elementos" />
                        </ListItem>
                      )}
                      <Divider component="li" />
                    </List>
                  </div>
                </Grid>
                <Grid xs={12}>
                  <>
                    <div
                      className={`${styles.cardFooter} ${styles.borderLight}`}
                      style={{ textAlign: "right" }}
                    >
                      <button
                        className={`${styles.btn} ${styles.btnPrimary}`}
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <span className={`${styles.small}`}>Regresar</span>
                      </button>
                    </div>
                    {checkRole(["Administrador"]) && (
                      <div
                        className={`${styles.cardFooter} ${styles.borderLight}`}
                        style={{ textAlign: "left" }}
                      >
                        <a
                          href="javascript:void(0"
                          onClick={eliminarReporte}
                          style={{ textDecoration: "underline" }}
                        >
                          Eliminar
                        </a>
                      </div>
                    )}
                  </>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Detalles;
