import styles from "../layout/assets/css/core.min.module.css";
import { TextField, Typography } from "@mui/material";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import WarningIcon from "@mui/icons-material/Warning";

import CustomDirectionInput from "../../utils/CustomImput/CustomDirectionInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { esES } from "@mui/x-date-pickers/locales";
import "dayjs/locale/es-mx";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import S3Service from "../../../services/S3.service";
import { formatDirection } from "../../utils/Format";

const DetallesAlumno = ({
  alumno,
  handleAlumno,
  fechaCita,
  handleFechacita,
}) => {
  let today = new Date();
  const [imageProfile, setImageProfile] = useState("");

  const [fechaNacimiento, setFechaNacimiento] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    }).format(today)
  );

  useEffect(() => {
    getAlumnoImage();
  }, [alumno]);

  const getAlumnoImage = async () => {
    try {
      if (alumno.imagen) {
        const image = await S3Service.get(alumno.imagen);
        setImageProfile(image.result);
      }
      setFechaNacimiento(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(new Date(alumno?.fechaNacimiento))
      );
    } catch (error) {
      toast.error(error.mensaje);
    }
  };

  return (
    <div className={`${styles.row} ${styles.g4}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          Alumno
        </div>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            {alumno?.id ? (
              <>
                <div className={`${styles["col-md-5"]}`}>
                  <div className={`${styles["my-4"]}`}>
                    {imageProfile && (
                      <div className={`${styles.colLg12}`}>
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={imageProfile}
                            style={{
                              height: 300,
                              objectFit: "contain",
                              width: "-webkit-fill-available",
                              maxWidth: "100%",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={`${styles["col-md-7"]}`}>
                  <div className={`${styles["my-4"]}`}>
                    <Typography typography="caption">
                      Nombre del alumno:{" "}
                    </Typography>
                    <Typography>{alumno?.nombre}</Typography>

                    <Typography typography="caption">
                      Fecha nacimiento:
                    </Typography>
                    <Typography>
                      {fechaNacimiento ? fechaNacimiento : "-"}
                    </Typography>
                  </div>
                  <div className={`${styles["my-4"]}`}>
                    <Typography typography="caption">Sexo:</Typography>
                    <Typography>{alumno?.sexo ? alumno.sexo : "-"}</Typography>
                  </div>
                  <div className={`${styles["my-4"]}`}>
                    {alumno?.grupo && (
                      <>
                        <Typography typography="caption">Grupo:</Typography>
                        <Typography>
                          {alumno?.grupo?.nombre ? alumno.grupo.nombre : "-"}
                        </Typography>
                        <Typography typography="caption">Area:</Typography>
                        <Typography>
                          {alumno?.grupo?.area?.tipo
                            ? alumno.grupo.area.tipo
                            : "-"}
                        </Typography>
                      </>
                    )}
                    {alumno?.subAreas[0] && (
                      <>
                        <Typography typography="caption">Sub-Áreas:</Typography>
                        <Typography>
                          {alumno?.subAreas?.map(
                            (element) => element.nombre + ", "
                          )}
                        </Typography>
                        <Typography typography="caption">Área:</Typography>
                        <Typography>
                          {alumno?.subAreas[0]?.area?.tipo}
                        </Typography>
                      </>
                    )}
                  </div>
                  <div className={`${styles["my-4"]}`}>
                    <Typography typography="caption">Domicilio:</Typography>
                    <Typography>
                      {alumno?.domicilio
                        ? formatDirection(alumno.domicilio)
                        : "-"}
                    </Typography>
                  </div>
                </div>
              </>
            ) : (
              <div className={`${styles["col-md-6"]}`}>
                <div className={`${styles["my-4"]}`}>
                  <Typography sx={{ color: "#9b242c", textAlign: "center" }}>
                    {" "}
                    <WarningIcon /> Alumno no seleccionado
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetallesAlumno;
