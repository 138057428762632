import React, { useEffect, useState, useCallback } from "react";
/*Servicios*/
import S3Service from "../../../services/S3.service";
import GaleriaService from "../../../services/PostVideo.service";
/*Componentes*/
import FullPageLoading from "../../../utils/FullPageLoading";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import YoutubeEmbed from "./YoutubeEmbed";

const Galeria = ({ subtitle }) => {
  const navigate = useNavigate();
  const [carrusels, setCarrusels] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
    AOS.init();
  }, []);

  const obtenerId = (url) => {
    if (!url) {
      return "";
    }
    return url.split("v=")[1];
  };

  const obtenerIdVideo = (url) => {
    if (!url) {
      return false;
    }
    try {
      let parts = new URL(url); // Crear un objeto URL a partir de la cadena
      if (parts.search) {
        // La URL tiene una cadena de consulta, como https://www.youtube.com/watch?v=jkQ41Xjn-K0
        let qs = new URLSearchParams(parts.search); // Crear un objeto URLSearchParams a partir de la cadena de consulta
        if (qs.has("v") && qs.get("v").length === 11) {
          // El parámetro v tiene el ID del video
          return qs.get("v");
        } else {
          // No se encontró el parámetro v
          return false;
        }
      } else if (parts.pathname) {
        // La URL tiene una ruta, como https://youtu.be/jkQ41Xjn-K0
        let path = parts.pathname.split("/").filter((p) => p); // Dividir la ruta por / y eliminar los elementos vacíos
        return path[0];
      } else {
        // La URL no tiene ni cadena de consulta ni ruta
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const data = await GaleriaService.getActive();
      setCarrusels(data.data);
      // for (const record of data.data) {
      //   const imagen = await S3Service.get(record.imagen);
      //   const paquete = {
      //     src: imagen.result,
      //     width: 4,
      //     height: 3,
      //     title: imagen.titulo,
      //   };
      //   setImagenes((imagenes) => [...imagenes, paquete]);
      // }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const buton = (url) => {
    return (
      <div className="text-center-xs">
        <a
          href={url}
          className="btn btn-primary bg-gradient scroll-to shadow-primary-xlg row-pill fw-medium d-inline-flex align-items-center px-4 mb-3"
        >
          <span className="me-1">Ir</span>
          <svg
            width={18}
            height={18}
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="bi bi-arrow-right-short"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
            />
          </svg>
        </a>
        <a
          href={url}
          target="_blank"
          rel="noopener nofollow"
          className="btn bg-light-hover btn-ghost row-pill fw-medium d-inline-flex align-items-center px-4 mb-3"
        >
          <span className="me-1">Ver</span>
          <svg
            width={18}
            height={18}
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="bi bi-arrow-right-short"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
            />
          </svg>
        </a>
      </div>
    );
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <section className="pt-9 pb-7 py-md-6" id="gallery_home">
      <div className="container">
        {/**Titulo */}
        <div className="section-title justify-content-center mb-4 mb-md-4 wow fadeInUp">
          <span className="shape shape-left bg-info" />
          <h2 className="text-danger">Nuestras publicaciones</h2>
          <span className="shape shape-right bg-info" />
        </div>
        <div
          className="font-size-18 mb-md-8 text-center"
          dangerouslySetInnerHTML={{
            __html: String(subtitle ? subtitle : ""),
          }}
        ></div>
        {/**Botónes 
        <div className="d-flex justify-content-center wow fadeInUp">
          <div id="filters" className="button-group">
            <button className="button is-checked" data-filter="*">
              Ver todo
            </button>
            <button className="button" data-filter=".charity">
              Lorem ipsum
            </button>
            <button className="button" data-filter=".nature">
              Lorem ipsum
            </button>
            <button className="button" data-filter=".children">
              Lorem ipsum
            </button>
          </div>
        </div>*/}
        {/**Galería */}
        <div id="gallery-grid">
          <div className="row grid wow fadeInUp">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                gap: "1rem",
              }}
            >
              {carrusels.map((item) => (
                <YoutubeEmbed embedId={obtenerIdVideo(item?.link)} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Galeria;
