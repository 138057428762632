import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import DatosCliente from "./DatosCliente";

import { FormatDateTime } from "../../../utils/Format";

// Create styles
const styles = StyleSheet.create({
  page: {
    margin: 10,
    fontSize: 7,
  },
  footer: {
    marginTop: 2,
    textAlign: "center",
  },
  table: {
    // display: "table",
    width: "100%",
    borderStyle: "dashed",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    borderStyle: "dashed",
    borderColor: "#7b818d",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableRowsub: {
    flexDirection: "row",
    paddingTop: 4,
    paddingBottom: 2,
  },
  tableCol: {
    width: "50%",
  },
  tableCell: {
    marginTop: 1,
  },
  tableColA: {
    width: "29%",
  },
  tableColindex: {
    width: "12%",
  },
  logo: {
    height: 10,
    backgroundColor: "#9b242c",
    width: "100%",
    color: "#ffffff",
    paddingLeft: 10,
    paddingTop: 1,
    marginBottom: 10,
  },
  rowStart: {
    borderTop: "1px solid #000",
    marginTop: 5,
  },
});
// Create Document Component
const MyDocument = ({ historial, alumno }) => {
  return (
    <Document pdfVersion="1.7">
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View style={styles.logo}>
          <Text>{`Fundación corazón down <<juntos nos fortalecemos>>`}</Text>
        </View>
        <DatosCliente alumno={alumno} />

        <View style={styles.tableRowsub} wrap={true}>
          <Text style={{ width: "100%", textAlign: "center" }}>
            Historial citas
          </Text>
        </View>
        <View style={styles.table} wrap={true}>
          {historial?.map((row, index) =>
            row.status === "Cancelada" ? (
              <>
                <View style={[styles.tableRow, styles.rowStart]} wrap={false}>
                  <View style={styles.tableCol}>
                    <Text>{FormatDateTime(row.date)}</Text>
                  </View>
                  <View style={styles.tableCol}></View>
                </View>
                <View style={styles.tableRow} wrap={false}>
                  <View style={styles.tableCol}>
                    <Text>{"Terapeuta: " + row.usuario?.nombre}</Text>
                  </View>
                  <View style={styles.tableCol}></View>
                </View>
                <View style={styles.tableRow} wrap={false}>
                  <View style={styles.tableCol}>
                    <Text>{row.reason ? "Motivo: " + row.reason : "-"}</Text>
                  </View>
                  <View style={styles.tableCol}></View>
                </View>
                <View style={styles.tableRow} wrap={false}>
                  <View style={styles.tableCol}></View>
                  <View style={styles.tableCol}>
                    <Text>{"Cita cancelada"}</Text>
                  </View>
                </View>
                <View style={styles.tableRow} wrap={false}>
                  <View style={styles.tableCol}>
                    <Text>{"Motivo cancelación:"}</Text>
                  </View>
                  <View style={styles.tableCol}></View>
                </View>
                <View style={[styles.tableRow]} wrap={false}>
                  <View style={styles.tableCol}>
                    <Text>
                      {row.additionalDetails?.motivoCancel
                        ? row.additionalDetails?.motivoCancel
                        : "Cita cancelada"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}></View>
                </View>
              </>
            ) : (
              <>
                <View style={[styles.tableRow, styles.rowStart]} wrap={false}>
                  <View style={styles.tableCol}>
                    <Text>{FormatDateTime(row.date)}</Text>
                  </View>
                  <View style={styles.tableCol}></View>
                </View>
                <View style={styles.tableRow} wrap={false}>
                  <View style={styles.tableCol}>
                    <Text>{"Terapeuta: " + row.usuario?.nombre}</Text>
                  </View>
                  <View style={styles.tableCol}></View>
                </View>
                <View style={styles.tableRow} wrap={false}>
                  <View style={styles.tableCol}>
                    <Text>{row.reason ? "Motivo: " + row.reason : "-"}</Text>
                  </View>
                  <View style={styles.tableCol}></View>
                </View>
                <View style={styles.tableRow} wrap={false}>
                  <View style={styles.tableCol}>
                    <Text>{"Objetivos trabajados:"}</Text>
                  </View>
                  <View style={styles.tableCol}></View>
                </View>
                <View style={styles.tableRow} wrap={false}>
                  <View style={styles.tableCol}></View>
                  <View style={styles.tableCol}>
                    {row.additionalDetails?.objectivesList?.map(
                      (item, index) => (
                        <Text>{item.label + " - " + item.estado}</Text>
                      )
                    )}
                    {row.additionalDetails?.objectivesList?.length === 0 && (
                      <Text>{"- "}</Text>
                    )}
                  </View>
                </View>
                <View style={styles.tableRow} wrap={false}>
                  <View style={styles.tableCol}>
                    <Text>{"Notas:"}</Text>
                  </View>
                  <View style={styles.tableCol}></View>
                </View>
                <View style={[styles.tableRow]} wrap={false}>
                  <View style={styles.tableCol}>
                    <Text>
                      {row.additionalDetails?.notas
                        ? row.additionalDetails?.notas
                        : "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}></View>
                </View>
              </>
            )
          )}
        </View>
        <View style={[styles.tableRowsub]}>
          <Text
            style={{
              width: "100%",
              textAlign: "center",
              paddingTop: 2,
              color: "#c2c2c2",
            }}
          >
            Fundación corazón down
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
