/* React */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { createTheme } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";

/* Servicios */
import S3Service from "../../../services/S3.service";
import AlumnoService from "../../../services/Alumno.service";
import AreaService from "../../../services/Area.service";
import AppointmentService from "../../../services/therapies/Appointment.service";

import styles from "../layout/assets/css/core.min.module.css";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import WarningIcon from "@mui/icons-material/Warning";
import HistorialCrear from "../historialClinico/Crear";
import CustomPaginationActionsTableCitas from "../../utils/CustomTable/CustomPaginationActionsTableCitas";
import { FormatDate } from "../../utils/Format";
import SectionCalendar from "./SectionCalendar";

const DashboardTherapies = () => {
  const { currentUser } = useContext(AuthContext);
  let today = new Date();

  const [alumnoSelect, setAlumnoSelect] = useState({});
  const [fechaCita, setFechaCita] = useState(today);
  const [duracion, setDuracion] = useState(1);
  const [motivoCita, setMotivoCita] = useState("Consulta");
  const [status, setStatus] = useState("Pendiente");
  const [detallesAdicionales, setDetallesAdicionales] = useState({});

  const [file, setFile] = useState(undefined);
  const [personas, setPersonas] = useState([]);
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState("N/A");

  const [historialClinico, setHistorialClinico] = useState({});

  const [openAddAuthorizedPerson, setOpenAddAuthorizedPerson] = useState(false);
  const navigate = useNavigate();

  const [alumnos, setAlumnos] = useState({});

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAlumnoSelect = (newAlumno) => {
    toast.info(`${newAlumno.nombre} fue seleccionad@.`);
    setAlumnoSelect((old) => newAlumno);
  };

  const handlePersonas = (newPersonas) => {
    setPersonas((old) => newPersonas);
  };

  const toggleDrawerAddAuthorizedPerson = (newOpen) => () => {
    setOpenAddAuthorizedPerson(newOpen);
  };

  const deletePersona = (nombre) => {
    let oldPersons = personas.filter((persona) => persona.nombre !== nombre);
    setPersonas(oldPersons);
  };

  const handleHistorialClinico = (newHistorialClinico) => {
    setHistorialClinico((old) => newHistorialClinico);
  };

  const handleArea = (event, newArea) => {
    setArea(newArea);
  };

  const handleFechaCita = (newFecha) => {
    setFechaCita(newFecha.toDate());
  };

  useEffect(() => {
    getAppointments();
  }, [page, limit, update, fechaCita]);

  const getAppointments = async () => {
    setLoading(true);
    try {
      const data = await AppointmentService.listSearchDate({
        limit: limit,
        offset: page * limit,
        date: fechaCita.toISOString(),
      });

      data.data.forEach(async (element) => {
        const image = await S3Service.get(element.alumno?.imagen);
        element.alumno.url = image.result;
      });

      if (data && data.data) {
        setAlumnos(data.data);
        setTotal(data.total);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Preloader />
  ) : (
    <main
      id={styles["middle"]}
      className={`${styles.flexFill} ${styles.mxAuto}`}
    >
      <header>
        <h1 className={`${styles.h4}`}>Inicio</h1>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb} ${styles.small}`}>
            <li
              className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
              aria-current="page"
            >
              Inicio
            </li>
          </ol>
        </nav>
      </header>

      <SectionCalendar />

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div
            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
          >
            Citas de {FormatDate(fechaCita)}
          </div>
          <div className={`${styles.section} ${styles.mb4}`}>
            <div className={`${styles.cardBody}`}>
              <div className={`${styles["m-4"]}`}>
                <div className={`${styles["py-4"]}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <div
                      className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                    ></div>
                    <div
                      className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
                    >
                      {/* <a
                        href="javascript:void(0)"
                        className={`${styles.w100} ${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
                        onClick={() => navigate("/citas/nuevo")}
                      >
                        <svg
                          width="18px"
                          height="18px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={5} y1={12} x2={19} y2={12} />
                        </svg>
                        <span>Crear cita</span>
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className={`${styles.tableResponsiveMd}`}>
                  <CustomPaginationActionsTableCitas
                    alumnoSelect={alumnoSelect}
                    onChangeAlumnoSelect={handleAlumnoSelect}
                    alumnos={alumnos}
                    page={page}
                    total={total}
                    rowsPerPage={limit}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DashboardTherapies;
