import styles from "../../layout/assets/css/core.min.module.css";

import { Box, Button, TextField, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState } from "react";

const SectionAntecendentes = ({ antecedentes, handleAntecedentes }) => {
  const handleMaternos = (event, value) => {
    handleAntecedentes({
      ...antecedentes,
      maternos: value,
    });
  };

  const handlePaternos = (event, value) => {
    handleAntecedentes({
      ...antecedentes,
      paternos: value,
    });
  };

  const handleMadre = (event, value) => {
    handleAntecedentes({
      ...antecedentes,
      madre: value,
    });
  };

  const handlePadre = (event, value) => {
    handleAntecedentes({
      ...antecedentes,
      padre: value,
    });
  };

  const handleHermanos = (event, value) => {
    handleAntecedentes({
      ...antecedentes,
      hermanos: value,
    });
  };

  return (
    <div className={`${styles.row} ${styles.colSmm12}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          Antecedentes heredofamiliares
        </div>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            <div className={`${styles["my-2"]} ${styles["col-sm-12"]}`}>
              <Typography>Padecen de enfermedades crónicas:</Typography>
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm2"]}`}>
              <Typography>Sus abuelos maternos:</Typography>
              <ToggleButtonGroup
                value={antecedentes?.maternos ? antecedentes.maternos : ""}
                exclusive
                aria-label="Platform"
                onChange={handleMaternos}
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["col-sm-10"]}`}>
              <Typography>Cual?</Typography>

              <TextField
                fullWidth
                sx={{ marginY: 2 }}
                variant="standard"
                placeholder="Cual?"
                disabled={antecedentes?.maternos === "No"}
                onChange={(e) =>
                  handleAntecedentes({
                    ...antecedentes,
                    especificacionMaternos: e.target.value,
                  })
                }
                value={antecedentes?.especificacionMaternos}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm2"]}`}>
              <Typography>Sus abuelos paternos:</Typography>
              <ToggleButtonGroup
                value={antecedentes?.paternos ? antecedentes.paternos : ""}
                exclusive
                aria-label="Platform"
                onChange={handlePaternos}
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["col-sm-10"]}`}>
              <Typography>Cual?</Typography>

              <TextField
                fullWidth
                sx={{ marginY: 2 }}
                variant="standard"
                placeholder="Cual?"
                disabled={antecedentes?.paternos === "No"}
                onChange={(e) =>
                  handleAntecedentes({
                    ...antecedentes,
                    especificacionPaternos: e.target.value,
                  })
                }
                value={antecedentes?.especificacionPaternos}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm2"]}`}>
              <Typography>Mamá:</Typography>
              <ToggleButtonGroup
                value={antecedentes?.madre ? antecedentes.madre : ""}
                exclusive
                aria-label="Platform"
                onChange={handleMadre}
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["col-sm-10"]}`}>
              <Typography>Cual?</Typography>

              <TextField
                fullWidth
                sx={{ marginY: 2 }}
                variant="standard"
                placeholder="Cual?"
                disabled={antecedentes?.madre === "No"}
                onChange={(e) =>
                  handleAntecedentes({
                    ...antecedentes,
                    especificacionMadre: e.target.value,
                  })
                }
                value={antecedentes?.especificacionMadre}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm2"]}`}>
              <Typography>Papá:</Typography>
              <ToggleButtonGroup
                value={antecedentes?.padre ? antecedentes.padre : ""}
                exclusive
                aria-label="Platform"
                onChange={handlePadre}
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["col-sm-10"]}`}>
              <Typography>Cual?</Typography>

              <TextField
                fullWidth
                sx={{ marginY: 2 }}
                variant="standard"
                placeholder="Cual?"
                disabled={antecedentes?.padre === "No"}
                onChange={(e) =>
                  handleAntecedentes({
                    ...antecedentes,
                    especificacionPadre: e.target.value,
                  })
                }
                value={antecedentes?.especificacionPadre}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm2"]}`}>
              <Typography>Hermanos:</Typography>
              <ToggleButtonGroup
                value={antecedentes?.hermanos ? antecedentes.hermanos : ""}
                exclusive
                aria-label="Platform"
                onChange={handleHermanos}
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["col-sm-10"]}`}>
              <Typography>Cual?</Typography>

              <TextField
                fullWidth
                sx={{ marginY: 2 }}
                variant="standard"
                placeholder="Cual?"
                disabled={antecedentes?.hermanos === "No"}
                onChange={(e) =>
                  handleAntecedentes({
                    ...antecedentes,
                    especificacionHermanos: e.target.value,
                  })
                }
                value={antecedentes?.especificacionHermanos}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionAntecendentes;
