import {
  Button,
  Grid,
  ListItemText,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { FormatDateTime } from "../../utils/Format";
import { useContext, useEffect, useState } from "react";
import { CitaContext } from "../../../context/CitaContext";
import CustomRatingSentiment from "../../utils/CustomRating/customRatingSentiment";
import styles from "../layout/assets/css/core.min.module.css";
import CustomList from "../../utils/CustomImput/CustomList";
import { toast } from "react-toastify";
import AppointmentService from "../../../services/therapies/Appointment.service";
import DialogCancelCita from "./DialogCancelCita";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import CustomListNoEdit from "../../utils/CustomImput/CustomListNoEdit";
import CustomRatingSentimentShow from "../../utils/CustomRating/customRatingSentimentShow";
import DialogEliminarCita from "./DialogEliminarCita";

const TabCita = () => {
  const navigate = useNavigate();
  const { checkRole } = useContext(AuthContext);

  const { currentCita, handleCurrentCita } = useContext(CitaContext);
  const [valoration, setValoration] = useState(5);
  const [objectivesList, setObjectivesList] = useState([]);
  const [comportamiento, setComportamiento] = useState(5);
  const [notas, setNotas] = useState("");

  const registerCita = async () => {
    try {
      let citaRegisterData = {
        id: currentCita.id,
        duration: 1,
        status: "Atendida",
        additionalDetails: {
          objectivesList,
          comportamiento,
          valoration,
          notas,
        },
      };

      await AppointmentService.update(citaRegisterData);
      toast.success("Observaciones registradas");

      navigate("/citas");
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (currentCita) {
      setValoration((old) => currentCita.additionalDetails?.valoration);
      setObjectivesList((old) =>
        currentCita.additionalDetails?.objectivesList
          ? currentCita.additionalDetails?.objectivesList
          : []
      );
      setComportamiento((old) => currentCita.additionalDetails?.comportamiento);
      setNotas((old) => currentCita.additionalDetails?.notas);
    } else {
      navigate("/citas");
    }
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid xs={12} md={8}>
          <ListItemText
            primary="Fecha cita:"
            secondary={FormatDateTime(currentCita?.date)}
          />
          <ListItemText primary="Motivo:" secondary={currentCita?.reason} />
        </Grid>
        {currentCita?.status === "Cancelada" ? (
          <>
            <Grid xs={12} md={4} textAlign="right">
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      display: "inline",
                      cursor: "pointer",
                      color: "#9b242c",
                    }}
                    component="span"
                    variant="caption"
                  >
                    Cita cancelada
                  </Typography>
                }
              />
            </Grid>
            <Grid xs={12} md={12}>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      display: "inline",
                      cursor: "pointer",
                      color: "#0009",
                    }}
                    component="span"
                    variant="caption"
                  >
                    Motivo cancelación:
                  </Typography>
                }
                secondary={
                  <Typography
                    sx={{
                      display: "block",
                      cursor: "pointer",
                      padding: "1rem",
                      marginY: "1rem",
                      border: "1px solid lightgray",
                    }}
                    component="p"
                    variant="caption"
                    color="black"
                  >
                    {currentCita.additionalDetails?.motivoCancel
                      ? currentCita.additionalDetails?.motivoCancel
                      : "Cita cancelada"}
                  </Typography>
                }
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid xs={12} md={4}>
              <ListItemText
                primary="Evaluación del progreso:"
                secondary={
                  currentCita?.status === "Pendiente" ? (
                    <CustomRatingSentiment
                      value={valoration}
                      handleValue={setValoration}
                    />
                  ) : (
                    <CustomRatingSentimentShow value={valoration} />
                  )
                }
              />
              <ListItemText
                primary="Comportamiento:"
                secondary={
                  currentCita?.status === "Pendiente" ? (
                    <CustomRatingSentiment
                      value={comportamiento}
                      handleValue={setComportamiento}
                    />
                  ) : (
                    <CustomRatingSentimentShow value={comportamiento} />
                  )
                }
              />
            </Grid>
            <Grid xs={12} md={6}>
              <ListItemText
                primary="Objetivos trabajados:"
                secondary={
                  currentCita?.status === "Pendiente" ? (
                    <CustomList
                      values={objectivesList}
                      handleValues={setObjectivesList}
                    />
                  ) : (
                    <CustomListNoEdit values={objectivesList} />
                  )
                }
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Typography>Notas:</Typography>
              {currentCita?.status === "Pendiente" ? (
                <TextareaAutosize
                  minRows={10}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    backgroundColor: "#ececec",
                  }}
                  value={notas}
                  onChange={(event) => {
                    event.preventDefault();
                    setNotas(event.target.value);
                  }}
                />
              ) : (
                <TextareaAutosize
                  minRows={10}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    backgroundColor: "#ececec",
                  }}
                  value={notas}
                  readOnly
                />
              )}
            </Grid>
            <Grid xs={12}>
              {currentCita?.status === "Pendiente" && (
                <>
                  <div
                    className={`${styles.cardFooter} ${styles.borderLight}`}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <DialogCancelCita currentCita={currentCita} />
                    <button
                      className={`${styles.btn} ${styles.btnPrimary}`}
                      onClick={() => registerCita()}
                    >
                      <span className={`${styles.small}`}>
                        Registrar observaciones
                      </span>
                    </button>
                  </div>
                </>
              )}
            </Grid>
          </>
        )}
        {currentCita?.status !== "Pendiente" &&
          checkRole(["Administrador"]) && (
            <div
              className={`${styles.cardFooter} ${styles.borderLight}`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <DialogEliminarCita currentCita={currentCita} />
            </div>
          )}
      </Grid>
    </Box>
  );
};

export default TabCita;
