/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../../utils/DropZone";
import Comentario from "./Comentario";
import styles from "../../layout/assets/css/core.min.module.css";

/* Servicios */
import ComentarioService from "../../../../services/Comentario.service";

const TablaComentarios = () => {
  const [mensajes, setMensajes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);

  const onUpdate = () => {
    setUpdate(!update);
  };

  useEffect(() => {
    getMensajes();
  }, [page, limit, update]);

  const getMensajes = async () => {
    setLoading(true);
    try {
      const data = await ComentarioService.list(limit, page * limit);
      setMensajes(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <main
          id={styles["middle"]}
          className={`${styles.flexFill} ${styles.mxAuto}`}
        >
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div style={{ display: "flex" }}>
                  <h4
                    className="card-title"
                    style={{ marginRight: "20px", marginTop: "6px" }}
                  >
                    Cometarios
                  </h4>
                </div>
              </div>
              <div className={`${styles.section} ${styles.p0}`}>
                <div className={`${styles.tableResponsiveMd}`}>
                  <table
                    className={`${styles.table}`}
                    style={{ marginLeft: "10px", width: "1000px" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Email</th>
                        <th scope="col"></th>
                        <th scope="col">Ver</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {mensajes.map((record, index) => {
                        return (
                          <Comentario
                            key={index}
                            id={record.id}
                            data={record}
                            onSubmit={onUpdate}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <TablePagination
                  style={{ color: "var(--color-dark-variant)" }}
                  component="div"
                  labelRowsPerPage="Items por página"
                  count={total}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default TablaComentarios;
