import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useState } from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Chip,
  Collapse,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { toast } from "react-toastify";
import CustomStatus from "./CustomStatus";

const CustomListNoEdit = ({ values, handleValues }) => {
  const [openAddElement, setOpenAddElement] = useState(false);
  const [inputElementValue, setInputElementValue] = useState("");
  const [estado, setEstado] = useState("Inicial");

  const handleOpenAddElement = () => {
    setOpenAddElement((old) => !old);
  };

  const addElement = () => {
    if (!inputElementValue) {
      toast.info("Debes nombrar el objetivo");
      return;
    }
    handleOpenAddElement();
    handleValues([
      ...values,
      { label: inputElementValue, value: 1, estado: estado },
    ]);
    setInputElementValue("");
    setEstado("Inicial");
  };

  const removeElement = (element) => {
    let valuesFilter = values.filter(
      (elementValue) => elementValue.label !== element.label
    );

    handleValues(valuesFilter);
  };

  const handleEstado = (event) => {
    console.info("You clicked the Chip.");
    console.log(event.target.textContent);
    setEstado(event.target.textContent);
  };

  return (
    <List
      dense
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
    >
      {values?.map((value, index) => {
        return (
          <>
            <Divider key={`${index}-divider`} component="li" />
            <ListItem key={index} disablePadding>
              <CustomStatus status={value.estado} />
              <Typography
                sx={{
                  display: "inline",
                  cursor: "pointer",
                  marginInline: "0.5rem",
                  color: "#000",
                }}
                component="p"
                variant="body2"
              >
                {`${value.label}`}
              </Typography>
            </ListItem>
          </>
        );
      })}
      {values?.length === 0 && (
        <ListItem disablePadding>
          <Typography
            sx={{
              display: "inline",
              cursor: "pointer",
              marginInline: "0.5rem",
              color: "#0009",
            }}
            component="p"
            variant="body2"
          >
            No hay objetivos
          </Typography>
        </ListItem>
      )}
    </List>
  );
};

export default CustomListNoEdit;
