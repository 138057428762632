import {
  Grid,
  ListItemText,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { FormatDateTime } from "../../utils/Format";
import { useEffect, useState } from "react";
import styles from "../layout/assets/css/core.min.module.css";
import { toast } from "react-toastify";
import ReporteService from "../../../services/Reporte.service";
import Preloader from "../../utils/PreloaderElement";
import DropComponent from "../filesDrop";
import S3Service from "../../../services/S3.service";
import { useNavigate, useParams } from "react-router-dom";
import Alumno from "./Alumno";
import Grupo from "./Grupo";

const Editar = () => {
  const { id } = useParams();

  const today = new Date();
  const navigate = useNavigate();

  const [reporte, setReporte] = useState(null);
  const [fechaCreacion, setFechaCreacion] = useState("1/1/2024");
  const [fechaEdicion, setFechaEdicion] = useState("1/1/2024");
  const [nota, setNota] = useState("");
  const [tituloreporte, setTituloReporte] = useState("");
  const [filesArray, setFilesArray] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);

  const [loading, setLoading] = useState(true);

  const actualizarCita = async () => {
    setLoading(true);

    try {
      if (!tituloreporte) {
        toast.info("El titulo es necesario.");
        return;
      } else if (!nota) {
        toast.info("Notas esta vacía.");
        return;
      }
      let citaRegisterData = {
        id: id,
        titulo: tituloreporte,
        notas: nota,
        fechaEdicion,
        archivos: [],
      };

      let imagenesArray = []; //Array para los id del s3
      for (const file of filesArray) {
        if (!file.keyFile) {
          const resultFile = await S3Service.upload(file);
          imagenesArray.push({
            keyFile: resultFile.result.data,
            name: file.name,
            size: file.size,
            type: file.type,
          });
        } else {
          imagenesArray.push({
            keyFile: file.keyFile,
            name: file.name,
            size: file.size,
            type: file.type,
          });
        }
      }

      citaRegisterData.archivos = imagenesArray;

      await ReporteService.update(citaRegisterData);
      toast.success("Reporte actualizado");
      await removeFiles();
      setTituloReporte("");
      setNota("");
      setFilesArray((old) => []);
      navigate("/reportes");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const removeFiles = async () => {
    try {
      for (const file of filesToDelete) {
        if (file.keyFile) {
          await S3Service.delete(file.keyFile);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getReporte();
    setFechaEdicion(today.toISOString());
  }, [id]);

  const getReporte = async () => {
    setLoading(true);

    try {
      const data = await ReporteService.getById(parseInt(id));
      if (data) {
        setFilesArray((old) => data.archivos);
        setTituloReporte((old) => data.titulo);
        setNota((old) => data.notas);
        setFechaCreacion((old) => data.fechaCreacion);
        setReporte(data);
      }
    } catch (error) {
      toast.error("Error, no disponible.");
    } finally {
      setLoading(false);
    }
  };

  const eliminarArchivo = async (archivo) => {
    try {
      if (archivo.keyFile) {
        setFilesToDelete((old) => [...old, archivo]);
      }
    } catch (error) {
      toast.error("Error,archivo no disponible.");
    }
  };

  return (
    <main
      id={styles["middle"]}
      className={`${styles.flexFill} ${styles.mxAuto}`}
    >
      <header>
        <h1 className={`${styles.h4}`}>Editar reporte</h1>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb} ${styles.small}`}>
            <li className={`${styles.breadcrumbItem}`}>
              <button
                style={{
                  border: "none",
                  backgroundColor: "unset",
                  color: "#9b242c",
                }}
                onClick={() => navigate("/reportes")}
              >
                Reportes
              </button>
            </li>
            <li
              className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
              aria-current="page"
            >
              Editar
            </li>
          </ol>
        </nav>
      </header>
      <div className={`${styles.row}`}>
        <div className={`${styles.section} ${styles.mb1}`}>
          <div className={`${styles["card-body"]}`}>
            {loading ? (
              <Preloader />
            ) : (
              <Grid container>
                <Grid xs={12}>
                  {reporte?.tipo === "Individual" ? (
                    <Alumno alumno={reporte?.alumno} />
                  ) : (
                    <Grupo grupo={reporte?.grupo} />
                  )}
                </Grid>
                <Grid xs={12} md={8}>
                  <ListItemText
                    primary="Fecha creación:"
                    secondary={FormatDateTime(fechaCreacion)}
                  />
                </Grid>
                <Grid xs={12} md={4}></Grid>
                <Grid xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="Titulo del reporte"
                    value={tituloreporte}
                    onChange={(event) => setTituloReporte(event.target.value)}
                  />
                </Grid>
                <Grid xs={12} md={6}></Grid>
                <Grid xs={12} md={12}>
                  <Typography>Notas:</Typography>
                  <TextareaAutosize
                    minRows={10}
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      backgroundColor: "#ececec",
                    }}
                    value={nota}
                    onChange={(event) => setNota(event.target.value)}
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <Typography>Archivos:</Typography>

                  <div style={{ width: "100%" }}>
                    <DropComponent
                      filesDefault={filesArray}
                      subirArchivo={(data) => setFilesArray(data)}
                      eliminarArchivo={eliminarArchivo}
                    />
                  </div>
                </Grid>
                <Grid xs={12}>
                  <>
                    <div
                      className={`${styles.cardFooter} ${styles.borderLight}`}
                      style={{ textAlign: "right" }}
                    >
                      <button
                        className={`${styles.btn} ${styles.btnPrimary}`}
                        onClick={() => {
                          actualizarCita();
                        }}
                      >
                        <span className={`${styles.small}`}>
                          Actualizar reporte
                        </span>
                      </button>
                    </div>
                  </>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Editar;
