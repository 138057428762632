import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({});

const AntecedentesHeredofamiliares = ({ data }) => {
  return (
    <View style={styles.container} wrap={true}>
      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Padecen de enfermedades crónicas `}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Sus abuelos maternos: ${
          data?.maternos ? data?.maternos : "-"
        }`}</Text>
      </View>
      {data?.maternos === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Cual?: ${
            data?.especificacionMaternos ? data?.especificacionMaternos : "-"
          }`}</Text>
        </View>
      )}

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Sus abuelos paternos: ${
          data?.paternos ? data?.paternos : "-"
        }`}</Text>
      </View>
      {data?.paternos === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Cual?: ${
            data?.especificacionPaternos ? data?.especificacionPaternos : "-"
          }`}</Text>
        </View>
      )}

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Mamá: ${
          data?.madre ? data?.madre : "-"
        }`}</Text>
      </View>
      {data?.madre === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Cual?: ${
            data?.especificacionMadre ? data?.especificacionMadre : "-"
          }`}</Text>
        </View>
      )}

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Papá: ${
          data?.padre ? data?.padre : "-"
        }`}</Text>
      </View>
      {data?.padre === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Cual?: ${
            data?.especificacionPadre ? data?.especificacionPadre : "-"
          }`}</Text>
        </View>
      )}

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Hermanos: ${
          data?.hermanos ? data?.hermanos : "-"
        }`}</Text>
      </View>
      {data?.hermanos === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Cual?: ${
            data?.especificacionHermanos ? data?.especificacionHermanos : "-"
          }`}</Text>
        </View>
      )}
    </View>
  );
};

export default AntecedentesHeredofamiliares;
