import styles from "./assets/css/core.min.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";

const MenuElementSubtitle = ({ subtitle, roles }) => {
  const { checkRole } = useContext(AuthContext);

  return !roles || checkRole(roles) ? (
    <li className={`${styles.navTitle} ${styles.mt4}`}>
      <h6
        className={`${styles.mb0} ${styles.smaller} ${styles.textMuted} ${styles.textUppercase}`}
      >
        {subtitle}
      </h6>
    </li>
  ) : null;
};

export default MenuElementSubtitle;
