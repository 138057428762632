import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Estilos personalizados para la tabla
const styles = StyleSheet.create({});

const Conductual = ({ data }) => {
  return (
    <View style={styles.container} wrap={true}>
      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Que le gusta hacer a su hijo (a): ${
          data?.actividadesPositivas ? data?.actividadesPositivas : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Que no le gusta hacer a su hijo (a): ${
          data?.actividadesNegadas ? data?.actividadesNegadas : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Se enoja con facilidad: ${
          data?.enojo ? data?.enojo : "-"
        }`}</Text>
      </View>
      {data?.enojo === "Si" && (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.text]}>{`Porque?: ${
            data?.pasatiempo ? data?.pasatiempo : "-"
          }`}</Text>
        </View>
      )}

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Su hijo (a) miente: ${
          data?.miente ? data?.miente : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Su hijo (a) tiene educación sexual: ${
          data?.educacionSexual ? data?.educacionSexual : "-"
        }`}</Text>
      </View>

      <View style={styles.row} wrap={false}>
        <Text style={[styles.text]}>{`Como describe a su hijo: ${
          data?.descripcion ? data?.descripcion : "-"
        }`}</Text>
      </View>
    </View>
  );
};

export default Conductual;
