import styles from "../../layout/assets/css/core.min.module.css";

import { Box, Button, TextField, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState } from "react";

const SectionSocioeconomico = ({
  socioEconomico,
  handleSocioEconomico,
  socioEconomicoVivienda,
  setSocioEconomicoVivienda,
}) => {
  const handleServicios = (event, value) => {
    handleSocioEconomico({
      ...socioEconomico,
      servicios: value,
    });
  };

  return (
    <div className={`${styles.row} ${styles.colSmm12}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          Socioeconómico
        </div>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Tipo de vivienda:</Typography>
              <TextField
                sx={{ marginY: 2 }}
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleSocioEconomico({
                    ...socioEconomico,
                    vivienda: e.target.value,
                  })
                }
                value={socioEconomico?.vivienda ? socioEconomico.vivienda : ""}
              />
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Cuenta con todos los servicios:</Typography>
              <ToggleButtonGroup
                value={
                  socioEconomico?.servicios ? socioEconomico.servicios : ""
                }
                exclusive
                aria-label="Platform"
                onChange={handleServicios}
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>Cuantas personas viven:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleSocioEconomico({
                    ...socioEconomico,
                    personas: e.target.value,
                  })
                }
                value={socioEconomico?.personas}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["colSm6"]}`}>
              <Typography>De que material es la casa:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleSocioEconomico({
                    ...socioEconomico,
                    materialCasa: e.target.value,
                  })
                }
                value={socioEconomico?.materialCasa}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSocioeconomico;
