import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { FormatDateTime } from "../Format";
import { Container, Grid, List, ListItem } from "@mui/material";
import CustomStatus from "../CustomImput/CustomStatus";
import CustomRatingSentimentShow from "../CustomRating/customRatingSentimentShow";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationActionsTableCitasNotes({
  citas,
  page,
  total,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - citas?.length) : 0;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableBody>
          {citas?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <ListItemText
                  sx={{
                    backgroundColor: "lightgray",
                    color: row.status === "Cancelada" ? "#9b242c" : "green",
                    paddingInline: "0.5rem",
                    textAlign: "end",
                  }}
                  primary={
                    <React.Fragment>
                      <Typography
                        sx={{
                          display: "inline",
                          cursor: "pointer",
                        }}
                        component="span"
                      >
                        {FormatDateTime(row.date)}
                      </Typography>
                    </React.Fragment>
                  }
                />
                {row.status === "Cancelada" ? (
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          display: "inline",
                          cursor: "pointer",
                          color: "#0009",
                        }}
                        component="span"
                        variant="caption"
                      >
                        Motivo cancelación:
                      </Typography>
                    }
                    secondary={
                      <Typography
                        sx={{
                          display: "block",
                          cursor: "pointer",
                          padding: "1rem",
                          marginY: "1rem",
                          border: "1px solid lightgray",
                        }}
                        component="p"
                        variant="caption"
                        color="black"
                      >
                        {row.additionalDetails?.motivoCancel
                          ? row.additionalDetails?.motivoCancel
                          : "Cita cancelada"}
                      </Typography>
                    }
                  />
                ) : (
                  <>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline", cursor: "pointer" }}
                            component="span"
                            variant="caption"
                            color="inherit"
                          >
                            Terapeuta:
                          </Typography>
                          <Typography
                            sx={{
                              display: "inline",
                              cursor: "pointer",
                              color: "#9b242c",
                              marginInline: "0.5rem",
                            }}
                            component="span"
                            variant="body2"
                          >
                            {" "}
                            {row.usuario?.nombre || "-"}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline", cursor: "pointer" }}
                            component="span"
                            variant="caption"
                            color="inherit"
                          >
                            Motivo:
                          </Typography>
                          <Typography
                            sx={{
                              display: "inline",
                              cursor: "pointer",
                              color: "#000",
                              marginInline: "0.5rem",
                            }}
                            component="span"
                            variant="body2"
                          >
                            {" "}
                            {row.reason}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <ListItemText
                      secondary={
                        <Grid container>
                          <Grid md={6}>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ display: "inline", cursor: "pointer" }}
                                  component="span"
                                  variant="caption"
                                  color="inherit"
                                >
                                  Evaluación del progreso:
                                </Typography>
                              }
                              secondary={
                                <CustomRatingSentimentShow
                                  value={row.additionalDetails?.valoration}
                                />
                              }
                            />
                          </Grid>
                          <Grid md={6}>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ display: "inline", cursor: "pointer" }}
                                  component="span"
                                  variant="caption"
                                  color="inherit"
                                >
                                  Comportamiento:
                                </Typography>
                              }
                              secondary={
                                <CustomRatingSentimentShow
                                  value={row.additionalDetails?.comportamiento}
                                />
                              }
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline", cursor: "pointer" }}
                            component="span"
                            variant="caption"
                            color="inherit"
                          >
                            Objetivos trabajados:
                          </Typography>
                          <List>
                            {row.additionalDetails?.objectivesList?.length <
                            1 ? (
                              <ListItem key={index}>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    cursor: "pointer",
                                    marginInline: "0.5rem",
                                    color: "#000",
                                  }}
                                  component="p"
                                  variant="body2"
                                >
                                  N/A
                                </Typography>
                              </ListItem>
                            ) : null}
                            {row.additionalDetails?.objectivesList?.map(
                              (objetive, index) => (
                                <ListItem key={index}>
                                  <CustomStatus status={objetive?.estado} />
                                  <Typography
                                    sx={{
                                      display: "inline",
                                      cursor: "pointer",
                                      marginInline: "0.5rem",
                                      color: "#000",
                                    }}
                                    component="p"
                                    variant="body2"
                                  >
                                    {`${objetive.label}`}
                                  </Typography>
                                </ListItem>
                              )
                            )}
                          </List>
                        </React.Fragment>
                      }
                    />
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline", cursor: "pointer" }}
                            component="span"
                            variant="caption"
                            color="inherit"
                          >
                            Notas:
                          </Typography>
                          <Typography
                            sx={{
                              display: "inline",
                              cursor: "pointer",
                              marginInline: "0.5rem",
                              color: "#000",
                            }}
                            component="p"
                            variant="body2"
                          >
                            {row.additionalDetails?.notas
                              ? row.additionalDetails?.notas
                              : "N/A"}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 88.52 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              labelRowsPerPage="Items por pagina"
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={3}
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
