import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import styles from "../layout/assets/css/core.min.module.css";
import DetallesNotaSeccion from "./DetallesNotaSeccion";
import AlumnoProvider from "../../../context/AlumnoContext";

const DetallesNota = () => {
  const navigate = useNavigate();

  return (
    <main
      id={styles["middle"]}
      className={`${styles.flexFill} ${styles.mxAuto}`}
    >
      <header>
        <h1 className={`${styles.h4}`}>Detalles nota</h1>
        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb} ${styles.small}`}>
            <li className={`${styles.breadcrumbItem}`}>
              <button
                style={{
                  border: "none",
                  backgroundColor: "unset",
                  color: "#9b242c",
                }}
                onClick={() => navigate("/area-educativa")}
              >
                Área educativa
              </button>
            </li>
            <li
              className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
              aria-current="page"
            >
              Detalles nota
            </li>
          </ol>
        </nav>
      </header>

      <AlumnoProvider>
        <DetallesNotaSeccion />
      </AlumnoProvider>
    </main>
  );
};

export default DetallesNota;
