import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import MensajeService from "../../../services/Mensaje.service";
// import ContactanosService from "../../../services/Contactanos.service";

const FormContact = () => {
  const google_apiKey = process.env.REACT_APP_GOOGLE_KEY_CAPTCHA;

  const [mensaje, setMensaje] = useState({ undefined });
  const [aceptar, setAceptar] = useState(false);
  const [valido, setValido] = useState(false);
  const navigate = useNavigate();

  const disabledSendBottom = () => {
    return !valido || !aceptar;
  };

  const enviarMensaje = async () => {
    if (!mensaje.Nombre) {
      toast.info("Error, tienes que colocar tu nombre");
    } else if (!mensaje.Email) {
      toast.info("Error, tienes que colocar un correo");
    } else if (!mensaje.Telefono) {
      toast.info("Error, tienes que colocar un número telefónico");
    } else if (!mensaje.MensajeCuerpo) {
      toast.info("Error, tienes que colocar una mensaje");
    } else if (!aceptar) {
      toast.info(
        "Para poder enviar tu mensaje debes de aceptar  los terminos y condiciones"
      );
    } else {
      try {
        const datosMensaje = {
          name: mensaje.Nombre,
          email: mensaje.Email,
          MensajeCuerpo: mensaje.MensajeCuerpo,
          phone: mensaje.Telefono,
          asunto: mensaje.Subject,
        };
        await MensajeService.create(datosMensaje);
      } catch (error) {
        toast.error(error);
      } finally {
        const limpiar = {
          Nombre: "",
          Email: "",
          MensajeCuerpo: "",
          Telefono: "",
          Subject: "",
        };
        setMensaje(limpiar);
        setValido(false);
        toast.info("Gracias, mensaje enviado");
      }
    }
  };

  function onChange(value) {
    value ? setValido(true) : setValido(false);
  }

  return (
    <div style={{ marginTop: "-4%" }}>
      <div className="section-title align-items-baseline mb-4">
        <h2 className="text-danger px-0 mb-0">Mensaje </h2>
      </div>
      <form>
        <div className="row">
          <div className="col-sm-6 col-xs-12">
            <div className="form-group form-group-icon">
              <i className="fa fa-user" />
              <input
                type="text"
                className="form-control border-primary"
                placeholder="Nombre"
                required=""
                onChange={(e) =>
                  setMensaje({ ...mensaje, Nombre: e.target.value })
                }
                value={mensaje.Nombre}
              />
            </div>
          </div>
          <div className="col-sm-6 col-xs-12">
            <div className="form-group form-group-icon">
              <i className="fa fa-envelope" />
              <input
                type="email"
                className="form-control border-success"
                placeholder="Correo electrónico"
                required=""
                onChange={(e) =>
                  setMensaje({ ...mensaje, Email: e.target.value })
                }
                value={mensaje.Email}
              />
            </div>
          </div>
          <div className="col-sm-6 col-xs-12">
            <div className="form-group form-group-icon">
              <i className="fas fa-phone-alt" />
              <input
                type="text"
                className="form-control border-purple"
                placeholder="Teléfono"
                required=""
                onChange={(e) =>
                  setMensaje({ ...mensaje, Telefono: e.target.value })
                }
                value={mensaje.Telefono}
              />
            </div>
          </div>
          <div className="col-sm-6 col-xs-12">
            <div className="form-group form-group-icon">
              <i className="fa fa-book" />
              <input
                type="text"
                className="form-control border-pink"
                placeholder="Asunto"
                required=""
                onChange={(e) =>
                  setMensaje({ ...mensaje, Subject: e.target.value })
                }
                value={mensaje.Subject}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group form-group-icon">
              <i className="fa fa-comments" />
              <textarea
                className="form-control border-info"
                placeholder="Mensaje"
                rows={6}
                defaultValue={""}
                onChange={(e) =>
                  setMensaje({
                    ...mensaje,
                    MensajeCuerpo: e.target.value,
                  })
                }
                value={mensaje.MensajeCuerpo}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-check">
              <input
                required=""
                className="form-check-input"
                id="contact_gdpr"
                name="contact_gdpr"
                type="checkbox"
                defaultValue={1}
                onClick={() => {
                  setAceptar(!aceptar);
                }}
              />
              <label className="form-check-label" htmlFor="contact_gdpr">
                Acepto términos y condiciones{" "}
                <button
                  className="text-decoration-none text-primary"
                  style={{ border: "none", backgroundColor: "unset" }}
                  onClick={() => navigate(`/termsofservice`)}
                >
                  (Términos y condiciones)
                </button>
              </label>
            </div>
            <ReCAPTCHA
              sitekey={google_apiKey}
              onChange={onChange}
              style={{
                transform: "scale(0.84)",
                transformOrigin: "0 0",
              }}
            />
          </div>
        </div>
      </form>
      <div className="text-sm-center mt-6">
        <button
          className="btn btn-danger text-uppercase"
          disabled={disabledSendBottom()}
          onClick={() => enviarMensaje()}
        >
          Enviar
        </button>
      </div>
    </div>
  );
};

export default FormContact;
