import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { FormatDateTime } from "../Format";
import { List, ListItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { NotaContext } from "../../../context/NotaContext";
import ImgsViewer from "react-images-viewer";
import S3Service from "../../../services/S3.service";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const CustomPaginationActionsTableCitasNotes = ({
  citas,
  page,
  total,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const { handleCurrentId, handleCurrentTab } = React.useContext(NotaContext);
  const [images, setImages] = React.useState([
    // { src: "../images/photo-1.jpg" },
  ]);
  const [imagesOpen, setImagesOpen] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState(0);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - citas?.length) : 0;

  const cargarNota = (nota) => {
    handleCurrentId(nota.id);
    handleCurrentTab(0);
  };
  const abrirImagenes = async (imagenesarray) => {
    setCurrentImage(0);
    let imagesa = [];
    if (imagenesarray) {
      for (const imagen of imagenesarray) {
        const result = await S3Service.get(imagen);
        imagesa.push({ src: result.result });
      }
    }
    setImages((old) => imagesa);
    setImagesOpen(true);
  };

  const handleImagesClose = () => {
    setImagesOpen(false);
  };

  const gotoPrevImg = () => {
    if (currentImage >= 1) {
      setCurrentImage((old) => old - 1);
    }
  };
  const gotoNextImg = () => {
    if (currentImage < images.length - 1) {
      setCurrentImage((old) => old + 1);
    }
  };
  const gotoImg = (index) => {
    setCurrentImage(index);
  };

  return (
    <TableContainer component={Paper}>
      {imagesOpen && (
        <ImgsViewer
          imgs={images}
          isOpen={imagesOpen}
          currImg={currentImage}
          onClickPrev={gotoPrevImg}
          onClickNext={gotoNextImg}
          onClose={handleImagesClose}
          backdropCloseable={true}
          showThumbnails={true}
          onClickThumbnail={gotoImg}
        />
      )}

      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableBody>
          {citas?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <ListItemText
                  sx={{
                    backgroundColor: "lightgray",
                    paddingInline: "0.5rem",
                    textAlign: "end",
                  }}
                  primary={
                    <React.Fragment>
                      <Typography
                        sx={{
                          display: "inline",
                          cursor: "pointer",
                        }}
                        component="span"
                        color="text.primary"
                      >
                        {FormatDateTime(row.date)}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline", cursor: "pointer" }}
                        component="span"
                        variant="caption"
                        color="inherit"
                      >
                        Maestro:
                      </Typography>
                      <Typography
                        sx={{
                          display: "inline",
                          cursor: "pointer",
                          color: "#9b242c",
                          marginInline: "0.5rem",
                        }}
                        component="span"
                        variant="body2"
                      >
                        {" "}
                        {row.usuario?.nombre}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline", cursor: "pointer" }}
                        component="span"
                        variant="caption"
                        color="inherit"
                      >
                        Motivo:
                      </Typography>
                      <Typography
                        sx={{
                          display: "inline",
                          cursor: "pointer",
                          color: "#000",
                          marginInline: "0.5rem",
                        }}
                        component="span"
                        variant="body2"
                      >
                        {" "}
                        {row.reason}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline", cursor: "pointer" }}
                        component="span"
                        variant="caption"
                        color="inherit"
                      >
                        Notas:
                      </Typography>
                      <Typography
                        sx={{
                          display: "inline",
                          cursor: "pointer",
                          marginInline: "0.5rem",
                          color: "#000",
                        }}
                        component="p"
                        variant="body2"
                      >
                        {row.additionalDetails?.notas
                          ? row.additionalDetails?.notas
                          : "N/A"}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <ListItemText
                  secondary={
                    row?.imagenes && row.imagenes?.length > 0 ? (
                      <React.Fragment>
                        <Typography
                          sx={{
                            display: "inline",
                            cursor: "pointer",
                            color: "green",
                          }}
                          component="a"
                          variant="caption"
                          color="inherit"
                          onClick={() => abrirImagenes(row.imagenes)}
                        >
                          Ver imágenes evidencia
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline", cursor: "pointer" }}
                          component="span"
                          variant="caption"
                          color="lightgray"
                        >
                          Ver imágenes evidencia
                        </Typography>
                      </React.Fragment>
                    )
                  }
                />
              </TableCell>
              <TableCell>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => cargarNota(row)}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 88.52 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              labelRowsPerPage="Items por pagina"
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={3}
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default CustomPaginationActionsTableCitasNotes;
