/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import MensajeDetalles from "./MensajeDetalles";
import styles from "../layout/assets/css/core.min.module.css";

/* Servicios */
import MensajeService from "../../../services/Mensaje.service";

const TablaMensaje = () => {
  const [mensajes, setMensajes] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("Vacio");
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);

  const onUpdate = () => {
    setUpdate(!update);
  };

  useEffect(() => {
    selectedStatus === "Vacio" ? getMensajes() : getMensajesFilter();
  }, [page, limit, selectedStatus, update]);

  const getMensajes = async () => {
    setLoading(true);
    try {
      const data = await MensajeService.list(limit, page * limit);
      setMensajes(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getMensajesFilter = async () => {
    setLoading(true);
    try {
      const data = await MensajeService.getByStatus(selectedStatus);
      setMensajes(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <main
          id={styles["middle"]}
          className={`${styles.flexFill} ${styles.mxAuto}`}
        >
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div style={{ display: "flex" }}>
                  <h4
                    className="card-title"
                    style={{ marginRight: "20px", marginTop: "6px" }}
                  >
                    Mensajes
                  </h4>
                </div>
              </div>
              <div
                className={`${styles.section} ${styles.p0}`}
                style={{ width: "100%", overflow: "auto" }}
              >
                <div
                  className={`${styles.tableResponsiveMd}`}
                  style={{ padding: "0.5rem" }}
                >
                  <table
                    className={`${styles.table}`}
                    style={{
                      width: "100%",
                      border: "white",
                    }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Email</th>
                        <th
                          scope="col"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ position: "relative" }}>
                            Filtro:{" "}
                            <select
                              name="selectedStatus"
                              style={{
                                paddingInlineEnd: "1rem",
                                borderRadius: "4px",
                                padding: "0.2rem 1rem",
                              }}
                              value={selectedStatus}
                              onChange={(e) =>
                                setSelectedStatus(e.target.value)
                              }
                            >
                              <option value="Vacio">SinFiltro </option>
                              <option value="Revisar">Revisar</option>
                              <option value="EnProceso">EnProceso</option>
                              <option value="SinAbrir">SinAbrir</option>
                            </select>
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiTablePagination-selectIcon MuiSelect-iconStandard css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowDropDownIcon"
                              style={{
                                width: "1em",
                              }}
                            >
                              <path d="M7 10l5 5 5-5z" />
                            </svg>
                          </label>
                          {selectedStatus !== "Vacio" && (
                            <button
                              style={{
                                border: "none",
                                padding: "0.3rem",
                              }}
                              type="button"
                              className={styles["btn-close"]}
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => setSelectedStatus("Vacio")}
                            ></button>
                          )}
                        </th>
                        <th scope="col">Ver</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {mensajes.map((record, index) => {
                        return (
                          <MensajeDetalles
                            key={index}
                            id={record.id}
                            data={record}
                            onSubmit={onUpdate}
                            statusDefault={record.status}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <TablePagination
                  style={{ color: "var(--color-dark-variant)" }}
                  component="div"
                  labelRowsPerPage="Items por página"
                  count={total}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default TablaMensaje;
