import {
  Grid,
  ListItemText,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { FormatDateTime } from "../../utils/Format";
import { useContext, useEffect, useState } from "react";
import styles from "../layout/assets/css/core.min.module.css";
import { toast } from "react-toastify";
import NotaService from "../../../services/Nota.service";
import { NotaContext } from "../../../context/NotaContext";
import { AlumnoContext } from "../../../context/AlumnoContext";
import Preloader from "../../utils/PreloaderElement";
import DropComponent from "../imagesDrop";
import S3Service from "../../../services/S3.service";

const TabNota = () => {
  const today = new Date();
  const {
    currentNota,
    handleCurrentNota,
    currentId,
    handleCurrentId,
    loading,
    handleLoading,
    handleChanges,
  } = useContext(NotaContext);
  const { currentAlumno, handleCurrentAlumno } = useContext(AlumnoContext);

  const [valoration, setValoration] = useState(5);
  const [objectivesList, setObjectivesList] = useState([]);
  const [comportamiento, setComportamiento] = useState(5);
  const [fechaNota, setFechaNota] = useState("1/1/2024");
  const [fechaNotaRegistro, setFechaNotaRegistro] = useState("1/1/2024");
  const [notas, setNotas] = useState("");
  const [motivoNota, setMotivoNota] = useState("");
  const [imagenesCarrusel, setImagenesCarrusel] = useState([]);

  const registerCita = async () => {
    handleLoading();
    try {
      if (!motivoNota) {
        toast.info("El motivo de la nota es necesario.");
        return;
      } else if (!notas) {
        toast.info("La nota esta vacía.");
        return;
      }
      if (currentId) {
        let citaRegisterData = {
          id: currentId,
          duration: 1,
          status: "Atendida",
          reason: motivoNota,
          additionalDetails: {
            objectivesList,
            comportamiento,
            valoration,
            notas,
          },
        };

        let imagenesArray = []; //Array para los id del s3
        for (const file of imagenesCarrusel) {
          if (!file.urlImaen) {
            const resultFile = await S3Service.upload(file);
            imagenesArray.push(resultFile.result.data);
          } else {
            const idImagen = file.urlImaen.split("/")[3].split("?")[0];
            imagenesArray.push(idImagen);
          }
        }

        citaRegisterData.imagenes = imagenesArray;

        const notaUpdate = await NotaService.update(citaRegisterData);
        console.log("notaUpdate");
        console.log(notaUpdate);
        toast.success("Nota actualizada");

        handleCurrentNota({ ...notaUpdate });
        handleCurrentId(notaUpdate?.id ? notaUpdate.id : null);
      } else {
        let citaRegisterData = {
          duration: 1,
          status: "Atendida",
          motivoCita: motivoNota,
          additionalDetails: {
            objectivesList,
            comportamiento,
            valoration,
            notas,
          },
          alumno: currentAlumno?.id,
          fechaCita: fechaNota,
        };

        let imagenesArray = []; //Array para los id del s3
        for (const file of imagenesCarrusel) {
          if (!file.urlImaen) {
            const resultFile = await S3Service.upload(file);
            imagenesArray.push(resultFile.result.data);
          } else {
            const idImagen = file.urlImaen.split("/")[3].split("?")[0];
            imagenesArray.push(idImagen);
          }
        }

        citaRegisterData.imagenes = imagenesArray;

        const notaRegistrada = await NotaService.create(citaRegisterData);
        toast.success("Nota registrada");
      }

      handleChanges();
      handleCurrentNota(null);
      handleCurrentId(null);
      setMotivoNota("");
      setNotas("");
      setImagenesCarrusel((old) => []);
    } catch (error) {
      toast.error(error);
    } finally {
      handleLoading();
    }
  };

  const cancelar = () => {
    handleLoading();

    setImagenesCarrusel([]);
    handleCurrentNota(null);
    handleCurrentId(null);
    setMotivoNota("");
    setNotas("");
    setTimeout(function () {
      handleLoading();
    }, 200);
  };

  const cargarNota = async () => {
    handleLoading();
    try {
      if (currentNota) {
        setValoration((old) => currentNota.additionalDetails?.valoration);
        setObjectivesList((old) =>
          currentNota.additionalDetails?.objectivesList
            ? currentNota.additionalDetails?.objectivesList
            : []
        );
        setComportamiento(
          (old) => currentNota.additionalDetails?.comportamiento
        );
        setNotas((old) => currentNota.additionalDetails?.notas);
        setMotivoNota((old) => currentNota.reason);
        setFechaNotaRegistro((oldFechaNota) => currentNota?.date);
        setFechaNota(today.toISOString());

        let images = [];
        if (currentNota.imagenes) {
          for (const imagen of currentNota.imagenes) {
            const result = await S3Service.get(imagen);
            images.push(result.result);
          }
        }
        setImagenesCarrusel((old) => images);
      } else {
        setFechaNota(today.toISOString());
      }
    } catch (error) {
      toast.error("Error, no se ha cargado la nota");
    } finally {
      handleLoading();
    }
  };

  useEffect(() => {
    cargarNota();
  }, [currentNota]);

  return (
    <Box sx={{ flexGrow: 1, minHeight: "33.5rem" }}>
      {loading ? (
        <Preloader />
      ) : (
        <Grid container spacing={2}>
          <Grid xs={12} md={8}>
            <ListItemText
              primary="Fecha:"
              secondary={FormatDateTime(fechaNota)}
            />
          </Grid>
          <Grid xs={12} md={4}>
            {currentId && (
              <ListItemText
                sx={{ color: "red" }}
                primary="Esta es una nota registrada."
              />
            )}
          </Grid>
          <Grid xs={12} md={6}>
            <TextField
              label="Motivo de la nota"
              value={motivoNota}
              onChange={(event) => setMotivoNota(event.target.value)}
            />
          </Grid>
          <Grid xs={12} md={6}>
            {currentId && (
              <ListItemText
                primary="Fecha registro:"
                secondary={FormatDateTime(fechaNotaRegistro)}
              />
            )}
          </Grid>
          <Grid xs={12} md={12}>
            <Typography>Nota:</Typography>
            <TextareaAutosize
              minRows={10}
              style={{
                width: "100%",
                padding: "0.5rem",
                backgroundColor: "#ececec",
              }}
              value={notas}
              onChange={(event) => setNotas(event.target.value)}
            />
          </Grid>
          <Grid xs={12} md={12}>
            <Typography>Evidencias:</Typography>

            <div style={{ width: "100%" }}>
              <DropComponent
                imagenesDefault={imagenesCarrusel}
                subirImagen={(data) => setImagenesCarrusel(data)}
              />
            </div>
          </Grid>
          <Grid xs={12}>
            {currentNota?.id ? (
              <>
                <div
                  className={`${styles.cardFooter} ${styles.borderLight}`}
                  style={{ textAlign: "right" }}
                >
                  <button
                    className={`${styles.btn} ${styles["btn-warning"]}`}
                    onClick={() => cancelar()}
                  >
                    <span className={`${styles.small}`}>Cancelar</span>
                  </button>
                  <button
                    className={`${styles.btn} ${styles["btn-secondary"]}`}
                    onClick={() => registerCita()}
                  >
                    <span className={`${styles.small}`}>Actualizar nota</span>
                  </button>
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${styles.cardFooter} ${styles.borderLight}`}
                  style={{ textAlign: "right" }}
                >
                  <button
                    className={`${styles.btn} ${styles.btnPrimary}`}
                    onClick={() => {
                      registerCita();
                    }}
                  >
                    <span className={`${styles.small}`}>Registrar nota</span>
                  </button>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default TabNota;
