import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import GroupsIcon from "@mui/icons-material/Groups";

const Grupo = ({ grupo }) => {
  return (
    <ListItem disablePadding>
      <ListItemAvatar>
        <Avatar
          sx={{ backgroundColor: grupo?.color ? grupo.color : "#f2f2f2" }}
        >
          <GroupsIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText secondary="Reporte de grupo" primary={grupo?.nombre} />
    </ListItem>
  );
};

export default Grupo;
