import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

//Services
import ServicioService from "../../../services/Servicio.service";

//Components
import ReactPaginate from "react-paginate";
import BreadCrumb from "./BreadCrumb";
import ServicioItem from "./ServicioItem";

const ServiciosGrid = () => {
  const { filtroI } = useParams();
  const [loading, setLoading] = useState(true);
  const [paquetes, setPaquetes] = useState([]);
  const [serviciosArray, setServiciosArray] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [numPages, setNumPages] = useState(0);
  const [page, setPage] = useState(0);
  const [tipo, setTipo] = useState(0);

  const { currentUser } = useContext(AuthContext);

  const [filtro, setFiltro] = useState(false);
  const [filtroCiudades, setFiltroCiudades] = useState([]);
  const [filtroFechaInicio, setFiltroFechaInicio] = useState(undefined);
  const [filtroFechaFin, setFiltroFechaFin] = useState(undefined);
  const [value, setValue] = React.useState([0, 1000]);
  const navigate = useNavigate();
  //Imagenes

  useEffect(() => {
    getPaquetes();
    window.scrollTo(0, 0);
  }, [page, limit]);

  const getPaquetes = async () => {
    setLoading(true);
    try {
      if (currentUser !== undefined && currentUser.tipo === "Empresa") {
        setTipo("Empresa");
      } else {
        setTipo("Usuario");
      }
      if (filtroI !== undefined) {
        setFiltro(true);
        setFiltroCiudades([filtroI]);
        const data = await ServicioService.filtroCiudad(limit, filtroI);
        console.log(data);

        // paquetes.map((record, index) => {
        //   serviciosArray.concat(record.titulo);
        // });

        setPaquetes(data);
        setTotal(data.total);
        setNumPages(Math.ceil(data.total / limit));
      } else {
        const data = await ServicioService.list(limit, page * limit);
        setPaquetes(data.data);
        setTotal(data.total);
        setNumPages(Math.ceil(data.total / limit));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage.selected);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const buscar = async () => {
    setLoading(true);

    try {
      if (filtro) {
        console.log(
          "--Hay filtros" +
            filtroCiudades +
            "," +
            filtroFechaInicio +
            "," +
            filtroFechaFin +
            ","
        );
        if (
          filtroCiudades.length > 0 &&
          filtroFechaInicio == undefined &&
          filtroFechaFin == undefined
        ) {
          let cds = [];
          for (const cd of filtroCiudades) {
            const filtros = {
              ciudades: cd,
            };
            console.log("filtro de ciudades");
            console.log("la ciudad:" + filtros.ciudades);
            const data = await ServicioService.filtroCiudad(100, filtros);
            console.log("tipo:" + data.data);
            if (tipo == "Usuario") {
              data.data.forEach((element) => {
                for (const pre of element.paquetesUsuario) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            } else {
              data.data.forEach((element) => {
                for (const pre of element.paquetesEmpresa) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            }
          }
          setPaquetes(cds);
          console.log("paquetes del filtro de ciudades:" + cds.length);
        }
        if (
          (filtroCiudades.length == 0 &&
            filtroFechaInicio != undefined &&
            filtroFechaFin != undefined) ||
          (filtroCiudades.length == 0 &&
            filtroFechaInicio != "" &&
            filtroFechaFin != "")
        ) {
          const filtros = {
            fechaInicio: filtroFechaInicio.fecha,
            fechaFin: filtroFechaFin.fecha,
          };
          console.log(
            "paquetes del filtro de fechas:" +
              filtros.fechaInicio +
              "--" +
              filtros.fechaFin
          );
          let paq = [];
          const data = await ServicioService.filtroFecha(100, filtros);
          if (tipo == "Usuario") {
            data.data.forEach((element) => {
              for (const pre of element.paquetesUsuario) {
                if (pre.props.tipo == "Adulto") {
                  if (
                    pre.props.precio >= value[0] &&
                    pre.props.precio <= value[1]
                  ) {
                    paq.push(element);
                  }
                }
              }
            });
          } else {
            data.data.forEach((element) => {
              for (const pre of element.paquetesEmpresa) {
                if (pre.props.tipo == "Adulto") {
                  if (
                    pre.props.precio >= value[0] &&
                    pre.props.precio <= value[1]
                  ) {
                    paq.push(element);
                  }
                }
              }
            });
          }
          setPaquetes(paq);
          console.log("paquetes del filtro de fechas:" + paq.length);
        }
        if (
          filtroCiudades.length > 0 &&
          filtroFechaInicio != undefined &&
          filtroFechaFin != undefined
        ) {
          let cds = [];
          for (const cd of filtroCiudades) {
            const filtros = {
              ciudades: cd,
              fechaInicio: filtroFechaInicio.fecha,
              fechaFin: filtroFechaFin.fecha,
            };
            console.log("filtro de ciudades y fechas");
            console.log("la ciudad:" + cd);
            const data = await ServicioService.filtroCiudadFecha(100, filtros);
            console.log("tipo:" + data.data);
            if (tipo == "Usuario") {
              data.data.forEach((element) => {
                for (const pre of element.paquetesUsuario) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            } else {
              data.data.forEach((element) => {
                for (const pre of element.paquetesEmpresa) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            }
          }
          setPaquetes(cds);
          console.log("paquetes del filtro de ciudades y fecha:" + cds.length);
        }
        if (currentUser != undefined && currentUser.tipo == "Empresa") {
          setTipo("Empresa");
        } else {
          setTipo("Usuario");
        }
      } else {
        console.log("sin filtros");
        let paqs = [];
        const data = await ServicioService.list(limit, page * limit);
        data.data.forEach((element) => {
          for (const pre of element.paquetesEmpresa) {
            if (pre.props.tipo == "Adulto") {
              if (
                pre.props.precio >= value[0] &&
                pre.props.precio <= value[1]
              ) {
                paqs.push(element);
              }
            }
          }
        });
        setPaquetes(paqs);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [personName, setPersonName] = React.useState([]);

  const setFiltrosCiudades = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroCiudades(value);
    setFiltro(true);
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="main-wrapper services">
      <BreadCrumb />
      <section className="py-8 py-md-10">
        <div className="container-xl">
          <div className="row col-12">
            {paquetes.map((record, index) => {
              return (
                <ServicioItem
                  key={index}
                  id={record.id}
                  titulo={record.titulo}
                  precios={record.precios}
                  descuento={record.descuento}
                  descripcion={record.descripcion}
                  incluye={record.incluye}
                  subtitulo={record.subtitulo}
                  imagenes={record.imagenes}
                  fecha={record.updateAt}
                  tipo={tipo}
                  edades={record.edades}
                  horario={record.horario}
                />
              );
            })}
          </div>
        </div>
        <div className="mt-4">
          <section className="py-5">
            <div className="container">
              <nav aria-label="Page navigation example">
                <nav aria-label="Page navigation example">
                  <ReactPaginate
                    nextLabel="next ►"
                    onPageChange={handleChangePage}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={4}
                    pageCount={numPages}
                    previousLabel="◄ previous"
                    pageClassName="page-item"
                    pageLinkClassName="d-sm-block m-sm-3 fs-5"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link page-item"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination justify-content-center align-items-center"
                    activeClassName="text-underline"
                    renderOnZeroPageCount={null}
                  />
                </nav>
              </nav>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default ServiciosGrid;
