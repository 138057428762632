import { useEffect, useState } from "react";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";

import { toast } from "react-toastify";
import styles from "../layout/assets/css/core.min.module.css";
import AreaService from "../../../services/Area.service";
import UserService from "../../../services/User.Service";

import {
  Autocomplete,
  Divider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

const FormCategoria = ({
  createCategoria,
  showFormCategoria,
  updateCategoria,
  categoria,
}) => {
  const [loading, setLoading] = useState(false);
  const [operation, setOperation] = useState("create");
  const [areas, setAreas] = useState([]);
  const [colores, setColores] = useState([
    {
      nombre: "rosa",
      color: "#FFC0CB",
    },
    {
      nombre: "purpura",
      color: "#7D2181",
    },
    {
      nombre: "menta",
      color: "#3EB489",
    },
    {
      nombre: "turquesa",
      color: "#5DC1B9",
    },
    {
      nombre: "olivo",
      color: "#808000",
    },
    {
      nombre: "naranja",
      color: "#ffa500	",
    },
    {
      nombre: "violeta",
      color: "#ee82ee",
    },
    {
      nombre: "salmon",
      color: "#fa8072",
    },
    {
      nombre: "rojo",
      color: "#FF0000",
    },
    {
      nombre: "azul",
      color: "#0000ff ",
    },
    {
      nombre: "verde",
      color: "#00913f",
    },
    {
      nombre: "amarillo",
      color: "#ffff00",
    },
    {
      nombre: "cafe",
      color: "#a18262",
    },
  ]);

  const [area, setArea] = useState("Área educativa");
  const [newCategoria, setNewCategoria] = useState(
    categoria?.nombre ? categoria.nombre : ""
  );
  const [rangoInicial, setRangoInicial] = useState(
    categoria?.rangoEdad?.rangoInicial ? categoria?.rangoEdad?.rangoInicial : 0
  );
  const [rangoFinal, setRangoFinal] = useState(
    categoria?.rangoEdad?.rangoFinal ? categoria?.rangoEdad?.rangoFinal : 1
  );
  const [color, setColor] = useState(
    categoria?.color ? categoria.color : "#FFC0CB"
  );
  const [maestros, setMaestros] = useState([]);
  const [maestroSelected, setMaestroSelected] = useState(null);

  const handleNewCategoria = (event) => {
    setNewCategoria(event.target.value);
  };

  const saveCategoria = () => {
    const newTipo = {
      nombre: newCategoria,
      color: color,
      rangoEdad: {
        rangoInicial: rangoInicial,
        rangoFinal: rangoFinal,
      },
    };

    if (!newTipo.nombre) {
      toast.info("Error, tienes que colocar nombre.");
      return;
    } else if (!rangoInicial || !rangoFinal) {
      toast.info("Error, revisa los valores de tus rangos.");
      return;
    } else if (area === "" || area === "N/A") {
      toast.info("Error, tienes que seleccionar el area.");
      return;
    } else if (!maestroSelected) {
      toast.info("Error, tienes que seleccionar un maestro.");
      return;
    }

    if (!categoria.id) {
      newTipo.area = area;
      newTipo.maestro = maestroSelected?.id;

      createCategoria(newTipo);
    } else {
      if (area && area !== categoria?.area?.tipo) {
        newTipo.area = area;
      }
      updateCategoria({
        ...newTipo,
        id: categoria.id,
        maestro: maestroSelected?.id,
      });
    }

    setNewCategoria("");
  };

  const handleArea = (event, newArea) => {
    setArea(newArea);
  };

  const handleColor = (event, newArea) => {
    setColor(newArea);
  };

  const handleRangoInicial = (event) => {
    let valor = parseInt(event.target.value);
    setRangoInicial(valor);
  };

  const handleRangoFinal = (event) => {
    let valor = parseInt(event.target.value);
    setRangoFinal(valor);
  };

  useEffect(() => {
    if (categoria.maestro) {
      formatMaestro();
      setOperation("update");
      getMaestros();
    } else {
      getMaestrosCreate();
    }
  }, []);

  const formatMaestro = () => {
    setMaestroSelected({
      label: categoria.maestro.nombre,
      id: categoria.maestro.id,
      correo: categoria.maestro.correo,
    });
  };

  const getMaestros = async () => {
    try {
      const data = await UserService.getAllMaestros({
        tipo: "Maestro",
      });
      if (data) {
        let productosFormat = [];
        data.data.map((prod) => {
          productosFormat.push({
            label: prod.nombre,
            id: prod.id,
            correo: prod.correo,
          });
        });

        if (categoria && categoria.maestro) {
          productosFormat.push({
            label: categoria.maestro.nombre,
            id: categoria.maestro.id,
            correo: categoria.maestro.correo,
          });
        }
        setMaestros(productosFormat);
      }
    } catch (error) {
      toast.error("Error al buscar maestros");
    }
  };

  const getMaestrosCreate = async () => {
    try {
      const data = await UserService.getAllMaestros({
        tipo: "Maestro",
      });
      if (data) {
        let productosFormat = [];
        data.data.map((prod) => {
          productosFormat.push({
            label: prod.nombre,
            id: prod.id,
            correo: prod.correo,
          });
        });
        setMaestros(productosFormat);
      }
    } catch (error) {
      toast.error("Error al buscar maestros");
    }
  };

  return (
    <div
      className={styles.formControl + " " + styles["animate-slidein"]}
      style={{ position: "absolute", zIndex: "1", maxWidth: "24rem" }}
    >
      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div
            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
          >
            Nuevo grupo
          </div>
          <div className={`${styles.section} ${styles.mb4}`}>
            <TextField
              fullWidth
              value={newCategoria}
              onChange={handleNewCategoria}
              placeholder="Nombre del grupo"
              label="Nombre del grupo"
            />
          </div>
        </div>
      </div>

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div
            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
          >
            Rango de edad
          </div>
          <div className={`${styles.section} ${styles.mb4}`}>
            <TextField
              fullWidth
              value={rangoInicial}
              onChange={handleRangoInicial}
              placeholder="Edad inicial"
              label="Edad inicial"
              type="number"
            />
            <Divider style={{ height: "0.5rem" }} />
            <TextField
              fullWidth
              value={rangoFinal}
              onChange={handleRangoFinal}
              placeholder="Edad final"
              label="Edad final"
              type="number"
            />
          </div>
        </div>
      </div>

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div
            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
          >
            Color
          </div>
          <div className={`${styles.section} ${styles.mb4}`}>
            <ToggleButtonGroup
              value={color}
              exclusive
              onChange={handleColor}
              aria-label="Platform-color"
              sx={{ flexWrap: "wrap" }}
            >
              {colores?.map((areaOption) => (
                <ToggleButton
                  value={areaOption.color}
                  style={{
                    margin: "0.1rem",
                    borderRadius: "4px",
                    borderTop: `0.5rem solid ${areaOption.color}`,
                  }}
                >
                  {areaOption.nombre}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        </div>
      </div>

      <div className={`${styles.row} ${styles.g4}`}>
        <div className={`${styles.col}`}>
          <div
            className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
          >
            Maestro
          </div>
          <div className={`${styles.section} ${styles.mb4}`}>
            <Autocomplete
              value={maestroSelected}
              onChange={(event, newValue) => {
                setMaestroSelected(newValue);
              }}
              disablePortal
              id="combo-box-demo"
              options={maestros}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Seleccionar maestro" />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </div>
        </div>
      </div>
      {/* options */}
      <div
        className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto} ${styles["m-2"]}`}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <button
          onClick={() => saveCategoria()}
          className={`${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
          style={{ width: "45%" }}
        >
          <svg
            width="18px"
            height="18px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1={12} y1={5} x2={12} y2={19} />
            <line x1={5} y1={12} x2={19} y2={12} />
          </svg>
          Guardar
        </button>
        <button
          id="calcelNewCountButton"
          className={`${styles.btn} ${styles.btnSm} ${styles["btn-secondary"]}`}
          onClick={showFormCategoria}
          style={{ width: "45%" }}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default FormCategoria;
