import { Route, Routes, Navigate } from "react-router-dom";

// import FullPageLoading from "../utils/FullPageLoading";

//#region PUBLIC
/* Public components*/
import PublicLayout from "../components/public/layout/PublicLayout";
import Portada from "../components/public/portada/Portada";
import BlogGrid from "../components/public/blog/BlogGrid";
import BlogEntrada from "../components/public/blog/BlogEntrada";
import Contactanos from "../components/public/contactanos/Contactanos";
import ServiciosGrid from "../components/public/servicios/ServiciosGrid";
import ServicioIndividual from "../components/public/servicios/servicio/ServicioIndividual";
import IniciarSesion from "../components/public/login/IniciarSesion";
import AvisoPrivacidad from "../components/public/information/AvisoPrivacidad";
import TermsAndConditions from "../components/public/information/TermsAndConditions";
import ForgotPassword from "../components/public/login/ForgotPassword";
import ResetPassword from "../components/public/login/ResetPassword";
//#endregion

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route index element={<Portada />} />
        <Route path="contactanos" element={<Contactanos />} />
        <Route path="servicios" element={<ServiciosGrid />} />
        <Route path="servicios/:id" element={<ServicioIndividual />} />
        <Route path="blog" element={<BlogGrid />} />
        <Route path="blog/:id" element={<BlogEntrada />} />
        <Route path="iniciarsesion" element={<IniciarSesion />} />
        <Route path="privacy" element={<AvisoPrivacidad />} />
        <Route path="termsofservice" element={<TermsAndConditions />} />
        {/* <Route path="forgotpassword" element={<ForgotPassword />} /> */}
        {/* <Route path="resetPassword/:id/:token" element={<ResetPassword />} /> */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Route>
    </Routes>
  );
};

export default PublicRoutes;
