/* React */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import DropComponent from "../imagesDrop";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";

/*Services */
import S3Service from "../../../services/S3.service";
import FullPageLoading from "../../FullPageLoading";
import CardServicioPrecio from "./CardServicioPrecio";
import ServicioService from "../../../services/Servicio.service";
import Preloader from "../../utils/Preloader";

import styles from "../layout/assets/css/core.min.module.css";

const CrearServicio = () => {
  //#region variables
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [servicio, setServicio] = useState(null);
  const [priceList, setPriceList] = useState([]);
  const [file, setFile] = useState(undefined);

  const [diasArray, setDiasArray] = useState([]);

  const [servicioPrecioU, setservicioPrecioU] = useState({});
  const [servicioPrecioArrayU, setservicioPrecioArrayU] = useState([]);
  const [precioUContador, setPrecioUContador] = useState(0);

  const [mostrarI, setMostrarI] = useState(false);
  const [destacados, setDestacados] = useState(true);

  const [imagenesCarrusel, setImagenesCarrusel] = useState([]);
  const myTheme = createTheme({});
  //#endregion

  useEffect(() => {
    setServicio({
      ...servicio,
      mostrarInicio: false,
      descuento: 0,
    });
  }, []);

  const guardarServicio = async () => {
    try {
      if (!servicio.titulo) {
        toast.info("Error, tienes que agregar un titulo");
      } else if (!servicio.descripcion) {
        toast.info("Error, tienes que colocar una descripción");
      } else if (priceList.length <= 0) {
        toast.info("Error, tienes que agregar los precios para los usuario");
      } else {
        setLoading(true);
        servicio.precios = priceList;
        servicio.mostrarInicio = mostrarI;
        let imagenesArray = []; //Array para los id del s3
        // let diasjson = []; //Array para almecenar los dias en formatos JSON y guardarlos en la BD

        // let servicioPrecioU = [];
        // let servicioPrecioE = [];
        /* Subida de imagenes del carrusel */
        for (const image of imagenesCarrusel) {
          const resultFile = await S3Service.upload(image);
          console.log("imagen carrusel guardad:" + resultFile.result.data);
          imagenesArray.push(resultFile.result.data);
        }

        servicio.imagenes = imagenesArray;

        // const { titulo, descripcion, horario,edades} = servicio;

        // const dataServicio = {
        //   titulo,
        //   horario,
        //   descripcion,
        //   edades,
        //   imagenes: imagenesArray,
        //   precios: servicioPrecioArrayU,
        //   mostrarInicio: !mostrarI,
        // };

        const servicioSave = await ServicioService.create(servicio);
        console.log("--------------" + servicioSave.response);
        navigate("/servicio");
        toast.success("Servicio creado con exito");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
      setLoading(true);
    }
  };

  const saveServicioPrecioU = (userType) => {
    setPrecioUContador(precioUContador + 1);
    try {
      const newPrice = {
        cantidad: servicioPrecioU.cant,
        tiempo: servicioPrecioU.tiempo,
        precio: servicioPrecioU.precio,
        userType: userType,
        id: precioUContador,
      };

      let oldPriceList = priceList.slice();
      oldPriceList.push(newPrice);
      setPriceList(oldPriceList);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarPrecioServicio = (id) => {
    try {
      let newPriceList = priceList.filter((item) => item.id !== id);
      setPriceList(newPriceList);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {/* PAGE TITLE */}
            <header>
              <h1 className={`${styles.h4}`}>Nuevo servicio</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li className={`${styles.breadcrumbItem}`}>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "unset",
                        color: "#9b242c",
                      }}
                      onClick={() => navigate("/servicio")}
                    >
                      servicios
                    </button>
                  </li>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Nuevo
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className={`${styles.row} ${styles.g4}`}>
                <div className={`${styles.col}`}>
                  {/* Shipping address */}
                  <div
                    className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                  >
                    {" "}
                    Servicio
                  </div>
                  <div className={`${styles.section} ${styles.mb4}`}>
                    <div className={`${styles.cardBody}`}>
                      <div className={`${styles.row} ${styles.g3}`}>
                        <div className={`${styles.colSm3}`}>
                          <div className={`${styles.formFloating}`}>
                            Mostrar al inicio
                            <Switch
                              checked={mostrarI}
                              color="error"
                              onClick={() => {
                                setMostrarI((prev) => !prev);
                                console.log("mostrar inicio:" + mostrarI);
                              }}
                            />
                          </div>
                        </div>
                        <div className={`${styles.colSm12}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Título"
                              value={servicio?.titulo}
                              onChange={(e) =>
                                setServicio({
                                  ...servicio,
                                  titulo: e.target.value,
                                })
                              }
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Horario"
                              value={servicio?.horario}
                              onChange={(e) =>
                                setServicio({
                                  ...servicio,
                                  horario: e.target.value,
                                })
                              }
                            />
                            <label>Horario</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Edades"
                              value={servicio?.edades}
                              onChange={(e) =>
                                setServicio({
                                  ...servicio,
                                  edades: e.target.value,
                                })
                              }
                            />
                            <label>Edades</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm12}`}>
                          <div className={`${styles.formFloating}`}>
                            <div>
                              <ThemeProvider theme={myTheme}>
                                <MUIRichTextEditor
                                  label="Descripción del servicio... "
                                  controls={[
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "highlight",
                                    "undo",
                                    "redo",
                                    "link",
                                    "numberList",
                                    "bulletList",
                                    "quote",
                                    "code",
                                    "clear",
                                    // "save"
                                  ]}
                                  // onSave={save}
                                  inlineToolbar={true}
                                  onChange={(value) =>
                                    setServicio({
                                      ...servicio,
                                      descripcion: stateToHTML(
                                        value.getCurrentContent()
                                      ),
                                    })
                                  }
                                />
                              </ThemeProvider>
                              <br></br>
                              <br></br>
                            </div>
                          </div>
                          {file ? (
                            <div>
                              <div className={`${styles.colLg12}`}>
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    style={{
                                      height: 300,
                                      objectFit: "contain",
                                      width: "-webkit-fill-available",
                                    }}
                                  />
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    style={{
                                      width: "-webkit-fill-available",
                                    }}
                                  >
                                    {file.name}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div style={{ width: "95%", marginLeft: "1.5%" }}>
                            <DropComponent
                              subirImagen={(data) => setImagenesCarrusel(data)}
                            />
                          </div>
                        </div>
                        <h4>Precios</h4>
                        {/* PRECIOS  */}
                        <div className={`${styles.colSm3}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="number"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Frase celebre"
                              value={servicioPrecioU.cant}
                              onChange={(e) =>
                                setservicioPrecioU({
                                  ...servicioPrecioU,
                                  cant: e.target.value,
                                })
                              }
                            />
                            <label>Cantidad</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm3}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="number"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Frase celebre"
                              value={servicioPrecioU.precio}
                              onChange={(e) =>
                                setservicioPrecioU({
                                  ...servicioPrecioU,
                                  precio: e.target.value,
                                })
                              }
                            />
                            <label>Precio</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm3}`}>
                          <div className={`${styles.formFloating}`}>
                            <Button
                              id="guardarUsuario"
                              variant="outlined"
                              color="success"
                              onClick={(userType) =>
                                saveServicioPrecioU("Usuario")
                              }
                            >
                              Guardar
                            </Button>
                          </div>
                        </div>
                        <div className={`${styles.colSm12}`}>
                          <div className={`${styles.formFloating}`}>
                            {priceList.map((record, index) => {
                              return (
                                <div
                                  key={index}
                                  id="row__posterLarge"
                                  style={{
                                    padding: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <CardServicioPrecio
                                    cantidad={record.cantidad}
                                    tiempo={record.tiempo}
                                    precio={record.precio}
                                    user={record.userType}
                                    id={record.id}
                                    eliminarActual={(data) => {
                                      eliminarPrecioServicio(data);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className={`${styles.co12}`}>
              <div
                className={`${styles.cardFooter} ${styles.borderLight}`}
                style={{ textAlign: "right" }}
              >
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => guardarServicio()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>{" "}
                  {"  "}
                  <span className={`${styles.small}`}>Guardar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearServicio;
