import styles from "../layout/assets/css/core.min.module.css";
import CustomSelectImage from "../../utils/CustomImageSelect/CustomSelectImage";
import { TextField, Typography } from "@mui/material";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import CustomDirectionInput from "../../utils/CustomImput/CustomDirectionInput";

const FormAlumno = ({ formik, imagen }) => {
  const handleFile = (file) => {
    formik.setFieldValue("alumno.file", file);
  };
  return (
    <div className={`${styles.row} ${styles.g4}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          Datos del alumno
        </div>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            <div className={`${styles["col-md-4"]}`}>
              <CustomSelectImage
                url={imagen}
                file={formik.values.alumno.file}
                handleFile={handleFile}
              />
            </div>
            <div className={`${styles["col-md-8"]}`}>
              <div className={`${styles["my-4"]}`}>
                <Typography>
                  Nombre del alumno{" "}
                  <Typography
                    sx={{ display: "inline", cursor: "pointer" }}
                    component="span"
                    variant="caption"
                    color="#9b242c"
                  >
                    Obligatorio
                  </Typography>
                </Typography>
                <TextField
                  variant="standard"
                  placeholder="Nombre completo"
                  fullWidth
                  id="nombre"
                  name="nombre"
                  value={formik.values.alumno.nombre}
                  onChange={(event) => {
                    formik.setFieldValue("alumno.nombre", event.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.nombre &&
                    Boolean(formik.errors.alumno?.nombre)
                  }
                  helperText={
                    formik.touched.nombre && formik.errors.alumno?.nombre
                  }
                />
              </div>
              <div className={`${styles["my-4"]}`}>
                <Typography>
                  Fecha nacimiento{" "}
                  <Typography
                    sx={{ display: "inline", cursor: "pointer" }}
                    component="span"
                    variant="caption"
                    color="#9b242c"
                  >
                    MM-DD-YYYY
                  </Typography>
                </Typography>
                <TextField
                  variant="standard"
                  type="date"
                  placeholder="Fecha de nacimiento"
                  fullWidth
                  id="fechaNacimiento"
                  name="fechaNacimiento"
                  value={formik.values.alumno.fechaNacimiento}
                  onChange={(event) =>
                    formik.setFieldValue(
                      "alumno.fechaNacimiento",
                      event.target.value
                    )
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.fechaNacimiento &&
                    Boolean(formik.errors.alumno?.fechaNacimiento)
                  }
                  helperText={
                    formik.touched.fechaNacimiento &&
                    formik.errors.alumno?.fechaNacimiento
                  }
                />
              </div>
              <div className={`${styles["my-4"]}`}>
                <Typography>
                  Sexo{" "}
                  <Typography
                    sx={{ display: "inline", cursor: "pointer" }}
                    component="span"
                    variant="caption"
                    color="#9b242c"
                  >
                    Obligatorio
                  </Typography>
                </Typography>
                <ToggleButtonGroup
                  value={formik.values.alumno.sexo}
                  exclusive
                  onChange={(event, newValue) =>
                    formik.setFieldValue("alumno.sexo", newValue)
                  }
                  aria-label="Platform"
                >
                  <ToggleButton value="Masculino">Masculino</ToggleButton>
                  <ToggleButton value="Femenino">Femenino</ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className={`${styles["my-4"]}`}>
                <CustomDirectionInput
                  direction={formik.values.alumno.domicilio}
                  handleDirection={(newValue) =>
                    formik.setFieldValue("alumno.domicilio", newValue)
                  }
                />
              </div>
              <div className={`${styles["my-4"]}`}>
                <Typography>
                  Con quien vive?{" "}
                  <Typography
                    sx={{ display: "inline", cursor: "pointer" }}
                    component="span"
                    variant="caption"
                    color="#9b242c"
                  >
                    Obligatorio
                  </Typography>
                </Typography>
                <ToggleButtonGroup
                  value={formik.values.alumno.responsable}
                  exclusive
                  onChange={(event, newValue) =>
                    formik.setFieldValue("alumno.responsable", newValue)
                  }
                  aria-label="Platform"
                >
                  <ToggleButton value="Madre">Madre</ToggleButton>
                  <ToggleButton value="Padre">Padre</ToggleButton>
                  <ToggleButton value="Ambos">Ambos</ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAlumno;
