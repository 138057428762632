import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthContext } from "../../../context/AuthContext";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationActionsTable({
  alumnos,
  page,
  total,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const navigate = useNavigate();
  const { currentUser, checkRole } = React.useContext(AuthContext);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - alumnos?.length) : 0;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableBody>
          {alumnos?.map((row) => (
            <TableRow key={row.nombre}>
              <TableCell
                style={{ width: 80 }}
                align="right"
                component="th"
                scope="row"
              >
                <ListItemAvatar>
                  <Avatar
                    loading="lazy"
                    alt="Remy Sharp"
                    src={row.url ? row.url : "/static/images/avatar/1.jpg"}
                  />
                </ListItemAvatar>
              </TableCell>
              <TableCell>
                <ListItemText
                  primary={row.nombre}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline", cursor: "pointer" }}
                        component="span"
                        variant="caption"
                        color="text.primary"
                      >
                        Información:
                      </Typography>
                      <Typography
                        sx={{
                          display: "inline",
                          cursor: "pointer",
                          color: "green",
                          marginInline: "0.5rem",
                        }}
                        component="a"
                        variant="body2"
                        onClick={() => navigate("/historial/" + row.id)}
                      >
                        Historial
                      </Typography>
                      <ListItemText
                        secondary={
                          <React.Fragment>
                            {row?.grupo && (
                              <>
                                <Typography
                                  sx={{ display: "inline", cursor: "pointer" }}
                                  component="span"
                                  variant="caption"
                                  color="text.primary"
                                >
                                  Grupo:
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    cursor: "pointer",
                                    color: "green",
                                    marginInline: "0.5rem",
                                  }}
                                  component="a"
                                  variant="body2"
                                >
                                  {row?.grupo?.nombre}
                                </Typography>
                              </>
                            )}
                            {row?.subAreas[0] && (
                              <>
                                <Typography
                                  sx={{ display: "inline", cursor: "pointer" }}
                                  component="span"
                                  variant="caption"
                                  color="text.primary"
                                >
                                  Sub-Áreas:
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    cursor: "pointer",
                                    color: "green",
                                    marginInline: "0.5rem",
                                  }}
                                  component="a"
                                  variant="body2"
                                >
                                  {row?.subAreas?.map(
                                    (subAream) => subAream.nombre + ", "
                                  )}
                                </Typography>
                              </>
                            )}
                          </React.Fragment>
                        }
                      />
                    </React.Fragment>
                  }
                />
              </TableCell>
              {checkRole(["Administrador"]) && (
                <TableCell style={{ width: 160 }} align="right">
                  <div>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => navigate("/alumnos/editar/" + row.id)}
                      style={{ marginInline: "0.1rem" }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => navigate("/alumnos/eliminar/" + row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 88.52 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              labelRowsPerPage="Items por pagina"
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={3}
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
