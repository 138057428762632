import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/*Servicios*/
import S3Service from "../../../../services/S3.service";

const FooterItemBlog = ({ data }) => {
  const navegar = useNavigate();
  const [imagen, setImagen] = useState({});

  useEffect(() => {
    setImagen("");
    window.scrollTo(0, 0);
    getImage();
  }, []);

  const getImage = async () => {
    try {
      console.log("/*/*/*//" + data.imagen);
      S3Service.get(data.imagen).then((dataimagen) => {
        document.getElementById(`imagen${data.imagen}`).src = dataimagen.result;
        setImagen(dataimagen.result);
        console.log("imaaaagen*****" + dataimagen.result);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <li className="mb-4 pb-4">
      <div className="media" style={{ overflow: "hidden" }}>
        <button
          className="me-2"
          style={{ border: "none", backgroundColor: "unset" }}
          onClick={() => navegar(`/blog/${data.id}`)}
        >
          <img
            className="rounded-lg card-img-top border-primary border-2 d-block"
            data-src="assets/img/blog/blog-sm-img5.jpg"
            id={`imagen${data.imagen}`}
            src={imagen}
            alt="imagen..."
            style={{ height: "60px", width: "80px", objectFit: "cover" }}
          />
        </button>
        <div className="media-body">
          <h5 className="line-hight-16 mb-1">
            <button
              className="font-base font-size-14"
              style={{
                border: "none",
                backgroundColor: "unset",
                color: "#f0c24b",
                textAlign: "start",
                marginInlineStart: "-0.5rem",
                overflowWrap: "anywhere",
              }}
              onClick={() => navegar(`/blog/${data.id}`)}
            >
              {data.titulo}
            </button>
          </h5>
          <time className="text-white">
            {String(data.updateAt).slice(0, 10)}
          </time>
        </div>
      </div>
    </li>
  );
};

export default FooterItemBlog;
