import styles from "../../layout/assets/css/core.min.module.css";

import { Box, Button, TextField, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState } from "react";

const SectionLenguaje = ({ lenguaje, handleLenguaje }) => {
  const handleMastica = (event, value) => {
    handleLenguaje({
      ...lenguaje,
      mastica: value,
    });
  };

  const handleRespiracion = (event, value) => {
    handleLenguaje({
      ...lenguaje,
      respiracion: value,
    });
  };

  const handleRonca = (event, value) => {
    handleLenguaje({
      ...lenguaje,
      ronca: value,
    });
  };

  const handleDormir = (event, value) => {
    handleLenguaje({
      ...lenguaje,
      problemasDormir: value,
    });
  };

  return (
    <div className={`${styles.row} ${styles.colSmm12}`}>
      <div className={`${styles.col}`}>
        <div
          className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
        >
          Desarrollo del lenguaje{" "}
        </div>
        <div className={`${styles.section} ${styles.mb4}`}>
          <div className={`${styles.cardBody} ${styles.row} ${styles["m-2"]}`}>
            <div className={`${styles["my-2"]} ${styles["col-md-6"]}`}>
              <Typography>Edad en la que balbuceó:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleLenguaje({
                    ...lenguaje,
                    edadBalbuceo: e.target.value,
                  })
                }
                value={lenguaje?.edadBalbuceo}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["col-md-6"]}`}>
              <Typography>Edad en la que dijo la primera palabra:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleLenguaje({
                    ...lenguaje,
                    edadPrimerPalabra: e.target.value,
                  })
                }
                value={lenguaje?.edadPrimerPalabra}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["col-md-6"]}`}>
              <Typography>Edad en la que dijo su primera oración:</Typography>
              <TextField
                sx={{ marginY: 2 }}
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleLenguaje({
                    ...lenguaje,
                    edadPrimerOracion: e.target.value,
                  })
                }
                value={lenguaje?.edadPrimerOracion}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["col-md-6"]}`}>
              <Typography>Mastica</Typography>
              <ToggleButtonGroup
                value={lenguaje?.mastica ? lenguaje.mastica : ""}
                exclusive
                aria-label="Platform"
                onChange={handleMastica}
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["col-md-12"]}`}>
              <Typography>Actualmente como es su alimentación:</Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleLenguaje({
                    ...lenguaje,
                    alimentacion: e.target.value,
                  })
                }
                value={lenguaje?.alimentacion}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["col-md-6"]}`}>
              <Typography>Cuantas veces come al día:</Typography>
              <TextField
                sx={{ marginY: 2 }}
                fullWidth
                variant="standard"
                placeholder="..."
                onChange={(e) =>
                  handleLenguaje({
                    ...lenguaje,
                    comidasDia: e.target.value,
                  })
                }
                value={lenguaje?.comidasDia}
              />
            </div>
            <div className={`${styles["my-2"]} ${styles["col-md-6"]}`}>
              <Typography>Su respiración es por:</Typography>
              <ToggleButtonGroup
                value={lenguaje?.respiracion ? lenguaje.respiracion : ""}
                exclusive
                aria-label="Platform"
                onChange={handleRespiracion}
              >
                <ToggleButton value="Boca">Boca</ToggleButton>
                <ToggleButton value="Nariz">Nariz</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["col-md-6"]}`}>
              <Typography>Ronca:</Typography>
              <ToggleButtonGroup
                value={lenguaje?.ronca ? lenguaje.ronca : ""}
                exclusive
                aria-label="Platform"
                onChange={handleRonca}
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={`${styles["my-2"]} ${styles["col-md-6"]}`}>
              <Typography>Problemas para dormir:</Typography>
              <ToggleButtonGroup
                value={
                  lenguaje?.problemasDormir ? lenguaje.problemasDormir : ""
                }
                exclusive
                aria-label="Platform"
                onChange={handleDormir}
              >
                <ToggleButton value="Si">Si</ToggleButton>
                <ToggleButton value="No">No</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionLenguaje;
